import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'radio',
    template: `
      <div [formGroup]="form">
        <div class="form-check" *ngFor="let opt of field.options">
          <input class="form-check-input" type="radio"
            [name]="field.name"
            [value]="opt.id"
            [required]="field.required"
            [checked]="field.value === opt.id"
            (change)="setValue(opt.id)"
            >
          <label class="form-check-label">
            {{opt.text}}
          </label>
        </div>
      </div>
    `
})
export class RadioComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;


    get isValid() {
      if (this.form.controls[this.field.name]) {
        return this.form.controls[this.field.name].valid;
      }
    }
    get isDirty() {
      if (this.form.controls[this.field.name]) {
        return this.form.controls[this.field.name].dirty;
      }
    }

    setValue(value) {
      console.log(value);
      return this.form.controls[this.field.name].setValue(value);
    }

    get isVoid() {
      if (this.form.controls[this.field.name]) {
        return !this.form.controls[this.field.name].value;
      }
    }
}
