import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import * as L from "leaflet";
import { GlobalSeguridad } from "@seguridad";

@Injectable({
  providedIn: "root",
})
export class ServiciosService {
  capas: any;

  constructor(private http: HttpClient, private seguridadGlobal: GlobalSeguridad) { }

  /**
   * Obtiene las capas asociadas a una url
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  DescubrirServidor(url: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    const urlEncode = encodeURIComponent(url);

    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).descubrirServidor + urlEncode, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene las estadísticas de los servicios
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetEstadisticasServicios(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).estadisticasServicios, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene las categorías de las capas
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetCategorias(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).categorias, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene las capas, según un filtro dado
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetCapasFiltradas(find: string): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).capasFiltradas + find, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene los servicios
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetServicios(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).servicios, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  

  /**
   * Hace un llamado al componente capas para mostrar la lista
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  LlenarCapas(capasFiltradas, mensaje) {
    this.capas.LlenarListaCapas(capasFiltradas, mensaje);
  }

  /**
   * Permite hacer una petición http dada una url y un tipo de respuesta esperado
   * @param url url del servicio que se va a consultar
   * @param respuestaEsperada tipo de respuesta que se espera de dicha consulta
   * @returns un observable con la respuesta del servicio
   */
  query(url: string, respuestaEsperada: any): Observable<any> {
    const headersT = new HttpHeaders({ "Proxy": "true", 'Content-Types': this.seguridadGlobal.atributoSesion });
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + url, {
      headers: headersT,
      responseType: respuestaEsperada,
    });
  }

  /**
   * Permite crear una uri que se usa para hacer una petición http
   * @param layer nombre de la capa
   * @param bbox Cuadro delimitador para la extensión del mapa
   * @returns la uri que se puede usar para hacer una petición http
   */
  getParamGeoserver(layer: string, bbox: string) {
    return L.Util.getParamString({
      request: "GetMap",
      service: "WMS",
      version: "2.0.0",
      layers: layer,
      srs: "EPSG:4326",
      bbox: bbox,
      width: "300",
      height: "200",
      format: "image/png",
    });
  }

  /**
   * Permite crear una uri que se usa para hacer una petición http
   * @param layer nombre de la capa
   * @param bbox Cuadro delimitador para la extensión del mapa
   * @returns la uri que se puede usar para hacer una petición http
   */
  getParamArcgis(layer: string, bbox: string, spatialReference: string) {
    return L.Util.getParamString({
      bbox: bbox,
      format: "png32",
      width: "300",
      height: "200",
      dpi: "96",
      transparent: "true",
      layers: "show:" + layer,
      bboxSR: spatialReference,
      imageSR: spatialReference,
      size: "300,200",
      f: "image"
    });
  }

  /**
   * Asocia la capa seleccionada por el usuario a la base de datos.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  AsociarCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).asociarCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Disocia la capa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  DisociarCapa(code) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.delete(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).disociarCapa + code, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Edita la capa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  EditarCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.put(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).editarCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Edita la infocapa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  EditarInfoCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.put(JSON.parse(sessionStorage.getItem('webConfig')).editarInfoCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Obtiene los dominios para poblar los select del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   * @param find dominio a buscar
   */
  ObtenerDominiosCapas(find: string) {
    // return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).descubrirServidor + urlEncode, { 
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) 
    // });
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).obtenerDominiosCapas + find, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
 * Obtiene los dominios para poblar los select del grid
 * @author Elisabeth Campuzano Jaramillo
 * @date 13/02/2022
 * @param find dominio a buscar
 */
  ObtenerDominiosCapasObj(find: string) {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).obtenerDominiosCapasObj + find, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene todas las capas almacenadas en base de datos
   * @author Lukas Mesa Buriticá
   * @date 20/02/2022
   */
  GetAllLayers(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).obtenerTodas, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

  /**
   * Obtiene los campos de una capa
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns
   */
  ObtenerCampos(url: string, urlCapa: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "GET");
    headersT.append("Access-Control-Allow-Origin", "*");

    const urlEncode = encodeURIComponent(urlCapa);
    const urlCompleta = JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).urlOuterLayer + url + urlEncode;
    return this.http.get(urlCompleta, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }


  /**
   * Se encarga de actualizar el cache del visor sobre los cambios realizados en gestión de capas
   * @author Nicolas Cardona
   * @date 2024-02-14
   * @param url 
   * @returns 
   */
  ActualizarCacheVisor(idVisor: string){
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "GET");
    headersT.append("Access-Control-Allow-Origin", "*");

    const urlCompleta = JSON.parse(sessionStorage.getItem('webConfig')).urlMap + "loadCache/" + idVisor;
    return this.http.get(urlCompleta, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion })
    });
  }

}
