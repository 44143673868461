import { Injectable } from "@angular/core";

interface ModeloDocxTemplater {
  [key: string]: {
    nom_llave: string;
    tipo: string;
    indice: number | null;
    nom_funcio: string[];
  };
}

@Injectable({
  providedIn: "root",
})
export class UtilidadesTemplateGeneratorService {
  constructor() {}

  replaceNullUndefinedWithEmpty(obj) {
    if (obj === null || typeof obj === "undefined") {
      return "";
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.replaceNullUndefinedWithEmpty(obj[key]);
        }
      }
    }
    return obj;
  }

  encontrarCamposJson(campoJson) {
    if (typeof campoJson === "undefined" || campoJson === null) {
      return "";
    }

    if (campoJson === "" || campoJson.length === 0) {
      return campoJson;
    }

    return this.replaceNullUndefinedWithEmpty(campoJson);
  }

  asignarXSiNo(campoJson, campo) {
    const campoJsonLower = campoJson.toString().toLowerCase();
    if (campo === "si" && campoJsonLower === "si") {
      return "X";
    } else if (campo === "no" && campoJsonLower === "no") {
      return "X";
    } else if (
      campo === "na" &&
      campoJsonLower !== "" &&
      campoJsonLower !== "si" &&
      campoJsonLower !== "no"
    ) {
      return "X";
    } else {
      return "";
    }
  }

  asignarXAguaSueloAlcantarillado(campoJson, campo) {
    const campoJsonLower = campoJson.toString().toLowerCase();
    if (campo === "cuerpo de agua" && campoJsonLower === "agua") {
      return "X";
    } else if (campo === "suelo" && campoJsonLower === "suelo") {
      return "X";
    } else if (
      campo === "alcantarillado" &&
      campoJsonLower !== "" &&
      campoJsonLower !== "agua" &&
      campoJsonLower !== "suelo"
    ) {
      return "X";
    } else {
      return "";
    }
  }

  asignarXSiCuadradaRectangularCircular(campoJson, campo) {
    const campoJsonLower = campoJson.toString().toLowerCase();
    if (campo === "cuadrada" && campoJsonLower === "cuadrada") {
      return "X";
    } else if (campo === "rectangular" && campoJsonLower === "rectangular") {
      return "X";
    } else if (campo === "circular" && campoJsonLower === "circular") {
      return "X";
    } else {
      return "";
    }
  }

  obtenerValorAnidado(data: any, llave: string): any {
    const partes = llave.split(".");
    let valor = data;

    for (const parte of partes) {
      // Verificar si el valor es un arreglo y no está vacío
      if (Array.isArray(valor) && valor.length > 0) {
        // Mapear cada elemento del arreglo utilizando recursivamente esta función
        valor = valor.map((item: any) => this.obtenerValorAnidado(item, parte));
      } else {
        valor = valor[parte];
      }

      if (valor === undefined || valor === null) {
        // Si en algún punto la clave no existe, devolvemos undefined
        return undefined;
      }
    }

    return valor;
  }

  // Función para construir un objeto según el modelo
  construirObjetoDesdeModelo(modelo: ModeloDocxTemplater, data) {
    const obj = {};

    for (const [nombreLlave, config] of Object.entries(modelo)) {
      if (config.tipo === "simple") {
        const llaveCompleta = config.nom_llave || nombreLlave;
        obj[nombreLlave] = config.nom_funcio
          ? this.ejecutarFuncionesSecuencialmente(
              config.nom_funcio,
              this.obtenerValorAnidado(data[0], llaveCompleta)
            )
          : this.obtenerValorAnidado(data[0], llaveCompleta);
      } else if (
        config.tipo === "tabla" &&
        config.indice !== null &&
        Array.isArray(data[config.indice])
      ) {
        const llaveCompleta = config.nom_llave || nombreLlave;
        obj[nombreLlave] = this.mapearTabla(config, data[config.indice]);
      }
    }

    return obj;
  }

  // función para ejecutar el mapeo de tablas
  mapearTabla(config: any, tablaData: any[]) {
    if (!config || !tablaData || config.nom_campo === undefined) {
      // Manejar la falta de configuración aquí, por ejemplo, lanzar un error, devolver un valor predeterminado, etc.
      return [];
    }

    // Verificar si tablaData es un array
    if (Array.isArray(tablaData) && tablaData.length > 0) {
      const firstItem = tablaData[0];

      // Verificar si el primer elemento es un objeto
      if (typeof firstItem === "object" && !Array.isArray(firstItem)) {
        const mappedTable: any[] = [];

        for (const item of tablaData) {
          const mappedItem: any = {};

          // Iterar sobre la configuración de campo
          for (const campo of config.nom_campo) {
            // Si es un objeto, significa que hay una estructura anidada
            if (typeof campo === "object") {
              const nombreLlave = Object.keys(campo)[0];
              const subConfig = campo[nombreLlave];

              // Verificar si la estructura interna existe en los datos
              if (nombreLlave in item) {
                // Utilizar la función mapearTabla de manera recursiva para la estructura interna
                const valor = this.mapearTablaInterna(
                  subConfig,
                  item[nombreLlave]
                );
                mappedItem[nombreLlave] = valor.length > 0 ? valor : null;
              } else {
                // Si la estructura interna no existe, asignar null
                mappedItem[nombreLlave] = null;
              }
            } else {
              // Si es un campo normal, utilizar la función obtenerValorAnidado
              const valor = this.obtenerValorAnidado(item, campo);

              // Si el valor es un arreglo y no está vacío, mapear sus elementos
              if (Array.isArray(valor) && valor.length > 0) {
                const mappedArray = valor.map(
                  (arrayItem: any) =>
                    this.mapearTabla({ nom_campo: config.nom_campo }, [
                      arrayItem,
                    ])[0]
                );
                mappedItem[campo] = mappedArray;
              } else {
                // Asignar el valor al campo correspondiente en mappedItem
                mappedItem[campo] = valor;
              }
            }
          }

          mappedTable.push(mappedItem);
        }

        return mappedTable;
      }
    }

    return [];
  }

  // función para ejecutar el mapeo de tablas dentro de tablas
  mapearTablaInterna(config: any, tablaData: any[]) {
    if (!config.nom_campo || !Array.isArray(config.nom_campo)) {
      // Manejar la falta de configuración aquí, por ejemplo, lanzar un error, devolver un valor predeterminado, etc.
      return [];
    }

    // Verificar si tablaData es un array
    if (Array.isArray(tablaData) && tablaData.length > 0) {
      const firstItem = tablaData[0];

      // Verificar si el primer elemento es un objeto
      if (typeof firstItem === "object" && !Array.isArray(firstItem)) {
        const mappedTable: any[] = [];

        for (const item of tablaData) {
          const mappedItem: any = {};

          for (const nomCampo of config.nom_campo) {
            // Utilizar la función obtenerValorAnidado
            const valor = this.obtenerValorAnidado(item, nomCampo);

            // Si el valor es un arreglo y no está vacío, mapear sus elementos
            if (Array.isArray(valor) && valor.length > 0) {
              const mappedArray = valor.map(
                (arrayItem: any) => this.mapearTabla(config, [arrayItem])[0]
              );
              mappedItem[nomCampo] = mappedArray;
            } else {
              // Asignar el valor al campo correspondiente en mappedItem
              mappedItem[nomCampo] = valor;
            }
          }

          mappedTable.push(mappedItem);
        }

        return mappedTable;
      }
    }

    return [];
  }

  // Función para ejecutar funciones secuencialmente
  ejecutarFuncionesSecuencialmente(funciones, data) {
    let result = data;

    for (const nombreFuncion of funciones) {
      result = this.ejecutarFuncion(nombreFuncion, result);
    }

    return result;
  }

  // Función de ejemplo que simula la ejecución de una función específica
  ejecutarFuncion(nombreFuncion, data) {
    if (nombreFuncion === "encontrarCamposJson") {
      return this.encontrarCamposJson(data);
    } else if (nombreFuncion === "asignarXSiNo") {
      return this.asignarXSiNo(data, "si");
    }

    // Implementa lógica adicional según sea necesario

    return data;
  }
}
