import { Injectable } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComboComponent } from '@shared/components/popups/popup-combo/popup-combo.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  @Injectable({
    providedIn: 'root'
  })
export class BarraHerramientasService {

    urlSigmaConsulta: string;
    constructor(
      private http: HttpClient,
      private seguridadGlobal: GlobalSeguridad) {
      this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
    }
  
    servicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
      return this.http.post(
        JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
        {
          idObjetoPizarra: idObjetoPizarra,
          filtro: filtro,
          datosPeticion: datosPeticion
        },
        // httpOptions
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  
    servicioAlmacenarInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
      return this.http.post(
        JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/AlmacenarInformacionCache',
        {
          idObjetoPizarra: idObjetoPizarra,
          filtro: filtro,
          datosPeticion: datosPeticion
        },
        // httpOptions
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  
    servicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
      this.seguridadGlobal.validarSesion();
      return this.http.post(
        this.urlSigmaConsulta,
        {
          sistema: sistema,
          idFuncion: idFuncion,
          filtro: filtro,
          datosPeticion: datosPeticion
        },
        // httpOptions
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  
    servicioRealizarAccion(sistema, nombreObjeto, jsonData, accion, servicio): Observable<any> {
      this.seguridadGlobal.validarSesion();
      let url = JSON.parse(sessionStorage.getItem('webConfig'))[servicio.servicio] + servicio.url;
      if (servicio !== undefined) {
        return this.http.post(
          url,
          {
            sesion: sistema,
            nombreObjeto: nombreObjeto,
            jsonDatos: JSON.stringify(jsonData),
            accion: accion
          },
          // httpOptions
          { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
      }
    }
  
    subirArchivo(fd): Observable<any> {
      this.seguridadGlobal.validarSesion();
      return this.http.post(
        JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/SubirArchivosFtp",
        fd,
        { headers: new HttpHeaders({  "Content-Types": this.seguridadGlobal.atributoSesion }) }
      );
    }
}