import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/Rx";
import { GlobalSeguridad } from '../../../../global-seguridad';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class FwVisorWebAppBuilderService {
  visor=undefined;
  visores:any=[];
  papa: any;

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) { }



  AsignarModeloYFIltrosVisor(id, modelo) {
    if (this.visor != undefined) {
      this.visor.ObtenerFiltrosCapas(id, modelo);
    }
  }

  getServicioConsultarMapa(codigoMapa): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNMapa/ObtenerMapa",
      {
        sistema: sessionStorage.getItem("sistemaLogueado"),
        perfil: sessionStorage.getItem("perfilLogueado"),
        codigoMapa: codigoMapa
      },
      httpOptions
      // { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }


  getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      //httpOptions
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  /**
   * Envía datos a un punto funcional del fichero SigmaUtilidades del visor WAB
   * @author Lukas Mesa Buriticá.
   * @date 2019-12-03.
   * @param id identificador de la sigma consulta (opcional)
   * @param parametros objeto con la información que se envía al visor
   * @param funcion acción que se ejecutará sí el visor envía un mensaje de respuesta
   * @param objeto elemento al que pertenece el parámetro funcion
  */
  EnviarDatosVisor(id, parametros, funcion, objeto) {
    if (this.visor != undefined) {
      this.visor.ConsultarEnviarDatos(id, parametros, funcion, objeto);
    }
  }
}
