import { CargadorIframeComponent } from '@shared/components/contenedores/cargador-iframe/cargador-iframe.component';
import { FraConDwhKnowageComponent } from '@shared/components/reporteadores/fra-con-dwh-knowage/fra-con-dwh-knowage.component';
import { ReporteadorComponent } from '@shared/components/reporteadores/reporteador/reporteador.component';
import { CategoriasComponent } from 'app/ideam/gestion-categorias/categorias/categorias.component';
import { CapasComponent } from 'app/ideam/gestion-capas/capas/capas.component';
import { FormularioPruebaComponent } from '@shared/components/formulario-dinamico/formulario-prueba/formulario-prueba.component';

export const environment = {
  production: true,
  tramitesEnLinea: [
    // {
    //   path: "tramites-linea",
    //   loadChildren: () => import('app/producto/gam-mod-tra-tralin/gam-mod-tra-tralin.module').then(m => m.GamModTraTralinMModule)
    // },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
  ],
  corpocaldas: [
    {
      "path": 'pages',
      "loadChildren": () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      "path": "tramites",
      "loadChildren": () => import('app/producto/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      "path": "caudales_reportados",
      "loadChildren": () => import('app/producto/caudales-reportados/caudales.module').then(m => m.CaudalesModule)
    },
    {
      "path": "obras",
      "loadChildren": () => import('app/producto/obras/obras.module').then(m => m.ObrasModule)
    },
    {
      "path": "gestion-solicitantes",
      "loadChildren": () => import('app/producto/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },

    {
      "path": "administracion",
      "loadChildren": () => import('app/producto/tnc-administracion/tnc-administracion.module').then(m => m.TNCAdministracionModule)
    },
    {
      "path": "seguimiento-control",
      "loadChildren": () => import('app/producto/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    }, 
    {
      "path": "psa",
      "loadChildren": () => import('app/producto/TNC/tnc.module').then(m => m.TncModule)
    },
    {
      "path": "modifica-pro-reno",
      "loadChildren": () => import('app/producto/modifica-pro-reno/modifica-pro-reno.module').then(m => m.ModificaProRenoModule)
    },
    {
      "path": "gestion-funcionarios",
      "loadChildren": () => import('app/producto/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      "path": 'catalogo-herramientas-visita',
      "loadChildren": () => import('app/producto/tnc-catalogo-herramientas/tnc-catalogo-herramientas.module').then(m => m.TNCCatalogoHerramientasModule)
    },
    {
      "path": "estudios-previos",
      "loadChildren": () => import('app/producto/estudios-previos/estudios-previos.module').then(m => m.EstudiosPreviosModule)
    },
    {
      "path": "supervision",
      "loadChildren": () => import('app/producto/supervision/supervision.module').then(m => m.SupervisionModule)
    },
    {
      "path": "eventos",
      "loadChildren": () => import('app/producto/eventos/eventos.module').then(m => m.EventosModule)
    },
    {
      "path": "administracion-ta",
      "loadChildren": () => import('app/producto/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      "path": "actividades-especiales",
      "loadChildren": () => import('app/producto/actividades-especiales/actividades-especiales.module').then(m => m.ActividadesEspecialesModule)
    },
    {
      "path": "clonar-estudio-previo",
      "loadChildren": () => import('app/producto/clonar-estudio-previo/clonar-estudio-previo.module').then(m => m.ClonarEstudioPrevioModule)
    },
    {
      "path": "tasa-retributiva",
      "loadChildren": () => import('app/producto/tasa-retributiva/tasa-retributiva.module').then(m => m.TasaRetributivaModule)
    },
    {
      "path": "expedientes-externos",
      "loadChildren": () => import('app/producto/expedientes-externos/expedientes-externos.module').then(m => m.ExpedientesExternosModule)
    },
    {
      "path": "sancionatorio",
      "loadChildren": () => import('app/producto/sancionatorio/sancionatorio.module').then(m => m.SancionatorioModule)
    },
    {
      "path": "anular-actos-corpocaldas",
      "loadChildren": () => import('app/producto/anular-actos/anular-actos.module').then(m => m.AnularActosModule)
    },
    {
      "path": "inventario-productos",
      "loadChildren": () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      "path": 'visorArcgis',
      "loadChildren": () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      "path": "tua",
      "loadChildren": () => import('app/producto/tua/tua.module').then(m => m.TUAModule)
    },
    {
      "path": "tcafm",
      "loadChildren": () => import('app/producto/TCAFM/TCAFM.module').then(m => m.TCAFMModule)
    },
    {
      "path": "Prediccion",
      "loadChildren": () => import('app/producto/prediccion/prediccion.module').then(m => m.PrediccionModule)
    },
    {
      "path": "agua",
      "loadChildren": () => import('app/producto/gam-mod-tra-agua/gam-mod-tra-agua.module').then(m => m.GamModTraAguaMModule)
    },
    {
      "path": "administracion",
      "loadChildren": () => import('app/producto/tnc-administracion/tnc-administracion.module').then(m => m.TNCAdministracionModule)
    },

    {
      "path": "reporteador-reportes-tnc",
      "component": ReporteadorComponent
    },
    {
      "path": "catalogo-herramientas-visita",
      "loadChildren": () => import('app/producto/tnc-catalogo-herramientas/tnc-catalogo-herramientas.module').then(m => m.TNCCatalogoHerramientasModule)
    },
    {
      path: 'clasificacion-rh',
      loadChildren: () => import('app/producto/clasificacion-rh/clasificacion-rh.module').then(m => m.ClasificacionRhModule)
    },
    {
      path: "historia_clinica_caldas",
      loadChildren: () => import('app/producto/historia-clinica/historia-clinica.module').then(m => m.HistoriaClinicaModule)
    },
    {
      path: "pase-expedientes",
      loadChildren: () => import('app/pro-corpoboyaca/pase-expedientes/pase-expedientes.module').then(m => m.PaseExpedientesModule)
    },
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    },
    {
      path: "reglamentacion",
      loadChildren: () => import('app/producto/gam-mod-tra-reglam/gam-mod-tra-reglam.module').then(m => m.GamModTraReglamModule)
    },
    {
      path: "personas-upm",
      loadChildren: () => import('app/producto/gam-mod-tra-perupm/gam-mod-tra-perupm.module').then(m => m.GamModTraPerUpmModule)
    },
    {
      path: "caracterizacion-suelo",
      loadChildren: () => import('app/producto/gam-mod-tra-caract/gam-mod-tra-caract.module').then(m => m.GamModTraCaractModule)
    },
    {
      "path": "FDinamico",
      "component": FormularioPruebaComponent
    },
    {
      path : "modulo-dashboard",
      loadChildren: () => import('app/producto/gam-mod-dwh-dashbo/gam-mod-dwh-dashbo.module').then(m => m.GamModDwhDashboModule)
    }
  ],
  corpamag: [
    {
      path: "tramites_corpamag",
      loadChildren: () => import('app/pro-corpamag/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      path: "administracion-ta-corpamag",
      loadChildren: () => import('app/pro-corpamag/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "seguimiento-control-corpamag",
      loadChildren: () => import('app/pro-corpamag/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    },
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    }
  ],
  tnc: [
    {
      path: "administracion",
      loadChildren: () => import('app/producto/tnc-administracion/tnc-administracion.module').then(m => m.TNCAdministracionModule)
    },
    {
      path: "psa",
      loadChildren: () => import('app/producto/TNC/tnc.module').then(m => m.TncModule)
    },
    {
      "path": 'reporteador-reportes-tnc',
      "component": ReporteadorComponent
    },
    {
      path: 'catalogo-herramientas-visita',
      loadChildren: () => import('app/producto/tnc-catalogo-herramientas/tnc-catalogo-herramientas.module').then(m => m.TNCCatalogoHerramientasModule)
    },
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    }
  ],
  corpoboyaca: [
    {
      path: "tramites_corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/tramites/tramites-corpoboyaca.module').then(m => m.TramitesCorpoboyacaModule)
    },
    {
      path: "seguimiento-control-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    },
    {
      path: "administracion-ta-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "gestion-solicitantes-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },
    {
      path: "gestion-funcionarios-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      path: "estudios-previos-corpoboyaca",
      loadChildren: () => import('app/producto/estudios-previos/estudios-previos.module').then(m => m.EstudiosPreviosModule)
    },
    {
      path: "supervision-corpoboyaca",
      loadChildren: () => import('app/producto/supervision/supervision.module').then(m => m.SupervisionModule)
    },
    {
      path: "expedientes-externos-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/expedientes-externos/expedientes-externos.module').then(m => m.ExpedientesExternosModule)
    },
    {
      path: "sancionatorio-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/sancionatorio/sancionatorio.module').then(m => m.SancionatorioModule)
    },
    {
      path: "pase-expedientes",
      loadChildren: () => import('app/pro-corpoboyaca/pase-expedientes/pase-expedientes.module').then(m => m.PaseExpedientesModule)
    },
    {
      path: "otros-informes",
      loadChildren: () => import('app/pro-corpoboyaca/otros-informes/otros-informes.module').then(m => m.OtrosInformesModule)
    },
    {
      path: "decomisos-entregas",
      loadChildren: () => import('app/pro-corpoboyaca/decomisos-entregas/decomisos-entregas.module').then(m => m.DecomisosEntregasModule)
    },
    {
      path: "historia_clinica",
      loadChildren: () => import('app/pro-corpoboyaca/historia-clinica/historia-clinica.module').then(m => m.HistoriaClinicaModule)
    },
    {
      path: "salvoconductos",
      loadChildren: () => import('app/pro-corpoboyaca/salvoconductos/salvoconductos.module').then(m => m.SalvoconductosModule)
    },
    {
      path: "novedades",
      loadChildren: () => import('app/pro-corpoboyaca/novedades/novedades.module').then(m => m.NovedadesModule)
    },
    {
      path: "modifica-pro-reno-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/modifica-pro-reno/modifica-pro-reno.module').then(m => m.ModificaProRenoModule)
    },
    {
      path: "fuentes",
      loadChildren: () => import('app/pro-corpoboyaca/fuentes/fuentes.module').then(m => m.FuentesModule)
    },
    {
      path: "anular-actos",
      loadChildren: () => import('app/pro-corpoboyaca/anular-actos/anular-actos.module').then(m => m.AnularActosModule)
    },
    {
      path: "dominios-valores",
      loadChildren: () => import('app/pro-corpoboyaca/dominios-valores/dominios-valores.module').then(m => m.DominiosValoresModule)
    },
    {
      path: "tua",
      loadChildren: () => import('app/producto/tua/tua.module').then(m => m.TUAModule)
    },
    {
      path: "tcafm",
      loadChildren: () => import('app/producto/TCAFM/TCAFM.module').then(m => m.TCAFMModule)
    },
    {
      path: "Prediccion",
      loadChildren: () => import('app/producto/prediccion/prediccion.module').then(m => m.PrediccionModule)
    },
    {
      path: "caudales_reportados",
      loadChildren: () => import('app/producto/caudales-reportados/caudales.module').then(m => m.CaudalesModule)
    },
    {
      path: 'clasificacion-rh',
      loadChildren: () => import('app/producto/clasificacion-rh/clasificacion-rh.module').then(m => m.ClasificacionRhModule)
    },
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    }
  ],
  carder: [
    {
      path: "tramites-carder",
      loadChildren: () => import('app/pro-carder/tramites/tramites-carder.module').then(m => m.TramitesCarderModule)
    },

    {
      path: "seguimiento-control-carder",
      loadChildren: () => import('app/pro-carder/seguimiento-control/seguimiento-control-carder.module').then(m => m.SeguimientoControlCarderModule)
    },


    {
      path: "expedientes-externos-carder",
      loadChildren: () => import('app/pro-carder/expedientes-externos/expedientes-externos-carder.module').then(m => m.ExpedientesExternosCarderModule)
    },
    {
      path: "sancionatorio-carder",
      loadChildren: () => import('app/pro-carder/sancionatorio/sancionatorio-carder.module').then(m => m.SancionatorioCarderModule)
    },
    {
      path: "gestionestrategica",
      loadChildren: () => import('app/producto/gam-mod-dwh-gesest/gam-mod-dwh-gesest.module').then(m => m.GamModDwhGesestModule)
    },
    {
      path: "administracion-ta-carder",
      loadChildren: () => import('app/pro-carder/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "tasa-retributiva-carder",
      loadChildren: () => import('app/pro-carder/tasa-retributiva/tasa-retributiva.module').then(m => m.TasaRetributivaModule)
    },
    {
      path: "tua",
      loadChildren: () => import('app/producto/tua/tua.module').then(m => m.TUAModule)
    },
    {
      path: "tcafm",
      loadChildren: () => import('app/producto/TCAFM/TCAFM.module').then(m => m.TCAFMModule)
    },
    {
      path: "Prediccion",
      loadChildren: () => import('app/producto/prediccion/prediccion.module').then(m => m.PrediccionModule)
    },
    {
      path: "modifica-pro-reno",
      loadChildren: () => import('app/producto/modifica-pro-reno/modifica-pro-reno.module').then(m => m.ModificaProRenoModule)
    },
    {
      path: "caudales_reportados",
      loadChildren: () => import('app/producto/caudales-reportados/caudales.module').then(m => m.CaudalesModule)
    },
    {
      path: "gestion-solicitantes",
      loadChildren: () => import('app/producto/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },
    {
      path: "gestion-funcionarios",
      loadChildren: () => import('app/producto/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      path: "obras",
      loadChildren: () => import('app/producto/obras/obras.module').then(m => m.ObrasModule)
    },
    {
      path: "anular-actos",
      loadChildren: () => import('app/pro-corpoboyaca/anular-actos/anular-actos.module').then(m => m.AnularActosModule)
    },
    {
      path: "pase-expedientes",
      loadChildren: () => import('app/pro-corpoboyaca/pase-expedientes/pase-expedientes.module').then(m => m.PaseExpedientesModule)
    },
    {
      path: 'clasificacion-rh',
      loadChildren: () => import('app/producto/clasificacion-rh/clasificacion-rh.module').then(m => m.ClasificacionRhModule)
    },
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    }
  ],
  carBogota: [
    //Generales
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    }
  ], 
  todos: [
    {
      path: 'gestion-correos',
      loadChildren: () => import('app/producto/gam-mod-tra-gestcor/gam-mod-tra-gestcor.module').then(m => m.GamModTraGestCorModule)
    },
    {
      path: "gestionar-capas",
      loadChildren: () =>
        import("../../src/app/ideam/gestion-capas/gestionar-capas.module").then(
          (m) => m.GestionarCapasModule
        ),
    },
    {
      path: "capas",
      component: CapasComponent,
    },
    {
      path: "categorias",
      component: CategoriasComponent,
    },
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    //CORPOBOYACA
    {
      path: "tramites_corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/tramites/tramites-corpoboyaca.module').then(m => m.TramitesCorpoboyacaModule)
    },
    {
      path: "seguimiento-control-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    },
    {
      path: "administracion-ta-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "gestion-solicitantes-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },
    {
      path: "gestion-funcionarios-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      path: "estudios-previos-corpoboyaca",
      loadChildren: () => import('app/producto/estudios-previos/estudios-previos.module').then(m => m.EstudiosPreviosModule)
    },
    {
      path: "supervision-corpoboyaca",
      loadChildren: () => import('app/producto/supervision/supervision.module').then(m => m.SupervisionModule)
    },
    {
      path: "expedientes-externos-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/expedientes-externos/expedientes-externos.module').then(m => m.ExpedientesExternosModule)
    },
    {
      path: "sancionatorio-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/sancionatorio/sancionatorio.module').then(m => m.SancionatorioModule)
    },
    {
      path: "pase-expedientes",
      loadChildren: () => import('app/pro-corpoboyaca/pase-expedientes/pase-expedientes.module').then(m => m.PaseExpedientesModule)
    },
    {
      path: "otros-informes",
      loadChildren: () => import('app/pro-corpoboyaca/otros-informes/otros-informes.module').then(m => m.OtrosInformesModule)
    },
    {
      path: "decomisos-entregas",
      loadChildren: () => import('app/pro-corpoboyaca/decomisos-entregas/decomisos-entregas.module').then(m => m.DecomisosEntregasModule)
    },
    {
      path: "historia_clinica",
      loadChildren: () => import('app/pro-corpoboyaca/historia-clinica/historia-clinica.module').then(m => m.HistoriaClinicaModule)
    },
    {
      path: "salvoconductos",
      loadChildren: () => import('app/pro-corpoboyaca/salvoconductos/salvoconductos.module').then(m => m.SalvoconductosModule)
    },
    {
      path: "novedades",
      loadChildren: () => import('app/pro-corpoboyaca/novedades/novedades.module').then(m => m.NovedadesModule)
    },
    {
      path: "modifica-pro-reno-corpoboyaca",
      loadChildren: () => import('app/pro-corpoboyaca/modifica-pro-reno/modifica-pro-reno.module').then(m => m.ModificaProRenoModule)
    },
    {
      path: "fuentes",
      loadChildren: () => import('app/pro-corpoboyaca/fuentes/fuentes.module').then(m => m.FuentesModule)
    },
    {
      path: "anular-actos",
      loadChildren: () => import('app/pro-corpoboyaca/anular-actos/anular-actos.module').then(m => m.AnularActosModule)
    },
    {
      path: "dominios-valores",
      loadChildren: () => import('app/pro-corpoboyaca/dominios-valores/dominios-valores.module').then(m => m.DominiosValoresModule)
    },
    //CORPOCALDAS
    {
      path: "tramites",
      loadChildren: () => import('app/producto/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      path: "caudales_reportados",
      loadChildren: () => import('app/producto/caudales-reportados/caudales.module').then(m => m.CaudalesModule)
    },
    {
      path: "obras",
      loadChildren: () => import('app/producto/obras/obras.module').then(m => m.ObrasModule)
    },
    {
      path: "gestion-solicitantes",
      loadChildren: () => import('app/producto/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },

    {
      path: "administracion",
      loadChildren: () => import('app/producto/tnc-administracion/tnc-administracion.module').then(m => m.TNCAdministracionModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: "seguimiento-control",
      loadChildren: () => import('app/producto/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "psa",
      loadChildren: () => import('app/producto/TNC/tnc.module').then(m => m.TncModule)
    },
    {
      path: "modifica-pro-reno",
      loadChildren: () => import('app/producto/modifica-pro-reno/modifica-pro-reno.module').then(m => m.ModificaProRenoModule)
    },
    {
      path: "gestion-funcionarios",
      loadChildren: () => import('app/producto/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      path: 'reporteador-reportes-tnc',
      component: ReporteadorComponent
    },
    {
      path: 'catalogo-herramientas-visita',
      loadChildren: () => import('app/producto/tnc-catalogo-herramientas/tnc-catalogo-herramientas.module').then(m => m.TNCCatalogoHerramientasModule)
    },
    {
      path: "estudios-previos",
      loadChildren: () => import('app/producto/estudios-previos/estudios-previos.module').then(m => m.EstudiosPreviosModule)
    },
    {
      path: "supervision",
      loadChildren: () => import('app/producto/supervision/supervision.module').then(m => m.SupervisionModule)
    },
    {
      path: "eventos",
      loadChildren: () => import('app/producto/eventos/eventos.module').then(m => m.EventosModule)
    },
    {
      path: "administracion-ta",
      loadChildren: () => import('app/producto/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "actividades-especiales",
      loadChildren: () => import('app/producto/actividades-especiales/actividades-especiales.module').then(m => m.ActividadesEspecialesModule)
    },
    {
      path: "clonar-estudio-previo",
      loadChildren: () => import('app/producto/clonar-estudio-previo/clonar-estudio-previo.module').then(m => m.ClonarEstudioPrevioModule)
    },
    {
      path: "tasa-retributiva",
      loadChildren: () => import('app/producto/tasa-retributiva/tasa-retributiva.module').then(m => m.TasaRetributivaModule)
    },
    {
      path: "expedientes-externos",
      loadChildren: () => import('app/producto/expedientes-externos/expedientes-externos.module').then(m => m.ExpedientesExternosModule)
    },
    {
      path: "reportes",
      loadChildren: () => import('app/producto/reportes/reportes.module').then(m => m.ReportesModule)
    },
    {
      path: "sancionatorio",
      loadChildren: () => import('app/producto/sancionatorio/sancionatorio.module').then(m => m.SancionatorioModule)
    },
    {
      path: "anular-actos-corpocaldas",
      loadChildren: () => import('app/producto/anular-actos/anular-actos.module').then(m => m.AnularActosModule)
    },
    {
      path: "usuario-ARnD",
      loadChildren: () => import('app/producto/gam-mod-tra-usuarnd/gam-mod-tra-usuarnd.module').then(m => m.GamModTraUsuarndModule)
    },
    {
      path : "modulo-dashboard",
      loadChildren: () => import('app/producto/gam-mod-dwh-dashbo/gam-mod-dwh-dashbo.module').then(m => m.GamModDwhDashboModule)
    },
    //CARDER
    {
      path: "tramites-carder",
      loadChildren: () => import('app/pro-carder/tramites/tramites-carder.module').then(m => m.TramitesCarderModule)
    },

    {
      path: "seguimiento-control-carder",
      loadChildren: () => import('app/pro-carder/seguimiento-control/seguimiento-control-carder.module').then(m => m.SeguimientoControlCarderModule)
    },


    {
      path: "expedientes-externos-carder",
      loadChildren: () => import('app/pro-carder/expedientes-externos/expedientes-externos-carder.module').then(m => m.ExpedientesExternosCarderModule)
    },
    {
      path: "sancionatorio-carder",
      loadChildren: () => import('app/pro-carder/sancionatorio/sancionatorio-carder.module').then(m => m.SancionatorioCarderModule)
    },
    {
      path: "gestionestrategica",
      loadChildren: () => import('app/producto/gam-mod-dwh-gesest/gam-mod-dwh-gesest.module').then(m => m.GamModDwhGesestModule)
    },
    {
      path: "administracion-ta-carder",
      loadChildren: () => import('app/pro-carder/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "tasa-retributiva-carder",
      loadChildren: () => import('app/pro-carder/tasa-retributiva/tasa-retributiva.module').then(m => m.TasaRetributivaModule)
    },


    //EXTRAS
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: 'fra-con-dwh-knowage',
      component: FraConDwhKnowageComponent
    },
    {
      path: 'clasificacion-rh',
      loadChildren: () => import('app/producto/clasificacion-rh/clasificacion-rh.module').then(m => m.ClasificacionRhModule)
    },
    //CORPAMAG
    {
      path: "tramites_corpamag",
      loadChildren: () => import('app/pro-corpamag/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      path: "administracion-ta-corpamag",
      loadChildren: () => import('app/pro-corpamag/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "seguimiento-control-corpamag",
      loadChildren: () => import('app/pro-corpamag/seguimiento-control/seguimiento-control.module').then(m => m.SeguimientoControlModule)
    },
    //GENERALES
    {
      path: "tua",
      loadChildren: () => import('app/producto/tua/tua.module').then(m => m.TUAModule)
    },
    {
      path: "tcafm",
      loadChildren: () => import('app/producto/TCAFM/TCAFM.module').then(m => m.TCAFMModule)
    },
    {
      path: "Prediccion",
      loadChildren: () => import('app/producto/prediccion/prediccion.module').then(m => m.PrediccionModule)
    },
    {
      path: "reportes-dinamicos",
      loadChildren: () => import('app/producto/reportes-dinamicos/reportes-dinamicos.module').then(m => m.ReportesDinamicosModule)
    },
    {
      path: "reporteDinamico",
      loadChildren: () => import('app/producto/gam-mod-tra-herrep/gam-mod-tra-herrep.module').then(m => m.GamModTraHerrepModule)
    },
    {
      path: "agua",
      loadChildren: () => import('app/producto/gam-mod-tra-agua/gam-mod-tra-agua.module').then(m => m.GamModTraAguaMModule)
    },
  
    {
      path: "tramites-linea",
      loadChildren: () => import('app/producto/gam-mod-tra-tralin/gam-mod-tra-tralin.module').then(m => m.GamModTraTralinMModule)
    },
    {
      "path": "aire",
      loadChildren: () => import('app/producto/gam-mod-tra-aire/gam-mod-tra-aire.module').then(m => m.GamModTraAireModule)
    },
    {
      path: "reglamentacion",
      loadChildren: () => import('app/producto/gam-mod-tra-reglam/gam-mod-tra-reglam.module').then(m => m.GamModTraReglamModule)
    }, 
    {
      path: "gestion-residuos",
      loadChildren: () => import('app/producto/gam-mod-tra-residu/gam-mod-tra-residu.module').then(m => m.GamModTraResiduModule)
    },
    {
      path: "seguimiento-residuos",
      loadChildren: () => import('app/producto/gam-mod-tra-segui/gam-mod-tra-segui.module').then(m => m.GamModTraSeguiModule)
    },
    {
      path: "gestion-ruido",
      loadChildren: () => import('app/producto/gam-mod-ges-ruido/gam-mod-ges-ruido.module').then(m => m.GamModGesRuidoModule)
    },
    {
      path: "gestion-especies",
      loadChildren: () => import('app/producto/gam-mod-ges-especi/gam-mod-ges-especi.module').then(m => m.GamModGesEspeciesModule)
    },    
    {
      path: "ecosistema",
      loadChildren: () => import('app/producto/gam-mod-tra-abacos/gam-mod-tra-abacos.module').then(m => m.GamModTraAbacosModule)
    },
    {
      path: "personas-upm",
      loadChildren: () => import('app/producto/gam-mod-tra-perupm/gam-mod-tra-perupm.module').then(m => m.GamModTraPerUpmModule)
    },
    {
      path: "caracterizacion-suelo",
      loadChildren: () => import('app/producto/gam-mod-tra-caract/gam-mod-tra-caract.module').then(m => m.GamModTraCaractModule)
    },
    {
      path: "rellenos-sanitarios",
      loadChildren: () => import('app/producto/gam-mod-tra-rellen/gam-mod-tra-rellen.module').then(m => m.GamModTraRellenModule)
    },
    {
      path: "gestion-especies",
      loadChildren: () => import('app/producto/gam-mod-ges-especi/gam-mod-ges-especi.module').then(m => m.GamModGesEspeciesModule)
    }, 
    {
      path: "tramites-sucre",
      loadChildren: () => import('app/pro-carsucre/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      path: "administracion-ta-sucre",
      loadChildren: () => import('app/pro-carsucre/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "seguimiento-reglamentacion",
      loadChildren: () => import('app/producto/gam-mod-tra-segregl/gam-mod-tra-segregl.module').then(m => m.GamModTraSegReglModule)
    }, 
    {
      path: "decomisos-entregas",
      loadChildren: () => import('app/pro-carder/gam-mod-tra-decent/gam-mod-tra-decent.module').then(m => m.GamModTraDecEntModule)
    },
    {
      path: "pase-expedientes",
      loadChildren: () => import('app/pro-corpoboyaca/pase-expedientes/pase-expedientes.module').then(m => m.PaseExpedientesModule)
    },
    {
      path: "decomisos-entregas-cor",
      loadChildren: () => import('app/producto/decomisos-entregas/decomisos-entregas.module').then(m => m.DecomisosEntregasModule)
    },
    {
      path: 'admin-acitivades',
      loadChildren: () => import('app/producto/gam-mod-adm-act-tnc/gam-mod-adm-act-tnc.module').then(m => m.GamModAdmActTncModule)
    }
    ,
    {
      path: "usuario-vrd",
      loadChildren: () => import('app/producto/gam-mod-tra-usrurh/gam-mod-tra-usrurh.module').then(m => m.GamModTraUsrurhModule)
    },
    {
      path: "usuario-ARnD",
      loadChildren: () => import('app/producto/gam-mod-tra-usuarnd/gam-mod-tra-usuarnd.module').then(m => m.GamModTraUsuarndModule)
    },
    {
      path: "historia_clinica_caldas",
      loadChildren: () => import('app/producto/historia-clinica/historia-clinica.module').then(m => m.HistoriaClinicaModule)
    },
    {
      path: "historia_clinica",
      loadChildren: () => import('app/pro-corpoboyaca/historia-clinica/historia-clinica.module').then(m => m.HistoriaClinicaModule)
    }
    
  ],
  sucre: [
    {
      path: 'pages',
      loadChildren: () => import('app/pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
      path: "tramites-sucre",
      loadChildren: () => import('app/pro-carsucre/tramites/tramites.module').then(m => m.TramitesModule)
    },
    {
      path: "gestion-solicitantes",
      loadChildren: () => import('app/producto/gestion-solicitantes/gestion-solicitantes.module').then(m => m.GestionSolicitantesModule)
    },
    {
      path: "administracion",
      loadChildren: () => import('app/producto/tnc-administracion/tnc-administracion.module').then(m => m.TNCAdministracionModule)
    },
    {
      path: "definicion-componentes",
      loadChildren: () => import('app/shared/components/permisos/arbol-definicion-componentes/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "inventario-productos",
      loadChildren: () => import('app/shared/components/permisos/arbol-inventario-productos/arbol.module').then(m => m.ArbolModule)
    },
    {
      path: "moodle",
      component: CargadorIframeComponent
    },
    {
      path: 'visorArcgis',
      loadChildren: () => import('app/producto/VisorArcgis/patron-visor-wab.module').then(m => m.PatronVisorWAPModule)
    },
    {
      path: "gestion-funcionarios",
      loadChildren: () => import('app/producto/gestion-funcionarios/gestion-funcionarios.module').then(m => m.GestionFuncionariosModule)
    },
    {
      path: 'reporteador-reportes-tnc',
      component: ReporteadorComponent
    },
    //EXTRAS
    {
      path: 'visor',
      loadChildren: () => import("app/visor/visor.module").then((m) => m.VisorModule)
    },
    {
      path: "geoserver",
      component: CargadorIframeComponent
    },
    {
      path: "adminCartografica",
      loadChildren: () => import('app/producto/administracion-cartografica/admin-cartografica.module').then(m => m.AdminCartograficaModule)
    },
    {
      path: "configuracion",
      loadChildren: () => import('app/producto/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
    },
    {
      path: "owncloud",
      component: CargadorIframeComponent
    },
    {
      path: 'reporteador',
      component: ReporteadorComponent
    },
    {
      path: "administracion-ta-sucre",
      loadChildren: () => import('app/pro-carsucre/administracion-ta/administracion-ta.module').then(m => m.AdministracionTAModule)
    },
    {
      path: "reporteDinamico",
      loadChildren: () => import('app/producto/gam-mod-tra-herrep/gam-mod-tra-herrep.module').then(m => m.GamModTraHerrepModule)
    }
  ]
  
};