import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { NguCarousel, NguCarouselStore, NguCarouselConfig } from '@ngu/carousel';
import { HttpErrorResponse } from '@angular/common/http';
import { Mensaje } from "@mensaje";
import { ServicioCarruselV3Service } from './servicio-carrusel-v3.service';
import { ServicioModuloGeneralService } from '@shared/services/servicio-modulo-general.service';

@Component({
  selector: 'app-carrusel-imagenes-v3',
  templateUrl: './carrusel-imagenes-v3.component.html',
  styleUrls: ['./carrusel-imagenes-v3.component.scss']
})
export class CarruselImagenesV3Component implements OnInit, OnChanges {

  public configsCarrusel: NguCarouselConfig;
  public gradientes: any = [];
  rutaActual: String;
  nombreImagenActual: String;
  rutaImagenesActual: any = "";
  coleccionImagenes: any;
  coleccionRutasImagenes: any = [];
  archivoActual: File = null;
  usuario: String = "";
  tipoActividad: any = "";
  codigoActividad: any = "";
  jsonActividad: any = "";
  mensaje: Mensaje;
  imagenes: any;
  codigoImagenes: any;
  ruta: any;
  botonesVisibles: boolean = true;
  private carouselToken: string;
  metodo: any;

  @Input() configuracion: any = {};
  @Input() modeloActual: any = "";

  @ViewChild('carousel') carousel: NguCarousel<any>;
  // @Output() salida = new EventEmitter();

  tipoElemento: string;
  codigoElemento: string;
  tipoPeticion: any;

  constructor(private servicio: ServicioCarruselV3Service,
    private servicioCom: ServicioModuloGeneralService) { }

  ngOnInit() {
    this.servicioCom.hijo = this;
    this.mensaje = new Mensaje("", "", "");
    this.usuario = JSON.parse(sessionStorage.getItem('infoUsuario'));
    this.configsCarrusel = {
      grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
      slide: 3,
      // speed: 900,
      // interval: 4000,
      //animation: 'lazy',
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner',
    };

    this.validarVariables();
    this.imagenes = this.configuracion.urlFotos;
    this.tipoElemento = this.configuracion.nombreElementoActual;
    this.codigoImagenes = this.configuracion.codigoUrlFotos;
    this.codigoElemento = this.configuracion.codigoUrlFotos;
    this.tipoPeticion = this.configuracion.tipo;
    this.metodo = this.configuracion.metodo;
    this.configuracionInicial();
    console.log(this.configuracion);

  }

  ngOnChanges() {
    if (this.modeloActual || this.configuracion.resumen) {
      this.imagenes = this.configuracion.urlFotos;
      this.tipoElemento = this.configuracion.nombreElementoActual;
      this.codigoImagenes = this.configuracion.codigoUrlFotos;
      this.codigoElemento = this.configuracion.codigoUrlFotos;
      this.tipoPeticion = this.configuracion.tipo;
      this.ObtenerCodigoElemento();
      this.configuracionInicial();
    }
  }

  configuracionInicial() {
    this.coleccionRutasImagenes = [];
    if (this.imagenes != undefined) {
      this.ObtenerImagenes(this.imagenes);
    }
  }

  imagenSeleccionada(ruta) {
    this.rutaActual = ruta;
    let posicionesRuta = this.rutaActual.split("/");
    this.nombreImagenActual = posicionesRuta[posicionesRuta.length - 1];
  }

  clicBotonAdicionarImagen() {
    document.getElementById('fileImagen').click();
  }

  cambioArchivo(files: FileList, fileImagen) {
    let archivo = files.item(0);
    let extension = this.obtenerExtension(archivo.name);

    if (extension.toLowerCase() == "png" || extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg") {
      this.subirArchivo(archivo, extension.toLowerCase());
    }
    else {
      this.mensaje.retornarMensaje("error", "Formato de imagen incorrecto", "Los formatos permitidos son jpg, jpeg", "");
    }
    fileImagen.value = "";
  }



  /**
   * Llama al servicio de c# y sube el archivo a ftp
   * @author Danny Alejandro
   * @date 2019-02-26.
   * @param parametros
   * @returns
   */
   subirArchivo(archivo, extension) {

      if (archivo != null) {
        let fecha = new Date();
        let ano = fecha.getFullYear();
        let mes = fecha.getMonth() + 1;
        let nombre = archivo.name;

        const fd = new FormData();
        fd.append("sistema", "");
        fd.append("usuario", "");
        fd.append("id", this.configuracion.sigmaConsulta);
        fd.append("directorio", this.configuracion.codigoUrlFotos + "/");
        fd.append("nombreArchivo", nombre);
        fd.append("file", archivo);
        this.mensaje.retornarMensajeFuncion(
          "loading",
          "subiendo archivo",
          nombre,
          "",
          null,
          null,
          null
        );

        this.servicio.subirArchivo(fd).subscribe(
          (res) => {
            if (res["respuesta"] == "true") {
              this.configuracion.modelo["foto"] = res["datos"];
              this.GetServicioDatosEditarUrl();
              this.mensaje.cerrarMensajeCarga();
            } else {
              this.mensaje.retornarMensaje(
                "error",
                "Error",
                res["noticia"],
                ""
              );
            }
          },
          (err: HttpErrorResponse) => {
            this.mensaje.cerrarMensajeCarga();
            if (err.error instanceof Error) {
              this.mensaje.retornarMensaje(
                "error",
                "Error",
                "Ocurrio un error",
                ""
              );
              console.log("Client-side error occured.");
            } else {
              this.mensaje.retornarMensaje(
                "error",
                "Error",
                "Ocurrio un error",
                ""
              );
              console.log("Server-side error occured.");
            }
          }
        );
      }
    
  }

  GetServicioDatosEditarUrl() {
    let resultado;
    const json = JSON.stringify(this.configuracion.modelo);
    this.servicio.getServicioRealizarAccion("", this.configuracion.tipo, json, "", this.configuracion.accionAdicionar, this.configuracion.servicio, "").subscribe(data => {
      if (data.respuesta === "true") {
        resultado = "true";
        this.mensaje.cerrarMensajeCarga();
        this.mensaje.retornarMensaje("exito", "Operación exitosa", "Éxito al subir el archivo", "");
        this.servicioCom.fotos = JSON.parse(data.sql);
        this.servicioCom.recargarLista();
        this.ObtenerImagenes(JSON.parse(data.sql));
        this.servicioCom.PoblarImagenesModelo(JSON.parse(data.sql));
      }
      else {
        resultado = "false";
        this.mensaje.cerrarMensajeCarga();
        this.mensaje.retornarMensaje("error", "Error", "No fue posible subir el archivo, por favor conectacte al área de soporte.", "");
      }
    });
    return resultado;
  }


  adicionarImagen(archivo, extension) {
    if (this.modeloActual != undefined && this.codigoElemento != "") {
      this.mensaje.retornarMensajeFuncion("loading", "Adicionando imagen", "", "", null, null, null);
      let datosAEnviar = { extension: extension, codigo: this.codigoElemento, datosPeticion: "" }

      const fd = new FormData();
      fd.append("file", archivo);
      fd.append("sistema", "");
      fd.append("nombreObjeto", this.tipoElemento);
      fd.append("jsonDatos", JSON.stringify(datosAEnviar));
      fd.append("jsonDatosPeticion", "");
      fd.append("accion", this.configuracion.accionAdicionar);

      this.servicio.getServicioRealizarAccionImagen(fd, this.configuracion.servicio, this.metodo).subscribe(res => {
        if (res.respuesta == "true") {
          if (res.datos != undefined && res.datos != "") {
            this.mensaje.retornarMensaje("exito", "Correcto", "La imagen se ha adicionado correctamente", "");
            this.servicioCom.fotos = res.sql;
            this.servicioCom.recargarLista();
            this.ObtenerImagenes(res.sql);

          }
          else {
            this.mensaje.cerrarMensajeCarga();
            this.mensaje.retornarMensaje("error", "Error", "Ocurrió un error al adicionar la imagen, contactese con el administrador del sistema", "");
          }
        }
        else {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", res.error, "");
        }
      }),
        (err: HttpErrorResponse) => {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", err.message, "");
        };
    }
    else {
      this.mensaje.retornarMensaje("error", "Error", "Debe seleccionar un Elemento de la Lista", "");
    }
  }

  eliminarImagen() {
    try {
      if (this.rutaActual != "" && this.rutaActual != undefined) {
        let parametros = {
          contenedor: this,
          datos: {
            accion: this.configuracion.accionEliminar,
            modelo: this.configuracion.modelo
          }
        };
        //Muestra un modal para asegurar la accion que quiere realizar el usuario
        this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea Eliminar la Imagen?", "", "", this, "eliminarImagenConfirmacion", parametros);
      }
      else {
        this.mensaje.retornarMensaje("error", "Error", "Debe seleccionar una imagen para eliminar", "");
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  eliminarImagenConfirmacion(parametros) {

    parametros.contenedor.mensaje.retornarMensajeFuncion("loading", "Eliminando imagen", "", "", null, null, null);
    let extension = parametros.contenedor.obtenerExtension(parametros.contenedor.nombreImagenActual);
    let nombreImagen = parametros.contenedor.nombreImagenActual.replace("." + extension, "");
    parametros.contenedor.configuracion.modelo["rutaEliminar"] = parametros.contenedor.rutaActual;
    parametros.contenedor.configuracion.modelo["nombreEliminar"] = parametros.contenedor.nombreImagenActual;

 

 
    let datosPeticion = "";
    parametros.contenedor.servicio.getServicioRealizarAccion("", parametros.contenedor.configuracion.tipo, JSON.stringify(parametros.contenedor.configuracion.modelo), "", parametros.contenedor.configuracion.accionEliminar, parametros.contenedor.configuracion.servicio, parametros.contenedor.metodo).subscribe(res => {
      parametros.contenedor.mensaje.cerrarMensajeCarga();
      if (res.respuesta == "true") {

        parametros.contenedor.mensaje.retornarMensaje("exito", "Correcto", "La imagen se ha eliminado correctamente", "");
        parametros.contenedor.servicioCom.fotos = JSON.parse(res.sql);
        parametros.contenedor.servicioCom.recargarLista();

        if (res.sql != "") {
          parametros.contenedor.ObtenerImagenes(JSON.parse(res.sql));
        } else {
          parametros.contenedor.ObtenerImagenes(JSON.parse(res.sql));
        }
      }
      else {
        parametros.contenedor.mensaje.retornarMensaje("error", "Error", "Ocurrió un error al eliminar la imagen", "");
      }

    }),
      (err: HttpErrorResponse) => {
        parametros.contenedor.mensaje.cerrarMensajeCarga();
      };



  }

  ObtenerCodigoElemento() {
    this.codigoElemento = this.codigoImagenes;
  }

  ObtenerImagenes(arreglo) {
    this.coleccionRutasImagenes = [];
    for (let entry of arreglo) {
      let ruta = entry.imagen;
      //se almacenan todas las rutas de las imagenes a mostrar
      this.coleccionRutasImagenes.push(ruta);
    }
  }

  obtenerExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  initDataFn(key: NguCarouselStore) {
    this.carouselToken = key.token;
  }

  /**
  * @description Verifica 
  * @author Brayan Rios
  * @date
  * @param {type} parametro
  * @returns
  */
  validarVariables() {
    this.configuracion.titulo = this.configuracion.titulo == undefined ? 'Gestión Imagen' : this.configuracion.titulo;
    this.configuracion.nombreElementoActual = this.configuracion.nombreElementoActual == undefined ? '' : this.configuracion.nombreElementoActual;
    this.configuracion.consultar = this.configuracion.consultar == undefined ? false : this.configuracion.consultar;
    this.configuracion.tamanoTitulo = this.configuracion.tamanoTitulo == undefined ? 'col-md-6' : this.configuracion.tamanoTitulo;
    this.configuracion.tamanoBoton = this.configuracion.tamanoBoton == undefined ? 'col-md-6' : this.configuracion.tamanoBoton;
    this.configuracion.resumen = this.configuracion.resumen == undefined ? false : this.configuracion.resumen;
  }


}
