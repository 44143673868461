import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Grid } from "app/grid/codebase/grid";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServiciosService } from "../servicios.service";
import { Mensaje } from "../../../shared/components/mensajes/mensaje.modelo";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-popup-infocapa",
  templateUrl: "./popup-infocapa.component.html",
  styleUrls: ["./popup-infocapa.component.scss"],
})
export class PopupInfocapaComponent implements OnInit {
  gridInfocapa: Grid;
  modalRef: any;
  mensaje: Mensaje;
  row: any;
  idVisorPublico: string = "MAP_LEAFLET_VISOR_PUBLICO"
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild("infocapa", { static: true }) containerInfocapa: ElementRef;

  constructor(
    private modalService: NgbModal,
    private servicios: ServiciosService
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit(): void {
    let clase = this;
    this.gridInfocapa = new Grid(this.containerInfocapa.nativeElement, {
      css: "dhx_demo-grid",
      columns: [
        {
          minWidth: 125,
          id: "name",
          header: [{ text: "Nombre campo" }, { content: "inputFilter" }],
          align: "center",
          editable: false,
        },
        {
          minWidth: 125,
          id: "alias",
          header: [{ text: "Alias" }, { content: "inputFilter" }],
          align: "center",
          resizable: true,
        },
        {
          minWidth: 100,
          id: "visible",
          header: [{ text: "Visualizar" }, { content: "inputFilter" }],
          align: "center",
          type: "boolean",
        },
        {
          minWidth: 150,
          id: "anima",
          header: [{ text: "Campo (Animación)" }, { content: "inputFilter" }],
          align: "center",
          type: "boolean",
        },
        {
          minWidth: 150,
          id: "rollov",
          header: [{ text: "Campo (Rollover)" }, { content: "inputFilter" }],
          align: "center",
          type: "boolean",
        },
      ],
      adjust: false,
      autoWidth: true,
      editable: true,
    });

    this.gridInfocapa.events.on("afterEditEnd", function (value, row, column) {
      if (column.id == "anima") {
        if (value == true) {
          clase.gridInfocapa.data.forEach((data) => {
            data.anima = false;
          });
          row.anima = true;
        }
      }
      if (column.id == "rollov") {
        if (value == true) {
          clase.gridInfocapa.data.forEach((data) => {
            data.rollov = false;
          });
          row.rollov = true;
        }
      }
    });

    this.ObtenerCampos();
  }

  /**
   * Obtiene la información de los campos asociados a la capa actual
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  ObtenerCampos() {
    try {
      if (this.row.code) {
        this.ObtenerCamposGeoserver();
      } else {
        let array = [];
        JSON.parse(this.row.info).forEach((data) => {
          array.push({
            name: data.name,
            alias: data.alias,
            visible: false,
            anima: data.anima,
            rollov: data.rollov,
          });
        });
        this.gridInfocapa.data.parse(array);
      }
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al consultar el servicio, por favor validar",
        ""
      );
    }
  }

  /**
   * Obtiene la información de los campos asociados a la capa actual de Geoserver
   * @author Santiago Hernandez
   * @date 08/01/2022
   * @param
   * @returns
   */
  ObtenerCamposGeoserver() {
    try {
      this.servicios
        .ObtenerCampos("getGeoserverFields/", this.row.url)
        .subscribe((respuestaGeo) => {
          if (respuestaGeo.state == "success") {
            let array = [];
            const infoActual = JSON.parse(this.row.info);
            respuestaGeo.info.forEach((data) => {
              const actual = infoActual.find(
                (element) => element.Id === data.id
              );
              if (actual) {
                array.push({
                  name: data.id,
                  alias: actual.Alias,
                  visible: true,
                  anima: actual.Anima,
                  rollov: actual.Rollov,
                });
              } else {
                array.push({
                  name: data.id,
                  alias: data.alias,
                  visible: false,
                  anima: false,
                  rollov: false,
                });
              }
            });
            this.gridInfocapa.data.parse(array);
          } else {
            this.ObtenerCamposArgis();
          }
        });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al consultar el servicio, por favor validar",
        ""
      );
    }
  }

  /**
   * Obtiene la información de los campos asociados a la capa actual de Argis
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns
   */
  ObtenerCamposArgis() {
    try {
      this.servicios
        .ObtenerCampos("getArcgisFields/", this.row.url)
        .subscribe((respuestaArg) => {
          if (respuestaArg.state == "success") {
            let array = [];
            const infoActual = JSON.parse(this.row.info);
            respuestaArg.info.forEach((data) => {
              const actual = infoActual.find(
                (element) => element.Id === data.id
              );
              if (actual) {
                array.push({
                  name: data.id,
                  alias: actual.Alias,
                  visible: true,
                  anima: actual.Anima,
                  rollov: actual.Rollov,
                });
              } else {
                array.push({
                  name: data.id,
                  alias: data.alias,
                  visible: false,
                  anima: false,
                  rollov: false,
                });
              }
            });
            this.gridInfocapa.data.parse(array);
          } else {
            this.mensaje.retornarMensaje(
              "error",
              "Atención",
              "Error la capa no tiene información",
              ""
            );
          }
        });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al consultar el servicio, por favor validar",
        ""
      );
    }
  }

  /**
   * Se realiza la petición para editar el infocapa
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns
   */
  EditarInfoCapa() {
    let entrada = {
      cap_codigo: this.row.code,
      campos: JSON.stringify(this.ProcesarInfoCapa()),
    };

    try {
      this.servicios
        .EditarInfoCapa(JSON.stringify(entrada))
        .subscribe((respuesta) => {
          if (respuesta["state"] == "success") {
            this.mensaje.retornarMensaje(
              "success",
              "La información fue actualiza con Éxito",
              "",
              ""
            );
            this.servicios.ActualizarCacheVisor(this.idVisorPublico)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      (res) => {
                        if(res["state"]==="success"){
                          this.mensaje.retornarMensaje(
                            "exito",
                            "Operación Exitosa",
                            "Visor actualizado",
                            ""
                          );
                        }
                        else{
                          this.mensaje.retornarMensaje(
                            "error",
                            "Operación Incorrecta",
                            "Error al actualizar el visor",
                            ""
                          );
                          console.log(res)
                        }
                      
                      },
                      (error) => {
                        this.mensaje.retornarMensaje(
                          "error",
                          "Operación Incorrecta",
                          "Error al actualizar el visor",
                          ""
                        );
                        console.log(error)
                      }
                    );
          } else {
            this.mensaje.retornarMensaje(
              "error",
              "Atención",
              "Ocurrio un error editando la información",
              ""
            );
          }
        });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al editar la infocapa",
        ""
      );
    }
  }

  ngOnDestroy() {
    if (this.gridInfocapa) {
      this.gridInfocapa.destructor();
    }
  }

  CerrarModal() {
    this.modalService.dismissAll();
  }

  /**
   * Se transforma la información del infocapa para hacer la petición de edición
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns Array con los campos a visualizar
   */
  ProcesarInfoCapa() {
    let infoCapa = [];
    this.gridInfocapa.data.forEach((info) => {
      if (info.visible) {
        infoCapa.push({
          id: info.name,
          alias: info.alias,
          visible: info.visible,
          anima: info.anima,
          rollov: info.rollov,
        });
      }
    });
    return infoCapa;
  }
}
