<div class="card">
	
	<div class="card-body">
		<div class="card-header pb-0 col-md-12" style="text-align:center;">
			<h4 class="card-title">Carga Masiva</h4>
		</div>
		<br>
		<div class="form-group col-md-12 mb-2">
			<label for="combo">Plantillas</label>
			<ng-select bindLabel="text" bindValue="id" (change)="cargarRutaPlantilla($event)" [items]="arrPlantillas">
			</ng-select>

		</div>
		<div class="card-block col-md-12">
			<input type="file" id="fileInput" (change)="seleccionarArchivoOperacion(fileInput,$event)" accept=".xlsx"
				#fileInput>
			<div class="text-center" *ngIf="urlPlantilla">
				<a class="card-link info" (click)="fileInput.click()"> <em class="fa fa-upload"></em> Subir Archivo</a>
				<a class="card-link success" (click)="onUpload()" [hidden]="fileInput.value==''"> <em class="fa fa-check-square-o "></em> Enviar</a>
			</div>
		</div>
		<div class="card-footer text-center" *ngIf="urlPlantilla">
			<a class="card-link success" href="{{urlPlantilla}}" #enlaceDescarga> <em class="fa fa-download"></em>
				Descargar Plantilla
			</a>
		</div>
	</div>
</div>