<div class="form-group col-md-12 mb-1">
    <div class="m-2 float-right">
        <button type="button" id="button-close" (click)="cerrarPopup()" class=" btn btn-raised btn-danger mr-1">
            <em class="ft-x"></em>
        </button>
    </div>
</div>
<div class="row">
    <div class="form-group col-md-12 md-2">
        <app-carga-masiva [arrPlantillas]="arrPlantillas" (salida)="actualizarInformacion()">
        </app-carga-masiva>
    </div>
    <!-- <div class="form-group col-md-6 md-2">
        <div class="row">
            <div class="form-group col-md-12 md-2">
                <label></label>
                <input type="text" id="campo.id" class="form-control" name="campo.nombre" [(ngModel)]="modelo">
            </div>
            <div class="form-group col-md-12 md-2">
                <button type="button" class=" btn btn-raised btn-primary mr-1">
                    <em class="fa boton.icono" title="boton">Confirmar</em>
                </button>
            </div>
        </div>

    </div> -->


</div>
