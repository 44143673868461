import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  token: string;

  constructor() { }
  
  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(SessionId: string) {
    sessionStorage.setItem('SessionId', SessionId);
  }

  logout() {
    sessionStorage.removeItem('SessionId');
    sessionStorage.removeItem('arrayMensajes');
    sessionStorage.removeItem('infoUsuario');
    sessionStorage.removeItem('cargaBase');
    sessionStorage.removeItem('sistemaLogueado');
    sessionStorage.removeItem('perfilLogueado');
    sessionStorage.removeItem('perfilPermisos');
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    let llave = sessionStorage.getItem('SessionId');
    if (llave != null) {
      return true;
    } else {
      return false;
    }
  }
}
