import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NguCarousel, NguCarouselStore, NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-objetos-control-v2',
  templateUrl: './objetos-control-v2.component.html',
  styleUrls: ['./objetos-control-v2.component.scss']
})
export class ObjetosControlComponentV2 implements OnInit {

  public configsCarrusel: NguCarouselConfig;
  public gradientes: any = [];
  public iconos: any = [];
  @Input() coleccionObjetosControl: any = [];
  @Input() objetoControlActual: any = [];
  @Input() permiteOcultar: boolean = false;
  @Output() salida = new EventEmitter();

  ocultarCantidad: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.configsCarrusel = {
      grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
      slide: 1,
      // speed: 900,
      // interval: 4000,
      animation: 'lazy',
      point: {
        visible: false
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner',
    }
  }

  ngAfterViewChecked() {
    let objetos = $(".carousel-control-prev .carousel-control-next");
    objetos.removeAttr("bottom");
    $(".carousel-control-prev .carousel-control-next").removeAttr("display");
    $(".carousel-control-prev .carousel-control-next").removeAttr("width");
    $(".carousel-control-prev .carousel-control-next").css("backgroundColor", "#ccc6c6");
  }

  ngOnChanges() {
    if (this.coleccionObjetosControl != undefined) {
      if (this.coleccionObjetosControl.length > 0) {
        this.mostrarCantidad();
      }
    }
  }

  mostrarCantidad(){
    if (this.coleccionObjetosControl.length > 0) {
      for (let index = 0; index < this.coleccionObjetosControl.length; index++) {
        let element = this.coleccionObjetosControl[index];
        if (element.cantidad != undefined) {
          if (element.cantidad == 0 && this.permiteOcultar) {
            this.ocultarCantidad = true;
          } else {
            this.ocultarCantidad = false;
          }
        } else {
          this.ocultarCantidad = true;
        }
      }
    }
  }

}
