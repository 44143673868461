import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from '@seguridad';

@Injectable({
  providedIn: 'root'
})
export class ServicioCarruselV3Service {
  header: any = {};
  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) { this.header = { "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }; }

  getServicioEnviarCargaMasiva(fd: FormData): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioPizarra"] + "/FUNDatosCache/ConsultarInformacionCache", fd,
      //httpOptions
      { headers: new HttpHeaders(this.header) }
    );
  }

  getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      //httpOptions
      { headers: new HttpHeaders(this.header) }
    );
  }

  getServicioObtenerInformacionCache(sistema, idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioPizarra"] + "/FUNDatosCache/ConsultarInformacionCache",
      {
        sistema: sistema,
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion

      },
      //httpOptions
      { headers: new HttpHeaders(this.header) }
    );
  }

  getServicioRealizarAccion(sistema, nombreObjeto, jsonDatos, jsonDatosPeticion, accion, url, metodo): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      url,
      {
        sistema: sistema,
        nombreObjeto: nombreObjeto,
        jsonDatos: jsonDatos,
        jsonDatosPeticion: jsonDatosPeticion,
        accion: accion
      },
      //httpOptions
      { headers: new HttpHeaders(this.header) }
    );
  }

  getServicioRealizarAccionImagen(fd: FormData, url, metodo): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      url,
      fd,
      //httpOptions
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  subirArchivo(fd): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/SubirArchivosFtp",
      fd,
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }


  

}
