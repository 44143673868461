import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { ServicioPopupService } from '../servicio-popup.service';
import { Mensaje } from '@mensaje';
import { SettingsList } from '@shared/components/listas/settings-list';

@Component({
  selector: 'app-popup-combo-lista',
  templateUrl: './popup-combo-lista.component.html',
  styleUrls: ['./popup-combo-lista.component.scss']
})
export class PopupComboListaComponent implements OnInit {
  mensaje: Mensaje;
  @Input() data;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  configBotones: any;
  configCombos: any;
  configLista: any;
  elementoSeleccionado: any;
  bloqueBoton: boolean;
  modalRef: any;
  modelo: any;
  valor: any;
  objetos: any;
  objetosLista: any;
  clase: "PopupComboListaComponent";
  bloqueoLista: boolean;
  configuracionLista: any;
  rows: any;
  columns: any;
  hijo: any;
  filter: any;
  arregloDias: any[] = [];
  temp_configuracionColumnasPopup: any;

  filtroPag = { "filtro": "%%" };
  filtro = { "offset": "0", "filtro": "%%" };

  idsSigmaConsulta = {
    paginacionLista: "",
    listaInformacion: ""
  };

  constructor(
    private globalSeguridad: GlobalSeguridad,
    private servicioPopupService: ServicioPopupService
  ) { this.mensaje = new Mensaje("", "", ""); }

  ngOnInit() {
    //this.bloqueBoton = true;
    this.configCombos = this.data.configCombo.combos;
    this.configBotones = this.data.configCombo.botones;
    this.configLista = this.data.configCombo.lista;
    this.objetos = this.data.objetos;
    this.hijo = this.data.hijo
    this.rows = [];
    this.columns = this.configLista[0].configuracionColumnasPopup;
    this.operacion(this.configCombos);
    this.organizarValoresLista(this.configLista);

    if (this.objetos.selected[0].hre_json.hre_cansol != undefined && this.objetos.selected[0].hre_json.hre_cansol != "") {
      this.bloqueoLista = false;
    } else {
      this.bloqueoLista = true;
    }
  }



  /**
  * @description
  * @author
  * @date
  * @param {type} parametro
  * @returns
  */
  organizarValoresLista(configLista) {
    try {
      this.idsSigmaConsulta.paginacionLista = configLista[0].consultapag;
      this.idsSigmaConsulta.listaInformacion = configLista[0].consultainf;
      this.configuracionLista = configLista[0].configuracionLista;
      this.cantidadElementosLista();
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description esta función hace la administración si el combo que se carga
  * utilizara el servicio de sigmaConsulta o el servicio de cache
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {Array} arreglo
  * @returns
  */
  operacion(arreglo) {
    try {
      for (let i = 0; i < arreglo.length; i++) {
        this.valor = arreglo[i].multiple;
        if (arreglo[i].cache) {
          this.cargarCombo(arreglo[i].filtro, arreglo[i].idConsulta, i);
        } else {
          this.cargaCombosSig(arreglo[i].filtro, arreglo[i].idConsulta, i);
        }
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description realiza el llamado al servicio que obtiene los datos de cache  y guarda los resultado
  * en el itemds que carga el combo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {string, string, number}. filtro, idConsulta, index
  * @returns
  */
  cargarCombo(filtro, idConsulta, index) {
    try {

      this.servicioPopupService.servicioObtenerInformacionCache(idConsulta, filtro, "").subscribe(res => {
        let respuesta = res.datos;
        if (this.data.configCombo.nombre = "cambioEstado") {
          if (respuesta.DOM_FUN_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_FUN_2;
          } else if (respuesta.DOM_VEH_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_VEH_2;
          } else if (respuesta.DOM_ACT_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_ACT_2;
          }
        } else {
          this.configCombos[index].items = respuesta;
        }
      });
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description realiza la sigma consulta y el resultado lo guarda en el items que carga al combo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {string, string, number}. filtro, idConsulta, index
  * @returns
  */
  cargaCombosSig(filtro, idConsulta, index) {
    try {
      this.servicioPopupService.getServicioSigmaConsulta("", idConsulta, this.asignaFiltro(filtro), "").subscribe(res => {
        let respuesta = res.datos;
        this.configCombos[index].items = respuesta.datos;
      });
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }
  /**
    * @description esta función asigna el filtro de la consulta
    * @author Jhonatan David Mosquera
    * @date 17/08/2020
    * @param {Array} arreglo
    * @returns
    */
  asignaFiltro(filtro: any) {
    let llaves = Object.keys(filtro);
    for (let i = 0; i < llaves.length; i++) {
      if (filtro[llaves[i]] == "_operacionActual") {
        filtro[llaves[i]] = this.data.operacion;
      }
    }
    return JSON.stringify(filtro);
  }

  asignarFiltroLista(filtro ) {
    let llaves1 = Object.keys(filtro);
    // filtro['hojas'] = '_hoja';
    // filtro['operacion'] = '_operacionActual';
    for (let i = 0; i < llaves1.length; i++) {
      if (filtro[llaves1[i]] == "_operacionActual") {
        filtro[llaves1[i]] = this.hijo.componenteActual.servicioModulo.operacionActual;
      } if (filtro[llaves1[i]] == "_hoja") {
        filtro[llaves1[i]] = this.hijo.registro.selected[0].hre_codigo;
      }
    }
    return JSON.stringify(filtro);
  }

  /**
  * @description confirma la selección de la operación y la guarda en un json como modelo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {type} parametro
  * @returns
  */
  confirmarSeleccion(valor) {
    try {
      if(this.bloqueoLista){
        if(this.objetos != undefined){
        }else{
          this.mensaje.retornarMensaje("error", "Atención", "Por favor seleccione un elemento de la lista", "");
        }
      }
      let accion = valor.currentTarget.innerText;
      let arreglo = [];
      this.elementoSeleccionado = [];
      for (let i = 0; i < this.configCombos.length; i++) {
        if (this.configCombos[i].modelo != "") {
          let accionSeleccionada = "";
          for (let index = 0; index < this.configBotones.length; index++) {
            if (this.configBotones[index].nombre === accion) {
              accionSeleccionada = this.configBotones[index].accion;
              break;
            }
          }
          let modelo = { valor: this.configCombos[i].modelo, objetos: this.objetos.selected, accion: accionSeleccionada }
          this.elementoSeleccionado.push(modelo);
        } else {
          // this.mensaje.retornarMensaje("Falta un paso", "error", "Seleccione una valor", "");
          this.mensaje.retornarMensaje("error", "Atención", "Por favor seleccione un vehiculo", "");
        
        }
      }
      this.cerrarPopup();
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description Busca la paginación de la lista, si ese valor es real busca los datos
  * @author Brayan Rios
  * @date 12-05-2020
  * @param {type} parametro
  * @returns
  */
  cantidadElementosLista() {
    try {

      this.configuracionLista.loading = true;
      let idSigmaConsulta = this.idsSigmaConsulta.paginacionLista;
      let filtropag; //JSON.stringify(this.filtroPag);
      filtropag = this.configLista[0].filtropag;

      if (this.filter != undefined) {
        filtropag["filtro"] = `%${this.filter}%`;
      }
      let filtro = this.asignarFiltroLista(filtropag);//JSON.stringify(filtro);
      this.servicioPopupService.servicioSigmaConsultaFiltro("", idSigmaConsulta, filtro, "")
        .subscribe(res => {
          if (res.respuesta == "true") {
            let respuesta = res.datos.datos;
            this.configuracionLista.totalElements = respuesta[0].total;
            this.configuracionLista = { ...this.configuracionLista };

            this.obtenerInformacionLista({ offset: 0 });
          }
        });
    } catch (error) {

    }
  }

  /**
  * @description Consulta los documentos asociados al recurso seleccionado
  * @author Brayan Rios
  * @date 05/05/2020
  * @param {type} pageInfo
  * @returns
  */
  obtenerInformacionLista(pageInfo) {
    try {
      this.configuracionLista.pageNumber = pageInfo.offset;
      const offset = this.configuracionLista.pageNumber;
      let idSigmaConsulta = this.idsSigmaConsulta.listaInformacion;
      let filtroinf;// JSON.stringify(this.filtro);
      filtroinf = this.configLista[0].filtroinf;
      filtroinf["offset"] = String(offset);
      if (this.filter != undefined) {
        filtroinf["filtro"] = `%${this.filter}%`;
      }
      //filtro["operacion"] = "";//this.servicioModulo.operacionActual;
      let filtro = this.asignarFiltroLista(filtroinf); //JSON.stringify(filtro);
      this.servicioPopupService.servicioSigmaConsultaFiltro("", idSigmaConsulta, filtro, "").subscribe(res => {
        if (res.respuesta == "true") {
          let respuesta = res.datos.datos;
          let coleccionDatosLista = [];

          for (let i in respuesta) {
            /*  if (respuesta[i].resumen) {
            respuesta[i].resumen = respuesta[i].resumen.replace(/@%@/g, "<br>");
            }*/
            coleccionDatosLista.push(respuesta[i]);
          }
          this.rows = coleccionDatosLista;

        } else {
          this.rows = [];
        }
        this.configuracionLista.loading = false;
      });
    } catch (error) {

    }
  }

  /**
  * @description cuando ingresen datos en el cajetin vuelve a realizar las consultas respectivas
  * @author 
  * @date
  * @param {type} filter
  * @returns
  */
  Filter(filter) {
    this.filter = filter;
    this.cantidadElementosLista();
  }

  ObjetoListaSeleccionado(registro) {
    // let registro = objeto.selected;
    this.objetos = registro;
  }

  /**
  * @description cierra el pop-up y retorna el objeto seleccionado
  *  al cual se le quiere realizar la operación
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {type} parametro
  * @returns
  */
  cerrarPopup() {
    this.configCombos = "";
    this.configBotones = "";
    this.modalRef.close(this.elementoSeleccionado);
  }


}
