import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { FwWebConfigService } from './fw-webconfig.service';
import { GlobalSeguridad } from "./global-seguridad";
import webConfigjson from 'app/webConfig.json';
import { ShaSerMensajesService } from '@shared/services/sha-ser-mensajes.service';
import { NotificationService } from '@shared/notification-sidebar/notification.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [FwWebConfigService]
})
export class AppComponent implements OnInit, OnDestroy {

    json: {} = {};
    subscription: Subscription;
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
        //console.log("Processing beforeunload...");
        // Do more processing...
        //event.returnValue = false;
        sessionStorage.setItem('a', this.seguridadGlobal.atributoInicial);
        sessionStorage.setItem('as', this.seguridadGlobal.atributoSesion);
        sessionStorage.setItem('sesionActivada', this.seguridadGlobal.activada);
    }


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serviceWebConfig: FwWebConfigService,
        private seguridadGlobal: GlobalSeguridad,
        private authService: AuthService,
        private servicioMensajes:ShaSerMensajesService,
        private notification_service: NotificationService
    ) {
        if (!environment.production) {
            this.WebConfigLocal();
        } else {
            this.WebConfigProduccion();
        }

    }

    ngOnInit() {
        this.almacenarToken();
        
        if (this.authService.isAuthenticated()) {
            this.notification_service.startConnection();
            this.servicioMensajes.CargarMensajes(this,"cargarComponente");
        }
    }
    cargarComponente() {
        var wrapper = JSON.parse(sessionStorage.getItem('cargaBase'))["wrapper"];
        sessionStorage.setItem('wrapper', this.router.url);
        let webConfig = JSON.parse(sessionStorage.getItem('webConfig'));
        let tiempo = webConfig["tiempoSesion"];
        if (tiempo == undefined) {
            tiempo = 900000;
        }
        this.seguridadGlobal.activarSesion(tiempo);
        if (wrapper === sessionStorage.getItem('cargaBase')) {
            
            this.router.navigate([wrapper], {
                relativeTo: this.route.root
            });    
            
        } else{
            this.router.navigate([sessionStorage.getItem('wrapper')], {
                relativeTo: this.route.root
            });    
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    almacenarToken() {
        let a = sessionStorage.getItem('a');
        let as = sessionStorage.getItem('as');
        let sesionActivada = sessionStorage.getItem('sesionActivada');
        if (a != "" && as != "") {
            this.seguridadGlobal.atributoInicial = a;
            this.seguridadGlobal.atributoSesion = as;
            this.seguridadGlobal.activada = sesionActivada;
            this.seguridadGlobal.apiKey = "AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo";
            sessionStorage.removeItem("a");
            sessionStorage.removeItem("as");
            sessionStorage.removeItem("sesionActivada");
        }
    }

    WebConfigLocal() {
        sessionStorage.setItem('webConfig', JSON.stringify(webConfigjson));
        let webConfig = JSON.parse(sessionStorage.getItem('webConfig'));
        let tiempo = webConfig["tiempoSesion"];
        if (tiempo == undefined) {
            tiempo = 900000;
        }
        setInterval(() => { this.seguridadGlobal.validarSesion(); }, tiempo);
    
    }


    WebConfigProduccion() {
        this.serviceWebConfig.getWebConfig().subscribe(webconfig => {
            webconfig = JSON.stringify(webconfig);
            sessionStorage.setItem('webConfig', webconfig);
            let webConfig = JSON.parse(sessionStorage.getItem('webConfig'));
            let tiempo = webConfig["tiempoSesion"];
            if (tiempo == undefined) {
                tiempo = 900000;
            }
            setInterval(() => { this.seguridadGlobal.validarSesion(); }, tiempo);
        });
    }





}