import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { resetStoreReducer } from './visor/store';
import { AppStateReducers } from '@shared/store/mapaReductores';
import { environment } from 'environments/environment';
import { AppEffects } from '@shared/store/app-effects-store';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        console.log('state', state);
        console.log('action', action);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [resetStoreReducer];

export const CONFIG_GNRX = [
    StoreModule.forRoot(
        AppStateReducers,
        {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: true,
                strictStateSerializability: true,
            },
        }
    ),
    StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production
    }),
    EffectsModule.forRoot([AppEffects]),
];
