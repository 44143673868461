import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { ConfigIdiomaGrid } from './ConfigIdiomaTabla';


import { IDataGridConf } from './Interfaces/IDataGridConf';

@Component({
  selector: 'app-tabla-datos',
  templateUrl: './tabla-datos.component.html',
  styleUrls: ['./tabla-datos.component.scss']
})
export class TablaDatosComponent implements OnInit {

  rowSelection
  paginationPageSize;
  paginationNumberFormatter;
  check = false;
  showTable = true;
  defaultColDef;
  sideBar;
  rowGroupPanelShow;
  localeText;
  configIdiomaGrid: ConfigIdiomaGrid;
  @Output() filasSeleccion = new EventEmitter();
  @Output() filaSeleccion = new EventEmitter();
  @Output() filtro = new EventEmitter();
  @Output() limpirFiltro = new EventEmitter();
  @Input() config: IDataGridConf;
  @Input() datos: Array<any>;
  @Input() existenSeleccionados: boolean;
  @Output() doubleClickedEvent = new EventEmitter();
  @Output() exportFile = new EventEmitter();
  @Output() quitarSeleccion = new EventEmitter();

  private gridApi;
  private gridColumnApi;
  private carga: number;
  private isSelected: boolean = true;



  constructor() {
    this.configIdiomaGrid = new ConfigIdiomaGrid();
    this.localeText = this.configIdiomaGrid.changeLanguageGrid();
    this.defaultColDef = { filterParams: { newRowsAction: 'keep' } };
    this.carga = 0;

  }

  ngOnInit(): void {

  }


  actualizarFiltro(filtro) {
    setTimeout(() => {
      this.filtro.next({ filtro: filtro.filterInstance, id: this.config.id, columna: filtro.column.colDef.field, serNombre: this.config.serNombre });
    }, 500);

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.existenSeleccionados)
      this.seleccionarFilas();
  }

  exportarCsv() {
    let exportCsv = this.gridApi.exportDataAsCsv();
    this.exportFile.emit(exportCsv);

  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.filasSeleccion.next(selectedRows);
  }

  rowSelectionChanged(event) {
    if (this.carga == 0) {

      event.data.$estado = !event.data.$estado;
      this.filaSeleccion.next(event.data);
    }
    else
      this.carga--;
  }

  rowDataChanged() {
    this.seleccionarFilas();
  }
  private seleccionarFilas() {
    if (this.existenSeleccionados && this.gridApi != undefined) {

      this.gridApi.forEachNode((node: RowNode) => {
        if (node.data.$estado) {
          this.carga++;
          node.setSelected(true, false);
        }
      })
    }
  }
  localizar(fila) {

    this.doubleClickedEvent.next(fila.data);
  }

  deselectAll() {
    this.isSelected = false;
    this.gridApi.deselectAll();
    this.quitarSeleccion.emit(this.isSelected);
  }

}
