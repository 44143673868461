import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {
  totalMensajes:any=0;  
  ngOnInit() {
    // Customizer JS File
    $.getScript('./assets/js/customizer.js');
  }

  AsignarCantidadNotificaciones(totalMensajes){
    this.totalMensajes=totalMensajes;
  }

}
