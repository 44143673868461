import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { TServicioNotificacionService } from "./t-servicio-notificacion.service";

@Component({
  selector: "app-navbar-notificacion",
  templateUrl: "./navbar-notificacion.component.html",
  styleUrls: ["./navbar-notificacion.component.scss"]
})
export class NavbarNotificacionComponent implements OnInit {
  mensajes: any;
  @Output() totalesMensajes = new EventEmitter<number>();
  constructor(private servicioMensajes: TServicioNotificacionService) { }

  ngOnInit() {
    this.servicioMensajes.getMensajes().subscribe(data => {
      this.mensajes = data;
      this.totalesMensajes.emit(this.mensajes.length);
    });
  }

  eliminarMensaje(index) {
    this.mensajes.splice(index, 1);
    this.totalesMensajes.emit(this.mensajes.length);
  }
}
