import { Component } from "@angular/core";
import { FwReporteadorService } from "./fw-reporteador.service";
import { FwSidebarService } from '@shared/sidebar-original/fw-sidebar.service';



@Component({
  selector: "app-reporteador",
  templateUrl: "./reporteador.component.html",
  styleUrls: ["./reporteador.component.scss"]
})
export class ReporteadorComponent {
  reportes: Object;
  url:any = JSON.parse(sessionStorage.getItem("cargaBase"))["url"]["urlReporteador"];
  tipo:any;
  idSigmaConsulta:any  
  datosPeticion:any={};
  sistemaLogueado:any;
  perfilLogueado:any;

  constructor(private servicioReportes: FwReporteadorService, private servicioSidebar:FwSidebarService) {}

  ngOnInit() {
    this.sistemaLogueado=sessionStorage.getItem("sistemaLogueado");
    this.perfilLogueado=sessionStorage.getItem("perfilLogueado");
    this.url = this.servicioSidebar.obtenerItem()["urlOnepage"];
    this.idSigmaConsulta =this.servicioSidebar.obtenerItem()["idSigmaConsulta"];
    this.tipo = this.servicioSidebar.obtenerItem()["tipo"];
    let filtro ='{"perfilLogueado":"'+this.perfilLogueado+'","sistemaLogueado":"'+this.sistemaLogueado+'","tipo":"'+this.tipo+'"}';
    let datosPeticion = `{"sistema":"${this.sistemaLogueado}"}`;


    this.servicioReportes.getServicioSigmaConsulta(this.sistemaLogueado, this.idSigmaConsulta, filtro, datosPeticion).subscribe(res => {
      let respuesta = res.datos;
      this.reportes = JSON.parse(respuesta).datos;      
    });

   
  }

  cambiarReporte(value) {
    console.log(value);
    (<HTMLImageElement>document.querySelector("#iframeReporteador")).src =
      this.url + value;
    console.log((<HTMLImageElement>document.querySelector("#iframeReporteador")).src);
  }
}
