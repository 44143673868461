import { createSelector } from '@ngrx/store';
import { IVisorState } from 'app/visor/shared/models/mapModels';
import { IWidgetState } from '../reducers';
import { getVisorState } from './config-leaflet.selector';

export const getWidgetState = createSelector(getVisorState, (state: IVisorState) => state.widget);

export const getWidgetActive = createSelector(getWidgetState, (state: IWidgetState) => state.active);

export const getWidgetTitle = createSelector(getWidgetState, (state: IWidgetState) => state.title);
