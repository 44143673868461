import { IConfigMap } from '../../shared/models/configMap';
import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';

import { LoadConfigLeaflet, LoadConfigLeafletSuccess } from '../actions';
import { VisorDatosService } from '../../shared/services/visor-datos.service';

@Injectable()
export class ConfigLeafletEffects {
    @Effect()
    data$ = this.actions$.pipe(
        ofType(LoadConfigLeaflet),
        mergeMap(({ mapId }) =>
            this.configLeaflet
                .getConfigLeaflet('', 'MAPA_LEAFLET', `{"mapa":"${mapId}"}`, '')
                .pipe(
                    map((configLeaflet: { map: IConfigMap; service: Array<any> }) => LoadConfigLeafletSuccess({ ...configLeaflet })),
                )
        )
    );

    constructor(private actions$: Actions, private configLeaflet: VisorDatosService) { }
}
