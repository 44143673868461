import { createAction, props } from '@ngrx/store';
import { IWidgetState } from '../reducers/widget.reducer';

const TOGGLE_WIDGET = '[WIDGET] Toggle widget';
const SET_WIDGET = '[WIDGET] Set widget';
const CLOSE_WIDGET = '[WIDGET] Close widget';

export const ToggleWidget = createAction(TOGGLE_WIDGET);

export const CloseWidget = createAction(CLOSE_WIDGET);

export const SetWidget = createAction(SET_WIDGET, props<{ payload: IWidgetState }>());
