<div class="row col-md-12" >  
  <div class="row col-md-6 left-15 mr-3 content-header">
      Gestión Imagen
  </div>
  <div class="row col-md-6 mb-1">
     <!-- <em  
        (click)="clicBotonAdicionarImagen()"
        class="btn btn-outline-info left-15 mt-2 mb-0 mr-1 fa fa-plus-square">
      </em>
      <em  
        (click)="eliminarImagen()"
        class="btn btn-outline-info left-15 mt-2 mb-0 mr-1 fas fa-trash-alt">
      </em>-->
      
    <input type="file" 
      name="fileImagen" 
      style="display:none;" 
      id="fileImagen"
      (change)="cambioArchivo($event.target.files, fileImagen)"
      #fileImagen
      >
  </div>
  <div class="col-md-12 pl-1 pr-1" *ngIf = "coleccionRutasImagenes != undefined && coleccionRutasImagenes.length>0">
  <ngu-carousel  #carousel
    [inputs]="configsCarrusel" class="col-md-12 pl-1 pr-1"
    (initData)="initDataFn($event)">    
    <ngu-item NguCarouselItem class="bannerStyle" 
      *ngFor="let ruta of coleccionRutasImagenes"
      [class.bordeTarjetaSeleccionado] = "ruta == rutaActual">
      <div  
          class="m-1 card no-border box-shadow-0"        
          (click)="imagenSeleccionada(ruta)"        
          >
          <div  class="card-body">
              <img  alt="Card image cap" class="card-img img-fluid" [src]="ruta">
          </div>
      </div>
    </ngu-item>
    <button [hidden]= "false" NguCarouselPrev class='btn btn-outline-primary btn-round .btn-sm leftRs'>&lt;</button>
    <button [hidden]= "false" NguCarouselNext class='btn btn-outline-primary btn-round .btn-sm rightRs'>&gt;</button>
  </ngu-carousel>
  </div>
</div>