<ngb-accordion #acc="ngbAccordion">
  <ngb-panel *ngFor="let mensaje of mensajes">
    <ng-template ngbPanelTitle>
        <em class="{{mensaje.clase}}" ngClass="{{mensaje.color}}"></em>
        <strong class="d-flex justify-content-between align-items-center">
          {{mensaje.titulo}}
          <span class="badge badge-primary badge-pill">{{mensaje.cantidad}}</span>
        </strong>
    </ng-template>
    <ng-template ngbPanelContent >
      <div *ngFor="let notificacion of mensaje.notificaciones; let i = index">
        <div class="dropdown-item noti-container py-0 border-bottom border-bottom-blue-grey border-bottom-lighten-33">          
          <span class="noti-wrapper">            
            <span *ngIf="notificacion.ruta" class="noti-text">{{notificacion.texto}}</span>
            <span *ngIf="notificacion.urlDescarga" class="noti-text">
              <a href="{{notificacion.urlDescarga}}"><span class="text-danger">{{notificacion.texto}}</span></a>
            </span><br>
            <span *ngIf="notificacion.ruta && router.url.indexOf(notificacion.ruta)==-1" class="noti-text">
              <button class="btn btn-link" [routerLink]="notificacion.ruta === '' ? null : notificacion.ruta"><span class="text-secondary">Abrir módulo</span>
                </button>
            </span>
            <span *ngIf="notificacion.ruta && router.url.indexOf(notificacion.ruta)!=-1" class="noti-text">
              <button class="btn btn-link" (click)="zoomMapa(notificacion)"><span class="text-secondary">Ver elemento</span></button>
            </span>
            <span class="noti-text">
              <button class="btn btn-link" (click)="eliminarMensaje(i,mensaje)"><span class="text-secondary">Quitar</span></button>
            </span>
          </span>
        </div>
      </div>
    </ng-template>
  </ngb-panel>  
</ngb-accordion>
