import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[keyCombine]'
})
export class KeyCombineDirective {

    @Output() keyEvent = new EventEmitter();
  

    constructor(private _el: ElementRef) { }

    @HostListener('keydown.shift.s', ['$event']) onKeyDown(e) {
        this.keyEvent.next(e);
    }
}
