import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[allowOnlyNumbers]'
})
export class AllowOnlyNumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initialValue = this._el.nativeElement.value;
    // en la expresion regular se ponen los caracteres aceptados
    this._el.nativeElement.value = initialValue.replace(/[^0-9.]+/g, '');
    if (initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
