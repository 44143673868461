<div *ngFor="let mensaje of mensajes; let i = index">
  <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4" [routerLink]="mensaje.ruta === '' ? null : mensaje.ruta"
    (click)="eliminarMensaje(i)">
    <em *ngIf="mensaje.ruta" class="ft-bell float-left d-block font-large-1 mt-1 mr-2" ngClass="{{mensaje.color}}"></em>
    <em *ngIf="mensaje.urlDescarga" class="ft-download float-left d-block font-large-1 mt-1 mr-2" ngClass="{{mensaje.color}}"></em>
    <span class="noti-wrapper">
      <span class="noti-title line-height-1 d-block text-bold-400" ngClass="{{mensaje.color}}">{{mensaje.titulo}}</span>
      <span *ngIf="mensaje.ruta" class="noti-text">{{mensaje.texto}}</span>
      <span *ngIf="mensaje.urlDescarga" class="noti-text">
        <a href="{{mensaje.urlDescarga}}">{{mensaje.texto}}</a>
      </span>
    </span>
  </a>
</div>