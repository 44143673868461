import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[blockSpecialChars]'
})
export class BlockSpecialCharsDirective {

  @Input() activeBlockSpecialChars = '';

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    if (!this.activeBlockSpecialChars || this.activeBlockSpecialChars === 'true') {
      const initialValue = this._el.nativeElement.value;
       //en la expresion regular se ponen los caracteres aceptados
       this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9ÁÉÍÓÚÑáéíóúäëïöüñ@%&.,;:*+/ _^\\=!¡#$()¿?\-\n\"]*/g, '');
      
      if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }
}
