import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-grafica',
  templateUrl: './grafica.component.html',
  styleUrls: ['./grafica.component.scss']
})
export class GraficaComponent implements OnInit {

  @Input() elementosGraficaFiltro: any;
  @Input() elementosGrafica: any;
  @ViewChild("baseChart")
  public chart: BaseChartDirective;

  public variables: string[] = ['Sin Información'];


  public labelsAux: string[];


  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  public barChartData: any[] = [
    { data: [1], label: 'Sin Información' }
  ];

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }

          },
        }
      }]
    }
  };

  constructor() { }
  ngOnInit() {
    // console.log(this.elementosGraficaFiltro);
  }

  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  ngOnChanges() {

    if (this.chart != undefined) {
      this.chart.ngOnDestroy();
      // this.chart.chart = 0;

      if (this.elementosGraficaFiltro.barChartData.length > 0) {
        this.chart.datasets = this.elementosGraficaFiltro.barChartData;
        this.chart.labels = this.elementosGraficaFiltro.variables;
        this.chart.ngOnInit();
      }
      else {
        this.chart.datasets = this.barChartData;
        this.chart.labels = this.variables;
      }

    }


  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  public randomize():void {
    this.elementosGraficaFiltro=this.elementosGrafica;    
    if(this.chart!=undefined){
      console.log(this.elementosGraficaFiltro);
      this.chart.ngOnDestroy();
      // this.chart.chart=0;
      if (this.elementosGraficaFiltro.barChartData.length>0){
        this.chart.datasets = this.elementosGraficaFiltro.barChartData;
        this.chart.labels = this.elementosGraficaFiltro.variables;
  
        this.chart.ngOnInit();
      }else{
 
        this.chart.datasets = this.barChartData;
        this.chart.labels=this.variables;
        this.chart.ngOnInit();
      }

    
      //this.chart.chart=this.chart.getChartBuilder(this.chart.ctx);
    }
  }

}
