<div class="row m-3">
    <div class="col-md-12 row" matchHeight="card">
      <div class="col-md-6" *ngFor="let indicador of configTarjeta">
        <div class="border border-dark rounded mb-2">
          <div class="card">
            <div class="card-header text-center pb-0">
              <span class="font-medium-1 primary">{{indicador.titulo}}</span>
              <h3 class="font-large-1 mt-1">
                <span class="font-medium-1 grey darken-1 text-bold-400">{{datosTarjeta[indicador.orden].etiqueta}} {{datosTarjeta[indicador.orden].valor}}</span>
              </h3>
            </div>
            <div class="card-body text-center">
              <div class="card-block text-center">
                <div class="info">
                  <em [className]="indicador.icono"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Indicadores Vehículos -->
   <!-- <div class="col-md-12 row" matchHeight="card">
      <div class="col-md-4">
        <div class="border border-dark rounded mb-2">
          <div class="card">
            <div class="card-header text-center pb-0">
              <span class="font-medium-1 primary">Kilómetros <br> <span class="text-center">Hoy</span></span>
              <h3 class="font-large-1 mt-1">{{vehiculo.kilometrosHoy}}
                <span class="font-medium-1 grey darken-1 text-bold-400">km</span>
              </h3>
            </div>
            <div class="card-body text-center">
              <div class="card-block text-center">
                <div class="info">
                  <em class="fas fa-tachometer-alt fa-2x"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






      <div class="col-md-4">
        <div class="border border-dark rounded mb-2">
          <div class="card">
            <div class="card-header text-center pb-0">
              <span class="font-medium-1 primary">Horas Motor Encendido Hoy</span>
              <h3 class="font-large-1 mt-1">{{vehiculo.horasEncendidoHoy}}
                <span class="font-medium-1 grey darken-1 text-bold-400">horas</span>
              </h3>
            </div>
            <div class="card-body text-center">
              <div class="card-block text-center">
                <div class="info">
                  <em class="fas fa-stopwatch fa-2x"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="border border-dark rounded mb-2">
          <div class="card">
            <div class="card-header text-center pb-0">
              <span class="font-medium-1 primary">Tiempo Varado Año</span>
              <h3 class="font-large-1 mt-1">{{vehiculo.tiempoVarado}}
                <span class="font-medium-1 grey darken-1 text-bold-400">horas</span>
              </h3> 
            </div>
            <div class="card-body text-center">
              <div class="card-block text-center">
                <div class="info">
                  <em class="fas fa-exclamation-triangle fa-2x"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  
    <!--<div class="col-md-6">
 
      <app-grafica-v2 [resultadoElementosGrafica]="resultadoElementosGrafica"></app-grafica-v2>     
        
    </div>-->
    <!--<div class="col-md-6">
      <app-lista-informacion 
      [elementosListaInformacion]="elementosListaInformacion"
      [configuracionColumnasListas]="configuracionColumnasListas" 
      [datosConfiguracionLista]="datosConfiguracionLista">
      </app-lista-informacion>
    </div>-->
  </div>