import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Mensaje } from '@mensaje';
import { CargaMasivaService } from './carga-masiva.service';
import { ExcelService } from "@shared/services/excel.service";

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.scss']
})
export class CargaMasivaComponent implements OnInit {

  archivoSeleccionadoOperacion: File = null;
  mensaje = new Mensaje("", "", "");
  @Input() arrPlantillas: any[];
  @Output() salida = new EventEmitter();
  urlPlantilla: any;
  tipoPlantilla: any;
  fileInput: any;
  modalRef: any;

  constructor(
    private http: HttpClient,
    private servicio: CargaMasivaService,
    private excelService: ExcelService,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  LimpiarControl(control: AbstractControl): ValidationErrors | null {
    try {
      return control.value === '' || control.value == null ? null : Validators.required(control);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  cargarRutaPlantilla(evento) {
    if (evento !== undefined) {
      this.urlPlantilla = evento["id"];
      this.tipoPlantilla = evento["text"];
    }
  }

  seleccionarArchivoOperacion(fileInput, event) {
    this.archivoSeleccionadoOperacion = <File>event.target.files[0];
    this.fileInput = fileInput;
    // console.log(this.archivoSeleccionadoOperacion);
  }

  onUpload() {
    if (this.archivoSeleccionadoOperacion.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.mensaje.retornarMensaje("error", "Atención", "Debe subir un archivo con extensión .xlsx antes de dar clic al botón Enviar", "");
      this.archivoSeleccionadoOperacion = null;
      this.fileInput.value = "";
    } else if (this.tipoPlantilla == "") {
      this.mensaje.retornarMensaje("error", "Atención", "Debe seleccionar un tipo de plantilla para subir", "");
    } else {
      let parametros = {
        contenedor: this,
        texto: "",
        plantilla: this.tipoPlantilla,
      };
      this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea subir el archivo " + this.archivoSeleccionadoOperacion.name + "?", "", "", this, "cargueMasivo", parametros);
    }
  }

  cargueMasivo(parametros) {
    parametros.contenedor.mensaje.retornarMensajeFuncion("loading", "", "Realizando carga masiva", "", null, null, null);
    const fd = new FormData();
    fd.append("id", parametros.contenedor.tipoPlantilla);
    fd.append("file", parametros.contenedor.archivoSeleccionadoOperacion);

    parametros.contenedor.servicio.getServicioCargaMasiva(fd).subscribe(res => {
      parametros.contenedor.mensajeFinalizarCargaMasiva(res.respuesta, res.noticia);
      parametros.contenedor.excelService.exportAsExcel(JSON.parse(res.tablaErrores), "logCargaMasiva");
      parametros.contenedor.archivoSeleccionadoOperacion = null;
      parametros.contenedor.salida.next();
      parametros.contenedor.fileInput.value = "";
      parametros.contenedor.tipo = "";

    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
          parametros.contenedor.mensaje.cerrarMensajeCarga();
          parametros.contenedor.mensaje.retornarMensaje("error", "Lo sentimos", "Ocurrió un error, por favor intente de nuevo", "");
        }
      })
  }

  mensajeFinalizarCargaMasiva(respuesta, noticia) {
    if (respuesta == "true") {
      this.mensaje.retornarMensaje("success", "Operación exitosa", noticia, "");
    }
    if (respuesta == 'false' && noticia === "Verifique el archivo de salida, se generaron excepciones."){
      this.mensaje.retornarMensaje("success", "Operación exitosa", "Cargados Correctamente:  ; Total Enviados: ", "");
    } 
    else {
      this.mensaje.retornarMensaje("warning", "Atención", noticia, "");
    }
  } 

}
