<ngu-carousel #carousel [inputs]="configsCarrusel" class="px-1" *ngIf="subsistemaActual != undefined">
  <ngu-item
  NguCarouselItem
  class="bannerStyle"
  *ngFor="let subsistema of coleccionSubsistemas">
    <div
    class="card-body"
    (click)="salida.next(subsistema)"
    [className]="subsistema.json.sub_gradie"
    [class.bordeTarjetaSeleccionado]="subsistemaActual.json.sub_nombre.toUpperCase() == subsistema.json.sub_nombre.toUpperCase()">
      <div class="card-block pt-2 pb-0">
        <div class="media">
          <div class="media-body white text-left">
            <h3 class="font-large-1 mb-0" [hidden] = "ocultarCantidad">{{subsistema.cantidad || 0}}</h3>
            <span>{{subsistema.json.sub_alias}}</span>
          </div>
          <div class="media-right white text-right">
            <img [src]="subsistema.json.sub_icono">
          </div>
        </div>
      </div>
    </div>
  </ngu-item>
  <button NguCarouselPrev="true" class='leftRs'>&lt;</button>
  <button NguCarouselNext="true" class='rightRs'>&gt;</button>
</ngu-carousel>
