import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ServiciosService } from "../servicios.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopupInfocapaComponent } from "../popup-infocapa/popup-infocapa.component";
import { Mensaje } from "../../../shared/components/mensajes/mensaje.modelo";
import { of, concat, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FwSidebarService } from "@shared/sidebar-original/fw-sidebar.service";
import { Grid } from "@shared/components/pagination/codebase/suite";
import { ShaSerDatosService } from "@shared/services/sha-ser-datos";
interface ICategoria {
  categoria: string;
  boton: HTMLDivElement;
}
@Component({
  selector: "app-capas",
  templateUrl: "./capas.component.html",
  styleUrls: ["./capas.component.scss"],
})
export class CapasComponent implements OnInit , OnDestroy{

  arrayURL: any = [];
  urlSeleccionada: any = "";
  arrayCapas: any = [];
  filtroCapa: any;
  grid: Grid;
  // pagination: Pagination;
  datos: any = [];
  asociadas: any;
  noAsociadas: any;
  conError: any;
  imagen: SafeResourceUrl;
  mensaje: Mensaje;
  capasEditadas: any[] = [];
  arrayModelo: any = [];
  arrayCategoria: any = [];
  arrayTipoInfocapa: any = [];
  arrayCapasCategoria: any = [];
  placeholder = "";
  mensajeVisualizar = "";
  filtroCategoria: Array<ICategoria>;
  codigoServicioCarga: string;
  idVisorPublico: string = "MAP_LEAFLET_VISOR_PUBLICO"
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild("widget", { static: true }) container: ElementRef;
  @ViewChild("popupVisualizar") popupVisualizar: ElementRef;

  constructor(
    private servicios: ServiciosService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private menuService: FwSidebarService,
    private servicioDatos: ShaSerDatosService,
  ) {
    this.mensaje = new Mensaje("", "", "");
    this.servicios.capas = this;
    this.asociadas = 0;
    this.noAsociadas = 0;
    this.conError = 0;
    this.placeholder = "Ingrese url del servicio";
    this.filtroCategoria = new Array<ICategoria>();
    this.codigoServicioCarga = "";
  }

  ngOnInit(): void {
    this.servicios.capas = this;

    this.CargarServicios();

    this.ObtenerDominios();

    let clase = this;
    this.grid = new Grid(this.container.nativeElement, {
      css: "dhx_demo-grid",
      columns: [
        {
          width: 200,
          id: "name",
          header: [{ text: "Nombre" }, { content: "inputFilter" }],
          align: "center",
          editable: false,
        },
        {
          width: 250,
          id: "alias",
          header: [
            { text: "Alias (Nombre en mapa) *" },
            { content: "inputFilter" },
          ],
          align: "center",
        },
        {
          width: 400,
          id: "url",
          header: [{ text: "URL - Capa *" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 250,
          id: "serviceName",
          header: [{ text: "Nombre servicio" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 130,
          id: "category",
          header: [{ text: "Categoría *" }, { content: "inputFilter" }],
          align: "center",
          editorType: "combobox",
          options: this.arrayCategoria,
        },
        {
          width: 130,
          id: "model",
          header: [{ text: "Modelo" }, { content: "inputFilter" }],
          type: "string",
          align: "center",
          editorType: "combobox",
          options: this.arrayModelo,
        },
        {
          width: 400,
          id: "historical",
          header: [{ text: "Url histórico" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 400,
          id: "statistical",
          header: [{ text: "Url estadística" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 200,
          id: "legend",
          header: [{ text: "Url leyenda" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 250,
          id: "infoType",
          header: [{ text: "Tipo de infocapa *" }, { content: "inputFilter" }],
          type: "string",
          align: "center",
          editorType: "combobox",
          options: this.arrayTipoInfocapa,
        },
        {
          width: 90,
          id: "state",
          header: [{ text: "Estado" }, { content: "inputFilter" }],
          type: "boolean",
        },
        {
          width: 90,
          id: "public",
          header: [{ text: "Público" }, { content: "inputFilter" }],
          type: "boolean",
        },
        {
          width: 90,
          id: "private",
          header: [{ text: "Privado" }, { content: "inputFilter" }],
          type: "boolean",
        },
        {
          width: 130,
          id: "downloadable",
          header: [{ text: "Descargar" }, { content: "inputFilter" }],
          type: "boolean",
        },
        {
          width: 200,
          id: "order",
          header: [{ text: "Orden visualización" }, { content: "inputFilter" }],
          align: "center",
          editable: true,
        },
        {
          width: 130,
          id: "action",
          gravity: 1.5,
          header: [{ text: "Acciones", align: "center" }],
          htmlEnable: true,
          align: "center",
          template: function () {
            return "<img class='visualizar' src='../../../../assets/img/logos/svg_interna_2/visualizar_capa_int_2.svg' style='display: inline-block; vertical-align: middle; max-width: 18px; max-height: 20px; float:left; margin: 2px;'> <img class='infocapa' src='../../../../assets/img/logos/svg_interna_2/infocapa_int_2.svg' style='display: inline-block; vertical-align: middle; max-width: 14px; max-height: 14px; float:left; margin: 2px;'> <img class='duplicar' src='../../../../assets/img/logos/svg_interna_2/duplicar_capa.svg' style='display: inline-block; vertical-align: middle; max-width: 14px; max-height: 14px; float:left; margin: 2px;'>";
          },
          editable: false,
        },
      ],
      adjust: false,
      autoWidth: false,
      autoHeight: true,
      editable: true,
      eventHandlers: {
        onclick: {
          visualizar: function (e, data) {
            clase.AbrirPopupVisualizar(
              data.row.serviceType,
              data.row.serviceUrl,
              data.row.url,
              data.row.name
            );
          },
          infocapa: function (e, data) {
            clase.AbrirPopupInfocapa(data.row);
          },
          duplicar: function (e, data) {
            clase.datos.push({
              code: Date.now(),
              id_capa: data.row.id_capa,
              name: data.row.name,
              alias: data.row.alias,
              url: data.row.url,
              category: data.row.category,
              state: data.row.state,
              public: data.row.public,
              private: data.row.private,
              model: data.row.model,
              alertLocation: data.row.alertLocation,
              alertEndpoint: data.row.alertEndpoint,
              alertFrecuency: data.row.alertFrecuency,
              historical: data.row.historical,
              statistical: data.row.statistical,
              order: data.row.order,
              legend: data.row.legend,
              downloadable: data.row.downloadable,
              infoType: data.row.infoType,
              type: data.row.type,
              info: data.row.info,
              serviceName: data.row.serviceName,
              serviceUrl: data.row.serviceUrl,
              serviceType: data.row.serviceType,
              serviceCode: data.row.serviceCode,
              error: data.row.error,
              active: false,
              subcat: false
            });
            clase.grid.data.parse(clase.datos);
          },
        },
      },

    });

    this.grid.events.on("afterEditEnd", function (value, row, column) {
      const index = clase.capasEditadas.findIndex((_row) => _row.code == row.code);
      clase.menuService.editable = true;
      if (index >= 0) {
        clase.capasEditadas[index] = row;
      }
      else {
        clase.capasEditadas.push(row);
      }
    });

    // this.pagination = new Pagination("pagination", {
    //   css: "dhx_widget--bordered dhx_widget--no-border_top",
    //   data: this.grid.data,
    //   pageSize: 100
    // });
    // this.grid.enablePaging(true,10,5,"pagingArea",true,"recinfoArea");
  }

  ngOnDestroy() {
    if (this.grid) {
      this.grid.destructor();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    
  }


  /**
   * Obtiene los servicios de donde se obtendrán las url para llenar el combo
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  CargarServicios() {
    try {
      this.servicios.GetServicios().subscribe((respuesta) => {
        let array = [];
        respuesta.services.forEach((service) => {
          array.push({ id: service.url, text: service.url });
        });
        this.arrayURL = [...array];
      });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al consultar el servicio, por favor validar",
        ""
      );
    }
  }

  /**
   * Obtiene la lista de capas asociadas a una url
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  DescubrirServidor() {
    if (this.capasEditadas.length > 0) {
      this.mensaje.retornarMensajeFuncion2(
        "confirmacion",
        "No ha almacenado los cambios realizados, ¿Desea guardarlos?",
        "",
        this,
        "ConfirmadoAccionGuardar",
        this,
        "CancelarAccionURL"
      );
    } else {
      if (this.urlSeleccionada) {
        try {
          this.mensaje.retornarMensajeFuncion('loading', 'Realizando petición', '', '', null, null, null);
          this.servicios
            .DescubrirServidor(this.urlSeleccionada)
            .subscribe((respuesta) => {
              if (respuesta.layers.length > 0) {
                let checkbox = document.getElementById("verTodas") as HTMLInputElement;
                if (checkbox.checked == true) {
                  checkbox.checked = false;
                }
                this.LlenarListaCapas(respuesta, this.mensaje);
              } else {
                this.mensaje.retornarMensaje(
                  "error",
                  "Atención",
                  "Error al consultar el servicio, por favor validar",
                  ""
                );
              }
            }, (error: any) => {
              this.mensaje.retornarMensaje(
                "error",
                "Atención",
                "Error al consultar el servicio, por favor validar",
                ""
              );
            });
        } catch (error) {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al consultar el servicio, por favor validar",
            ""
          );
          this.urlSeleccionada = "";
        }
      }
    }
  }

  /**
   * Obtiene la lista de capas desde el servicio según un filtro dado
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  ObtenerCapasFiltradas(filtro: string) {
    if (this.capasEditadas.length > 0) {
      this.mensaje.retornarMensajeFuncion2(
        "confirmacion",
        "No ha almacenado los cambios realizados, ¿Desea guardarlos?",
        "",
        this,
        "ConfirmadoAccionGuardar",
        this,
        "CancelarAccionCapa"
      );
    } else {
      if (filtro) {
        try {
          this.mensaje.retornarMensajeFuncion('loading', 'Realizando petición', '', '', null, null, null);
          this.servicios.GetCapasFiltradas(filtro).subscribe((respuesta) => {
            if (respuesta.state == "success") {
              this.LlenarListaCapas(respuesta, this.mensaje);
            } else {
              this.mensaje.retornarMensaje(
                "error",
                "Atención",
                "Error al consultar el servicio, por favor validar",
                ""
              );
            }
          });
        } catch (error) {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al consultar el servicio, por favor validar",
            ""
          );
          this.urlSeleccionada = "";
        }
      }
    }
  }

  /**
   * Llena la lista de capas con los datos de la respuesta del servicio
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  LlenarListaCapas(respuesta, mensaje) {
    this.datos = [];
    this.InicializarEstadisticas();
    let categoriasCapas = [];
    let pos = -1;
    respuesta.layers.forEach((capa, index) => {

      if (capa) {
        if (!capa.code || (capa.code && capa.code == "")) {
          capa.code = index;
        }
        this.datos.push({
          serviceName: capa.serviceName,
          serviceUrl: capa.serviceUrl,
          serviceType: capa.serviceType,
          code: capa.code,
          serviceCode: capa.serviceCode,
          name: capa.name,
          alias: capa.alias,
          id_capa: capa.id,
          type: capa.type,
          url: capa.url,
          category: capa.category,
          state: capa.state,
          public: capa.public,
          private: capa.private,
          error: capa.error,
          model: capa.model,
          alertLocation: capa.alertLocation,
          alertEndpoint: capa.alertEndpoint,
          alertFrecuency: capa.alertFrecuency,
          historical: capa.historical,
          statistical: capa.statistical,
          order: capa.order,
          legend: capa.legend,
          downloadable: capa.downloadable,
          infoType: capa.infoType,
          info: capa.info,
          active: false,
          subcat: false
        });

        if (capa.state == true) {
          this.asociadas++;
        } else if (capa.state == false) {
          this.noAsociadas++;
        }

        if (capa.error == true) {
          this.conError++;
        }

        pos = categoriasCapas.findIndex(element => element.nombre.toUpperCase() === capa.category.toUpperCase());
        if (pos >= 0) {
          categoriasCapas[pos].cantidad = categoriasCapas[pos].cantidad + 1;
        }
        else {
          categoriasCapas.push({ nombre: capa.category, cantidad: 1 });
        }

      }
    });
    this.arrayCapasCategoria = categoriasCapas;
    this.grid.data.parse(this.datos);
    this.filtroCapa = "";
    this.urlSeleccionada = "";
    this.placeholder = "Ingrese url del servicio";
    mensaje.cerrarMensajeCarga();
  }




  /**
   * Verifica si el checkbox de Ver todas está seleccionado y muestra las capas o limpia la lista según corresponda
   * @author Elisabeth Campuzano Jaramillo
   * @date 07/01/2022
   * @param
   * @returns
   */
  ValidarVerTodas(checkbox) {
    checkbox = document.getElementById("verTodas");
    if (checkbox.checked == true) {
      this.urlSeleccionada = "";
      this.placeholder = "Ingrese url del servicio";
      this.filtroCapa = "";
      this.mensaje.retornarMensajeFuncion('loading', 'Realizando petición', '', '', null, null, null);
      this.servicios.GetAllLayers().subscribe((respuesta) => {
        if (respuesta.state == "success") {
          this.LlenarListaCapas(respuesta, this.mensaje);
        } else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al consultar el servicio, por favor validar",
            ""
          );
        }
      });
    } else {
      this.grid.data.parse([]);
    }
  }

  /**
   * Inicializa las estadísticas de las capas
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  InicializarEstadisticas() {
    this.asociadas = 0;
    this.noAsociadas = 0;
    this.conError = 0;
    this.arrayCapasCategoria = [];
  }

  /**
   * Abre un modal con una imagen donde se visualiza la capa
   * @author Elisabeth Campuzano Jaramillo
   * @date 14/01/2022
   * @param
   * @returns
   */
  AbrirPopupVisualizar(type: string, urlService: string, urlLayer: string, nameLayer: string) {
    try {
      this.mensajeVisualizar = "Cargando vista previa";
      if (type == "esriDynamic") {

        this.servicios.query(urlLayer + "?f=json", "json").subscribe((response) => {
          let minx = response.extent.xmin;
          let miny = response.extent.ymin;
          let maxx = response.extent.xmax;
          let maxy = response.extent.ymax;

          let bbox = minx + "," + miny + "," + maxx + "," + maxy;

          let urlServ = urlService.split("?");
          this.servicios
            .query(
              urlServ[0] + "/export" + this.servicios.getParamArcgis(nameLayer, bbox, response.extent.spatialReference.wkid),
              "blob"
            )
            .subscribe((imagenC) => {

              if (imagenC["type"] === "text/xml") {
                this.mensajeVisualizar = "Capa no disponible";
                this.imagen = undefined;
              }
              else {
                let urlCreator = window.URL;
                let imageUrl = urlCreator.createObjectURL(imagenC);
                this.imagen = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
              }

            }, (error: any) => {
              this.mensajeVisualizar = "Vista previa no disponible";
            });
        }, (error: any) => {
          this.mensajeVisualizar = "Vista previa no disponible";
        });

      } else {

        this.servicios
          .query(urlLayer + "&request=GetCapabilities&service=WMS", "XML")
          .subscribe((respuesta) => {
            let convert = require("xml-js");

            let jsonCapabilities = convert.xml2js(respuesta, {
              compact: true,
              spaces: 4,
              textKey: "value",
              cdataKey: "value",
              commentKey: "value",
            });

            if (jsonCapabilities.WMS_Capabilities.Capability.Layer.Layer.constructor === Array) {
              jsonCapabilities.WMS_Capabilities.Capability.Layer.Layer.forEach(
                (layer) => {
                  if (layer.Name.value.toUpperCase() === nameLayer.toUpperCase()) {
                    let minx =
                      layer.EX_GeographicBoundingBox.westBoundLongitude.value;
                    let miny =
                      layer.EX_GeographicBoundingBox.southBoundLatitude.value;
                    let maxx =
                      layer.EX_GeographicBoundingBox.eastBoundLongitude.value;
                    let maxy =
                      layer.EX_GeographicBoundingBox.northBoundLatitude.value;

                    let bbox = minx + "," + miny + "," + maxx + "," + maxy;

                    let urlServ = urlLayer.split("?");
                    this.servicios
                      .query(
                        urlServ[0] +
                        this.servicios.getParamGeoserver(nameLayer, bbox),
                        "blob"
                      )
                      .subscribe((imagenCapa) => {
                        if (imagenCapa["type"] === "text/xml") {
                          this.mensajeVisualizar = "Capa no disponible";
                          this.imagen = undefined;
                        }
                        else {

                          // se asigna la imagen de la respuesta al elemento img del modal
                          let urlCreator = window.URL;
                          let imageUrl = urlCreator.createObjectURL(imagenCapa);
                          this.imagen = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
                        }


                      }, (error: any) => {
                        this.mensajeVisualizar = "Vista previa no disponible";
                      });
                  }
                }
              );
            }
            else {
              let layer = jsonCapabilities.WMS_Capabilities.Capability.Layer.Layer;
              if (layer.Name.value.toUpperCase() === nameLayer.toUpperCase()) {
                let minx =
                  layer.EX_GeographicBoundingBox.westBoundLongitude.value;
                let miny =
                  layer.EX_GeographicBoundingBox.southBoundLatitude.value;
                let maxx =
                  layer.EX_GeographicBoundingBox.eastBoundLongitude.value;
                let maxy =
                  layer.EX_GeographicBoundingBox.northBoundLatitude.value;

                let bbox = minx + "," + miny + "," + maxx + "," + maxy;

                let urlServ = urlLayer.split("?");
                this.servicios
                  .query(
                    urlServ[0] +
                    this.servicios.getParamGeoserver(nameLayer, bbox),
                    "blob"
                  )
                  .subscribe((imagenCapa) => {

                    if (imagenCapa["type"] === "text/xml") {
                      this.mensajeVisualizar = "Capa no disponible";
                      this.imagen = undefined;
                    }
                    else {
                      // se asigna la imagen de la respuesta al elemento img del modal
                      let urlCreator = window.URL;
                      let imageUrl = urlCreator.createObjectURL(imagenCapa);
                      this.imagen = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
                    }


                  }, (error: any) => {
                    this.mensajeVisualizar = "Vista previa no disponible";
                  });

              }
            }
          }, (error: any) => {
            this.mensajeVisualizar = "Vista previa no disponible";
          });
      }

      this.modalService.open(this.popupVisualizar, {
        backdrop: "static",
        centered: true,
      });

    } catch (error) {
      console.log("Error: " + error);
    }
  }

  /**
   * Abre un modal con un grid para configurar la información de la capa
   * @author Elisabeth Campuzano Jaramillo
   * @date 14/01/2022
   * @param
   * @returns
   */
  AbrirPopupInfocapa(row) {
    try {
      if (row.code) {
        let modalRef = this.modalService.open(PopupInfocapaComponent, {
          backdrop: "static",
          centered: true,
          size: "lg",
        });
        this.urlSeleccionada = row.serviceUrl;
        modalRef.componentInstance.row = row;
        modalRef.result.then(
          (resultado) => {
            this.DescubrirServidor();
          },
          (reason) => {
            this.DescubrirServidor();
          }
        );
      } else {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "La capa seleccionada no se encuentra registrada",
          ""
        );
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  AccionGuardar() {
    let peticiones: any[] = [];
    this.capasEditadas.forEach((capa) => {
      if (typeof (capa.code) === "number" && capa.state == true) {
        if (capa.alias != "" && capa.url != "" && capa.category != "" && capa.infoType != "") {
          peticiones.push(this.AsociarCapa(capa));
        }
        else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Por favor diligencie los campos obligatorios",
            ""
          );
        }
      } else if (typeof (capa.code) !== "number" && capa.state == false) {
        peticiones.push(this.DisociarCapa(capa.code));
      }
      else {
        if (capa.alias != "" && capa.url != "" && capa.category != "" && capa.infoType != "") {
          peticiones.push(this.EditarCapa(capa));
        }
        else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Por favor diligencie los campos obligatorios",
            ""
          );
        }
      }
      this.urlSeleccionada = capa.serviceUrl;
    });
    const _request = concat(peticiones).pipe(
      catchError((error) => of(error))
    );
    _request.subscribe((result) => {
      let conError = 0;
      let conExito = 0;
      result.forEach((element) => {
        if (element.state === "success") {
          conExito++;
        }
        else {
          conError++;
        }
        if (conExito + conError == peticiones.length - 1) {
          this.LimpiarFiltro();
          this.DescubrirServidor();
        }
      });
      if (conError == 0) {
        this.mensaje.retornarMensajeFuncion(
          "confirmacionok",
          "La información fue actualizada con Éxito",
          "",
          "",
          () => {
            this.DescubrirServidor();
          },
          "",
          null
        );
      } else if (conExito == 0) {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "Ocurrió un error! Por favor valide nuevamente",
          ""
        );
      } else {
        this.mensaje.retornarMensajeFuncion(
          "confirmacionerror",
          "Se actualizaron " + conExito + " registros con exito y " + conError + " tuvieron error",
          "",
          "",
          () => {
            this.DescubrirServidor();
          },
          "",
          null
        );
      }
    });
    if(this.capasEditadas.length > 0){
      this.servicios.ActualizarCacheVisor(this.idVisorPublico)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if(res["state"]==="success"){
            this.mensaje.retornarMensaje(
              "exito",
              "Operación Exitosa",
              "Visor actualizado",
              ""
            );
          }
          else{
            this.mensaje.retornarMensaje(
              "error",
              "Operación Incorrecta",
              "Error al actualizar el visor",
              ""
            );
            console.log(res)
          }
         
        },
        (error) => {
          this.mensaje.retornarMensaje(
            "error",
            "Operación Incorrecta",
            "Error al actualizar el visor",
            ""
          );
          console.log(error)
        }
      );
    }
    this.menuService.editable = false;
    this.capasEditadas = [];
    this.filtroCapa = "";
  }

  ConfirmadoAccionGuardar(contenedor) {
    contenedor.Guardar()
  }


  CancelarAccionURL(contenedor) {
    contenedor.menuService.editable = false;
    contenedor.capasEditadas = [];
    contenedor.DescubrirServidor();
  }

  CancelarAccionCapa(contenedor) {
    contenedor.menuService.editable = false;
    contenedor.capasEditadas = [];
    contenedor.ObtenerCapasFiltradas(contenedor.filtroCapa);
  }

  AsociarCapa(capa) {
    let entrada = {
      cap_codigo: "",
      cap_id: capa.id_capa,
      cap_nombre: capa.name,
      cap_alias: capa.alias,
      cap_url: capa.url,
      cap_tipo: capa.type,
      cap_estado: capa.state ? "Asociada" : "Inactiva",
      cap_catego: capa.category,
      cap_public: capa.public,
      cap_privad: capa.private,
      cap_modelo: capa.model,
      cap_ubiale: capa.alertLocation,
      cap_endale: capa.alertEndpoint,
      cap_period: capa.alertFrecuency,
      cap_histor: capa.historical,
      cap_estadi: capa.statistical,
      cap_orden: capa.order,
      cap_leyend: capa.legend,
      cap_descar: capa.downloadable,
      cap_tipinf: capa.infoType,
      ser_nombre: capa.serviceName,
      ser_url: capa.serviceUrl,
      ser_tipo: capa.serviceType,
      ser_codigo: capa.serviceCode,
      cap_activa: false,
      cap_subcat: false
    };


    try {
      return this.servicios.AsociarCapa(JSON.stringify(entrada));
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al asociar la capa",
        ""
      );
    }
  }

  DisociarCapa(code) {
    try {
      return this.servicios.DisociarCapa(code);
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al disociar la capa",
        ""
      );
    }
  }

  EditarCapa(capa) {
    let entrada = {
      cap_codigo: capa.code,
      ser_codigo: capa.serviceCode,
      cap_id: capa.id_capa,
      cap_nombre: capa.name,
      cap_alias: capa.alias,
      cap_url: capa.url,
      cap_tipo: capa.type,
      cap_estado: capa.state ? "Asociada" : "Inactiva",
      cap_catego: capa.category,
      cap_public: capa.public,
      cap_privad: capa.private,
      cap_modelo: capa.model,
      cap_ubiale: capa.alertLocation,
      cap_endale: capa.alertEndpoint,
      cap_period: capa.alertFrecuency,
      cap_histor: capa.historical,
      cap_estadi: capa.statistical,
      cap_orden: capa.order,
      cap_leyend: capa.legend,
      cap_descar: capa.downloadable,
      cap_tipinf: capa.infoType,
      cap_info: capa.info,
      ser_nombre: capa.serviceName,
      ser_url: capa.serviceUrl,
      ser_tipo: capa.serviceType,
      cap_activa: false,
      cap_subcat: false
    };

    try {
      return this.servicios.EditarCapa(JSON.stringify(entrada));
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al editar la capa",
        ""
      );
    }
  }

  /**
   * Se realizan los llamados al servicio para obtener los dominios y poblar los select del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   */
  ObtenerDominios() {
    this.servicios
      .ObtenerDominiosCapas("DOM_CAP_2")
      .subscribe((respuesta: any) => {
        this.arrayCategoria = JSON.parse(respuesta.domain);
        this.ConfigurarColumnasGrid();
      });

    this.servicios
      .ObtenerDominiosCapas("DOM_CAP_4")
      .subscribe((respuesta: any) => {
        this.arrayModelo = JSON.parse(respuesta.domain);
        this.ConfigurarColumnasGrid();
      });

    this.servicios
      .ObtenerDominiosCapas("DOM_CAP_5")
      .subscribe((respuesta: any) => {
        this.arrayTipoInfocapa = JSON.parse(respuesta.domain);
        this.ConfigurarColumnasGrid();
      });
  }



  /**
   * Configuración de las columnas del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   */
  ConfigurarColumnasGrid() {
    this.grid.setColumns([
      {
        width: 200,
        id: "name",
        header: [{ text: "Nombre" }, { content: "inputFilter" }],
        align: "center",
        editable: false,
      },
      {
        width: 250,
        id: "alias",
        header: [
          { text: "Alias (Nombre en mapa) *" },
          { content: "inputFilter" },
        ],
        align: "center",
      },
      {
        width: 400,
        id: "url",
        header: [{ text: "URL - Capa *" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 250,
        id: "serviceName",
        header: [{ text: "Nombre servicio" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 130,
        id: "category",
        header: [{ text: "Categoría *" }, { content: "inputFilter" }],
        align: "center",
        editorType: "combobox",
        options: this.arrayCategoria,
      },
      {
        width: 130,
        id: "model",
        header: [{ text: "Modelo" }, { content: "inputFilter" }],
        type: "string",
        align: "center",
        editorType: "combobox",
        options: this.arrayModelo,
      },
      {
        width: 400,
        id: "historical",
        header: [{ text: "Url histórico" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 400,
        id: "statistical",
        header: [{ text: "Url estadística" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 200,
        id: "legend",
        header: [{ text: "Url leyenda" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 250,
        id: "infoType",
        header: [{ text: "Tipo de infocapa *" }, { content: "inputFilter" }],
        type: "string",
        align: "center",
        editorType: "combobox",
        options: this.arrayTipoInfocapa,
      },
      {
        width: 90,
        id: "state",
        header: [{ text: "Estado" }, { content: "inputFilter" }],
        type: "boolean",
      },
      // {
      //   width: 90,
      //   id: "public",
      //   header: [{ text: "Público" }, { content: "inputFilter" }],
      //   type: "boolean",
      // },
      // {
      //   width: 90,
      //   id: "private",
      //   header: [{ text: "Privado" }, { content: "inputFilter" }],
      //   type: "boolean",
      // },
      {
        width: 130,
        id: "downloadable",
        header: [{ text: "Descargar" }, { content: "inputFilter" }],
        type: "boolean",
      },
      {
        width: 200,
        id: "order",
        header: [{ text: "Orden visualización" }, { content: "inputFilter" }],
        align: "center",
        editable: true,
      },
      {
        width: 130,
        id: "action",
        gravity: 1.5,
        header: [{ text: "Acciones", align: "center" }],
        htmlEnable: true,
        align: "center",
        template: function () {
          return "<img  data-toggle='tooltip' data-placement='top' data-toggle='tooltip' title='Previsualizar capa' class='visualizar' src='../../../../assets/img/logos/svg_interna_2/visualizar_capa_int_2.svg' style='display: inline-block; vertical-align: middle; max-width: 18px; max-height: 20px; float:left; margin: 2px;'> <img  data-toggle='tooltip' data-placement='top' data-toggle='tooltip' class='infocapa'   title='InfoCapa' class='infocapa' src='../../../../assets/img/logos/svg_interna_2/infocapa_int_2.svg' style='display: inline-block; vertical-align: middle; max-width: 14px; max-height: 14px; float:left; margin: 2px;'> <img  data-toggle='tooltip' data-placement='top' data-toggle='tooltip' title='Duplicar Capa' class='duplicar' src='../../../../assets/img/logos/svg_interna_2/duplicar_capa.svg' style='display: inline-block; vertical-align: middle; max-width: 14px; max-height: 14px; float:left; margin: 2px;'>";
        },
        editable: false,
      },
    ]);
    this.grid.data.parse(this.datos);
  }

  validarUrlSeleccionada() {
    if (this.urlSeleccionada != "") {
      this.placeholder = "";
    } else {
      this.placeholder = "Ingrese url del servicio";
    }
  }
  /**
   * Filtra la tabla de datos segun el filtro dado
   * @date 28/02/2022
   * @param 
   * @returns
   */
  Filtrar(filtro: string, evento: MouseEvent) {
    evento.stopPropagation();
    const base = "asociadas,noasociadas,error";
    const tmp: HTMLDivElement = <HTMLDivElement>evento.currentTarget;
    if (!this.filtroCategoria.some((item) => filtro == item.categoria)) {
      this.filtroCategoria.push({ categoria: filtro, boton: tmp });
      tmp.style.backgroundColor = "white";
    } else {
      this.filtroCategoria = this.filtroCategoria.filter((item) => !(item.categoria == filtro));
      tmp.style.backgroundColor = "";
    }
    if (this.filtroCategoria.length > 0) {
      const datosFiltro = this.datos.filter((fila) => {
        let estado: boolean = false;
        let esta: boolean = false;

        if (this.filtroCategoria.findIndex((item) => item.categoria == "asociadas") >= 0 && !estado) {
          esta = true;
          estado = fila.state;
        }
        if (this.filtroCategoria.findIndex((item) => item.categoria == "noasociadas") >= 0 && !estado) {
          esta = true;
          estado = !fila.state;
        }
        if (this.filtroCategoria.findIndex((item) => item.categoria == "error") >= 0 && !estado) {
          esta = true;
          estado = fila.error;
        }
        if (this.filtroCategoria.some((item) => base.indexOf(item.categoria) < 0)) {
          const categoria = this.filtroCategoria.some((item) => fila.category == item.categoria);
          if (esta) {
            estado = estado && categoria;
          }
          else {
            estado = categoria;
          }
        }
        return (estado);
      });
      this.grid.data.parse(datosFiltro);
    } else {
      this.grid.data.parse(this.datos);
    }
  }
  /**
   * Limpiar Filtro Categorías
   * @date 28/02/2022
   * @param 
   * @returns
   */
  LimpiarFiltro() {
    this.filtroCategoria.forEach((item) => {
      item.boton.style.backgroundColor = "";
    });
    this.filtroCategoria = [];
  }

  /**
 * Limpia la imagen de vista previa
 * @date 28/02/2022
 * @param 
 * @returns
 */
  LimpiarImagen() {
    this.imagen = undefined;
    this.mensajeVisualizar = "";
  }

}
