    <div class="row col-md-12 rounded p-0">		
		<div class="row col-md-12 rounded p-0">	formulario interno
						<div class="mt-1 pl-2">							
							<dynamic-form *ngIf="fields_plan_hallaz.length > 0" [fields]="fields_plan_hallaz"
								[type]="'form'" [forms]="forms_plan_hallaz" [messageRequired]="false"
								[systemCurrent]="sistema" (buttonEvent)="eventCustom($event)"></dynamic-form>
						</div>	
		</div>				
	</div>


				