import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-grafica-v2',
  templateUrl: './grafica-v2.component.html',
  styleUrls: ['./grafica-v2.component.scss']
})
export class GraficaV2Component implements OnInit {
  @Input() resultadoElementosGrafica: any;
  @ViewChild("baseChart")
  public chart: BaseChartDirective;
  

  elementosGrafica: any;
  elementosGraficaFiltro: any;
  graficaDefecto: any;
  public variables: string[] = ['Sin Información'];


  public labelsAux: string[];
  public pieChartColors = [];

  public barChartType: string = '';
  public barChartLegend: boolean = true;

  public barChartData: any[] = [
    { data: [1], label: 'Sin Información' }
  ];

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }

          },
        }
      }]
    }
  };

  constructor() { }
  ngOnInit() {

  }

  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  ngOnChanges() {
  
    if(this.resultadoElementosGrafica){
      this.barChartType = this.resultadoElementosGrafica[0]["tipo"];
    }
    if(this.barChartType === "pie"){
      this.GraficaV2();
    } else {
      this.ConstruirElementosGrafica();
    }
    if (this.chart != undefined) {
      this.chart.ngOnDestroy();
      // this.chart.chart = 0;

      if (this.elementosGraficaFiltro.barChartData.length > 0) {
        this.chart.datasets = this.elementosGraficaFiltro.barChartData;
        this.chart.labels = this.elementosGraficaFiltro.variables;
        this.chart.ngOnInit();
      }
      else {
        this.chart.datasets = this.barChartData;
        this.chart.labels = this.variables;
      }

    }


  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  public randomize():void {
    this.elementosGraficaFiltro=this.elementosGrafica;    
    if(this.chart!=undefined){
      //console.log(this.elementosGraficaFiltro);
      this.chart.ngOnDestroy();
      // this.chart.chart=0;
      if (this.elementosGraficaFiltro.barChartData.length>0){
        this.chart.datasets = this.elementosGraficaFiltro.barChartData;
        this.chart.labels = this.elementosGraficaFiltro.variables;
  
        this.chart.ngOnInit();
      }else{
 
        this.chart.datasets = this.barChartData;
        this.chart.labels=this.variables;
        this.chart.ngOnInit();
      }

    
    }
  }

  ConstruirElementosGrafica() {
    if (this.resultadoElementosGrafica) {
     
      let xPos = [];
      let yPos = [];
      let objElementoGrafica = [];
      objElementoGrafica["variables"] = [];
      objElementoGrafica["barChartData"] = [];
      for (let i in this.resultadoElementosGrafica) {
        this.insertarX(
          xPos,
          this.resultadoElementosGrafica[i],
          "estado",
          objElementoGrafica
        );
      }

      for (let i in this.resultadoElementosGrafica) {
        this.insertarY(
          xPos,
          yPos,
          this.resultadoElementosGrafica[i],
          "cantidad",
          "estado",
          "elemento",
          objElementoGrafica
        );
      }

      this.elementosGraficaFiltro = objElementoGrafica;

      xPos = [];
      yPos = [];
      objElementoGrafica = [];
      objElementoGrafica["variables"] = [];
      objElementoGrafica["barChartData"] = [];
      for (let i in this.resultadoElementosGrafica) {
        this.insertarX(
          xPos,
          this.resultadoElementosGrafica[i],
          "estado",
          objElementoGrafica
        );

      }

      for (let i in this.resultadoElementosGrafica) {
        this.insertarY(
          xPos,
          yPos,
          this.resultadoElementosGrafica[i],
          "cantidad",
          "estado",
          "elemento",
          objElementoGrafica
        );
      }
      
      this.elementosGrafica = objElementoGrafica;
      if(this.resultadoElementosGrafica[0].color) {
          let colors = this.resultadoElementosGrafica.map(x =>  x.color);
          let colorArray = colors.filter((v, i) => colors.indexOf(v) === i);
          if(colorArray != undefined) {
            this.pieChartColors = colorArray
          }
        }
    }
  }


  insertarX(xPos, obj, llaveLabel, objElementoGrafica) {
    if (xPos[obj[llaveLabel]] == undefined) {
      let posy: number = objElementoGrafica["variables"].length;
      xPos[obj[llaveLabel]] = posy;
      objElementoGrafica["variables"][posy] = obj[llaveLabel];
    }
  }

  insertarY(
    xPos,
    yPos,
    obj,
    llaveValor,
    llaveLabelX,
    llaveLabelY,
    objElementoGrafica
  ) {
    let posy: number = objElementoGrafica["barChartData"].length;

    if (yPos[obj[llaveLabelY]] == undefined) {
      yPos[obj[llaveLabelY]] = posy;
      objElementoGrafica["barChartData"][posy] = {
        data: new Array(objElementoGrafica["variables"].length),
        label: obj[llaveLabelY]
      };
      if(obj["stack"]){
        objElementoGrafica["barChartData"][posy]["stack"] = obj["stack"];
      }

      if(obj["color"]){
        objElementoGrafica["barChartData"][posy]["backgroundColor"] = obj["color"];
        objElementoGrafica["barChartData"][posy]["borderColor"] = obj["color"];
      }


      for (let i = 0; i < objElementoGrafica["variables"].length; i++) {
        objElementoGrafica["barChartData"][posy]["data"][i] = 0;
      }
    }
    let xpostmp = xPos[obj[llaveLabelX]];
    let ypostmp = yPos[obj[llaveLabelY]];
    objElementoGrafica["barChartData"][ypostmp]["data"][xpostmp] =
      obj[llaveValor];
  }

  GraficaV2() {
    //eje x
    let ejeX = this.resultadoElementosGrafica.map(x => x.estado);    
    let objElementoGrafica = [];
    let arrayData = [];
    let arrayTemp = [];
    objElementoGrafica["variables"] = [];
    objElementoGrafica["barChartData"] = [];
    objElementoGrafica["variables"] = ejeX;
    let names = this.resultadoElementosGrafica.map(x =>  x.elemento);
    let labelArray = names.filter((v, i) => names.indexOf(v) === i);
    if(this.resultadoElementosGrafica[0].color) {
      let colors = this.resultadoElementosGrafica.map(x =>  x.color);
      let colorArray = colors.filter((v, i) => colors.indexOf(v) === i);
      if(colorArray != undefined) {
        this.pieChartColors = [
          {
            backgroundColor: colorArray
          }
        ]
      }
    }
    for (let label of labelArray) {
      arrayData = this.resultadoElementosGrafica.map(x => {
        if(x.elemento != label){
          return 0;
        }
        else{
          return x.cantidad;
        }
      });

     
      arrayTemp.push({
        data: arrayData,
        label: label,
        
       });
    }
    
    objElementoGrafica["barChartData"] = arrayTemp;
    this.elementosGraficaFiltro = objElementoGrafica;
  }


}
