import { Component, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../shared/auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Perfil } from "./Perfil";
import { FwLoginService } from "app/pages/content-layout-page/login/fw-login.service";
import { GlobalSeguridad } from "app/global-seguridad";
import { Mensaje } from "app/shared/components/mensajes/mensaje.modelo";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { NotificationService } from '@shared/notification-sidebar/notification.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PopupReporteadorSencilloComponent } from "@shared/components/popups/popup-reporteador-sencillo/popup-reporteador-sencillo.component";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
    currentLang = "es";
    toggleClass = "ft-maximize";
    placement: string;
    public isCollapsed = true;
    infoUsuario: Perfil;
    logoSigmaNav: string;
    logoEmpresaNav: string;
    fotoPerfilDefecto: string;
    navbar: {};
    usu_foto: string;
    mensaje = new Mensaje("", "", "");
    data: any;
    @Output()
    toggleHideSidebar = new EventEmitter<Object>();
    public config: any = {};
    notifications$: Observable<Array<any>>;
    urlMoodle: any = "";

    constructor(
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private servicioLogin: FwLoginService,
        private seguridadGlobal: GlobalSeguridad,
        private cdRef: ChangeDetectorRef,
        public notificationService: NotificationService,
        private modalService: NgbModal,
    ) {
        this.infoUsuario = JSON.parse(sessionStorage.getItem("infoUsuario"));
        translate.addLangs(['en', 'es', 'pt', 'de']);
        translate.setDefaultLang('es');
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "es");
    }

    ngOnInit() {
        this.config = this.configService.templateConf;
        this.navbar = JSON.parse(sessionStorage.getItem("cargaBase"))["navbar"];
        this.logoSigmaNav = this.navbar["iconoSigmaNav"];
        this.logoEmpresaNav = this.navbar["iconoEmpresaNav"];
        this.fotoPerfilDefecto = JSON.parse(sessionStorage.getItem("webConfig"))[
            "urlFotoPerfilDefecto"
        ];

        this.urlMoodle = JSON.parse(sessionStorage.getItem("webConfig"))[
            "urlMoodle"
        ];

        /*this.notifications$ = this.notificationService.notificationSignalR$.pipe(
            tap(() => this.cdRef.detectChanges()));*/
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngAfterViewInit() {
        if (this.config.layout.dir) {
            const dir = this.config.layout.dir;
            if (dir === "rtl") {
                this.placement = "bottom-left";
            } else if (dir === "ltr") {
                this.placement = "bottom-right";
            }
        }
    }


    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === "ft-maximize") {
            this.toggleClass = "ft-minimize";
        } else {
            this.toggleClass = "ft-maximize";
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.emitChange(true);
    }

    toggleSidebar() {
        const appSidebar = document.getElementsByClassName("app-sidebar")[0];
        if (appSidebar.classList.contains("hide-sidebar")) {
            this.toggleHideSidebar.emit(false);
        } else {
            this.toggleHideSidebar.emit(true);
        }
    }

    CerrarSesion() {
        this.authService.logout();
        this.router.navigate(["/content-layout/login"], {
            relativeTo: this.route.root
        });

        this.servicioLogin
            .cerrarSesion(this.seguridadGlobal.sistema, this.seguridadGlobal.usuario, this.seguridadGlobal.contrasena)
            .subscribe(data => {
                this.data = data;
                if (this.data.respuesta == "true") {

                    this.seguridadGlobal.atributoSesion = "";
                    this.seguridadGlobal.usuario = "";
                    this.seguridadGlobal.contrasena = "";
                    this.seguridadGlobal.sistema = "";
                    this.seguridadGlobal.activada = "false";
                }
                else {
                    console.log("No elimino la sesión en el servidor", data);
                    // window.location.reload();
                }
            });
    }

    AbrirPopup() {
        try {
            let modalRef = this.modalService.open(PopupReporteadorSencilloComponent, { backdrop: 'static', size: 'lg', centered: true });

            modalRef.result.then((resultado) => {


            });

            let camposSet: any = {};
            camposSet["report"] = "~/reports/corpocaldas/SIS_01/consulta-tramites/consulta-tramites.mrt";

            modalRef.componentInstance.esPopup = true;
            modalRef.componentInstance.camposSet = camposSet;
            modalRef.componentInstance.modalRef = modalRef;


        } catch (e) {
            console.log("Error: " + e);
        }

    }
}