import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicService } from '../dynamic.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ngselect',
  template: `
      <div [formGroup]="form">
        <ng-select *ngIf="!field.idSigmaconsulta"
        bindLabel="text"
        bindValue="id"
        [items]="field.options"
        [formControlName]="field.name"
        [multiple]="field.multiple"
        [closeOnSelect]="field.closeOnSelect ? field.closeOnSelect: 'true'"
        [searchable]="field.searchable"
        [required]="field.required">
        </ng-select>
        <ng-select *ngIf="field.idSigmaconsulta"
        bindLabel="text"
        bindValue="id"
        [items]="domains"
        [formControlName]="field.name"
        [multiple]="field.multiple"
        [closeOnSelect]="field.closeOnSelect ? field.closeOnSelect: 'true'"
        [searchable]="field.searchable"
        [required]="field.required">
        </ng-select>
      </div>
    `
})
export class SelectComponent implements OnInit{
  @Input() field: any = {};
  @Input() form: FormGroup;
  domains = [];
  sistema: any;

  constructor(private service: DynamicService) {
    this.sistema = localStorage.getItem('sistemaLogueado');
  /*  if (this.field.idSigmaconsulta) {
      this.getDomains(this.field.idSigmaconsulta);
    }*/
  }

  ngOnInit()
  {
    if (this.field.idSigmaconsulta) {
      this.getDomains(this.field.idSigmaconsulta);
    }
  }

  getDomains(idSigmaconsulta) {
    try {
      const filter = '{}';
      // const filter = '{"filter": "valor"}';
      this.service.getServicioSigmaConsulta(this.sistema, idSigmaconsulta, filter, '')
        .subscribe(data => {
          if (data.respuesta === 'true') {
            this.domains = JSON.parse(data.datos).domains;
          } else {
            this.domains = [];
          }
        });
    } catch (error) {
      console.log('Error: NgSelectComponent getDomains(): ' + error);
    }
  }
}
