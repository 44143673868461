import { Component, OnInit, Output, EventEmitter,ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';
import { TServicioNotificacionService } from "./t-servicio-notificacion-grupos.service";
import { FwVisorWebAppBuilderService } from "app/shared/components/visores/visor-webappbuilder/fw-visor-web-app-builder.service";



@Component({
  selector: "app-lista-notificacion-grupos",
  templateUrl: "./lista-notificacion-grupos.component.html",
  styleUrls: ["./lista-notificacion-grupos.component.scss"],
  // encapsulation: ViewEncapsulation.None // <------
})
export class ListaNotificacionGruposComponent implements OnInit {
  mensajes: any;
  @Output() totalesMensajes = new EventEmitter<number>();
  constructor(
    private servicioMensajes: TServicioNotificacionService,
    private servicioWAB: FwVisorWebAppBuilderService,
    public router:Router) { }

  ngOnInit() {
    this.servicioMensajes.getMensajes().subscribe(data => {
      // this.mensajes = data;
      this.mensajes = [
        {
          "titulo": "Exceso velocidad",
          "cantidad": 3,
          "clase": "ft-download float-left d-block font-large-0 mt-0 mr-2",
          "color": "info",
          "notificaciones": [
            {
              "texto": "Click acá para Descargar archivo",
              "ruta": "",
              "urlDescarga": "https://download.sublimetext.com/Sublime%20Text%20Build%203176%20Setup.exe",
              "estado": 1
            },
            {
              "texto": "Se ha creado la mirco ruta MP01R1",
              "filtro": [
                {
                  "cap_codigo": "CAP_17",
                  filtro: "strtolowercase(mic_codigo) like '%mp01r1%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_PLANEACION_LEAFLET_01",
              "ruta": "/planeacion",
              "urlDescarga": "",
              "estado": 0,              
            },
            {
              "texto": "El vehículo 1112 ha salido de la zona",
              "ruta": "/ejecucion",
              "filtro": [
                {
                  "cap_codigo": "CAP_24",
                  filtro: "strtolowercase(veh_codigo) like '%1112%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_EJECUCION_LEAFLET_01",
              "urlDescarga": "",
              "estado": 0,
              
            }
          ]
        },
        {
          "titulo": "Paradas prolongadas",
          "cantidad": 1,
          "clase": "ft-bell float-left d-block font-large-0 mt-0 mr-2",
          "color": "danger",
          "notificaciones": [            
            {
              "texto": "El vehículo 111 lleva 30 minutos detenido",
              "ruta": "/ejecucion",
              "filtro": [
                {
                  "cap_codigo": "CAP_24",
                  filtro: "strtolowercase(veh_codigo) like '%111%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_EJECUCION_LEAFLET_01",
              "urlDescarga": "",
              "estado": 0,
              
            }
          ]
        },
        {
          "titulo": "Puertas cerradas",
          "cantidad": 3,
          "clase": "ft-download float-left d-block font-large-0 mt-0 mr-2",
          "color": "info",
          "notificaciones": [
            {
              "texto": "Click acá para Descargar archivo",
              "ruta": "",
              "urlDescarga": "https://download.sublimetext.com/Sublime%20Text%20Build%203176%20Setup.exe",
              "estado": 1
            },
            {
              "texto": "Se ha creado la mirco ruta MP01R1",
              "filtro": [
                {
                  "cap_codigo": "CAP_17",
                  filtro: "strtolowercase(mic_codigo) like '%mp01r1%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_PLANEACION_LEAFLET_01",
              "ruta": "/planeacion",
              "urlDescarga": "",
              "estado": 0,
              
            },
            {
              "texto": "El vehículo 1112 ha salido de la zona",
              "ruta": "/ejecucion",
              "filtro": [
                {
                  "cap_codigo": "CAP_24",
                  filtro: "strtolowercase(veh_codigo) like '%1112%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_EJECUCION_LEAFLET_01",
              "urlDescarga": "",
              "estado": 0,
              
            }
          ]
        },
        {
          "titulo": "Salio de zona",
          "cantidad": 2,
          "clase": "ft-download float-left d-block font-large-0 mt-0 mr-2",
          "color": "info",
          "notificaciones": [
            {
              "texto": "Click acá para Descargar archivo",
              "ruta": "",
              "urlDescarga": "https://download.sublimetext.com/Sublime%20Text%20Build%203176%20Setup.exe",
              "estado": 1
            },
            {
              "texto": "Se ha creado la mirco ruta MP01R1",
              "filtro": [
                {
                  "cap_codigo": "CAP_17",
                  filtro: "strtolowercase(mic_codigo) like '%mp01r1%'",
                  "zoom": true
                }
              ],
              "visor":"leaflet",
              "idmapa": "MAP_PLANEACION_LEAFLET_01",
              "ruta": "/planeacion",
              "urlDescarga": "",
              "estado": 0,              
            }
          ]
        },        
        {
          "titulo": "Salio de zona Web App Builder",
          "cantidad": 1,
          "clase": "ft-download float-left d-block font-large-0 mt-0 mr-2",
          "color": "info",
          "notificaciones": [            
            {
              "texto": "El vehículo 1112 ha salido de la zona",
              "ruta": "/visorArcgis",
              "filtro": {"wms_CAP_27":{"Ultima Posicion": "strToLowerCase(veh_codigo) LIKE '%1112%'","zoom": "Ultima Posicion"}},
              "visor":"webAppBuilder",
              "idmapa": "MAP_SEGUIMIENTO_WAB_01",
              "urlDescarga": "",
              "estado": 0,
              
            }
          ]
        }
      ];
      let cantidad = this.obtenerCantidadNotificaciones();
      this.totalesMensajes.emit(cantidad);
      this.totalesMensajes.next(cantidad);
      
      
      //setInterval(() =>this.demoAgregarNotificacionesVelocidad(), 10000);
      //setInterval(() =>this.demoAgregarNotificacionesParadas(), 15000);
      
    });
  }

  obtenerCantidadNotificaciones(){
    let cantidad=0;
    for(let i=0;i<this.mensajes.length;i++){
      cantidad = cantidad+this.mensajes[i]["cantidad"];
    }
    return cantidad;
  }

  eliminarMensaje(index,mensaje) {
    mensaje.cantidad=mensaje.cantidad-1;
    mensaje.notificaciones.splice(index, 1);
    this.totalesMensajes.emit(this.obtenerCantidadNotificaciones());
    this.totalesMensajes.next(this.obtenerCantidadNotificaciones());
  }

  zoomMapa(valores){    
    let visor;
    if(valores["visor"]=="leaflet"){
      let filtroMapa = valores["filtro"];
    }
    if(valores["visor"]=="webAppBuilder"){
      visor = this.servicioWAB.visores[valores["idmapa"]];  
      let filtroMapa = valores["filtro"];
      visor.EnviarFiltroIframe(filtroMapa);        
    }
    
    let currentUrl = this.router.url;
    let a = "";
    console.log('URL:' + window.location.href);
    console.log('Path:' + window.location.pathname);
    console.log('Host:' + window.location.host);
    console.log('Hostname:' + window.location.hostname);
    console.log('Origin:' + window.location.origin);
    console.log('Port:' + window.location.port);
    console.log('Search String:' + window.location.search);
  }

  demoAgregarNotificacionesVelocidad(){
    this.mensajes[0].cantidad++;
    this.totalesMensajes.emit(this.obtenerCantidadNotificaciones());
    this.totalesMensajes.next(this.obtenerCantidadNotificaciones());
    this.mensajes[0].notificaciones.push({
      "texto": "Se ha creado la mirco ruta MP01R1",
      "filtro": [
        {
          "cap_codigo": "CAP_17",
          filtro: "strtolowercase(mic_codigo) like '%mp01r1%'",
          "zoom": true
        }
      ],
      "idmapa": "MAP_PLANEACION_LEAFLET_01",
      "ruta": "/planeacion",
      "urlDescarga": "",
      "estado": 0,
      
    });
  }

  demoAgregarNotificacionesParadas(){
    this.mensajes[1].cantidad++;
    this.totalesMensajes.emit(this.obtenerCantidadNotificaciones());
    this.totalesMensajes.next(this.obtenerCantidadNotificaciones());
    this.mensajes[1].notificaciones.push({
      "texto": "El vehículo 1112 ha salido de la zona",
      "ruta": "/ejecucion",
      "filtro": [
        {
          "cap_codigo": "CAP_24",
          filtro: "strtolowercase(veh_codigo) like '%1112%'",
          "zoom": true
        }
      ],
      "idmapa": "MAP_EJECUCION_LEAFLET_01",
      "urlDescarga": "",
      "estado": 0,
      
    });
  }
}
