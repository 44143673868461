import { Component } from '@angular/core';
import { FwSidebarService } from '@shared/sidebar-original/fw-sidebar.service';



@Component({ 
  selector: 'core-cargador-iframe',
  templateUrl: './cargador-iframe.component.html',
  styleUrls: ['./cargador-iframe.component.scss']
})


export class CargadorIframeComponent {  

  // url:any = JSON.parse(sessionStorage.getItem("cargaBase"))["url"]["urlOwncloud"];    
  url:any;
  constructor(private servicioSidebar:FwSidebarService) { }  

  ngOnInit() {  
    this.url = this.servicioSidebar.obtenerItem()["urlOnepage"];
    (<HTMLImageElement>document.querySelector("#iframeOwncloud")).src = this.url;
  }
}

