import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IVisorState } from 'app/visor/shared/models/mapModels';
import { IConfigLeafletState } from '../reducers';

export const getVisorState = createFeatureSelector<IVisorState>('visor');

export const getConfigLeafletState = createSelector(getVisorState, (state: IVisorState) => state.configLeaflet);

export const getWidgetsState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.map?.mapJson?.widgets);

export const getMapState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.map);

export const getBaseMapsState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.map?.mapJson?.mapasBase);

export const getDefaultMapState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.map?.mapJson?.mapasBase[0]);

export const getLoading = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.loading);

export const getLoaded = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.loaded);

export const getServicesState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => state?.service);

export const getServicesActiveState = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => (
    state?.service?.filter(service => service.serActivo).map(service => (
        {
            ...service,
            serCapasActivas: service?.serCapasActivas?.filter(layer => layer.activo)
        }
    ))
));

export const getConfigWidgetConsultar = createSelector(getConfigLeafletState, (state: IConfigLeafletState) => (
    state?.map?.mapJson?.widgets.filter(widget => widget.nombre == 'consultar').map(
        widget => {
            if (widget.nombre==='consultar') {
                return widget.configuracion;
            }
        }
    )
));
