<div class="card">
    <div class="m-2 p-2">
        <section *ngIf="configuracionColumnasListas != undefined" id="selection">
            <div class="row">
                <div class="col-12">
                    <div class="content-header">
                        <span style="font-size: 1.4rem;">{{configuracionColumnasListas.titulo}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pr-0">
                    <div class="input-group mt-1 mb-0">
                        <input type="text" id="txt_filtrar" class="rounded form-control" placeholder="Valor a filtrar"
                            aria-label="Escriba el valor a filtrar" aria-describedby="basic-addon2"
                            (keyup)='filtrar($event)' [(ngModel)]="txt_filtrar">
                        <div class="input-group-append">
                            <button (click)="buscarFiltro()" class="btn btn-outline-secondary" type="button"
                                [hidden]="!datosConfiguracionLista.paginacion"><em class="ft-search"></em></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 pr-0">
                    <ngx-datatable #table class="bootstrap selection-cell" [scrollbarH]="true"
                        [rows]="elementosListaInformacion" [rowHeight]="'auto'" [columnMode]="'force'"
                        [columns]="configuracionColumnasListas.columnas" [headerHeight]="50" [footerHeight]="50"
                        [externalPaging]="datosConfiguracionLista.paginacion" [selected]="selected"
                        [selectionType]="'cell'" [loadingIndicator]="loading" [limit]="datosConfiguracionLista.size"
                        [count]="datosConfiguracionLista.totalElements" [offset]="datosConfiguracionLista.pageNumber"
                        (select)="salida.next($event)" (activate)="onActivate($event)" (page)="page.next($event)">
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template
                                let-rowCount="datosConfiguracionLista.totalElements" let-pageSize="pageSize"
                                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                let-isVisible="isVisible">
                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage" [size]="pageSize"
                                    [hidden]="!((datosConfiguracionLista.cantidadRegistros / pageSize) > 1)"
                                    [count]="datosConfiguracionLista.totalElements"
                                    (change)="pagechange($event)">
                                </datatable-pager>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>

                </div>
            </div>
        </section>
    </div>
</div>