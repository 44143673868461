import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import * as L from "leaflet";
import { GlobalSeguridad } from "@seguridad";

@Injectable({
  providedIn: "root",
})
export class GestionServiciosService {
  capas: any;

  constructor(private http: HttpClient, private seguridadGlobal: GlobalSeguridad) { }

  /**
   * Obtiene las capas asociadas a una url
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  DescubrirServidor(url: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).descubrirServidor, '"' + url + '"', {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Obtiene las estadísticas de los servicios
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetEstadisticasServicios(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).estadisticasServicios);
  }

  /**
   * Obtiene las categorías de las capas
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetCategorias(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).categorias);
  }

  /**
   * Obtiene las capas, según un filtro dado
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetCapasFiltradas(find: string): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).capasFiltradas + find);
  }

  /**
   * Obtiene los servicios
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  GetServicios(): any {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).servicios);
  }

  /**
   * Hace un llamado al componente capas para mostrar la lista
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  LlenarCapas(capasFiltradas) {
    this.capas.LlenarListaCapas(capasFiltradas);
  }

  /**
   * Permite hacer una petición http dada una url y un tipo de respuesta esperado
   * @param url url del servicio que se va a consultar
   * @param respuestaEsperada tipo de respuesta que se espera de dicha consulta
   * @returns un observable con la respuesta del servicio
   */
  query(url: string, respuestaEsperada: any): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");
    headersT.append('Content-Types', this.seguridadGlobal.atributoSesion);

    return this.http.get(url, {
      headers: headersT,
      responseType: respuestaEsperada,
    });
  }

  /**
   * Permite crear una uri que se usa para hacer una petición http
   * @param layer nombre de la capa
   * @param bbox Cuadro delimitador para la extensión del mapa
   * @returns la uri que se puede usar para hacer una petición http
   */
  getParamGeoserver(layer: string, bbox: string) {
    return L.Util.getParamString({
      request: "GetMap",
      service: "WMS",
      version: "2.0.0",
      layers: layer,
      srs: "EPSG:4326",
      bbox: bbox,
      width: "300",
      height: "200",
      format: "image/png",
    });
  }

  /**
   * Permite crear una uri que se usa para hacer una petición http
   * @param layer nombre de la capa
   * @param bbox Cuadro delimitador para la extensión del mapa
   * @returns la uri que se puede usar para hacer una petición http
   */
  getParamArcgis(layer: string, bbox: string) {
    return L.Util.getParamString({
      bbox: bbox,
      f: "image",
      width: "300",
      height: "200",
    });
  }

  /**
   * Asocia la capa seleccionada por el usuario a la base de datos.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  AsociarCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).asociarCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Disocia la capa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  DisociarCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.delete(JSON.parse(sessionStorage.getItem('webConfig')).disociarCapa + entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Edita la capa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  EditarCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.put(JSON.parse(sessionStorage.getItem('webConfig')).editarCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Edita la infocapa seleccionada por el usuario.
   * @author Elisabeth Campuzano Jaramillo
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  EditarInfoCapa(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.put(JSON.parse(sessionStorage.getItem('webConfig')).editarInfoCapa, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Obtiene la lista de gestión de alertas
   * @author Elisabeth Campuzano Jaramillo
   * @date 08/01/2022
   * @param
   * @returns
   */
  ObtenerDatosAlertas(category: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).obtenerAlertas, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  /**
   * Obtiene los dominios para poblar los select del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   * @param find dominio a buscar
   */
  ObtenerDominiosCapas(find: string) {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).obtenerDominiosCapas + find, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  /**
   * Obtiene los campos de una capa
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns
   */
  ObtenerCampos(url: string, urlCapa: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "GET");
    headersT.append("Access-Control-Allow-Origin", "*");

    const urlEncode = encodeURIComponent(urlCapa);
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).urlOuterLayer + url + urlEncode, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  /**
   * Actualiza la alerta de la capa.
   * @author Santiago Hernandez
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  ActualizarAlerta(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).actualizarAlerta, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Editar la alerta de la capa.
   * @author Santiago Hernandez
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  EditarAlerta(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).editarAlerta, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Disocia la alerta seleccionada por el usuario.
   * @author Santiago Hernandez
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  DisociarAlerta(code) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "DELETE");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.delete(JSON.parse(sessionStorage.getItem('webConfig')).disociarAlerta + code, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  /**
   * Asocia la alerta seleccionada por el usuario a la base de datos.
   * @author Santiago Hernandez
   * @date 17/01/2022
   * @param entrada
   * @returns
   */
  AsociarAlerta(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).asociarAlerta, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Obtiene la lista de gestión de categorías
   * @author Elisabeth Campuzano Jaramillo
   * @date 29/03/2022
   * @param
   * @returns
   */
  ObtenerDatosCategorias(category: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).obtenerCategorias, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  /**
   * Asocia la categoria
   * @author Elisabeth Campuzano Jaramillo
   * @date 29/03/2022
   * @param entrada
   * @returns
   */
  AdicionarCategoria(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).adicionarCategoria, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Edita la categoría
   * @author Elisabeth Campuzano Jaramillo
   * @date 29/03/2022
   * @param entrada
   * @returns
   */
  EditarCategoria(entrada) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.post(JSON.parse(sessionStorage.getItem('webConfig')).editarCategoria, entrada, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "text/plain",
        'Content-Types': this.seguridadGlobal.atributoSesion
      }),
    });
  }

  /**
   * Elimina la categoría
   * @author Elisabeth Campuzano Jaramillo
   * @date 29/03/2022
   * @param entrada
   * @returns
   */
  EliminarCategoria(codigo) {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "POST");
    headersT.append("Access-Control-Allow-Origin", "*");

    return this.http.delete(JSON.parse(sessionStorage.getItem('webConfig')).eliminarCategoria + codigo, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }
}
