<div class="card mt-0 p-0">
    <div class="m-1 p-1">
      <section *ngIf="columns">

        <div class="content-header mt-0" *ngIf="title != ''">
          <span style="font-size: 1.4rem;">{{title}}</span>
        </div>

        <div>
          <ngx-datatable #table class="bootstrap selection-cell" [rows]="rows" [columns]="columns"
            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="size"
            [scrollbarH]="true" [selected]="selected" [messages]="messages" [externalPaging]="paging"
            [selectionType]="type" [loadingIndicator]="loading" [offset]="offset" [count]="rowCount"
            (select)="salida.next($event)" (page)="page.next($event)">
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                  [hidden]="!((rowCount / pageSize) > 1)" [count]="rowCount" (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </section>
    </div>
</div>
