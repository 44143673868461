import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-reporteador-sencillo',
  templateUrl: './popup-reporteador-sencillo.component.html',
  styleUrls: ['./popup-reporteador-sencillo.component.scss']
})
export class PopupReporteadorSencilloComponent implements OnInit {

  camposSet: any = {};
  modalRef: any;
  url: string = JSON.parse(sessionStorage.getItem('webConfig'))["urlReportes"] + "?entrada=";
  report: string;

  constructor() { }

  ngOnInit() {
    this.report = this.camposSet["report"];
    (<HTMLImageElement>document.querySelector("#iframeReporteador")).src =
      this.url + this.report;
      console.log(this.url)

  }

  cerrarModal() {
    this.modalRef.close();
  }
  

}
