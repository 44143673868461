/**
  * @description Carga la configuración y actualiza la información de los objetos de control que ingresan como input
  * @author Lukas Mesa Buriticá
  * @date 22/04/2020
  */
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { AppSelector, AppStore } from '@shared/store/app-state-store';
import { IAPPState, IObjetoControl, ISigmaConsulta } from '@shared/store/estados/estados';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-objetos-control-v3',
  templateUrl: './objetos-control-v3.component.html',
  styleUrls: ['./objetos-control-v3.component.scss']
})
export class ObjetosControlV3Component implements OnInit, OnDestroy {

  @ViewChild('myCarousel') myCarousel: NguCarousel<NguCarouselStore>;
  @Input() moduloID: string;
  carouselConfig: NguCarouselConfig;
  coleccionObjetosControl$: Observable<Array<IObjetoControl>>;
  objetoControlActual$: Observable<IObjetoControl>;
  clase: string = "ObjetosControlV3Component";
  mostrar: boolean = true;
  objSigmaConsulta: Subscription;
  appStore: AppStore;
  appSelector: AppSelector;
  operacion:ISigmaConsulta;
  constructor(private cdr: ChangeDetectorRef, private store: Store<IAPPState>) {
    this.appStore = new AppStore();
    this.appSelector = new AppSelector();
  }
  ngOnDestroy(): void {
    this?.objSigmaConsulta?.unsubscribe();
  }

  /**
    * @description Carga la configuración del carrusel
    * @author Lukas Mesa Buriticá
    * @date 22/04/2020
    */
  ngOnInit() {
    try {
      this.carouselConfig = {
        grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
        slide: 1,
        // speed: 900,
        // interval: 4000,
        animation: 'lazy',
        point: {
          visible: false,
          hideOnSingleSlide: false
        },
        load: 2,
        touch: true,
        loop: true,
        custom: 'banner',
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
    this.objSigmaConsulta = this.store.select(this.appSelector.getSigmaConsultaObjControl(), { ruta: this.moduloID }).subscribe((operacion) => {
      if (operacion != undefined && operacion.id != "" && JSON.stringify(this.operacion) != JSON.stringify(operacion)) {
        this.operacion=operacion;
        this.store.dispatch(this.appStore.cargarObjetosControl(operacion));
      }
    });
    this.coleccionObjetosControl$ = this.store.select(this.appSelector.getObjetosControl(), { ruta: this.moduloID, codigo: "" });
    this.objetoControlActual$ = this.store.select(this.appSelector.getObjetoControl(), { ruta: this.moduloID, codigo: "" })
  }
  /**
   * @description Muestra u oculta la cantidad del objeto de control que ingresa por parámetro
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  mostrarCantidad(elemento): boolean {
    try {
      let retorno = false;
      if (elemento.json.obc_moscan === "si") {
        retorno = false;
      } else {
        retorno = true;
      }
      return retorno;
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }
  /**
    * @description Selecciona el objeto de control en el Store
    * @author Carlos Alberto Gonzalez
    * @date 17/12/2020
    */
  seleccionarObjetoControl(objeto: IObjetoControl) {
    this.store.dispatch(this.appStore.asignarObjetoControl({ ruta: this.moduloID, codigo: objeto.obc_codigo }));
  }
} 