import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import "rxjs/add/operator/map"; //para escuchar los cambio en la aplicaci´´on
import { Observable } from "rxjs/Rx";
import {Globals} from '../../globals';

@Injectable()
export class FwSidebarService {
  item:any;
  editable = false;
  constructor(
    public http: HttpClient,
    private globals:Globals
  ) {}

  public getMenu(): Observable<any> {
    return this.http
      //.get(this.globals.webConfig["servicioFw"]+"/")
      .get("http://52.27.212.55/fwdesarrollo1/herramientas/MenuAngular.json");
  }

  asignarItem(item){    
    this.item = item;
  }

  obtenerItem(){
    return this.item;
  }

}
