import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class CargaMasivaService {

  header: any = {};

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad,
  ) {

  }


  getServicioCargaMasiva(fd): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/CargaMasivaV3",
      fd,
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }
}
