/**
  * @description Carga la configuración y actualiza la información de los objetos de control que ingresan como input
  * @author Lukas Mesa Buriticá
  * @date 22/04/2020
  */
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';

@Component({
  selector: 'app-objetos-control',
  templateUrl: './objetos-control.component.html',
  styleUrls: ['./objetos-control.component.scss']
})
export class ObjetosControlComponent implements OnInit, AfterViewInit {

  @ViewChild('myCarousel') myCarousel: NguCarousel<NguCarouselStore>;

  carouselConfig: NguCarouselConfig;
  @Input() coleccionObjetosControl: any = [];
  @Input() objetoControlActual: any = [];
  @Output() salida = new EventEmitter();
  clase: string = "ObjetosControlComponent";
  mostrar: boolean = true;
  
  constructor(private cdr: ChangeDetectorRef) {}

  /**
    * @description Carga la configuración del carrusel
    * @author Lukas Mesa Buriticá
    * @date 22/04/2020
    */
  ngOnInit() {
    try {
      this.carouselConfig = {
        grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
        slide: 1,
        // speed: 900,
        // interval: 4000,
        animation: 'lazy',
        point: {
          visible: false,
          hideOnSingleSlide: false
        },
        load: 2,
        touch: true,
        loop: true,
        custom: 'banner',
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Después de inicializar el componente, actualiza los cambios en los elementos dinámicos
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  ngAfterViewInit() {
    try {
      this.cdr.detectChanges();  
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Detecta algún cambio en las propiedades
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  ngOnChanges() {
    try {
      if (this.coleccionObjetosControl != undefined) {
        if (this.coleccionObjetosControl.length > 0) {
          for (let index = 0; index < this.coleccionObjetosControl.length; index++) {
            this.mostrarCantidad(this.coleccionObjetosControl[index]);  
          }
          if (this.coleccionObjetosControl.length > this.carouselConfig.grid.xs) {
            this.mostrar = false;
          } else{
            this.mostrar = true;
          }
        }
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Muestra u oculta la cantidad del objeto de control que ingresa por parámetro
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  mostrarCantidad(elemento) : boolean{
    try {
      let retorno = false;
      if (elemento.json.obc_moscan === "si"){
        retorno = false;
      } else {
        retorno = true;
      }
      return retorno;
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

}
