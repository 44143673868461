import { createReducer, on, Action } from '@ngrx/store';

import { IBaseMap } from './../../shared/models/baseMap';
import * as fromActions from '../actions';

export interface IGalleryMapState {
    baseMap: IBaseMap;
    opened: boolean;
}

export const galleryMapState: IGalleryMapState = {
    baseMap: undefined,
    opened: false,
};

const featureReducerGalleryMap = createReducer(
    galleryMapState,
    on(fromActions.ToggleGalleryMap, (state) => ({ ...state, opened: !state.opened })),
    on(fromActions.SetbaseMap, (state, { payload }) => ({ ...state, baseMap: payload })),
    on(fromActions.CloseGalleryMap, (state) => ({ ...state, opened: false }))
);

export function reducerGalleryMap(state = galleryMapState, action: Action): IGalleryMapState {
    return featureReducerGalleryMap(state, action);
}
