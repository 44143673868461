import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Mensaje } from '@mensaje';
import { FormControl, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors } from '@angular/forms';
import { ServicioUtilidadesService } from 'app/shared/services/servicio-utilidades.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSeguridad } from '@seguridad';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisorDatosService } from 'app/visor/shared/services/visor-datos.service';
import { SharedService } from '@shared/shared.service';


@Component({
  selector: 'app-formulario-prueba',
  templateUrl: './formulario-prueba.component.html',
  styleUrls: ['./formulario-prueba.component.scss']
})
export class FormularioPruebaComponent implements OnInit {

  bloqueDominios: any = true;
  bloqueoBoton: any = true;
  mensaje: Mensaje;
  modelo: any;
  regularForm: FormGroup;
  resultadosDominios: any;
  per_codigo: any;
  vis_tipo: any;
  campoObligatorio: any;
  fields_plan_hallaz: any[] = [];
  forms_plan_infjso: any = {};
  forms_plan_hallaz: any = {};

  modalRef: any;
  sistema = sessionStorage.getItem('sistemaLogueado');




  arrTipoIdentificacion: any = [];

  arrTipoTramite: any;

  estadoCheck: any = false;



  constructor(
    public utilidades: ServicioUtilidadesService, private servicioGlobal: GlobalSeguridad, private visorDatosService: VisorDatosService, private sharedService: SharedService) { this.mensaje = new Mensaje("", "", ""); }

  ngOnInit() {
/* this.regularForm = new FormGroup({
      'int_tipdoc': new FormControl(null, [Validators.required]),
      'int_identi': new FormControl(null, [Validators.required]),
      'int_nombre': new FormControl(null, [Validators.required]),
      'int_email': new FormControl(null, [Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')])]),
      'int_entida': new FormControl(null, [Validators.required]),
      'int_usoinf': new FormControl(null, [Validators.required]),
      'int_licuso': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });*/

    this.cargarConfiguracionFormularioDinamicoPorTipo('TESTING2');

    /*this.visorDatosService.ObtenerDominiosCapas('TipoIdenti').subscribe((respuesta2: any) => {
      const domains = JSON.parse(respuesta2.domain);
      this.arrTipoIdentificacion = [...domains];
      this.bloqueDominios = false;
    });*/


  }


  cargarConfiguracionFormularioDinamicoPorTipo(tipo) {
    const funcion = 'FORMULARIO_DINAMICO_POR_TIPO';
    const filtro = '{"sistema":"' + this.sistema +
      '","id":"' + tipo + '"}';
    this.visorDatosService.getServicioSigmaConsulta(
      this.sistema,
      funcion, filtro, '').subscribe(res => {
        res = JSON.parse(res.datos);
        for (const i of res.fields) {
          switch (i.fdi_codigo) {           
            case 'FDIPRUEBA':
              this.fields_plan_hallaz = i.fdi_defini;
              break;
          }
        }
      })
  }

  LimpiarControl(control: AbstractControl): ValidationErrors | null {
    try {
      return control.value === '' || control.value == null ? null : Validators.required(control);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
     * Metodo encargado de registrar el usuario.
     * @author Titanq Ingenieria.
     * @date 2023-05-25.
     * @param
     * @returns
    */
  Ejecutar() {
    try {

      this.mensaje.retornarMensajeFuncion("loading", "Registrando usuario", "", "", null, null, null);
      const json = JSON.stringify(this.regularForm.getRawValue());
      this.visorDatosService.getServicioRealizarAccion(sessionStorage.getItem('sistemaLogueado'),JSON.parse(sessionStorage.infoUsuario).usu_codigo, "interesadoCertificado", json, "", "adicionar", "urlServicioVisor", "FUNVisor").subscribe(data => {
        if (data.respuesta === "true") {
          this.mensaje.cerrarMensajeCarga();          
          this.modalRef.close(true);          
        }
        else {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", "No fue posible registrar el usuario", "");
        }
      });


    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * Metodo encargado de abrir una url en una nueva pestaña
   * @author Titanq Ingenieria.
   * @date 2023-05-25.
   */
  openUrl() {
    window.open('http://161.10.233.23:48001/herramientas/adjuntos/Anexos/ANEXO-01PEV-12%20CONDICIONES%20DE%20USO%20Y%20DERECHOS%20DE%20AUTOR%20V1.pdf', '_blank');
  }

  /**
     * Metodo encargado de limpiar el formulario de registro.
     * @author Titanq Ingenieria.
     * @date 2023-05-25.
     * @param
     * @returns
    */
  LimpiarModelo() {
    this.regularForm.reset();
  }

  /**
  * @description cierra el pop-up
  * @author Titanq Ingenieria
  * @date 24/05/2023
  * @returns
  */
  cerrarPopup() {
    this.modalRef.close();
  }

    /*Evento que se dispara cuanto dan clic sobre un boton dinámico */
    eventCustom(object) {
      switch (object.action) {
        case 'AdicionarInfoPlan':
          object.json['pla_estado'] = 'PLANEADO';
          this.eventoRealizarAccion(object.nameObject, object.action, 'adicionar plan de pruebas', object.json);
          break;
        case 'EditarInfoPlan':
          this.eventoRealizarAccion(object.nameObject, object.action, 'editar plan de pruebas', object.json);
          break;
        case 'EliminarInfoPlan':
          this.eventoRealizarAccion(object.nameObject, object.action, 'eliminar plan de pruebas', object.json);
          break;
        case 'AdicionarInfoComponente':
          this.eventoRealizarAccion(object.nameObject, object.action, 'adicionar información componente', object.json);
          break;
        case 'EditarInfoComponente':
          this.eventoRealizarAccion(object.nameObject, object.action, 'editar información componente', object.json);
          break;
        case 'EliminarInfoComponente':
          this.eventoRealizarAccion(object.nameObject, object.action, 'eliminar información componente', object.json);
          break;
        case 'AdicionarInfoAdicionalPlan':
          this.eventoRealizarAccion(object.nameObject, object.action, 'adicionar información adicional', object.json);
          break;
        case 'EditarInfoAdicionalPlan':
          this.eventoRealizarAccion(object.nameObject, object.action, 'editar información adicional', object.json);
          break;
        case 'EliminarInfoAdicionalPlan':
          this.eventoRealizarAccion(object.nameObject, object.action, 'eliminar información adicional', object.json);
          break;
        case 'AdicionarCasosPruebas':
          object.json['cas_aproba'] = true;
          object.json['cas_verifi'] = false;
          this.eventoRealizarAccion(object.nameObject, object.action, 'adicionar caso de pruebas', object.json);
          break;
        case 'EditarCasosPruebas':
          this.eventoRealizarAccion(object.nameObject, object.action, 'editar caso de pruebas', object.json);
          break;
        case 'EliminarCasosPruebas':
          this.eventoRealizarAccion(object.nameObject, object.action, 'eliminar caso de pruebas', object.json);
          break;
        case 'LimpiarInfoPlan':
          this.forms_plan_infjso = this.cleanModel(this.forms_plan_infjso);
          break;        
        case 'AdicionarHallazgo':
              
                object.json['tik_codigo'] ="";
                object.json['tik_ciclo'] = "";
                object.json['pca_codigo'] = "";
                this.eventoRealizarAccion(object.nameObject, object.action, 'adicionar hallazgo', object.json);          
          break;
        case 'EditarHallazgo':
          
          this.eventoRealizarAccion2(object.nameObject, object.action, 'editar hallazgo', object.json);
          break;
        case 'EliminarHallazgo':
          this.eventoRealizarAccion(object.nameObject, object.action, 'eliminar hallazgo', object.json);
          break;
        default:
          break;
        case 'LimpiarHallazgo':
          this.forms_plan_hallaz = this.cleanModel(this.forms_plan_hallaz);
          break;
      }
      console.log(object);
    }

    eventoRealizarAccion(nombreObjeto, accion, mensaje, jData) {

      const htmlMensaje = '';
      const datosPeticion = '{}'; 
        jData['tik_codigo'] = "Dato prueba envio1";
        jData['tik_genera'] = "Dato prueba envio1";
        jData['usu_codigo'] = "Dato prueba envio1";
        jData['pla_codigo'] = "Dato prueba envio1";
        const parametros = {
          componente: this,
          sistema: this.sistema,
          perfil: '',
          nombreObjeto: nombreObjeto,
          accion: accion,
          jsonData: jData,
          datosPeticion: datosPeticion
        }
        this.mensaje.retornarMensajeFuncion('confirmacion', '¿Desea ' + mensaje + '?', '', htmlMensaje, this, 'realizarAccion', parametros);
      
  
    }

    realizarAccion(parametros) {

      parametros.componente.mensaje.retornarMensajeFuncion('loading', 'Procesando tu solicitud...', '', '', null, '', null);
      const coleccion = [];
      const json = {};
  
      coleccion.push(json);
      let jsonPeticion = JSON.stringify(coleccion);
      jsonPeticion = jsonPeticion.replace(/null/g, '\"undefined\"');
  
      parametros.sharedService.getRealizarAccionEjecucionForDinamy(
        parametros.sistema,
        parametros.nombreObjeto,
        parametros.accion,
        parametros.jsonData,
        parametros.datosPeticion).subscribe(res => {
          const dataRespuesta = res;
          if (dataRespuesta.respuesta === 'true') {
            if (dataRespuesta.datos) {
              const datos = JSON.parse(dataRespuesta.datos);
              parametros.componente.pla_codigo = datos.pla_codigo;
            }
            parametros.componente.mensaje.cerrarMensajeCarga();
            parametros.componente.mensaje.retornarMensaje('success', 'Exito', '' + dataRespuesta.noticia, '');
            parametros.componente.obtenerPlan();
            // parametros.componente.obtenerListaComponentes();
            // parametros.componente.obtenerListaInfoAdicional();
            // parametros.componente.obtenerListaCasosPrueba();
            // parametros.componente.obtenerListaTicketsNuevos();
  
            if (parametros.accion === 'AdicionarHallazgo' ||
              parametros.accion === 'EditarHallazgo' ||
              parametros.accion === 'EliminarHallazgo') {
              parametros.componente.obtenerListaHallazgos();
            }
          } else {
            parametros.componente.mensaje.cerrarMensajeCarga();
            parametros.componente.mensaje.retornarMensaje('error', 'Error', dataRespuesta.noticia, '');
          }
        });
    }

    eventoRealizarAccion2(nombreObjeto, accion, mensaje, jData) {

      const htmlMensaje = '';
      const datosPeticion = '{}';
  
        //jData['tik_codigo'] = this.globalTicket.ticketActual.tik_codigo;
        //jData['tik_genera'] = this.globalTicket.ticketActual.tik_codigo;
        //jData['usu_codigo'] = this.usuario.usu_codigo;
        //jData['pla_codigo'] = this.pla_codigo;
        const parametros = {
          componente: this,
          sistema: this.sistema,
          perfil: '',
          nombreObjeto: nombreObjeto,
          accion: accion,
          jsonData: jData,
          datosPeticion: datosPeticion
        }
        this.mensaje.retornarMensajeFuncion('confirmacion', '¿Desea ' + mensaje + '?', '', htmlMensaje, this, 'realizarAccion', parametros);
      }


    cleanModel(forms) {
      const jsonNew = {};
      // tslint:disable-next-line: forin
      for (const key in forms) {
        jsonNew[key] = '';
      }
      return jsonNew;
    }

    
  


}
