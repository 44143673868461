import { createReducer, on, Action } from '@ngrx/store';
import * as fromActions from '../actions';
import * as L from 'leaflet';
import { isEmpty } from 'lodash';

export interface IHistoricalGpsState {
    historicalGps: Array<fromActions.IHistoricalGpsResponse>,
    payloadRequest: fromActions.IHistoricalGpsRequest,
    loading: boolean;
    isEmpty: boolean;
    geoJSONLayer: L.GeoJSON<any>,
}

export const historicalGpsState: IHistoricalGpsState = {
    historicalGps: Object.assign([]),
    payloadRequest: Object.assign({}),
    loading: false,
    isEmpty: true,
    geoJSONLayer: Object.assign([]),
};

const featureReducerHistoricalGps = createReducer(
    historicalGpsState,
    on(fromActions.LoadHistoricalGps, (state, { payloadRequest }) => {
        return {
            ...state,
            payloadRequest: { ...payloadRequest },
            loading: true
        };
    }),

    on(fromActions.LoadHistoricalGpsSuccess, (state, { payloadResponse }) => {
        if (isEmpty(payloadResponse)) {
            return {
                ...state,
                loading: false,
                isEmpty: true
            }
        }

        return {
            ...state,
            historicalGps: state?.historicalGps.concat(payloadResponse),
            loading: false,
            isEmpty: false
        }
    }),

    on(fromActions.CleanHistoricalGps, (state) => {
        return {
            ...state,
            historicalGps: Object.assign([]),
            isEmpty: true
        }
    })
);

export function reducerHistoricalGps(state = historicalGpsState, action: Action): IHistoricalGpsState {
    return featureReducerHistoricalGps(state, action);
}
