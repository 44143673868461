<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded py-0">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse"
                (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <em class="ft-more-vertical"></em>
                </a>
            </span>
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">


                    <li class="nav-item mr-2">
                        <a href="{{urlMoodle}}" class="nav-link" target="_blank">
                            <em class="fas fa-graduation-cap font-medium-3 blue-grey"></em>
                            <p class="d-none">Manuales de usuario</p>
                        </a>
                    </li>

                    <li class="nav-item mr-2">
                        <a href="javascript:;" class="nav-link" id="consulta-tramites" (click)="AbrirPopup()">
                            <em class="fas fa-clipboard-list font-medium-3 blue-grey"></em>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li>

                    <li class="nav-item mr-2  d-none d-lg-block">
                        <a class="nav-link" id="navbar-logo">
                            <img class="img-fluid" [src]="logoSigmaNav" alt="logo sigma" />
                        </a>
                    </li>
                    <li class="nav-item mr-2  d-none d-lg-block">
                        <a href="javascript:;" class="nav-link" id="navbar-fullscreen" appToggleFullscreen
                            (click)="ToggleClass()">
                            <em class="{{toggleClass}} font-medium-3 blue-grey darken-4"></em>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li>
                    <!--<li class="nav-item d-none d-lg-block">
                        <a class="nav-link position-relative notification-sidebar-toggle"
                            (click)="toggleNotificationSidebar();">
                            <em class="ft-bell font-medium-3 blue-grey darken-4"></em>
                            <span class="notification badge badge-pill badge-danger">
                                <app-counter-bubble [counter]="(notifications$|async)?.length">
                                </app-counter-bubble>
                            </span>
                            <p class="d-none">Notifications</p>
                        </a>
                    </li>-->
                    <li class="nav-item" ngbDropdown display="dynamic" [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <em class="ft-user font-medium-3 blue-grey darken-4"></em>
                            <p class="d-none">{{'User Settings' | translate}}</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
                            <div class="align-self-center halfway-fab text-center ">
                                <a class="profile-image ">
                                    <img [src]="infoUsuario.usu_foto " *ngIf="infoUsuario.usu_foto && infoUsuario.usu_foto.length>
                                0; else fotoDefecto" class="rounded-circle img-border gradient-summer width-100" alt="Card
                                    image">
                                    <ng-template #fotoDefecto>
                                        <img [src]="fotoPerfilDefecto"
                                            class="rounded-circle img-border gradient-summer width-100"
                                            alt="Card image">
                                    </ng-template>
                                </a>
                            </div>

                            <a class="dropdown-item py-1" href="javascript:;">
                                <div>
                                    <p class="nombreUsuarioPerfil">{{infoUsuario.usu_nombre}}</p>
                                    <p class="cargoUsuarioPerfil grey font-small-2">{{infoUsuario.usu_cargo}}</p>
                                </div>
                            </a>

                            <a class="dropdown-item py-1" href="javascript:;">
                                <em class="ft-mail mr-2"></em>
                                <span>{{infoUsuario.usu_correo}}</span>
                            </a>
                            <!--<div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;">
                                <em class="ft-edit mr-2"></em>
                                <span>{{'My Profile' | translate}}</span>
                            </a>-->
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" (click)="CerrarSesion()">
                                <em class="ft-power mr-2"></em>
                                <span>{{'LogOut' | translate }}</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->