import { Injectable } from '@angular/core';
import webConfigjson from 'app/webConfig.json';



@Injectable({
  providedIn: 'root'
})
export class ServicioModuloGeneralService {
  modelo: any
  papa: any;
  hijo: any;
  valorTarjetaPrincipal: any;
  nombreElementoActual: any;
  urlFotos: any;
  codigoUrlFotos: any;
  tipo: any;
  metodo: any;
  fotos: any;
  codigoPermiso: any = "";
  codigoPredio: any = "";
  tipoSeleccionado: any = "";
  codigoSancionatorio: any = "";
  tiempoLogeo: any = "";
  subsistema: any = "";
  visita: any = "";
  vis_codigo = '';
  constructor() { }

  /**
  * Recibe un modelo
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  RecibirModelo(objeto) {
    this.modelo = objeto;
    this.ObtenerModelo();
  }

  /**
  * Se envia un modelo al componente que se haya registrado como hijo
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  ObtenerModelo() {
    if (this.hijo) {
      this.hijo.AsignarModelo(this.modelo);
    }
  }

  /**
* Se envia un modelo al componente que se haya registrado como hijo
* @author Andrés Quintero
* @date 2019-02-08.
* @param
* @returns
*/
  ActualizarModelo(modelo) {
    if (this.papa) {
      this.papa.ActualizarModelo(modelo);
    }
  }

  /**
  * Se envia un modelo recibido desde un hijo al componente padre(contenedor)
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  EnviarModeloAccion(modelo) {
    if (this.papa) {
      this.papa.RealizarAccion(modelo);
    }
  }

  /**
  * Recibe el valor de la tarjeta Principal
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  SetTarjetaPrincipal(valor) {
    this.valorTarjetaPrincipal = valor;
  }

  /**
  * Envia al hijo el valor de la TarjetaPrincipal
  * @author Andrés Quintero
  * @date 2019-02-12.
  * @param
  * @returns
  */
  ObtenerTarjetaPrincipal() {
    if (this.hijo) {
      this.hijo.SetTarjetaPrincipal();
    }
  }


  /**
   * Se reinicia el model que tenga el hijo
   * @author Victor Bedoya
   * @date 2019-02-19.
   * @param
   * @returns
   */
  ObtenerModeloInicial() {
    if (this.hijo) {
      this.hijo.ObtenerModelo();
    }
  }


  BuscarImagenes() {
    this.hijo.ngOnInit();
  }

  /**
  * Recargar la lista del componente de donde se hace el llamado
  * @author Esteban Muñoz
  * @date 2020-07-21.
  * @param
  * @returns
  */
  recargarLista() {
    if (this.papa) {
      this.papa.jsonFotos = this.fotos;
      this.papa.recargarListaImagenes();
    }
  }


  PoblarImagenesModelo(fotos) {
    if (this.papa) {
      this.papa.jsonFotos = fotos;
      this.papa.PoblarImagenesModelo();
    }
  }

  validarSesion() {
    try {
      let tiempo = JSON.parse(sessionStorage.getItem('webConfig'))["tiempoSesion"];

      let tiempoLogueo = sessionStorage.getItem('horaLogueo');
      if (tiempo == undefined) {
        tiempo = 900000;
      }

      let total = tiempo / 60;

      // hora a convertir

      if (tiempoLogueo) {
        // creamos una fecha genérica con tu tiempo
        let horaHoy: any = this.getHoraActual();


        let inicioMinutos = parseInt(horaHoy.substr(3, 2));
        let inicioHoras = parseInt(horaHoy.substr(0, 2));

        let finMinutos = parseInt(tiempoLogueo.substr(3, 2));
        let finHoras = parseInt(tiempoLogueo.substr(0, 2));

        let transcurridoMinutos = inicioMinutos - finMinutos;
        let transcurridoHoras = inicioHoras - finHoras;

        if (transcurridoMinutos < 0) {
          transcurridoHoras--;
          transcurridoMinutos = 60 + transcurridoMinutos;
        }

        let horas = transcurridoHoras.toString();
        let minutos = transcurridoMinutos.toString();

        if (horas.length < 2) {
          horas = "0" + horas;
        }

        if (minutos.length < 2) {
          minutos = "0" + minutos;
        }

        let valorTotal = horas + ":" + minutos;;



        var d = new Date("0001-01-01T" + valorTotal);


        // calculamos los minutos a partir de las horas y minutos de la fecha creada
        var valorMinutos = d.getHours() * 60 + d.getMinutes();

        if (total < valorMinutos) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }

    } catch (e) {
      console.log("Error: " + e);
      return true;
    }


  }

  getHoraActual() {
    let fecha = new Date();
    let ano = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minuto = fecha.getMinutes();
    let segundo = fecha.getSeconds();
    let dd = ''
    let mm = ''
    let ss = ''
    if (dia < 10) {
      dd = '0' + dia;
    } else {
      dd = '' + dia;
    }
    if (mes < 10) {
      mm = '0' + mes;
    } else {
      mm = '' + mes;
    }

    if (segundo < 10) {
      ss = '0' + segundo;
    } else {
      ss = '' + segundo;
    }
    return hora + ':' + minuto + ':' + ss;
  }


  LimpiarListaFiltros() {
    if (this.hijo) {
      this.hijo.LimpiarFiltros();
    }

  }

}