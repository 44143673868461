import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FwLoginService } from 'app/pages/content-layout-page/login/fw-login.service';
import { Mensaje } from '@mensaje';
import { TreeComponent, ITreeOptions, TreeNode } from 'angular-tree-component';
import { v4 } from 'uuid';
import { DynamicService } from '../../dynamic.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class DynamicTreeComponent implements OnInit {

  /*dominios*/
  arr_plantillas: any = [];
  systemCurrent: any = '';
  selectedCheckBox: any = [];

  puedeEditar = false;
  /*Arbol*/

  options: ITreeOptions = {

    useVirtualScroll: true,
    animateExpand: false,
    idField: 'id',
    displayField: 'alias',
    levelPadding: 10,
    // allowDrag: (node) => node.isLeaf,
    // allowDrop: (element, { parent, index }) => {
    //   return parent.hasChildren;
    // },
    // getNodeClone: (node) => ({
    //   ...node.data,
    //   id: v4(),
    //   name: `copy of ${node.data.name}`
    // })
    // nodeHeight: 100,
    useCheckbox: false,
    useTriState: false
  };


  nodeActual: any = undefined;
  nodeGrupoActual: any = undefined;

  name: any = '';
  message: Mensaje;
  atributo: any = '';
  vatributo: any = '';

  modelo = {
    descripcion: '',
    sintomas: '',
    pasos_verificacion: '',
    pasos_soluciones: ''
  };

  @ViewChild(TreeComponent) private tree: TreeComponent;
  @Input() nodes: any = [];
  @Input() pk: string;
  @Output() selectNode = new EventEmitter();
  @Output() delEvent = new EventEmitter();
  @Input() useCheckbox = false;

  constructor(
    private dynamicService: DynamicService,
    private servicioLogin: FwLoginService
  ) {
    this.message = new Mensaje('', '', '');
    this.dynamicService.treeComponent = this;
  }


  ngOnInit() {
    const systemsConfig = JSON.parse(localStorage.getItem('webConfig'))['filtroSistemasLogin'];
    this.servicioLogin.getSistemas(systemsConfig).subscribe(data => {
      if (data.respuesta === 'true') {
        const systems = JSON.parse(data.datos);
        localStorage.setItem('sistemas', JSON.stringify(systems));
        this.systemCurrent = systems[0].valor;
        this.name = '';
      }
    });
  }


  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.tree.treeModel.expandAll();
    this.options.useCheckbox = this.useCheckbox;
  }


  saveFormNode(nameObject, action, form) {
    const jsonAux = Object.assign({}, this.nodeActual.data, form);
    this.asignModelNode(jsonAux);
    this.eventAction(nameObject, action, this.pk, '');
  }

  saveFormChild(nameObject, action, form) {
    if (this.nodeActual.data && !this.nodeActual.data.leaf) {
      this.addChild(form);
    } else {
      this.nodeActual.data = Object.assign(this.nodeActual.data, form);
      this.tree.treeModel.update();
    }
    this.eventAction(nameObject, action, this.pk, '');
  }

  asignModelNode(jsonAux) {
    // tslint:disable-next-line: forin
    for (const i in jsonAux) {
      this.nodeActual.data[i] = jsonAux[i];
    }
  }

  setFilter(text) {
    this.selectedCheckBox = Object.assign({}, this.tree.treeModel.selectedLeafNodeIds);
    this.tree.treeModel.filterNodes((node) => {
      let exists = false;
      for (const i in node.data) {
        if (i !== 'children' && i !== 'id' && i !== 'cloneChild') {
          if (node.data[i]) {
            exists = String(node.data[i]).toLowerCase().indexOf(text.toLowerCase()) !== -1 || !text;
            if (exists) {
              return exists;
            }
          } else {
            exists = false;
          }
        }
      }
      return exists;
    });
  }

  addRoot(type) {
    if (this.name !== '') {
      const json = {
        id: this.getId(),
        name: this.name,
        children: [],
        type: type
      };
      this.nodes.push(json);
      this.tree.treeModel.update();
      this.name = '';
    } else {
      this.message.retornarMensaje('error', 'Te faltó un paso', 'Debes digitar el nombre', null);
    }
  }

  getId() {
    const d = new Date();
    return d.getFullYear() + '' + d.getMonth() + '' + d.getDate() + '' +
      d.getHours() + '' + d.getMinutes() + '' + d.getSeconds() + '' + d.getMilliseconds();
  }

  addChild(form) {
    const copia = JSON.parse(JSON.stringify(this.nodeActual.data));
    copia.id = this.getId();
    const name_copia = copia.name + copia.id;
    const alias_copia = `hijo de ${copia.alias}`;
    copia.name = name_copia;
    copia.alias = alias_copia;
    copia['leaf'] = true;
    copia['children'] = [];
    copia['alowChangeAlias'] = true;
    const nodeChild = Object.assign(copia, form);
    this.nodeActual.data.children.push(nodeChild);
    this.tree.treeModel.update();
  }

  cloneNode() {
    /*console.log(this.nodeActual);*/
    const copia = JSON.parse(JSON.stringify(this.nodeActual.data));
    copia.id = this.getId();
    const name_copia = copia.name + copia.id;
    const alias_copia = `copia de ${copia.alias}`;
    copia.name = name_copia;
    copia.alias = alias_copia;
    const clone = this.updateIdsClone(copia);
    this.nodeActual.parent.data.children.push(clone);
    this.tree.treeModel.update();
  }

  updateIdsClone(nodes) {
    // Se hace por referencia
    const coleccion = [];
    coleccion.push(nodes);
    while (coleccion.length > 0) {
      const childs = coleccion[0].children;
      // tslint:disable-next-line: forin
      for (const i in childs) {
        childs[i]['id'] = v4();
        coleccion.push(childs[i]);
      }
      coleccion.shift()
    }
    return nodes;
  }

  getCloneNode(node) {
    const nodeAux = JSON.parse(JSON.stringify(node));
    nodeAux.children = [];
    nodeAux.id = this.getId();
  }

  delNode() {
    this.nodeActual.parent.data.children.splice(this.nodeActual.index, 1);
    this.tree.treeModel.update();
    this.nodeActual = undefined;
    this.eventAction(this.dynamicService.configService.nameObject, this.dynamicService.configService.actionSave, this.pk, '');
  }

  changeAlias() {
    this.nodeActual.data.alias = JSON.parse(JSON.stringify(this.name));
  }

  get canDelete() {
    if (!this.nodeActual) {
      return false
    } else if (!this.nodeActual.data) {
      return false
    } else if (this.nodeActual.data.canDelete) {
      return true;
    } else if (this.nodeActual.parent === null) {
      return false;
    } else if (this.nodeActual.parent.data.canDeleteChildLength !== undefined) {
      if (Number(this.nodeActual.parent.data.canDeleteChildLength) <
        (this.nodeActual.parent.data.children.length)) {
        return true;
      }
    } else if (this.nodeActual.data.parent && this.nodeActual.parent.data.canDeleteChild) {
      return true;
    } else {
      return false;
    }
  }

  get canClone() {
    if (this.nodeActual.parent.data.cloneChild) {
      return true;
    }
  }

  delTree() {
    this.delEvent.next();
  }

  onEvent(event) {

    switch (event.eventName) {
      case 'initialized':
        setTimeout(function (tree) {
          if (tree &&
            tree.treeModel &&
            tree.treeModel.nodes[0] &&
            tree.treeModel.nodes[0].id) {
            const id = tree.treeModel.nodes[0].id;
            const someNode = tree.treeModel.getNodeById(id);
            someNode.expand();
            const firstRoot = tree.treeModel.roots[0];
            firstRoot.setActiveAndVisible();
          }
        }, 500, this.tree);
        break;
      case 'activate':
        this.nodeActual = event.node;
        // const treeModel = event.treeModel;
        const data = JSON.stringify(event.node.data);
        this.selectNode.next(data);
        break
      case 'copyNode':
        console.log(event);
        break;
    }
  }

  eventAction(nameObject, action, pk, message) {

    const htmlMensaje = '<br><br><B></B>';
    const dataRequest = '{}';
    const data = { json: this.tree.treeModel.nodes, pk: pk };

    const parameters = {
      component: this,
      system: this.systemCurrent,
      perfil: '',
      nameObject: nameObject,
      action: action,
      jsonData: data,
      dataRequest: dataRequest
    }

    if (message) {
      this.message.retornarMensajeFuncion('confirmacion', '¿Desea ' + message + '?', '', htmlMensaje, this, 'takeAction', parameters);
    } else {
      this.takeAction(parameters);
    }
  }

  takeAction(parameters) {
    parameters.component.message.retornarMensajeFuncion('loading', 'Procesando tu solicitud...', '', '', null, '', null);
    let json = JSON.stringify(parameters.jsonData);
    json = json.replace(/null/g, '"undefined"');
    parameters.component.dynamicService.getServicioRealizarAccion2(
      parameters.system,
      parameters.nameObject,
      parameters.action,
      json,
      parameters.dataRequest
    ).subscribe(res => {
      const dataRespuesta = res;
      if (dataRespuesta.respuesta === 'true') {
        parameters.component.message.cerrarMensajeCarga();
        parameters.component.message.retornarMensaje('success', 'Exito', '' + dataRespuesta.noticia, '');
      } else {
        parameters.component.message.cerrarMensajeCarga();
        parameters.component.message.retornarMensaje('error', 'Error, inténtelo de nuevo', dataRespuesta.noticia, '');
        console.log(dataRespuesta.error);
      }
    });
  }
}

