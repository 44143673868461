/**
 * An object used to get page information from the server
 */
export class SettingsList {
  //Title of the list
  title: string = '';
  // The number of elements in the page. Default: 5
  size: number = 5;
  //Type of selection [cell, single, multi, multiClick]. Default: single
  type: string = 'single';
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
  //Boolean loading indicator
  loading: boolean = false;
  //Boolean for external paging
  paging: boolean = false;
}