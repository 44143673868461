import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'checkbox',
  template: `
    <div [formGroup]="form">
      <div [formGroupName]="field.name" >
          <div *ngFor="let opt of field.options" class="form-check form-check">
            <label class="form-check-label">
              <input [formControlName]="opt.id"
                    class="form-check-input" type="checkbox"
                    [value]="opt.value" />
              {{opt.text}}
              </label>
          </div>
      </div>
    </div>
    `
})
export class CheckBoxComponent {
  @Input() field: any = {};
  @Input() form: any;

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    console.log(this.form);
    const opts = {};
    for (const opt of this.field.options) {
      opts[opt.id] = new FormControl(opt.value ? opt.value : false, null);
    }
    this.form.controls[this.field.name] = new FormGroup(opts);
  }
}
