import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';

function loadFile(url: string, callback: { (error: any, content: any): void; (err: Error, data: string): void; }) {
  PizZipUtils.getBinaryContent(url, callback);
}

@Injectable({
  providedIn: 'root'
})
export class TemplateGeneratorService {

  constructor() { }

  replaceNullUndefinedWithEmpty(obj) {
    if (obj === null || typeof obj === "undefined") {
      return "";
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.replaceNullUndefinedWithEmpty(obj[key]);
        }
      }
    }
    return obj;
  }

  encontrarCamposJson(campoJson) {
    if (typeof campoJson === "undefined" || campoJson === null) {
      return "";
    }

    if (campoJson === "" || campoJson.length === 0) {
      return campoJson;
    }

    return this.replaceNullUndefinedWithEmpty(campoJson);
  }

  asignarXSiNo(campoJson, campo) {
    const campoJsonLower = campoJson.toString().toLowerCase();    
    if (campo === "si" && campoJsonLower === "si") {
      return "X";
    } else if (campo === "no" && campoJsonLower === "no") {
      return "X";
    } else if (campo === "na" && campoJsonLower !== "" && campoJsonLower !== "si" && campoJsonLower !== "no") {
      return "X";
    } else {
      return "";
    }
  }
  asignarXAguaSueloAlcantarillado(campoJson, campo) {
    const campoJsonLower = campoJson.toString().toLowerCase();    
    if (campo === "cuerpo de agua" && campoJsonLower === "agua") {
      return "X";
    } else if (campo === "suelo" && campoJsonLower === "suelo") {
      return "X";
    } else if (campo === "alcantarillado" && campoJsonLower !== "" && campoJsonLower !== "agua" && campoJsonLower !== "suelo") {
      return "X";
    } else {
      return "";
    }
  }

  generarReporte(nombrePlantilla: string, rutaPlantilla: string, datos: any) {

    datos = this.replaceNullUndefinedWithEmpty(datos);

    console.log("entre al generador de plantillas");
    loadFile(
      rutaPlantilla,
      (error, content) => {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        
        doc.render({
          per_codigo: "1",

          horizonteDePlanificación: datos.map((item) => ({
            programa: item.programa,
            proyecto: item.proyecto?.map((item) => ({
              nomPro: item.nomPro,
              actividad: item.actividad?.map((item) => ({
                nombreActividad: item.nombreActividad,
                indicador: item.indicador,
                meta: item.meta,
                tiempo: item.tiempo.map((item) => ({ valor: item.valor, numAnno: item.numAnno, anno: item.anno })),
              })),
            })),
          })),

        });
        

        const blob = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(blob, nombrePlantilla);
      }
    );

  }

}
