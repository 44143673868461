<button (click)="exportarCsv()">
    Descargar archivo
  </button>
  <button (click)="deselectAll()">Quitar seleccion</button>
<ag-grid-angular 
[style]="'width:' + config.width + '; height:' + config.height" 
[class]="config.class"
[rowData]="datos"
[columnDefs]="config.columns" 
[rowSelection]="config.rowSelection"
(rowSelected)= "rowSelectionChanged($event)" 
[pagination]="config.pagination"
[paginationAutoPageSize]="true"
[defaultColDef]="defaultColDef"
(filterModified)="actualizarFiltro($event)"
[localeText]="localeText"
(gridReady)="onGridReady($event)"
(selectionChanged)="onSelectionChanged()"
(rowDataChanged)="rowDataChanged()"
(rowDoubleClicked)="localizar($event)"
>
</ag-grid-angular>

