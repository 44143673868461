import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { FwSidebarService } from './fw-sidebar.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
@Component({
  selector: "app-sidebar-original",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations,
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  logoProductoDos: string;
  public texto: string;
  public icono: string;
  public sidebar = {};
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logos/logo-test.png';
  public config: any = {};
  menuItemTemp: any[];
  regularForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigService,
    private menuService: FwSidebarService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }


  ngOnInit() {

    this.regularForm = new FormGroup({
      'valor': new FormControl(null, this.LimpiarControl),

    }, { updateOn: 'change' });


    this.config = this.configService.templateConf;
    this.sidebar = JSON.parse(sessionStorage.getItem('cargaBase'))["sidebar"];
    this.texto = this.sidebar["texto"];
    this.icono = this.sidebar["iconoEmpresa"];
    this.logoProductoDos = this.sidebar["iconoProducto"];
    this.menuItems = this.sidebar["menu"];
    this.menuItemTemp = this.menuItems;



    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logos/logo-test.png';
    }


  }

  /**
* Validacion nula para los regularForm
* @author Andrés Quintero
* @date 2019-06-01
*/
  LimpiarControl(control: AbstractControl): ValidationErrors | null {
    try {
      return control.value === '' || control.value == null ? null : Validators.required(control);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  cambioEstado() {
    if (this.expanded) {
      (<HTMLDivElement>(document.querySelector("#aLogo"))).hidden = true;
    } else {
      (<HTMLDivElement>(document.querySelector("#aLogo"))).hidden = true;
    }
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
  asignarItem(item) {
    this.menuService.asignarItem(item);
  }

  Filter() {
    try {
      this.menuItems = [];
      let search: Boolean = false;
      let arrayMenuTemp: any[];
      let arrayMain: any[] = this.menuItemTemp;
      let enter: Boolean = false;
      let arrayMainTemp = JSON.stringify(this.menuItemTemp);

      if (this.regularForm.get("valor").value) {
        for (let i = 0; i < arrayMain.length; i++) {

          let arrayMenu: any[] = arrayMain[i]['submenu'];

          arrayMenuTemp = [];
          enter = false;
          const countMenu = arrayMenu.length;
          for (let m = 0; m < arrayMenu.length; m++) {
            let nameFolderMenu: String = arrayMenu[m]['title'];
            enter = true;
            if (nameFolderMenu.includes(this.regularForm.get("valor").value)) {
              arrayMenuTemp.push(arrayMenu[m]);
              m = countMenu - 1;
              search = true;
              
          
            }
          }

          arrayMain[i]['submenu'] = arrayMenuTemp;




          if (search) {

            this.menuItems.push(arrayMain[i]);
          }

          search = false;

        }
      } else {
        this.menuItems = JSON.parse(arrayMainTemp);
      }


      // this.menuItems = this.menuItemTemp.filter(x => x.submenu.filter(s => String(s.title).includes(this.regularForm.get("valor").value)));
      // if (this.menuItems == undefined) {
      //   this.menuItems = this.menuItemTemp;
      // }
    } catch (e) {
      this.menuItems = this.menuItemTemp;
    }

  }

}
