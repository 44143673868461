import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from '@seguridad';
import { tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class ServicioPopupService {
  urlSigmaConsulta: string;
  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) {
    this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
   }

  // getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
  //   this.seguridadGlobal.validarSesion();
  //   return this.http.post(
  //     JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
  //     {
  //       sistema: sistema,
  //       idFuncion: idFuncion,
  //       filtro: filtro,
  //       datosPeticion: datosPeticion
  //     },
  //     //httpOptions
  //     { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
  //   );
  // }

  getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      this.urlSigmaConsulta,
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
      {
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioSigmaConsultaFiltro(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacionFiltros',
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioRealizarAccion(sistema, nombreObjeto, jsonData, accion, url): Observable<any> {
    this.seguridadGlobal.validarSesion();
    if (url !== undefined) {
      return this.http.post(
        url,
        {
          sesion: sistema,
          nombreObjeto: nombreObjeto,
          jsonDatos: JSON.stringify(jsonData),
          accion: accion
        },
        // httpOptions
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  }
  
}
