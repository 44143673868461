import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { TServicioNotificacionService } from "./t-servicio-notificacion.service";


@Component({
  selector: "app-lista-notificacion",
  templateUrl: "./lista-notificacion.component.html",
  styleUrls: ["./lista-notificacion.component.scss"]
})
export class ListaNotificacionComponent implements OnInit {
  mensajes: any;
  @Output() totalesMensajes = new EventEmitter<number>();
  constructor(
    private servicioMensajes: TServicioNotificacionService,
    ) { }

  ngOnInit() {
    this.servicioMensajes.getMensajes().subscribe(data => {
      // this.mensajes = data;
      this.mensajes = [
        {
        "color": "info",
        "titulo": "Exceso de velocidad",
        "texto": "Click acá para Descargar archivo",
        "ruta": "",
        "urlDescarga": "https://download.sublimetext.com/Sublime%20Text%20Build%203176%20Setup.exe",
        "estado": 1,
        "clase":"ft-download float-left d-block font-large-1 mt-1 mr-2"
        },
        {
        "color": "warning",
        "titulo": "Microruta creada",
        "texto": "Se ha creado la mirco ruta MP01R1",
        "filtro":[{"cap_codigo":"CAP_17",filtro:"strtolowercase(mic_codigo) like '%mp01r1%'","zoom":true}],
        "idmapa":"MAP_PLANEACION_LEAFLET_01",
        "ruta": "/planeacion",
        "urlDescarga": "",
        "estado": 0,
        "clase":"ft-bell float-left d-block font-large-1 mt-1 mr-2"
        },
        {
        "color": "danger",
        "titulo": "Salió de la zona",
        "texto": "El vehículo 1112 ha salido de la zona",
        "ruta": "/ejecucion",
        "filtro":[{"cap_codigo":"CAP_24",filtro:"strtolowercase(veh_codigo) like '%1112%'","zoom":true}],
        "idmapa":"MAP_EJECUCION_LEAFLET_01",
        "urlDescarga": "",
        "estado": 0,
        "clase":"ft-bell float-left d-block font-large-1 mt-1 mr-2"
        }
        ];
      this.totalesMensajes.emit(this.mensajes.length);
      this.totalesMensajes.next(this.mensajes.length);
    });
  }

  eliminarMensaje(index) {
    this.mensajes.splice(index, 1);
    this.totalesMensajes.emit(this.mensajes.length);
  }

  zoomMapa(valores){    
    let filtroMapa = valores["filtro"];
  }
}
