<div class="col-md-12 row">
    <div class="fondo texto">
        <div class="row">
            <div class="row combo-ingresar-url">
                <div class="col-12 card shadow-none ingresar campo-url row">
                    <img src="../../assets/img/logos/svg_interna_2/ingresar_url_int_2.svg" alt="Icono url"
                        class="img-ingresar">
                    <span class="texto-ingresar">URL:</span>
                    <input title="URL" class="select-ingresar" name="url" id="url" list="urlList"
                        [(ngModel)]="urlSeleccionada" placeholder="Ingrese url del servicio">
                    <datalist id="urlList">
                        <option *ngFor="let url of arrayURL">{{url.text}}</option>
                    </datalist>
                    <button class="input-group-text lupa" for="inputGroupSelect02"
                        (click)="LimpiarFiltro(); DescubrirServidor();"></button>
                </div>
            </div>

            <div class="row combo-ingresar-capa">
                <div class="col-12 card shadow-none ingresar row">
                    <img src="../../assets/img/logos/svg_interna_2/ingresar_capa_int_2.svg" alt="icono ingresar capa"
                        class="img-ingresar">
                    <span class="texto-ingresar">CAPA:</span>
                    <input title="CAPA" type="text" [(ngModel)]="filtroCapa" name="capa" id="capa"
                        class="input input-ingresar" placeholder="Ingrese capa a consultar">
                    <button class="input-group-text lupa    " for="inputGroupSelect02"
                        (click)="LimpiarFiltro(); ObtenerCapasFiltradas(filtroCapa);"></button>

                </div>
            </div>
            <div class="combo-botones">
                <div class="col-12 botones row">
                    <input class="form-check-input checkbox" type="checkbox" value="" id="verTodas"
                        (change)="ValidarVerTodas(this)">
                    <label class="form-check-label verTodas ml-3" for="verTodas">
                        Ver todas
                    </label>
                    <button type="button" class="btn btn-sm boton-guardar" (click)="AccionGuardar()">
                        Guardar
                    </button>
                    <button type="button" class="btn btn-sm flecha1" routerLink="/gestionar-capas">
                        &lt; </button>
                    <button type="button" class="btn btn-sm flecha2"> > </button>
                </div>
            </div>
        </div>

        <div class="row contenido-capas">
            <div class="col-3 pr-0">
                <div class="nav-container">
                    <span class="navigation">

                        <p class="texto-capas">CAPAS</p>
                        <div>
                            <button class="btn btn-lg btn-block" (click)="Filtrar('asociadas',$event)">
                                <img src="../../../../assets/img/logos/svg_interna_2/capas_menu_izq_int_2.svg"
                                    alt="icono capas asociadas" class="imagen alinear-izq">
                                <span class="mx-2 alinear-izq texto-capas2">Asociadas</span>
                                <span class="numero-estad texto-estad"> {{asociadas}} </span>
                            </button>
                        </div>

                        <div>
                            <button class="btn btn-lg btn-block" (click)="Filtrar('noasociadas',$event)">
                                <img src="../../../../assets/img/logos/svg_interna_2/no_asociadas_int_2.svg"
                                    alt="icono capas no asociadas" class="imagen alinear-izq">
                                <span class="mx-2 alinear-izq texto-capas2">No asociadas</span>
                                <span *ngIf="noAsociadas!=0" class="numero-no-asociadas texto-no-asociadas">
                                    {{noAsociadas}}
                                </span>
                            </button>
                        </div>

                        <div>
                            <button class="btn btn-lg btn-block" (click)="Filtrar('error',$event)">
                                <img src="../../../../assets/img/logos/svg_interna_2/con_error_int_2.svg"
                                    alt="icono capa con error" class="imagen alinear-izq">
                                <span class="mx-2 alinear-izq texto-capas2">Con error</span>
                                <span class="numero-con-error texto-con-error"> {{conError}} </span>
                            </button>
                        </div>

                    </span>



                    <ul *ngIf="arrayCapasCategoria.length > 0" class="navigation m-0">
                        <li>
                            <hr class="separador" />

                            <p class="texto-capas">CAPAS POR CATEGORÍA</p>

                            <div *ngFor="let c of arrayCapasCategoria; let index=index; let odd=odd; let even=even;">
                                <div>
                                    <button class="btn btn-lg btn-block" (click)="Filtrar(c.nombre,$event)">
                                        <span class="mx-2 alinear-izq texto-capas2">{{c.nombre}}</span>
                                        <span class="numero-estad texto-estad"> {{c.cantidad}} </span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-9 p-0">
                <div class="w-100">
                    <p class="mx-1 texto-listado">Listado de capas</p>

                    <div #widget class="dhx-container--grid w-100 m-0 p-0 grid dhx_container__width"></div>
                    <div id="pagination"></div>
                </div>
            </div>


            <ng-template #popupVisualizar let-modal>

                <div class="modal-body text-center">
                    <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click'); LimpiarImagen()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <img *ngIf="imagen != undefined; else mensaje" [src]="imagen" alt="Cargando previsualización">
                    <ng-template #mensaje>
                        <h3>{{mensajeVisualizar}}</h3>
                    </ng-template>

                </div>

            </ng-template>

        </div>
    </div>
</div>