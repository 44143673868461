import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'ngb-multi-date-picker',
  templateUrl: './multi-date-picker.html',
  styleUrls: ['./multi-date-picker.scss']

})
export class MultiDatePickerComponent {

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  _datesSelected: NgbDateStruct[] = [];
  dateCurrent: NgbDateStruct;
  @Input() displayMonths;
  outsideDays = 'visible';

  @Input()
  set datesSelected(value: NgbDateStruct[]) {
    this._datesSelected = value;
  }

  get datesSelected(): NgbDateStruct[] {
    return this._datesSelected ? this._datesSelected : [];
  }

  @Output() datesSelectedChange = new EventEmitter<any>();

  constructor(calendar: NgbCalendar) {
  }

  selectCurrent(event: any, date: NgbDateStruct) {
    this.dateCurrent = date;
    let jsonChange = { datesSelected: this.datesSelected, dateCurrent: this.dateCurrent }
    this.datesSelectedChange.emit(jsonChange);
  }

  addDate(date: NgbDateStruct) {
    let index = this.datesSelected.findIndex(f => f.day == date.day && f.month == date.month && f.year == date.year);
    if (index >= 0)       //If exist, remove the date
      this.datesSelected.splice(index, 1);
    else   //a simple push
      this.datesSelected.push(date);
  }

  addRangeDate(fromDate: NgbDateStruct, toDate: NgbDateStruct) {
    //We get the getTime() of the dates from and to
    let from = new Date(fromDate.year + "-" + (fromDate.month - 2) + "-" + fromDate.day).getTime();
    let to = new Date(toDate.year + "-" + toDate.month + "-" + toDate.day).getTime();
    for (let time = from; time <= to; time += (24 * 60 * 60 * 1000)) //add one day
    {
      let date = new Date(time);
      //javascript getMonth give 0 to January, 1, to February...
      this.addDate({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() });
    }
    this.datesSelectedChange.emit(this.datesSelected);
  }
  //return true if is selected
  isDateSelected(date: NgbDateStruct) {
    return (this.datesSelected.findIndex(f => f.day == date.day && f.month == date.month && f.year == date.year) >= 0);
  }
  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isCurrent = date => equals(date, this.dateCurrent);
  isTo = date => equals(date, this.toDate);

  ngOnChanges() {
    this.displayMonths = this.displayMonths
  }
}

