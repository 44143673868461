
export class ConfigIdiomaGrid {

  changeLanguageGrid(): any {
    let localeTextEs = {
      // Set Filter
      selectAll: '(Seleccionar todo)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Buscar...',
      blanks: '(Blanks)',
      noMatches: 'No coincidencias',

      // Number Filter & Text Filter
      filterOoo: 'Filtro...',
      equals: 'Igual',
      notEqual: 'No es Igual',
      empty: 'Elija un filtro',

      // Number Filter
      lessThan: 'Menor que',
      greaterThan: 'Mayor que',
      lessThanOrEqual: 'Menor o igual que',
      greaterThanOrEqual: 'Mayor o igual que',
      inRange: 'En el rango',
      inRangeStart: 'a',
      inRangeEnd: 'De',

      // Text Filter
      contains: 'Contiene',
      notContains: 'No contiene',
      startsWith: 'Empieza con',
      endsWith: 'Termina en',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',

      // Filter Conditions
      andCondition: 'Y',
      orCondition: 'O',

      // Filter Buttons
      applyFilter: 'Aplicar',
      resetFilter: 'Reiniciar',
      clearFilter: 'Limpiar',
      cancelFilter: 'Cancelar',

      // Filter Titles
      textFilter: 'Filtro de texto',
      numberFilter: 'Filtro numerico',
      dateFilter: 'Filtro de fecha',
      setFilter: 'Cambiar filtro',

      // Side Bar
      columns: 'Columnas',
      filters: 'Filtros',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Grupo',

      // Other
      loadingOoo: 'Cargando...',
      noRowsToShow: 'No hay datos para mostrar',
      enabled: 'Habilitado',

      // Menu
      pinColumn: 'Pin Column',
      pinLeft: 'Pin Left',
      pinRight: 'Pin Right',
      noPin: 'No Pin',
      valueAggregation: 'Value Aggregation',
      autosizeThiscolumn: 'Autosize This Column',
      autosizeAllColumns: 'Autosize All Columns',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      resetColumns: 'Reset Columns',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export (.xlsx)',
      excelXmlExport: 'Excel Export (.xml)',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Suma',
      min: 'Minimo',
      max: 'Maximo',
      none: 'Ninguno',
      count: 'Contar',
      avg: 'Promedio',
      filteredRows: 'Filtrado',
      selectedRows: 'Seleccionado',
      totalRows: 'Total filas',
      totalAndFilteredRows: 'Filas',
      more: 'Mas',
      to: 'a',
      of: 'de',
      page: 'Página',
      nextPage: 'Siguiente página',
      lastPage: 'última página',
      firstPage: 'Primera página',
      previousPage: 'Anterior página',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    };
    return localeTextEs;
  }

}