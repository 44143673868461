import { typeNotification } from 'app/visor/shared/types/type-notification';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    urlSigmaConsulta: string;
    private estadoIni: boolean;
    private sigmaChatHubProxy: any;
    private connection: any;
    private urlHub: string;

    private notificationSignalR = new BehaviorSubject<Array<any>>(new Array<any>());
    public notificationSignalR$ = this.notificationSignalR.asObservable();
    private notifications: Array<any> = new Array<any>();

    constructor(
        private http: HttpClient,
        private seguridadGlobal: GlobalSeguridad,
    ) {
        this.estadoIni = false;
    }

    servicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
        this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacionFiltros';
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            this.urlSigmaConsulta,
            {
                sistema: sistema,
                idFuncion: idFuncion,
                filtro: filtro,
                datosPeticion: datosPeticion
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        ).pipe(map((data: any) => data?.datos?.datos[0]?.config || []));
    }

    startConnection() {
        if (!this.estadoIni) {
            this.estadoIni = true;
            this.urlHub = JSON.parse(sessionStorage.getItem('webConfig'))['servicioHub'];
            const sistema = sessionStorage.getItem('sistemaLogueado');
            const infoUsuario = JSON.parse(sessionStorage.getItem('infoUsuario'));
            let tryingToReconnect = false;
            this.connection = $['hubConnection'](this.urlHub, { useDefaultPath: true });
            this.connection.qs = {
                cliente: "{ 'idCliente': '" + infoUsuario.usu_nomcor + "', 'canales':{'" + sistema + "-" + infoUsuario.per_codigo + "':true}}"
            };
            this.sigmaChatHubProxy = this.connection.createHubProxy('FUNWebSocketHub');
            this.sigmaChatHubProxy.on('RecibirMensaje', (notificacion) => {
                console.log('Llega Mensaje..:' + notificacion);
                notificacion = JSON.parse(notificacion);

                if (isEmpty(notificacion?.idNotificacion)) {
                    return;
                }

                if (notificacion?.tipo === typeNotification.DELETE) {
                    this.notifications = this.notifications.filter(_notification => (
                        _notification?.idNotificacion !== notificacion?.idNotificacion
                    ));
                } else if (notificacion?.tipo === typeNotification.VIEWED) {
                    const index = this.notifications.findIndex(_notification => (
                        _notification?.idNotificacion === notificacion?.idNotificacion
                    ));
                    if (index !== -1) {
                        this.notifications[index].estado = 'true';
                    }
                } else if (notificacion?.tipo === typeNotification.STATE) {
                    // TODO manipular la notificación cuando diligencio causa y/o solución.
                } else {
                    this.notifications.push({ ...notificacion });
                }
                this.notificationSignalR.next([...this.notifications]);
                this.fireAlert('¡Tu bandeja de entrada ha sido actualizada!');
            });

            this.connection.start().done(() => {
                console.log('Se Conecto');
                setTimeout(() =>
                    this.sigmaChatHubProxy.invoke('DescargarNotificacioenes', JSON.stringify({ idCliente: infoUsuario.usu_nomcor, canal: sistema + "-" + infoUsuario.per_codigo })),
                    500);
            });

            this.connection.connectionSlow(() => {
                console.log('We are currently experiencing difficulties with the connection.')
            });

            this.connection.error((error) => {
                console.log('SignalR error: ' + error)
            });

            this.connection.reconnecting(() => {
                tryingToReconnect = true;
                console.log('TryingToReconnect: ')
            });

            this.connection.reconnected(() => {
                tryingToReconnect = false;
            });

            this.connection.disconnected(() => {
                console.log('Desconectado: ')
                if (tryingToReconnect) {

                    setTimeout(function () {
                        this.connection.start();
                    }, 5000);
                }
            });
        }
    }

    fireAlert = (message: string) => {
        const toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true
        });

        toast.fire({ icon: 'info', title: message });
    }
}
