/**
 * @author Jhonata David Mosquera 
 * @description servicio de datos que sirve para realizar la s sigma cosulta, consultas de cache y demas funciones de los 
 *              servicios de geoaseo
 * 
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GlobalSeguridad } from '@seguridad';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ShaSerMensajesService {
  private urlSigmaConsulta: string;
  private mensajes: any;
  private etiquetas: any;
  private clase = "ShaSerMensajesService";
  public estado: boolean;
  private idioma: string;
  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad) {
    this.estado = false;
    this.idioma = "es_CO";
  }

  public obtenerMensaje(id: string, lang?: string) {
    if(lang==undefined)
      lang=this.idioma;
    let mensaje = "";
    try {
      mensaje = this.mensajes[id][lang];
    } catch (error) {
      try {
        mensaje = this.mensajes["default"][lang];
      } catch (err) {
        console.log("0)Exception ShaSerMensajesService-> obtenerMensaje:" + err);
      }
      console.log("1)Exception ShaSerMensajesService-> obtenerMensaje:" + error);
    }
    return (mensaje);
  }
  public obtenerEtiqueta(id: string, lang?: string) {
    let etiqueta = "";
    if(lang==undefined)
      lang=this.idioma;
    try {
      etiqueta = this.etiquetas[lang][id];
    } catch (error) {
      console.log("Exception ShaSerMensajesService-> obtenerEtiqueta:" + error);
    }
    return (etiqueta);
  }
  public async CargarMensajes(obj, funcionCarga) {
    try {
      this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';

      const filtro = '';
      await this.servicioObtenerInformacionCache("MENSAJES", filtro, '').toPromise().then(res => {
        this.mensajes = res.datos;
      });
      await this.servicioObtenerInformacionCache("ETIQUETAS", filtro, '').toPromise().then(res => {
        this.etiquetas = res.datos;

      });
      obj[funcionCarga]();
      this.estado = true;
      //Promise.all([p1,p2]).then(res=>{});

    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }
  public asignarMensajes(mensaje) {
    this.estado = true;
    this.mensajes = mensaje;
  }
  public servicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
      {
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  private servicioAlmacenarInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/AlmacenarInformacionCache',
      {
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  private servicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      this.urlSigmaConsulta,
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }
  public cambiarIdioma(idioma: string) {
    this.idioma = idioma;
  }
}
