import { createReducer, on, Action } from '@ngrx/store';

import * as fromActions from '../actions';

export interface IInfoLayersState {
    infoLayers: Array<any>;
    error: any;
    loaded:boolean;
}

export const infoLayersState: IInfoLayersState = {
    infoLayers: new Array<any>(),
    error: null,
    loaded:false
};

const featureReducerInfoLayer = createReducer(
    infoLayersState,
    on(fromActions.setInfoLayer, (state, { payload }) => (
        {
            ...state,
            infoLayers: [...state?.infoLayers, ...payload],
            error: null
        }
    )),
    on(fromActions.resetInfoLayer, (state) => (
        {
            ...state,
            infoLayers: [],
            error: null
        }
    )),
    on(fromActions.failLoadingInfoLayer, (state, { error }) => (
        {
            ...state,
            error: error || null
        }
    )),on(fromActions.dataloaded, (state,{loaded}) => (
        {
            ...state,
            loaded
        }
    ))
);

export function reducerInfoLayer(state = infoLayersState, action: Action): IInfoLayersState {
    return featureReducerInfoLayer(state, action);
}
