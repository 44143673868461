/**
  * @description Carga la configuración y actualiza la información de los subsistemas que ingresan como input
  * @author Lukas Mesa Buriticá
  * @date 22/04/2020
  */
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';

@Component({
  selector: 'app-subsistemas',
  templateUrl: './subsistemas.component.html',
  styleUrls: ['./subsistemas.component.scss']
})
export class SubsistemasComponent implements OnInit, AfterViewInit {

  @ViewChild('myCarousel') myCarousel: NguCarousel<NguCarouselStore>;
  
  carouselConfig: NguCarouselConfig;
  @Input() coleccionSubsistemas: any = [];
  @Input() subsistemaActual: any = [];
  @Output() salida = new EventEmitter();
  clase: string = "SubsistemasComponent";
  mostrar: boolean = true;
  subsistemas: any = [];

  constructor(private cdr: ChangeDetectorRef) { }

  /**
    * @description Carga la configuración del carrusel
    * @author Lukas Mesa Buriticá
    * @date 22/04/2020
    */
  ngOnInit() {
    try {
      this.carouselConfig = {
        grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
        slide: 1,
        // speed: 900,
        // interval: 4000,
        animation: 'lazy',
        point: {
          visible: false,
          hideOnSingleSlide: false
        },
        load: 2,
        touch: true,
        loop: true,
        custom: 'banner',
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Después de inicializar el componente, actualiza los cambios en los elementos dinámicos
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  ngAfterViewInit() {
    try {
      this.cdr.detectChanges();
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Detecta algún cambio en las propiedades
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  ngOnChanges() {
    try {
      if (this.coleccionSubsistemas != undefined) {
        if (this.coleccionSubsistemas.length > 0) {
          for (let index = 0; index < this.coleccionSubsistemas.length; index++) {
            this.mostrarCantidad(this.coleccionSubsistemas[index]);  
          }
          this.validarCambioObjeto();
          this.cursoresCarrusel();
        }
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
   * @description Muestra u oculta la cantidad del subsistema que ingresa por parámetro
   * @author Lukas Mesa Buriticá
   * @date 22/04/2020
   */
  mostrarCantidad(elemento) : boolean{
    try {
      let retorno = false;
      if (elemento.json.sub_moscan === "si"){
        retorno = false;
      } else {
        retorno = true;
      }
      return retorno;
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
    * @description Valida si se debe reubicar el carrusel en la primera posición por cambio de objeto de control
    * @author Lukas Mesa Buriticá
    * @date 11/08/2020
    */
   validarCambioObjeto(){
    if (this.subsistemas != this.coleccionSubsistemas) {
      this.subsistemas = this.coleccionSubsistemas;
      if (this.myCarousel != undefined) {
        this.myCarousel.reset(false);
      }
    }
  }

  /**
   * @description Valida si se requieren los cursores del carrusel o no
   * @author Lukas Mesa Buriticá
   * @date 11/08/2020
   */
  cursoresCarrusel(){
    if (this.coleccionSubsistemas.length > this.carouselConfig.grid.xs) {
      this.mostrar = false;
    } else{
      this.mostrar = true;
    }
  }

}
