<div *ngFor="let mensaje of mensajes; let i = index">
  <div class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
    
    <em  class="{{mensaje.clase}}" ngClass="{{mensaje.color}}"></em> <!-- carga icono desde el json-->
    <span class="noti-wrapper">
      <span class="noti-title line-height-1 d-block text-bold-400" ngClass="{{mensaje.color}}">{{mensaje.titulo}}</span>      
      <span *ngIf="mensaje.ruta" class="noti-text">{{mensaje.texto}}</span>
      <span *ngIf="mensaje.urlDescarga" class="noti-text">
        <a href="{{mensaje.urlDescarga}}">{{mensaje.texto}}</a>
      </span><br>      
      <span *ngIf="mensaje.ruta" class="noti-text">
        <button  class="btn btn-link" [routerLink]="mensaje.ruta === '' ? null : mensaje.ruta">Abrir módulo</button>
      </span>    
      <span *ngIf="mensaje.ruta" class="noti-text">
          <button  class="btn btn-link" (click)="zoomMapa(mensaje)">Ver elemento</button>
        </span>    
      <span class="noti-text">
          <button class="btn btn-link" (click)="eliminarMensaje(i)">Quitar</button>
      </span>    
    </span>
    
  </div>
    
</div>