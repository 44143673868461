import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'date',
  template: `
      <div [formGroup]="form">
        <input *ngIf="field && field.name && !field.rows" type="date" class="form-control"
         [id]="field.name" [name]="field.name" [required]="field.required"
          [formControlName]="field.name" [placeholder]="field.placeholder?field.placeholder:''">
      </div>
    `
})
export class DateComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;

  constructor() { }
}
