import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Mensaje } from "@mensaje";
import { HttpErrorResponse } from '@angular/common/http';
import { ServicioModuloGeneralService } from '@shared/services/servicio-modulo-general.service';


@Component({
  selector: 'app-tarjet-indica',
  templateUrl: './tarjet-indica.component.html',
  styleUrls: ['./tarjet-indica.component.scss']
})
export class TarjetIndicaComponent implements OnInit {
  //arrayOperacion: any = [];
  arrayVehiculo: any;
  arrayOperacionInicial = [];
  vehiculo = {
    kilometrosHoy: 0,
    horasEncendidoHoy: 0,
    tiempoVarado: 0
  }

  mensaje: Mensaje;

  @Input() configTarjeta: any;
  @Input() datosTarjeta: any;


  //Gráfica
  elementosGrafica: any;
  elementosGraficaFiltro: any;
  resultadoElementosGrafica: any;
  graficaDefecto = [];

  constructor(
    private servicioModulo: ServicioModuloGeneralService
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    this.datosTarjeta = [{"valor":"98","etiqueta":"KM"},{"valor":"87","etiqueta":"MIN"},{"valor":"100","etiqueta":"%"},{"valor":"89","etiqueta":"%"},{"valor":"3","etiqueta":"h"},{"valor":"1","etiqueta":"A"}]
    /*this.ObtenerIndicadoresOperacion();
    this.cargarGrafica();*/
    /*this.configTarjeta = [
      {
        "titulo": "Kilómetros hoy",
        "icono": "fas fa-tachometer-alt fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Kilómetros recorridos por el vehículo hoy",
        "orden": 1,
        "datos": ""
      },
      {
        "titulo": "Horas motor encendido hoy",
        "icono": "fas fa-stopwatch fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Horas de motor encendido del vehículo hoy",
        "orden": 2,
        "datos": ""
      },
      {
        "titulo": "Eficiencia lavado hoy",
        "icono": "fas fa-stopwatch fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Eficiencia lavado hoy",
        "orden": 3,
        "datos": ""
      },
      {
        "titulo": "Eficiencia lavado mes actual",
        "icono": "fas fa-stopwatch fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Eficiencia lavado mes actual",
        "orden": 4,
        "datos": ""
      },
      {
        "titulo": "Tiempo atención fallas a ruta mes",
        "icono": "fas fa-stopwatch fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Tiempo atención fallas a ruta mes",
        "orden": 5,
        "datos": ""
      },
      {
        "titulo": "Tiempo varado año",
        "icono": "fas fa-exclamation-triangle fa-2x",
        "clase": "tarjetaIndicador",
        "tooltip": "Tiempo varado año",
        "orden": 6,
        "datos": ""
      }
    ];*/
  }

  /**
   * Obtiene la información de los indicadores para la operación
   * @author Victor Bedoya.
   * @date 2019-01-30.
   * @param
   * @returns
  */
  /*ObtenerIndicadoresOperacion() {
    let filtro = "{'empresa':'" + this.servicioModulo.valorTarjetaPrincipal + "'}";
    this.servicio.getServicioSigmaConsulta("", "EJECUCION_FORMULARIO_vehiculos_resumen_indicadores_operacion", filtro, "").subscribe(data => {
      if (data.respuesta == "true") {
        let respuesta = this.utilidades.RetornarStringObjeto(data.datos);
        let datos = [];
        datos.push(respuesta.lavadoVehiculosHoy[0]);
        datos.push(respuesta.lavadoVehiculosMes[0]);
        datos.push(respuesta.tiempoFallas[0]);
        
        this.arrayOperacion = datos;
        if (!respuesta.resumenOperacion.find(x => x.nombre == "Eficiencia lavado hoy")) {
          respuesta.resumenOperacion.push(this.arrayOperacionInicial[0]);
        }
        if (!respuesta.resumenOperacion.find(x => x.nombre == "Eficiencia lavado mes")) {
          respuesta.resumenOperacion.push(this.arrayOperacionInicial[1]);
        }
        if (!respuesta.resumenOperacion.find(x => x.nombre == "Tiempo atención fallas a ruta mes")) {
          respuesta.resumenOperacion.push(this.arrayOperacionInicial[2]);
        }
        respuesta.resumenOperacion = respuesta.resumenOperacion.sort((x, y) => x.orden - y.orden);
        this.arrayOperacion = respuesta.resumenOperacion;
        
        //this.arrayOperacion = respuesta;
      }
    });
  }*/

  /**
  * Obtiene la información de los indicadores para el vehiculo
  * @author Victor Bedoya.
  * @date 2019-01-30.
  * @param
  * @returns
  */
  /*ObtenerIndicadoresVehiculo() {
    let filtro = "{'vehiculo':'" + this.servicioCom.valorTarjetaPrincipal + "', 'empresa':'" + this.servicioCom.valorTarjetaPrincipal + "'}";
    this.servicio.getServicioSigmaConsulta("", "EJECUCION_FORMULARIO_vehiculos_resumen_indicadores_vehiculo", filtro, "").subscribe(data => {
      if (data.respuesta == "true") {
        let respuesta = this.utilidades.RetornarStringObjeto(data.datos);
        this.arrayOperacion = respuesta.resumenOperacion;
      }
    });
  }*/

  /**
   * Asigna el valor de la tarjeta principal a un valor del modelo, se deja vacio
   * si no se necesita
   * @author Andrés Quintero
   * @date 2019-02-12.
   * @param 
   * @returns 
  */
  SetTarjetaPrincipal() {
    //this.ObtenerIndicadoresOperacion();
  }

  /**
  * Asigna el valor de la tarjeta principal a un valor del modelo, se deja vacio
  * si no se necesita
  * @author Andrés Quintero
  * @date 2019-02-12.
  * @param 
  * @returns 
  */
  ObtenerModelo() {
    //this.ObtenerIndicadoresOperacion();
  }



  /**
  * Asigna el modelo seteado desde un servicio
  * @author Sebastián Cardona
  * @date 2019-03-13.
  * @param modelo 
  * @returns Respuesta del servicio de la capa funcional
  */
  AsignarModelo(modelo: any) {
    try {
      if (modelo != undefined) {
        let respuesta = JSON.parse(JSON.stringify(modelo))
        //this.placaVehiculo = respuesta.veh_codigo;
        //this.CalcularIndicadoresVehiculo(this.placaVehiculo);


      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  /**
* @description Obtiene la información de la gráfica según la consulta almacenada en la configuración del subsistema seleccionado
* @author Jhonatan David Mosquera
* @date 19/06/2020
* @param {type} parametro
* @returns
*/
  /*cargarGrafica() {
    try {
      let sigmaConsulta = this.servicioModulo.subsistemaActual.json.sub_grafic[0];
      let filtro = "{'empresa':'"+this.servicioModulo.operacionActual+"'}";
      this.servicioDatos.servicioSigmaConsulta("", sigmaConsulta, filtro, "").subscribe(data => {
        if (data.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(data.datos);
          this.resultadoElementosGrafica = respuesta.datos;
        }
      });
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }*/



}
