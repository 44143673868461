import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tarjeta-principal',
  templateUrl: './tarjeta-principal.component.html',
  styleUrls: ['./tarjeta-principal.component.scss']
})
export class TarjetaPrincipalComponent implements OnInit {

  constructor() { }

  @Input() elementos: any;
  @Output() salida = new EventEmitter();

  elemento: any = { text: "", id: "" };

  ngOnInit() {
  }

  onChange(value) {
    this.salida.next(value);
  }

  ngOnChanges() {
    if (this.elementos != undefined) {
      this.elemento.text = this.elementos[0].text;
      this.elemento.id = this.elementos[0].id;
      this.salida.next(this.elemento.id);
    }
  }

}
