import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'default', pure: false })
export class DefaultPipe implements PipeTransform{
  transform(value: any, defaultValue: any): any {
    if(value){
      return value
    }
    else{
      return defaultValue
    }
    
  }
}
