import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Mensaje } from '@mensaje';
import { GestionServiciosService } from 'app/ideam/gestion-servicios.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FwSidebarService } from '@shared/sidebar-original/fw-sidebar.service';
import { Grid } from '@shared/components/pagination/codebase/suite';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {

  grid: Grid;
  datos: any = [];
  mensaje: Mensaje;
  registrosEditados: any = [];
  arrayCategoria: any[] = [];
  arrayTipoInfocapa: any = [];
  codigoServicioCarga: string;
  nuevosRegistros: any = [];
  val_codigo: any = "";

  @ViewChild("widget", { static: true }) container: ElementRef;

  constructor(
    private servicios: GestionServiciosService,
    private modalService: NgbModal,
    private menuService: FwSidebarService

  ) {
    this.mensaje = new Mensaje("", "", "");
    this.servicios.capas = this;
    this.codigoServicioCarga = "";
  }

  ngOnInit(): void {

    let clase = this;
    this.grid = new Grid(this.container.nativeElement, {
      css: "dhx_demo-grid",
      columns: [
        {
          // width: 400,
          id: "val_nombre",
          header: [
            { text: "Nombre", css: "titulosTabla" },
            { content: "inputFilter" },
          ],
          align: "center",
          type: "string",
        },
        {
          // width: 400,
          id: "val_valor",
          header: [
            { text: "Valor", css: "titulosTabla" }, { content: "inputFilter" }
          ],
          align: "center",
          type: "number",
          editable: true,
        }
      ],
      adjust: false,
      autoWidth: true,
      autoHeight: true,
      editable: true,
      autoEmptyRow: true,

    });

    let valorActual;
    this.grid.events.on("afterEditStart", (row, col, editorType) => {
      if (col.id == 'val_valor') {
        valorActual = row.val_valor;
      }
      if (row.$emptyRow == true) {
        this.nuevosRegistros.push({
          val_codigo: row.val_codigo,
          val_nombre: row.val_nombre,
          val_valor: row.val_valor,
          id: row.id
        });
      }
      clase.val_codigo = row.val_codigo;
    });

    this.grid.events.on("afterEditEnd", (value, row, column) => {
      clase.menuService.editable = true;
      if (column.id == 'val_valor') {
        if (typeof (value) != 'number') {
          row.val_valor = valorActual;
        }
      }
      let nuevoRegistroEditado = false;
      if (clase.nuevosRegistros.length > 0) {
        clase.nuevosRegistros.forEach((registro, index) => {
          if (registro.id == row.id) {
            nuevoRegistroEditado = true;
            clase.nuevosRegistros[index] = {
              val_codigo: row.val_codigo,
              val_nombre: row.val_nombre,
              val_valor: row.val_valor,
              id: row.id
            }
          }
        });
      }
      if (!nuevoRegistroEditado) {
        if (clase.registrosEditados.length > 0) {
          let editadoPreviamente = false;
          clase.registrosEditados.forEach((registro, index) => {
            if (registro.id == row.id) {
              editadoPreviamente = true;
              clase.registrosEditados[index] = {
                val_codigo: row.val_codigo,
                val_nombre: row.val_nombre,
                val_valor: row.val_valor,
                id: row.id
              }
            }
          });
          if (!editadoPreviamente) {
            clase.registrosEditados.push({
              val_codigo: row.val_codigo,
              val_nombre: row.val_nombre,
              val_valor: row.val_valor,
              id: row.id
            });
          }
        }
        else {
          clase.registrosEditados.push({
            val_codigo: row.val_codigo,
            val_nombre: row.val_nombre,
            val_valor: row.val_valor,
            id: row.id
          });
        }
      }

    });

    this.ObtenerDatosLista();
  }

  ngOnDestroy() {
    if (this.grid) {
      this.grid.destructor();
    }
  }


  /**
   * Llena la lista de categorías con los datos de la respuesta del servicio
   * @author Elisabeth Campuzano Jaramillo
   * @date 29/03/2022
   * @param
   * @returns
   */
  LlenarListaCategorias(respuesta, mensaje) {
    this.datos = [];
    this.nuevosRegistros = [];
    this.registrosEditados = [];
    this.val_codigo = "";
    this.menuService.editable = false;

    respuesta.categories.forEach((capa, index) => {

      if (capa) {
        if (!capa.code || (capa.code && capa.code == "")) {
          capa.code = index;
        }

        this.datos.push({
          val_codigo: capa.val_codigo,
          val_nombre: capa.val_nombre,
          val_valor: capa.val_valor
        });

      }
    });

    this.datos.sort(function (a, b) {
      if (a.val_codigo > b.val_codigo) {
        return 1;
      }
      if (a.val_codigo < b.val_codigo) {
        return -1;
      }
      return 0;
    });

    this.grid.data.parse(this.datos);
    mensaje.cerrarMensajeCarga();
  }


  RealizarAccion(accion: string) {

    if (accion == 'adicionar') {
      if (this.nuevosRegistros.length > 0) {
        this.nuevosRegistros.forEach(registro => {
          this.Adicionar(registro);
        });
      }
      else {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "No hay registros adicionados",
          ""
        );
      }
    }
    else if (accion == 'editar') {
      if (this.registrosEditados.length > 0) {
        this.registrosEditados.forEach(registro => {
          this.Editar(registro);
        });
      }
      else {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "No hay registros editados",
          ""
        );
      }
    }
    else if (accion == 'eliminar') {
      if (this.val_codigo != "") {
        this.Eliminar(this.val_codigo);
      }
      else {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "Seleccione un registro para eliminar",
          ""
        );
      }

    }
    this.menuService.editable = false;
  }

  ObtenerDatosLista() {
    this.mensaje.retornarMensajeFuncion('loading', 'Realizando petición', '', '', null, null, null);
    this.servicios.ObtenerDatosCategorias("Categorias")
      .subscribe((respuesta) => {
        this.LlenarListaCategorias(respuesta, this.mensaje);
      });
  }

  ConfirmadoAccionGuardar(contenedor) {
    contenedor.Guardar()
  }


  CancelarAccionURL(contenedor) {
    contenedor.menuService.editable = false;
    contenedor.registrosEditados = [];
    contenedor.DescubrirServidor();
  }

  CancelarAccionCapa(contenedor) {
    contenedor.menuService.editable = false;
    contenedor.registrosEditados = [];
  }

  Adicionar(registro) {

    let entrada = {
      val_codigo: "",
      val_nombre: registro.val_nombre,
      val_valor: registro.val_valor.toString()
    };

    try {
      return this.servicios.AdicionarCategoria(JSON.stringify(entrada)).subscribe((resultado: any) => {
        if (resultado.state == 'success') {
          this.mensaje.retornarMensaje(
            "success",
            "Éxito",
            "La adición se realizó con éxito",
            ""
          );
          let clase = this;
          setTimeout(function () {
            clase.ObtenerDatosLista();
          }, 2000);

        }
        else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al adicionar, por favor validar",
            ""
          );
        }
      });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al adicionar, por favor validar",
        ""
      );
    }
  }


  Eliminar(codigo) {
    try {
      return this.servicios.EliminarCategoria(codigo).subscribe((resultado: any) => {
        if (resultado.state == 'success') {
          this.mensaje.retornarMensaje(
            "success",
            "Éxito",
            "Se eliminó con éxito",
            ""
          );
          let clase = this;
          setTimeout(function () {
            clase.ObtenerDatosLista();
          }, 2000);

        }
        else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al eliminar, por favor validar",
            ""
          );
        }
      });
    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al eliminar, por favor validar",
        ""
      );
    }
  }

  Editar(registro) {
    let entrada = {
      val_codigo: registro.val_codigo,
      val_nombre: registro.val_nombre,
      val_valor: registro.val_valor.toString()
    };
    try {
      this.servicios.EditarCategoria(JSON.stringify(entrada)).subscribe((resultado: any) => {
        if (resultado.state == 'success') {
          this.mensaje.retornarMensaje(
            "success",
            "Éxito",
            "La edición se realizó con éxito",
            ""
          );
          let clase = this;
          setTimeout(function () {
            clase.ObtenerDatosLista();
          }, 2000);

        }
        else {
          this.mensaje.retornarMensaje(
            "error",
            "Atención",
            "Error al editar, por favor validar",
            ""
          );
        }
      }, (error: any) => {
        this.mensaje.retornarMensaje(
          "error",
          "Atención",
          "Ocurrió un error! Por favor valide nuevamente",
          ""
        );
      });

    } catch (error) {
      this.mensaje.retornarMensaje(
        "error",
        "Atención",
        "Error al editar, por favor validar",
        ""
      );
    }
  }


  /**
   * Configuración de las columnas del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   */
  ConfigurarColumnasGrid() {
    this.grid.setColumns([
      {
        width: 400,
        id: "val_nombre",
        header: [
          { text: "Nombre" },
          { content: "inputFilter" },
        ],
        align: "center",
      },
      {
        width: 400,
        id: "val_valor",
        header: [{ text: "Valor" }, { content: "inputFilter" }],
        align: "center",
        type: Number,
        editable: true,
      }
    ]);
    this.grid.data.parse(this.datos);
  }

}
