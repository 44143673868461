import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-lista-informacion-multiple-tabs',
  templateUrl: './lista-informacion-multiple-tabs.component.html',
  styleUrls: ['./lista-informacion-multiple-tabs.component.scss']
})
export class ListaInformacionMultipleTabsComponent implements OnInit {

  todosLosElementos = [];
  selected: any[] = [];
  titulo: "Información";

  @Input() elementosListaInformacion: any;
  @Input() configuracionColumnasListas: any;
  @Input() datosConfiguracionLista: any;
  @Input() tabs:any;
  @Input() tabActivo:any;
  @Output() salida = new EventEmitter();
  @Output() cambioTabLista = new EventEmitter();
  @ViewChild(DatatableComponent) table: DatatableComponent;

  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;

  txt_filtrar: string = "";
  

  constructor() {    
  }

  ngOnInit() {
  }

  //  On select of dataTable's data row
  onSelect(event) {
    this.elementosListaInformacion;
    this.configuracionColumnasListas;
  }

  //  On Activation of dataTable's data row
  onActivate(event) {
    this.elementosListaInformacion;
    this.configuracionColumnasListas;
  }

  onDetailToggle(event) {
  }

  ngOnChanges() {
    this.todosLosElementos = this.elementosListaInformacion;
  }

  filtrar(event) {
    const val = event.target.value.toLowerCase();

    if (val != "") {
      this.elementosListaInformacion = this.todosLosElementos;

      const elementosFiltrados = this.elementosListaInformacion.filter(function (objeto) {

        for (let i in objeto) {
          let valorObjeto = String(objeto[i]);
          let existe = valorObjeto.toLowerCase().indexOf(val) !== -1 || !val;
          if (existe) {
            return objeto;
          }
        }
      });
      // update the rows
      this.elementosListaInformacion = elementosFiltrados;
      this.datosConfiguracionLista.totalElements = elementosFiltrados.length;
      this.datosConfiguracionLista.cantidadRegistros = elementosFiltrados.length;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
    } else {
      this.elementosListaInformacion = this.datosConfiguracionLista.modeloInicial;
      this.todosLosElementos = this.datosConfiguracionLista.modeloInicial;
      this.datosConfiguracionLista.pageNumber = this.datosConfiguracionLista.pageNumberInicial;
      this.datosConfiguracionLista.totalElements = this.datosConfiguracionLista.totalElementsInicial;
      this.datosConfiguracionLista.cantidadRegistros = this.datosConfiguracionLista.totalElementsInicial;
      this.table.offset = 0;
    }
  }    
}
