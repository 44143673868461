import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-lista-informacion-tabs-v2',
  templateUrl: './lista-informacion-tabs-v2.component.html',
  styleUrls: ['./lista-informacion-tabs-v2.component.scss']
})
export class ListaInformacionTabsV2Component implements OnInit {
  @Input() rows = [];
  @Input() columns = [];
  @Input() settings: any;
  @Output() cambioTabLista = new EventEmitter();
  @Output() salida = new EventEmitter();
  @Output() page = new EventEmitter();
  @Output() filter = new EventEmitter();

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @Input() tabs: any;
  @Input() tabActivo: any;

  selected: any[] = [];
  temp: any = [];
  loading: any;
  paging: any;
  title: any;
  type: any;
  size: any;
  offset: any;
  rowCount: any;
  filtro: any;
  messages = {
    emptyMessage: `<div class="empty-row">No hay datos a mostrar</div>`
  }

  constructor() {
    this.temp = [...this.rows];
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.table.selected = [];
  }

  ngOnChanges() {
    this.temp = [...this.rows];
    this.loading = this.settings.loading;
    this.paging = this.settings.paging;
    this.title = this.settings.title;
    this.type = this.settings.type;
    this.size = this.settings.size;
    this.offset = this.settings.pageNumber;
    this.rowCount = this.settings.totalElements;
    this.limpiarArreglo();
  }

  filtrar(event) {
    const val = event.target.value.toLowerCase();
    if (val === "") {
      this.filtro = "";
      this.FilterFromServer();
      this.table.offset = 0;
    }
    if (this.paging === false) {
      if (val) {

        const rowsFilter = this.temp.filter(function (objeto) {

          for (let i in objeto) {
            let valorObjeto = String(objeto[i]);
            let existe = valorObjeto.toLowerCase().indexOf(val) !== -1 || !val;
            if (existe) {
              return objeto;
            }
          }
        });
        // update the rows
        this.rows = rowsFilter;

        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }
      else {
        this.rows = [...this.temp];
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }

    }
  }

  FilterFromServer() {
    const val = this.filtro.toLowerCase();
    if (this.paging === true) {
      this.filter.next(val);
    }
  }

  GetSearch(event) {
    if (this.paging === true) {
      this.FilterFromServer()
    }
    else {
      this.filtrar(event)
    }

  }

  limpiarArreglo() {
    if (this.table != undefined) {
      this.table.selected = [];
    }
  }

  //onSelect({ selected }) {
  //  console.log('Select Event', selected, this.selected);
  //  this.selected.splice(0, this.selected.length);
  //  this.selected.push(...selected);
  //  this.salida.next(this.selected);
  //  //this.salida.next({selected});
  //}
}
