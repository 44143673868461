import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BarraHerramientasService } from './barra-herram.service';
//import { DwhAdmDatosService } from 'app/producto/geo-mod-dwh-admini/geo-ser-dwh-admini/dwh-adm-ser-datos';
//import { DwhAdmModuloService } from 'app/producto/geo-mod-dwh-admini/geo-ser-dwh-admini/dwh-adm-ser-modulo';
import { PopupComboComponent } from '@shared/components/popups/popup-combo/popup-combo.component';
import { Mensaje } from '@mensaje';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { PopupCarmasComponent } from '@shared/components/popups/popup-carmas/popup-carmas.component';
import { ListaInformacionV2Component } from '@shared/components/listas/lista-informacion-v2/lista-informacion-v2.component';
import { PopupResumenListaComponent } from '@shared/components/popups/popup-resumen-lista/popup-resumen-lista.component';
import { PopupComboListaComponent } from '@shared/components/popups/popup-combo-lista/popup-combo-lista.component';
//import { TraEjeFrmSolrapComponent } from 'app/producto/geo-mod-tra-ejecuc/geo-frm-tra-ejecuc/tra-eje-frm-solrap/tra-eje-frm-solrap.component';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';


@Component({
  selector: 'app-barra-herram',
  templateUrl: './barra-herram.component.html',
  styleUrls: ['./barra-herram.component.scss']
})
export class BarraHerramComponent implements OnInit {

  @Input() configBarra: any;
  @Input() hijo: any;
  @Output() Aceptar: EventEmitter<any> = new EventEmitter();
  configPopup: any;
  mensaje: Mensaje;
  clase: "BarraHerramComponent";

  constructor(private barraHerramientasService: BarraHerramientasService,
    private ModalService: NgbModal,
    private utilidades: ServicioUtilidadesService) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
  }
  /**
  * @description metodo obtendio de la intefaz de la barra de herramientas 
  * @author Jhonatan David Mosquera.
  * @date 26/06/2020
  * @param {object} elemento
  * @returns void
  */
  realizarAccion(elemento) {
    try {
      let componente;
      let carmas = false;
      let combo = false;
      let list = false;
      let filtro = elemento.configuracion;
      this.barraHerramientasService.servicioObtenerInformacionCache("POP_UP", filtro, "").subscribe(res => {
        if (res.datos != "") {
          let respuesta = this.utilidades.RetornarStringObjeto(res.datos);
          this.configPopup = respuesta;
        } else {
          this.configPopup = "";
        }
        if (this.hijo.registro != undefined) {
          if (this.hijo.registro.selected.length >= 1) {
            combo = true;
            list = true;
          }
          if (this.hijo.registro.selected.length == 1) {
            carmas = true;
          }
        }
        if (elemento.configuracion == "POPUPNO" || elemento.configuracion == "POPUPT1_PHR") {
          carmas = true;
          combo = true;
          list = true;
        }
        if (this.configPopup != undefined && this.configPopup != "") {
          if (this.configPopup.combos[0].filtro == "DOM_NO") {
            let valores = this.hijo.componenteActual.cargarCombo();
            this.configPopup.combos[0].items = valores;
          }
        }
        if (this.configPopup != undefined || this.configPopup != "") {
          switch (elemento.tipo) {
            case "PopupComboComponent":
              if (combo) {
                if (this.hijo.subsistemaActual.sub_codigo == "SUB_21") {
                  this.configPopup.combos[0].filtro = "DOM_HRE_2_1"
                }
                componente = PopupComboComponent;
              } else {
                this.mensaje.retornarMensaje("warning", "Falta seleccionar un elemento de la lista", "", "");
              }
              break;
            case "PopupCarmasComponent":
              if (carmas) {
                componente = PopupCarmasComponent;
              } else {
                this.mensaje.retornarMensaje("warning", "Recuerda seleccionar solo un elemento de la lista", "", "");
              }
              break;
            case "ListaInformacionV2Component":
              if (list) {
                let filtros = this.asignaFiltro(elemento.configLista.consulta.filtroSigmaConsultaInformacion, elemento.configLista.consulta.filtroSigmaConsultaPaginacion);
                this.abrirPopup(elemento, filtros);
              } else {
                this.mensaje.retornarMensaje("warning", "Falta seleccionar un elemento de la lista", "", "");
              }
              break;
            case "PopupComboListaComponent":
              if (list) {

                componente = PopupComboListaComponent;
              } else {
                this.mensaje.retornarMensaje("warning", "Falta seleccionar un elemento de la lista", "", "");
              }
              break;
            case "component":
              if (carmas) {
                /*if (elemento.formulario == "TraEjeFrmSolrapComponent") {
                  componente = TraEjeFrmSolrapComponent;
                }*/
              } else {
                this.mensaje.retornarMensaje("warning", "Falta seleccionar un elemento de la lista", "", "");
              }
              break;
            case "cierre":
              elemento.objetos = this.hijo.registro;
              let recargar = elemento;
              if (combo) {
                this.Aceptar.next(recargar);
              } else {
                this.mensaje.retornarMensaje("warning", "Falta seleccionar un elemento de la lista", "", "");
              }
              break;
          }
          if (componente != undefined) {
            let modalRef = this.ModalService.open(componente, { backdrop: 'static', size: 'lg', centered: true });
            modalRef.componentInstance.data = { configCombo: this.configPopup, hijo: this.hijo, objetos: this.hijo.registro, accion: elemento.accion, operacion: this.hijo.componenteActual.servicioModulo.operacionActual };
            modalRef.result.then((recargar) => {
              if (recargar) {
                recargar[0].elemento = elemento;
                this.Aceptar.next(recargar);
              }
              this.configPopup = "";
            }, (reason) => {
              //alert("2");
            });
            let camposSet: any = {};
            modalRef.componentInstance.esPopup = true;
            modalRef.componentInstance.camposSet = camposSet;
            modalRef.componentInstance.modalRef = modalRef;
          }
        }
      });
    } catch (error) {
      this.mensaje.retornarMensaje("warning", "falta elemento de la lista", "recuerda seleccionar un elemento de la lista", "");
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
    * @description esta función asigna el filtro de la consulta
    * @author Jhonatan David Mosquera
    * @date 17/08/2020
    * @param {Array} arreglo
    * @returns
    */
  asignaFiltro(filtrolista: any, filtropaginacion: any) {
    let llaves1 = Object.keys(filtrolista);
    let llaves2 = Object.keys(filtropaginacion);
    filtrolista['dias'] = '_dias';
    filtrolista['empresa'] = '_operacionActual';
    filtropaginacion['dias'] = '_dias';
    filtropaginacion['empresa'] = '_operacionActual';
    for (let i = 0; i < llaves1.length; i++) {
      if (filtrolista[llaves1[i]] == "_operacionActual") {
        filtrolista[llaves1[i]] = this.hijo.componenteActual.servicioModulo.operacionActual;
      } if (filtrolista[llaves1[i]] == "_dias") {
        filtrolista[llaves1[i]] = this.hijo.registro.selected[0].phr_json.phr_dias;
      }
    }
    for (let i = 0; i < llaves2.length; i++) {
      if (filtropaginacion[llaves2[i]] == "_operacionActual") {
        filtropaginacion[llaves2[i]] = this.hijo.componenteActual.servicioModulo.operacionActual;
      } if (filtropaginacion[llaves2[i]] == "_dias") {

        filtropaginacion[llaves2[i]] = this.hijo.registro.selected[0].phr_json.phr_dias;
      }
    }
    let filtros = {
      filtroinf: JSON.stringify(filtrolista),
      filtropag: JSON.stringify(filtropaginacion),
    }
    return filtros
  }

  /**
  * @description abre el popup de los campos con popups
  * @author Jhonatan David Mosquera
  * @date  24/07/2020 23/07/2020
  * @param {type} parametro
  * @returns
  */
  abrirPopup(elemento, filtros) {
    try {
      let recargar = [{
        accion: "",
        objetos: [],
        elemento: ""
      }];
      let modalRef = this.ModalService.open(PopupResumenListaComponent, { backdrop: 'static', size: "lg", centered: true });
      modalRef.result.then((resultado) => {
        if (resultado) {
          recargar[0].elemento = elemento;
          recargar[0].accion = this.configPopup.botones[0].accion;
          if (resultado.length > 0) {
            recargar[0].objetos = resultado;
          } else {
            recargar[0].objetos.push(resultado);
          }
          this.Aceptar.next(recargar);
        }
      });
      // 
      let camposSet: any = {};
      camposSet["titulo"] = elemento.configLista.configuracion.titulo;
      camposSet["configuracionLista"] = elemento.configLista.configuracion.datosConfiguracionLista;
      camposSet["sigmaConsultaPaginacion"] = elemento.configLista.consulta.sigmaConsultaPaginacion;
      camposSet["sigmaConsulta"] = elemento.configLista.consulta.sigmaConsultaInformacion;
      camposSet["filtroSigmaConsultaPaginacion"] = filtros.filtroinf;
      camposSet["filtroSigmaConsulta"] = filtros.filtropag;
      camposSet["configuracionColumnas"] = elemento.configLista.configuracion.configuracionColumnasListas;

      modalRef.componentInstance.esPopup = true;
      modalRef.componentInstance.camposSet = camposSet;
      modalRef.componentInstance.modalRef = modalRef;
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * @description realiza la acción de editar la operacion de la lista
   * @author Jhonatan David Mosquera.
   * @date 23/06/2020
   * @param {array, array}, objetos, elemento
   * @returns
   */
  accionBarraHerramientas(objetos, elemento) {
    try {
      let filtro = elemento.url;
      let funcion = elemento.funcion;
      let objeto = elemento.objeto;
      let accion = objetos[0].accion;
      let res;
      if (elemento.accion == "CambiarVehiculo") {
        this.mensaje.retornarMensajeFuncion("loading", "Realizando petición", "", "", null, null, null);
        this.barraHerramientasService.servicioObtenerInformacionCache(funcion, filtro, "").subscribe(res => {

          let respuesta = res.datos;
          this.EnviarDatos(objetos, elemento, respuesta);
          res = "";

        });
      } else {
        if (this.hijo.registro.selected.length > 0) {
          this.mensaje.retornarMensajeFuncion("loading", "Realizando petición", "", "", null, null, null);
          this.barraHerramientasService.servicioObtenerInformacionCache(funcion, filtro, "").subscribe(res => {

            let respuesta = res.datos;
            this.EnviarDatos(objetos, elemento, respuesta);
            res = "";

          });
        }
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }


  /**
  * @description Envia los objetos a editar uno a la vez
  * @author Carlos Alberto Gonzalez G.
  * @date 2020-08-14.
  * @param
  * @returns
 */
  private EnviarDatos(modelo, elemento, url) {
    let editados = [];
    let errores = [];
    let termino = modelo[0].objetos.length;
    for (let i = 0; i < modelo[0].objetos.length; i++) {
      modelo[0].objetos[i].valor = modelo[0].valor;
      modelo[0].objetos[i].accion = elemento.accion;
      modelo[0].objetos[i].boton = modelo[0].accion;

      if (url != undefined && url != "") {
        this.barraHerramientasService.servicioRealizarAccion("", elemento.objeto, modelo[0].objetos[i], modelo[0].accion, url).subscribe(data => {
          if (data.respuesta == "true") {
            editados.push(data.sql);
          } else {
            if (data.noticia != "") {
              errores.push(data.noticia);
            }
          }
          termino--;
          this.MostrarMensaje(termino, errores, editados);

        },

          response => {
            termino--;
            this.MostrarMensaje(termino, errores, editados);
          });
      }
    }
  }

  /**
   * @description Muestra el mensaje de resultado de la operación
   * @author Carlos Alberto Gonzalez G.
   * @date 2020-08-14.
   * @param
   * @returns
  */
  private MostrarMensaje(termino: number, errores: any, editados: any) {
    if (termino == 0) {
      this.hijo.papa.asignardatosConfiguracionLista();
      if (errores.length == 0)
        this.mensaje.retornarMensaje("exito", "Operación exitosa", "Éxito al editar el elemento [" + editados.join(", ") + "]", "");
      if (this.configBarra[0].accion == "AdicionarCiente") {
        this.hijo.hijo.cantidadElementosListaPlaneacion();
      }
      else if (errores.length > 0 && editados.length == 0)
        this.mensaje.retornarMensaje("error", "Atención", "[" + errores.join(", ") + "]", "");
      else if (errores.length > 0 && editados.length > 0)
        this.mensaje.retornarMensaje("error", "Atención", "Editados:[" + editados.join(", ") + "] " + " Errores:[" + errores.join(", ") + "]", "");
    }
  }
}