import { Injectable } from '@angular/core';
import { Mensaje } from '@mensaje';



@Injectable()
export class Globals {

  sistemas: any = new Array();
  mensaje: Mensaje = new Mensaje("", "", "");
  webConfig: any = {};
  infoUsuario: any = {};
  cargaBase: any = {};

}