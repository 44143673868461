import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Mensaje } from '@mensaje';
import { FormControl, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors } from '@angular/forms';
import { ServicioUtilidadesService } from 'app/shared/services/servicio-utilidades.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSeguridad } from '@seguridad';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisorDatosService } from 'app/visor/shared/services/visor-datos.service';

@Component({
  selector: 'app-popup-usuarios-interesados',
  templateUrl: './popup-usuarios-interesados.component.html',
  styleUrls: ['./popup-usuarios-interesados.component.scss']
})
export class PopupUsuariosInteresadosComponent implements OnInit {

  bloqueDominios: any = true;
  bloqueoBoton: any = true;
  mensaje: Mensaje;
  modelo: any;
  regularForm: FormGroup;
  resultadosDominios: any;
  per_codigo: any;
  vis_tipo: any;
  campoObligatorio: any;

  modalRef: any;
  sistema = sessionStorage.getItem('sistemaLogueado');




  arrTipoIdentificacion: any = [];

  arrTipoTramite: any;

  estadoCheck: any = false;



  constructor(
    public utilidades: ServicioUtilidadesService, private servicioGlobal: GlobalSeguridad, private visorDatosService: VisorDatosService) { this.mensaje = new Mensaje("", "", ""); }

  ngOnInit() {

    this.regularForm = new FormGroup({
      'int_tipdoc': new FormControl(null, [Validators.required]),
      'int_identi': new FormControl(null, [Validators.required]),
      'int_nombre': new FormControl(null, [Validators.required]),
      'int_email': new FormControl(null, [Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')])]),
      'int_entida': new FormControl(null, [Validators.required]),
      'int_usoinf': new FormControl(null, [Validators.required]),
      'int_licuso': new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });

    this.visorDatosService.ObtenerDominiosCapas('TipoIdenti').subscribe((respuesta2: any) => {
      const domains = JSON.parse(respuesta2.domain);
      this.arrTipoIdentificacion = [...domains];
      this.bloqueDominios = false;
    });


  }


  LimpiarControl(control: AbstractControl): ValidationErrors | null {
    try {
      return control.value === '' || control.value == null ? null : Validators.required(control);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
     * Metodo encargado de registrar el usuario.
     * @author Titanq Ingenieria.
     * @date 2023-05-25.
     * @param
     * @returns
    */
  Ejecutar() {
    try {

      this.mensaje.retornarMensajeFuncion("loading", "Registrando usuario", "", "", null, null, null);
      const json = JSON.stringify(this.regularForm.getRawValue());
      this.visorDatosService.getServicioRealizarAccion(sessionStorage.getItem('sistemaLogueado'),JSON.parse(sessionStorage.infoUsuario).usu_codigo, "interesadoCertificado", json, "", "adicionar", "urlServicioVisor", "FUNVisor").subscribe(data => {
        if (data.respuesta === "true") {
          this.mensaje.cerrarMensajeCarga();          
          this.modalRef.close(true);          
        }
        else {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", "No fue posible registrar el usuario", "");
        }
      });


    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * Metodo encargado de abrir una url en una nueva pestaña
   * @author Titanq Ingenieria.
   * @date 2023-05-25.
   */
  openUrl() {
    window.open('http://161.10.233.23:48001/herramientas/adjuntos/Anexos/ANEXO-01PEV-12%20CONDICIONES%20DE%20USO%20Y%20DERECHOS%20DE%20AUTOR%20V1.pdf', '_blank');
  }

  /**
     * Metodo encargado de limpiar el formulario de registro.
     * @author Titanq Ingenieria.
     * @date 2023-05-25.
     * @param
     * @returns
    */
  LimpiarModelo() {
    this.regularForm.reset();
  }

  /**
  * @description cierra el pop-up
  * @author Titanq Ingenieria
  * @date 24/05/2023
  * @returns
  */
  cerrarPopup() {
    this.modalRef.close();
  }


}
