import { ServicioDatosService } from "@shared/services/servicio-datos.service";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { AppStore } from "./app-state-store";
import { mergeMap, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { IAPPState } from "./estados/estados";

@Injectable()
export class AppEffects {
  appStore: AppStore = new AppStore();
  @Effect()
  data$ = this.actions$.pipe(
    ofType(this.appStore.cargarObjetosControl),
    mergeMap(({ ...sigmaConsulta }) =>
      this.servicioDatos.servicioSigmaConsultaFiltroUsuario(sigmaConsulta.id, sigmaConsulta.filtro).pipe(
        map((objetosControl) => {
          let res: Array<any> = [];
          if (objetosControl.respuesta == "true") {
            let respuesta = JSON.parse(objetosControl.datos);
            res = [...respuesta.objetosControl];
          }
          return this.appStore.asignarObjetosControl({ ruta: sigmaConsulta.modulo, objs: res });
        })
      )
    )
  );
  @Effect()
  dataSub$ = this.actions$.pipe(
    ofType(this.appStore.cargarSubsistemas),
    mergeMap(({ ...sigmaConsulta }) =>
      this.servicioDatos.servicioSigmaConsultaFiltroUsuario(sigmaConsulta.id, sigmaConsulta.filtro).pipe(
        map((objetosControl) => {
          let res: Array<any> = [];
          if (objetosControl.respuesta == "true") {
            let respuesta = JSON.parse(objetosControl.datos);
            res = [...respuesta.subsistemas];
          }
          return this.appStore.asignarSubsistemas({ ruta: sigmaConsulta.modulo, subs: res });
        })
      )
    )
  );

  @Effect()
  dataOperation$ = this.actions$.pipe(
    ofType(this.appStore.cargarTarjetaPrincipal),
    mergeMap(({ ...sigmaConsulta }) =>
      this.servicioDatos.servicioSigmaConsultaFiltroUsuario(sigmaConsulta.id, sigmaConsulta.filtro).pipe(
        map((datos) => {
          let res: Array<any> = [];
          if (datos.respuesta == "true") {
            let respuesta = JSON.parse(datos.datos);
            res = [...respuesta.datos];
          }
          return this.appStore.asignarOperaciones({ datos: res, consulta: sigmaConsulta });
        })
      )
    )
  );
  constructor(private actions$: Actions, private storeApp: Store<IAPPState>, private servicioDatos: ServicioDatosService) {}
}
