import { Injectable } from '@angular/core';
import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {
  }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  static toExportAsExcel(excelFileName: string): string {
    return `${excelFileName}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: WorkSheet = utils.json_to_sheet(json);
    const workbook: WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    writeFile(workbook, ExcelService.toExportFileName(excelFileName));
  }

  public exportAsExcel(json: any[], excelFileName: string): void {
    const worksheet: WorkSheet = utils.json_to_sheet(json);
    const workbook: WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    writeFile(workbook, ExcelService.toExportAsExcel(excelFileName));
  }

}
