<div class="card mt-1 mb-1">
  <div class="card-body py-0">
    <div class="px-1 py-2">
      <div class="media">
        <div class="media-body text-left">
          <div class="d-flex justify-content-between">
            <div class="col-md-10">
              <label><strong>Operación</strong></label>
              <ng-select bindLabel="text" 
              bindValue="id" 
              (change)="onChange(elemento.id)" 
              [items]="elementos"
              [(ngModel)]="elemento.id">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>