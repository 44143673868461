import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalSeguridad } from '@seguridad';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    hijo: any;
    modelo: any;

    constructor(private http: HttpClient, private seguridadGlobal: GlobalSeguridad) {}

    RecibirModelo(objeto) {
        this.modelo = objeto;
        this.ObtenerModelo();
    }

    ObtenerModelo() {
        if (this.hijo) {
            this.hijo.AsignarModelo(this.modelo);
        }
    }

    servicioSigmaConsulta(sistema: string, idFuncion: string, filtro: string, datosPeticion: string): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion',
            {
                sistema: sistema,
                idFuncion: idFuncion,
                filtro: filtro,
                datosPeticion: datosPeticion,
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
    }

    servicioRealizarAccion(nombreObjeto: string, jsonData: string, accion: string, url: string): Observable<any> {
        this.seguridadGlobal.validarSesion();
        if (url !== undefined) {
            return this.http.post(
                url,
                {
                    nombreObjeto: nombreObjeto,
                    jsonDatos: jsonData,
                    accion: accion,
                },
                { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
            );
        }
    }

    servicioObtenerInformacionCache(sistema, idObjetoPizarra, filtro, datosPeticion): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
            {
                sistema: sistema,
                idObjetoPizarra: idObjetoPizarra,
                filtro: filtro,
                datosPeticion: datosPeticion,
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
    }

    descargarArchivo(url, param): Observable<any> {
        this.seguridadGlobal.validarSesion();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Content-Types': this.seguridadGlobal.atributoSesion,
        });
        return this.http.post<Blob>(url, param, { headers: headers, responseType: 'blob' as 'json' });
    }

    subirArchivo(fd): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http.post(JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNArchivo/SubirArchivosFtp', fd, {
            headers: new HttpHeaders({ 'Content-Types': this.seguridadGlobal.atributoSesion }),
        });
    }

    getRealizarAccionEjecucionForDinamy(sistema, nombreObjeto, accion, jsonData, datosPeticion): Observable<any> {

        this.seguridadGlobal.validarSesion();
        const url = JSON.parse(localStorage.getItem('webConfig'))['urlServicioTickets'] + '/FUNGestionTickets/RealizarAccion';
    
        if (url) {
            return this.http.post(
            url,
            {
              sistema: sistema,
              nombreObjeto: nombreObjeto,
              accion: accion,
              jsonData: JSON.stringify(jsonData),
              datosPeticion: datosPeticion
            },
            // httpOptions
            {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion})}
          );
        }
      }
}
