import { typeNotification } from 'app/visor/shared/types/type-notification';
import { VisorDatosService } from './../../visor/shared/services/visor-datos.service';
import { Component, OnInit, ElementRef, Renderer2, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Observable, Subscription } from 'rxjs';
import { NotificationService } from './notification.service';
import { Store } from '@ngrx/store';
import { ShowNotification } from '@leafletStore/index';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { IVisorState } from 'app/visor/shared/models/mapModels';

@Component({
    selector: 'app-notification-sidebar',
    templateUrl: './notification-sidebar.component.html',
    styleUrls: ['./notification-sidebar.component.scss'],
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

    layoutSub: Subscription;
    isOpen = false;
    categoryNotifications$: Observable<Array<any>>;
    notifications$: Observable<Array<any>>;

    @ViewChild('sidebar') sidebar: ElementRef;

    constructor(
        private renderer: Renderer2,
        private layoutService: LayoutService,
        public notificationService: NotificationService,
        private store: Store<IVisorState>,
        private cdRef: ChangeDetectorRef,
        private visorDatosService: VisorDatosService
    ) {
        this.layoutSub = this.layoutService.changeEmitted$.subscribe(
            value => {
                if (this.isOpen) {
                    this.renderer.removeClass(this.sidebar.nativeElement, 'open');
                    this.isOpen = false;
                } else {
                    this.renderer.addClass(this.sidebar.nativeElement, 'open');
                    this.isOpen = true;
                }
                this.cdRef.detectChanges();
            });
    }

    ngOnInit() {
        this.categoryNotifications$ = this.notificationService.servicioSigmaConsulta('', 'NOTIFICACIONES', '', '');
        this.notifications$ = this.notificationService.notificationSignalR$.pipe(
            tap(() => this.cdRef.detectChanges())
        )
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    onClose() {
        this.renderer.removeClass(this.sidebar.nativeElement, 'open');
        this.isOpen = false;
        this.cdRef.detectChanges();
    }

    showInfoLayer = (notification: any, category: any) => this.store.dispatch(ShowNotification({ notification: {
       ...notification,
       ...category
    } }));

    renderUrlImg = (tipos: { [key: string]: string }, eve_codigo: string) => tipos[eve_codigo];

    markAsViewed(idNotificacion: string) {
        const registerNews = {
            idNotificacion: idNotificacion,
            tipo: typeNotification.VIEWED, // VISTO
        };

        this.visorDatosService.enviarRespuesta(registerNews).subscribe(
            {
                next: (response: {error: string, sugerencia: string}) => {
                    if (response?.error) {
                        this.fireAlert(response.sugerencia, 'error')
                    } else {
                        this.fireAlert('Notificación guardada con exito', 'success')
                    }
                },
                error: () => this.fireAlert('Error inesperado', 'error'),
            });
    }

    buildNameNotification = (configText: {Separador: string, campos: Array<any>}, notification: object) => {
        const buildString = [];
        configText?.campos?.forEach(campo => {
            Object.entries(campo).forEach(entry => {
                const [key, value] = entry;
                if (notification.hasOwnProperty(key)) {
                    const dinamicNameNotification = `${value} ${notification[key]}`;
                    buildString.push(dinamicNameNotification);
                }
              });
        })
        return buildString.join(configText?.Separador);
    }

    fireAlert = (message: string, type: 'success' | 'error') => {
        const toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true
        });

        toast.fire({ icon: type, title: message });
    }
}
