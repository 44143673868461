import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/Rx";
import {Globals} from '../../../globals';
import { GlobalSeguridad } from "../../../global-seguridad";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class FwLoginService {

  sistemaCarga: string;

  constructor(
    private http: HttpClient,
    private globals:Globals,
    private seguridadGlobal: GlobalSeguridad
  ) {}

  getSistemas(sistemas: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      //this.globals.webConfig["servicioFw"]+"/FUNLogin/ObtenerSistemas",
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"]+"/FUNLogin/ObtenerSistemas",
      { sistemas: sistemas },
      {headers: new HttpHeaders({ "Content-Type": "application/json","Content-Types":this.seguridadGlobal.atributoSesion})}

    );
  }

  getCargarApp(sistemas: string): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"]+"/FUNLogin/CargarApp",
      { sistemas: sistemas },
      //{headers: new HttpHeaders({ "Content-Type": "application/json","a":this.seguridadGlobal.apiKey})}
      {headers: new HttpHeaders({ "Content-Type": "application/json"})}
    );
  }

  iniciarSesion(sistema, usuario, passwd): Observable<any> {
    return this.http.post(
      //this.globals.webConfig["servicioFw"]+"/FUNLogin/IniciarSesion",
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"]+"/FUNLogin/IniciarSesion",
      { sistema: sistema, usuario: usuario, contrasenia: passwd },
      //httpOptions
      {headers: new HttpHeaders({ "Content-Type": "application/json","Content-Types":this.seguridadGlobal.atributoInicial})}
    );
  }

  cerrarSesion(sistema, usuario, passwd) {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"]+"/FUNLogin/CerrarSesion",
      { sistema: sistema, usuario: usuario, contrasenia: passwd },
      //httpOptions
      {headers: new HttpHeaders({ "Content-Type": "application/json","Content-Types":this.seguridadGlobal.atributoSesion})}
    );
  }

  cambioContrasenia(sistema: string,usuario: string, contrasena: string): Observable<any> {
    return this.http.post(
      //this.globals.webConfig["servicioFw"]+"/FUNLogin/CambiarContrasenia",
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"]+"/FUNLogin/CambiarContrasenia",
      { sistema: sistema,
        usuario: usuario,
        contrasenia: contrasena },
      //httpOptions
      {headers: new HttpHeaders({ "Content-Type": "application/json","Content-Types":this.seguridadGlobal.atributoSesion})}
    );
  }

  // Prueba(): Observable<any> {
  //   return this.http.post(
  //     "https://integraalti.uat.geoambiental.sigmaingenieria.net/integraalsus/api/login",
  //     { user: "sigma", password: "Bv2uPO67.?6789" },
  //     //{headers: new HttpHeaders({ "Content-Type": "application/json","a":this.seguridadGlobal.apiKey})}
  //     {headers: new HttpHeaders({ "Content-Type": "application/json"})}
  //   );
  // }
}
