<div class="text-center m-3">
    <button type="button" class="close" aria-label="Close" (click)="CerrarModal()">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4>Configurar Información de la Capa</h4>
</div>
<div class="modal-body text-center">
    <div #infocapa class="dhx-container--grid m-3 tabla-infocapa"></div>
</div>

<div class="m-3">
    <button type="button" class="btn btn-sm boton-guardar texto-guardar" (click)="EditarInfoCapa()">
        Guardar
    </button>
</div>
