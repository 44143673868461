import { animate, animation, keyframes, style, transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

const slideInAnimation = animation([
    style({ transform: 'translateY({{ from }})', opacity: 0 }),
    animate('{{ timings }}', style({ transform: 'translateY(0)', opacity: 1 }))
]);

const slideOutAnimation = animation([
    animate(
        '{{ timings }}',
        style({ transform: 'translateY({{ to }})', opacity: 0 })
    )
]);

export const pulseAnimation = animation([
    style({ transform: 'scale(0.5)' }),
    animate(
        '{{ timings }}',
        keyframes([
            style({ transform: 'scale(0.5)', offset: 0 }),
            style({ transform: 'scale({{ scale }})', offset: 0.5 }),
            style({ transform: 'scale(0.5)', offset: 1 })
        ])
    )
]);

@Component({
    selector: 'app-counter-bubble',
    template: '{{counter}}',
    styleUrls: ['./counter-bubble.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('visibilityChange', [
            transition(':enter', [useAnimation(slideInAnimation, { params: { from: '2%', timings: '200ms ease-in' } })]),
            transition(':leave', [useAnimation(slideOutAnimation, { params: { to: '-20%', timings: '200ms ease-in' } })])
        ]),
        trigger('counterChange', [
            transition(':increment', useAnimation(pulseAnimation, { params: { timings: '200ms', scale: 2 } }))
        ])
    ]
})
export class CounterBubbleComponent {

    @HostBinding('@counterChange')
    @Input()
    counter: number;

    @HostBinding('@visibilityChange')
    animation = true;
}
