import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Mensaje } from '@mensaje';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsList } from '@settingsList';
import { ServicioFiltroCompuestoService } from './servicio-filtro-compuesto.service';


@Component({
  selector: 'app-filtro-compuesto',
  templateUrl: './filtro-compuesto.component.html',
  styleUrls: ['./filtro-compuesto.component.scss']
})
export class FiltroCompuestoOldComponent implements OnInit {

  resultadosDominios: any;
  modelo: any;
  regularForm: FormGroup;
  bloqueDominios: any = true;
  mensaje: Mensaje;
  bloqueoBoton: any = true;

  //variables para configuracion de popup
  camposSet: any = {};
  modalRef: any;

  //variables para la lista
  rows: any;
  columns: any;
  configuracionLista = new SettingsList();
  temp_configuracionColumnasPopup = '[{"prop":"campo","name":"Campo"},{"prop":"operador","name":"Operador"},{"prop":"valor","name":"Valor"}]';
  coleccionDatosLista: any[] = [];
  posicionElemento: any = 0;

  resultadoConfigFiltroCompuesto: any;
  arrCampos: any[] = [];
  arrOperadores: any[] = [];
  operadores: any[] = [];
  arrDominio: any;
  esCombo: boolean = false;
  dominio: any;
  campo: any = "";
  operador: any = "";
  valor: any = "";
  tipoCampo: any = "";

  constructor(
    private servicioDatos: ServicioFiltroCompuestoService,
    private ModalService: NgbModal,
    private cdRef: ChangeDetectorRef,
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit(): void {
    this.obtenerDatosFiltroCompuesto();
    this.obtenerDominios();

 
    this.configuracionLista.pageNumber = 0;
    this.columns = JSON.parse(this.temp_configuracionColumnasPopup);
    this.rows = [];
  }

  /**
   * Obtiene los datos de la configuración del filtro compuesto
   * @author Michael Agudelo
   * @date 2020-07-10.
   * @param
   * @returns
  */
  async obtenerDatosFiltroCompuesto() {
    try {
      let filtro = this.camposSet["id_sigma_consulta"];
      let valoresLista = this.camposSet["valoresLista"];
      this.coleccionDatosLista = valoresLista;
      let resultado = await this.servicioDatos.servicioObtenerInformacionCache("FILTRO_COMPUESTO", filtro, "").toPromise();
      if (resultado.respuesta == "true") {
        this.resultadoConfigFiltroCompuesto = resultado.datos;
        this.arrCampos = [...this.resultadoConfigFiltroCompuesto];
        this.rows = [...this.coleccionDatosLista];
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
  * @description obtiene los dominios de cache segun el filtro 
  * @author Michael Agudelo
  * @date 14/07/2020
  * @param 
  * @returns
  */
  obtenerDominios() {
    try {
      //let respuesta;
      //let filtro = "DOM_OPERAD";
      //this.servicioDatos.servicioObtenerInformacionCache("DOMINIOS", filtro, '').subscribe(res => {
      //  respuesta = res.datos;
      //  this.arrOperadores = respuesta.DOM_OPERAD;
      //});
    } catch (error) {

    }
  }

  cerrarModal() {
    this.modalRef.close();
  }

  ObjetoListaSeleccionado(evento) {
    try {
      let registro = evento.selected[0];
      for (let i = 0; i < this.coleccionDatosLista.length; i++) {
        if (registro == this.coleccionDatosLista[i]) {
          this.posicionElemento = i;
        }
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  /**
  * @description Valida que el filtro a ingresar no exista
  * @author Michael Agudelo
  * @date 14-07-2020
  * @param evento
  * @returns
  */
  validarExisteFiltro(filtro) {
    try {
      for (let i = 0; i < this.coleccionDatosLista.length; i++) {
        if (filtro.campo == this.coleccionDatosLista[i]["campo"] && filtro.operador == this.coleccionDatosLista[i]["operador"] && filtro.valor == this.coleccionDatosLista[i]["valor"]) {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.log("Error: " + error);
    }
  }



  /**
   * @description Valida el tipo de campo de la configuración, si tiene dominio muestra el combobox; sino , la caja de texto
   * @author Michael Agudelo
   * @date 14-07-2020
   * @param evento
   * @returns
   */
  validarTipoCampo(evento) {
    this.dominio = "";
    for (let i = 0; i < this.resultadoConfigFiltroCompuesto.length; i++) {
      if (evento["text"] == this.resultadoConfigFiltroCompuesto[i]["text"]) {
        this.dominio = this.resultadoConfigFiltroCompuesto[i]["dominio"];
        this.operadores = this.resultadoConfigFiltroCompuesto[i]["operadores"];
        this.tipoCampo = this.resultadoConfigFiltroCompuesto[i]["tipo"];
      }
    }
    
    if (this.dominio != "") {
      this.esCombo = true;
      let filtro = this.dominio;
      try {
        let respuesta;
        this.servicioDatos.servicioObtenerInformacionCache("DOMINIOS", filtro, '').subscribe(res => {
          respuesta = res.datos;
          this.arrDominio = respuesta[this.dominio];
        });
      } catch (error) {
      }
    } else {      
      this.esCombo = false;
    }
    

    this.arrOperadores = [...this.operadores];
  }



  /**
     * @description Agregar campos seleccionados a la lista de filtros
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
  agregarFiltro() {
    if (this.campo != "" && this.operador != "") {
      let objeto = { campo: this.campo, operador: this.operador, valor: this.valor };
      if (!this.validarExisteFiltro(objeto)) {
        this.coleccionDatosLista.push(objeto);
        this.configuracionLista.pageNumber = 0;
        this.rows = [...this.coleccionDatosLista];
      }
    }

    this.campo = "";
    this.operador = "";
    this.valor = "";
  }

  /**
     * @description eliminar filtro seleccionado de la lista de filtros
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento  
     * @returns
     */
  eliminarFiltro() {
    this.coleccionDatosLista.splice(this.posicionElemento, 1);
    this.rows = [...this.coleccionDatosLista];
  }

  /**
    * @description crear cadena con el filtro a enviar
    * @author Michael Agudelo
    * @date 14-07-2020
    * @param evento  
    * @returns
    */
  enviarFiltro() {
    let filtroTotal = [];
    let filtro = {};
    let campo = "";
    let operador = "";
    let valor = "";
    let configCampo = "";
    let tipo = "";
    let campoJson = "";
    for (let i = 0; i < this.coleccionDatosLista.length; i++) {
      campo = this.coleccionDatosLista[i]["campo"];
      operador = this.coleccionDatosLista[i]["operador"];
      valor = this.coleccionDatosLista[i]["valor"];
      configCampo = this.obtenerConfiguracionCampo(campo);
      tipo = configCampo["tipo"];
      campoJson = configCampo["campo_json"];

      filtro = { campo: campo, operador: operador, valor: valor, tipo: tipo, campoJson: campoJson };
      filtroTotal.push(filtro);
    }

    this.modalRef.close({ filtroTotal: filtroTotal, registros: this.rows });
  }



  /**
    * @description obtiene el tipo de dato de un campo de la lista de filtros
    * @author Michael Agudelo
    * @date 14-07-2020
    * @param evento  
    * @returns
    */
  obtenerConfiguracionCampo(campo) {
    let config = "";
    for (let i = 0; i < this.resultadoConfigFiltroCompuesto.length; i++) {
      if (campo == this.resultadoConfigFiltroCompuesto[i]["id"]) {
        config = this.resultadoConfigFiltroCompuesto[i];
        return config;
      }
    }
    return config;
  }


  confirmarSeleccion() {
    let parametros = {
      contenedor: this
    };
    //Muestra un modal para asegurar la accion que quiere realizar el usuario
    this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea agregar el registro seleccionado?", "", "", this, "retornarResultado", parametros);
  }



}
