import { createAction, props } from '@ngrx/store';

import { IBaseMap } from './../../shared/models/baseMap';

const TOGGLE_GALLERY_MAP = '[GALLERY_MAP] Toggle gallery map';
const SET_BASE_MAP = '[GALLERY_MAP] Set gallery map';
const CLOSE_GALLERY_MAP = '[GALLERY_MAP] Close gallery map';

export const ToggleGalleryMap = createAction(TOGGLE_GALLERY_MAP);

export const CloseGalleryMap = createAction(CLOSE_GALLERY_MAP);

export const SetbaseMap = createAction(SET_BASE_MAP, props<{ payload: IBaseMap }>());
