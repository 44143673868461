<div class="row col-md-12">
  <div class="row col-md-6 left-15 mr-3 content-header">
    Gestión Imagen
  </div>
  <div class="row col-md-6 mb-1">
    <div>
      <em (click)="clicBotonAdicionarImagen()" class="btn btn-outline-info left-15 mt-2 mb-0 mr-1 fa fa-plus-square">
      </em>
      <em (click)="eliminarImagen()" class="btn btn-outline-info left-15 mt-2 mb-0 mr-1 fa fa-trash">
      </em>
    </div>


    <input type="file" name="fileImagen" style="display:none;" id="fileImagen"
      (change)="cambioArchivo($event.target.files, fileImagen)" #fileImagen>
  </div>
  <div class="col-md-12 pl-1 pr-1" *ngIf="coleccionRutasImagenes != undefined && coleccionRutasImagenes?.length>0">
    <ngu-carousel #carousel [inputs]="configsCarrusel"  [dataSource]="coleccionRutasImagenes" class="col-md-12 pl-1 pr-1" (initData)="initDataFn($event)">
      <ngu-item NguCarouselItem class="bannerStyle" *nguCarouselDef="let item;" [class.bordeTarjetaSeleccionado] = "item == rutaActual">
        <div class="m-1 card no-border box-shadow-0" (click)="imagenSeleccionada(item)"> 
          <div class="card-body">
            <img alt="Card image cap" class="card-img img-fluid" [src]="item">
          </div>
        </div>
      </ngu-item>

      <button [hidden]="false" NguCarouselPrev class='btn btn-outline-primary btn-round .btn-sm leftRs'>&lt;</button>
      <button [hidden]="false" NguCarouselNext class='btn btn-outline-primary btn-round .btn-sm rightRs'>&gt;</button>
    </ngu-carousel>
  </div>
</div>