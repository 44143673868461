import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-lista-informacion-v2-2',
  templateUrl: './lista-informacion-v2-2.component.html',
  styleUrls: ['./lista-informacion-v2-2.component.scss']
})
export class ListaInformacionV22Component implements OnInit {

  @Input() rows = [];
  @Input() columns = [];
  @Input() settings: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  selected: any[] = [];
  temp: any = [];
  loading: any;
  paging: any;
  title: any;
  type: any;
  size: any;
  offset: any;
  rowCount: any;
  filtro: any;
  messages = {
    emptyMessage: `<div class="empty-row">No hay datos a mostrar</div>`
  }

  constructor() {
    this.temp = [...this.rows];
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.temp = [...this.rows];
    this.loading = this.settings.loading;
    this.paging = this.settings.paging;
    this.title = this.settings.title;
    this.type = this.settings.type;
    this.size = this.settings.size;
    this.offset = this.settings.pageNumber;
    this.rowCount = this.settings.totalElements;
  }

  filtrar(event) {
    const val = event.target.value.toLowerCase();
    if(val === ""){
      this.filtro = "";
      this.table.offset = 0;
    }
    if (this.paging === false) {
      if (val) {

        const rowsFilter = this.temp.filter(function (objeto) {

          for (let i in objeto) {
            let valorObjeto = String(objeto[i]);
            let existe = valorObjeto.toLowerCase().indexOf(val) !== -1 || !val;
            if (existe) {
              return objeto;
            }
          }
        });
        // update the rows
        this.rows = rowsFilter;

        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }
      else {
        this.rows = [...this.temp];
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }

    }
  }



}
