import { Pipe, PipeTransform } from '@angular/core';
import { ShaSerMensajesService } from '@shared/services/sha-ser-mensajes.service';

@Pipe({
  name: 'mensajes'
})
export class MensajesPipe implements PipeTransform {
  constructor(public mensajeServicio: ShaSerMensajesService) {
  }
  transform(id: string, tipo:string, lang?: string): any { 
    let valor="";
    if(tipo=="mensaje")
      valor=this.mensajeServicio.obtenerMensaje(id, lang)["mensaje"];
    else
      valor=this.mensajeServicio.obtenerEtiqueta(id, lang);
    return(valor);
  }

}
