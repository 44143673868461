<!-- Sidebar Header starts -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <a class="logo-text">
            <div class="logo-img color-obc d-none">
                <em class="fa fa-bars"></em>
            </div>
            <span class="text align-middle">
                <img class="imgEmpresaSidebar" [src]="icono" alt="logo uno" />
            </span>
        </a>
        <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">
            <em #toggleIcon appSidebarToggle class=" color-obc ft-toggle-right toggle-icon" data-toggle="expanded"></em>
        </a>
        <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">
            <em class="ft-x"></em>
        </a>

        <!-- <form novalidate [formGroup]="regularForm">
            <div class="form-group col-md-12 mb-2">
                <input type="text" id="valor" class="form-control" formControlName="valor" placeholder="Buscador"
                    name="valor" (change)="Filter()" (keyup.enter)="Filter()">
            </div>
        </form> -->




    </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content" [perfectScrollbar]>
    <div class="nav-container">
        <ul class="navigation" appSidebarList>
            <!-- First level menu -->
            <li appSidebarlink level="{{depth + 1}}" (toggleEmit)="handleToggle($event)" (click)="toggleSlideInOut()"
                [routePath]="menuItem.path" [classes]="menuItem.class" [title]="menuItem.title" [parent]=""
                *ngFor="let menuItem of menuItems"
                [ngClass]="{'has-sub': menuItem.class === 'has-sub' ? true: false, 'open': activeTitles.includes(menuItem.title) && !nav_collapsed_open ? true : false, 'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuItem.title)}"
                [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
                [routerLinkActiveOptions]="{exact: false}">
                <a appSidebarAnchorToggle [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
                    *ngIf="!menuItem.isExternalLink; else externalLinkBlock" (click)="asignarItem(menuItem)">
                    <em class="color-obc" [ngClass]="[menuItem.icon]"></em>
                    <span class="menu-title">{{menuItem.title | translate }}</span>
                    <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <ng-template #externalLinkBlock>
                    <a [href]="[menuItem.path]" target="_blank">
                        <em [ngClass]="[menuItem.icon]"></em>
                        <span class="menu-title">{{menuItem.title | translate }}</span>
                        <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>
                </ng-template>
                <!-- Second level menu -->
                <ul class="menu-content" *ngIf="menuItem.submenu.length > 0"
                    [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false">
                    <li appSidebarlink level="{{depth + 2}}" (toggleEmit)="handleToggle($event)"
                        [routePath]="menuSubItem.path" [classes]="menuSubItem.class" [title]="menuSubItem.title"
                        [parent]="menuItem.title" *ngFor="let menuSubItem of menuItem.submenu"
                        [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
                        [ngClass]="{'has-sub': menuSubItem.class === 'has-sub' ? true: false, 'open': activeTitles.includes(menuSubItem.title) && !nav_collapsed_open ? true : false, 'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuSubItem.title)}">
                        <a appSidebarAnchorToggle
                            [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
                            *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
                            (click)="asignarItem(menuSubItem)">
                            <em [ngClass]="[menuSubItem.icon]"></em>
                            <span class="menu-title">{{menuSubItem.title | translate }}</span>
                            <span *ngIf="menuSubItem.badge != '' "
                                [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <ng-template #externalSubLinkBlock>
                            <a [href]="[menuSubItem.path]">
                                <em [ngClass]="[menuSubItem.icon]"></em>
                                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                                <span *ngIf="menuSubItem.badge != '' "
                                    [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                            </a>
                        </ng-template>
                        <!-- Third level menu -->
                        <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0"
                            [@slideInOut]="activeTitles.includes(menuSubItem.title) ? true : false">
                            <li appSidebarlink level="{{depth + 3}}" [routePath]="menuSubsubItem.path"
                                [classes]="menuSubsubItem.class" [title]="menuSubsubItem.title"
                                [parent]="menuSubItem.title" *ngFor="let menuSubsubItem of menuSubItem.submenu"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [ngClass]="[menuSubsubItem.class]">
                                <a appSidebarAnchorToggle [routerLink]="[menuSubsubItem.path]"
                                    *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock"
                                    (click)="asignarItem(menuSubsubItem)">
                                    <em [ngClass]="[menuSubsubItem.icon]"></em>
                                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                                    <span *ngIf="menuSubsubItem.badge != '' "
                                        [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                </a>
                                <ng-template #externalSubSubLinkBlock>
                                    <a [href]="[menuSubsubItem.path]">
                                        <em [ngClass]="[menuSubsubItem.icon]"></em>
                                        <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                                        <span *ngIf="menuSubsubItem.badge != '' "
                                            [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                    </a>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <!-- <div class="logo clearfix">
            <a class="logo-text">
                <span class="text align-middle">
                    <img class="img-fluid" [src]="logoProductoDos" alt="logo dos" />
                </span>
            </a>
        </div> -->
    </div>
</div>
<!-- Sidebar Content Ends -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <hr class="mt-0">
        <div class="text-center">
            <span class="text align-middle">
                <img [src]="logoProductoDos" alt="logo dos" class="imgProductoFooter">
            </span>
        </div>
    </div>
</div>