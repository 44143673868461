<div class="col-md-12">
    <div class="fondo texto">
        <div class="row contenido-categorias">

            <div class="contenido-lista">
                <p class="mx-1 texto-listado">Listado de Categorías</p>

                <div #widget class="dhx-container--grid col-12 grid dhx_container__width"></div>
                <div id="pagination" class="dhx_container__pagination"></div>

                <button type="button" class="btn btn-sm mr-5 boton-guardar" (click)="RealizarAccion('eliminar')">
                    Eliminar
                </button>

                <button type="button" class="btn btn-sm mr-1 boton-guardar" (click)="RealizarAccion('editar')">
                    Editar
                </button>

                <button type="button" class="btn btn-sm mr-1 boton-guardar" (click)="RealizarAccion('adicionar')">
                    Adicionar
                </button>

            </div>

        </div>
    </div>

</div>