<div class="row col-md-12">
  <button id="button-close" type="button" (click)="cerrarPopup()" class="btn btn-raised btn-warning mr-1">
    <em class="ft-x"></em>
  </button>

  <div class="col-md-12">

    <form class="form" novalidate [formGroup]="regularForm">
      <div class="form-body">

        <div>
          <h4 class="form-section"><em class="ft-clipboard"></em>Información usuario</h4>
          <div class="row">


            <div class="form-group col-md-6">
              <label for="int_tipdoc">Tipo de identificación<strong class="requerido">*</strong></label>
              <ng-select bindLabel="text" bindValue="id" [items]="arrTipoIdentificacion" formControlName="int_tipdoc" [loading]="bloqueDominios">
              </ng-select>
            </div>



            <div class="form-group col-md-6">
              <label for="int_identi">NO. Identificación<strong class="requerido">*</strong></label>
              <div>
                <input type="text" id="int_identi" class="form-control" formControlName="int_identi"
                  placeholder="NO. Identificación" name="int_identi">
              </div>
            </div>


            <div class="form-group col-md-12">
              <label for="int_nombre">Nombre completo<strong class="requerido">*</strong></label>
              <div>
                <input type="text" id="int_nombre" class="form-control" formControlName="int_nombre"
                  placeholder="Nombre completo" name="int_nombre">
              </div>
            </div>

            <div class="form-group col-md-12">
              <label for="int_email">Email<strong class="requerido">*</strong></label>
              <div>
                <input type="text" id="int_email" class="form-control" formControlName="int_email" placeholder="Email"
                  name="int_email">
              </div>
            </div>

            <div class="form-group col-md-12">
              <label for="int_entida">Entidad<strong class="requerido">*</strong></label>
              <div>
                <input type="text" id="int_entida" class="form-control" formControlName="int_entida"
                  placeholder="Entidad" name="int_entida">
              </div>
            </div>

            <div class="form-group col-md-12">
              <label for="int_usoinf">Uso de la información<strong class="requerido">*</strong></label>
              <div>
                <textarea id="int_usoinf" rows="5" class="form-control block-textarea" formControlName="int_usoinf"
                  name="int_usoinf" placeholder="Uso de la información" blockSpecialChars></textarea>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="form-group col-md-12 m-0 d-flex" style="text-align: center;">
              <label style="margin-left: 33px" class="label-check pr-2" for="int_licuso">Aceptar licencia de uso</label>
              <input style="margin-left: 16px" class="checkmark" type="checkbox"
                formControlName="int_licuso" />
            </div>

            <div class="col-md-4">
              <button type="button" class="btn btn-link info" (click)="openUrl()"><em
                  class="fas fa-external-link-square-alt"></em>
                Licencia de uso</button>
            </div>
          </div>
        </div>




        <div class="form-actions">
          <small class="form-text text-muted danger" *ngIf="!regularForm.valid">Recuerde llenar todos los campos
            requeridos</small>

          <button type="button" (click)="Ejecutar()" class="btn btn-raised btn-primary mr-1"
            [disabled]="!regularForm.valid || !regularForm.controls.int_licuso.value">
            <em class="fa fa-check-square-o"></em> Guardar
          </button>

          <button type="button" (click)="LimpiarModelo()" class="btn btn-default" aria-label="Left Align">
            <span class="glyphicon glyphicon-align-left" aria-hidden="true"></span>
            Limpiar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>