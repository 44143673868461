/**
* @description realiza la carga de la configuración del pop-up y retorna un modelo
* @author Jhonatan David Mosquera
* @date 29/04/2020
*/
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { Mensaje } from '@mensaje';
import { ServicioPopupService } from '../servicio-popup.service';
//import { FormsModule } from '@angular/forms';



@Component({
  selector: 'app-popup-combo',
  templateUrl: './popup-combo.component.html',
  styleUrls: ['./popup-combo.component.scss']
})
export class PopupComboComponent implements OnInit {

  mensaje: Mensaje;
  @Input() data;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  configBotones: any;
  configCombos: any;
  elementoSeleccionado: any;
  bloqueBoton: boolean;
  modalRef: any;
  modelo: any;
  valor: any;
  objetos: any;
  clase: "PopupComboComponent";
  constructor(
    private globalSeguridad: GlobalSeguridad,
    private servicioPopupService: ServicioPopupService
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    //this.bloqueBoton = true;
    this.configCombos = this.data.configCombo.combos;
    this.configBotones = this.data.configCombo.botones;
    this.objetos = this.data.objetos;
    this.operacion(this.configCombos);
  }

  /**
  * @description esta función hace la administración si el combo que se carga
  * utilizara el servicio de sigmaConsulta o el servicio de cache
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {Array} arreglo
  * @returns
  */
  operacion(arreglo) {
    try {
      for (let i = 0; i < arreglo.length; i++) {
        this.valor = arreglo[i].multiple;
        if (arreglo[i].cache) {
          this.cargarCombo(arreglo[i].filtro, arreglo[i].idConsulta, i);
        } else {
          this.cargaCombosSig(arreglo[i].filtro, arreglo[i].idConsulta, i);
        }
      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description realiza el llamado al servicio que obtiene los datos de cache  y guarda los resultado
  * en el itemds que carga el combo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {string, string, number}. filtro, idConsulta, index
  * @returns
  */
  cargarCombo(filtro, idConsulta, index) {
    try {
      this.servicioPopupService.servicioObtenerInformacionCache(idConsulta, filtro, "").subscribe(res => {
        let respuesta = res.datos;
        if (this.data.configCombo.nombre = "cambioEstado") {
          if (respuesta.DOM_FUN_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_FUN_2;
          } else if (respuesta.DOM_VEH_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_VEH_2;
          } else if (respuesta.DOM_ACT_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_ACT_2;
          } else if (respuesta.DOM_HRE_2 != undefined) {
            this.configCombos[index].items = respuesta.DOM_HRE_2;
          } else if (respuesta.DOM_HRE_2_1 != undefined) {
            this.configCombos[index].items = respuesta.DOM_HRE_2_1;
          } else if (respuesta.DOM_SOL_1 != undefined) {
            this.configCombos[index].items = this.organizarEstado(respuesta.DOM_SOL_1);
            // this.configCombos[index].items = respuesta.DOM_SOL_1
          } else {
            this.configCombos[index].items = respuesta[filtro];
          }
        } else {
          this.configCombos[index].items = respuesta;
        }
      });
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  organizarEstado(respuesta) {
    try {
      let array = [];
      for (let i = 0; i < respuesta.length; i++) {
        if (this.data.hijo.subsistemaActual.sub_codigo == "SUB_26" || this.data.hijo.subsistemaActual.sub_codigo == "SUB_27") {
          if (respuesta[i].id == "En recoleccion") {
            array.push(respuesta[i]);
          } else if (respuesta[i].id == "Atendida") {
            array.push(respuesta[i]);
          }
        } else if (this.data.hijo.subsistemaActual.sub_codigo == "SUB_28") {
          if (respuesta[i].id == "Abrir") {
            array.push(respuesta[i]);
          }
        }
      }
      return array;
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description realiza la sigma consulta y el resultado lo guarda en el items que carga al combo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {string, string, number}. filtro, idConsulta, index
  * @returns
  */
  cargaCombosSig(filtro, idConsulta, index) {
    try {
      if (this.configCombos[index].filtro != "DOM_NO") {


        let filtrosig = this.asignaFiltro(filtro);
        if (filtrosig == "") {
          filtrosig = "";
        }
        this.servicioPopupService.getServicioSigmaConsulta("", idConsulta, filtrosig, "").subscribe(res => {
          if (res.respuesta) {
            let respuesta = res.datos;
            this.configCombos[index].items = respuesta.datos;
            if (this.data.accion == "cambioOperacion") {
              this.configCombos[index].modelo = this.data.objetos.selected[0].emp_codigo;
            }
            else if (this.data.objetos.selected[0].fun_lladal != undefined) {
              this.configCombos[index].modelo = this.data.objetos.selected[0].fun_lladal;
            } else if (this.data.objetos.selected[0].hre_codigo != undefined) {
              this.configCombos[index].modelo = this.data.objetos.selected[0].hre_codigo;
            }
          }
        });
      } else {

      }
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }
  /**
    * @description esta función asigna el filtro de la consulta
    * @author Jhonatan David Mosquera
    * @date 17/08/2020
    * @param {Array} arreglo
    * @returns
    */
  asignaFiltro(filtros: any) {
    let filtro 
    if(filtros != ""){
      filtro = JSON.parse(filtros);
    }else{
      filtro =filtros;
    }
    let respuesta = "";
    let llaves = Object.keys(filtro);
    for (let i = 0; i < llaves.length; i++) {
      if (filtro[llaves[i]] == "_operacionActual") {
        filtro[llaves[i]] = this.data.operacion;
      }
    }
    if (filtro != "") {
      respuesta = JSON.stringify(filtro);
    }
    return respuesta;
  }

  /**
  * @description confirma la selección de la operación y la guarda en un json como modelo
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {type} parametro
  * @returns
  */
  confirmarSeleccion(valor) {
    try {
      let accion = valor.currentTarget.innerText;
      let arreglo = [];
      this.elementoSeleccionado = [];
      for (let i = 0; i < this.configCombos.length; i++) {
        // if (this.configCombos[i].modelo != "") {
        let accionSeleccionada = "";
        for (let index = 0; index < this.configBotones.length; index++) {
          if (this.configBotones[index].nombre === accion) {
            accionSeleccionada = this.configBotones[index].accion;
            break;
          }
        }
        let modelo = { valor: this.configCombos[i].modelo, objetos: this.objetos.selected, accion: accionSeleccionada }
        this.elementoSeleccionado.push(modelo);
      }// else {
      //   //this.mensaje.retornarMensaje("Falta un paso", "error", "Seleccione una operación", "");
      // }
      this.cerrarPopup();
    } catch (error) {
      console.log("Error: " + this.clase + "->" + error);
    }
  }

  /**
  * @description cierra el pop-up y retorna el objeto seleccionado
  *  al cual se le quiere realizar la operación
  * @author Jhonatan David Mosquera
  * @date 29/04/2020
  * @param {type} parametro
  * @returns
  */
  cerrarPopup() {
    this.configCombos = "";
    this.configBotones = "";
    this.modalRef.close(this.elementoSeleccionado);
  }

}
