<button id="button-close" type="button" (click)="cerrarModal()" class="btn btn-raised btn-warning mr-1">
	<em class="ft-x"></em>
</button>
<div class="card">
	<div class="card-body">
		<div>
			<span>
				<h4 class="form-section"><em class="ft-filter"></em>FILTRO</h4>
			</span>
		</div>
		<br>
		<div class="row">
			<div class="form-group col-md-4 mb-1">
				<label>Campos</label>
				<ng-select bindLabel="text" bindValue="id" [items]="arrCampos" (change)="validarTipoCampo($event)"
					name="campos" id="campos" [(ngModel)]="campo">
				</ng-select>

			</div>
			<div class="form-group col-md-4 mb-1">
				<label>Operador</label>
				<ng-select bindLabel="text" bindValue="id" [items]="arrOperadores" name="operador" id="operador"
					[(ngModel)]="operador">
				</ng-select>
			</div>
			<div *ngIf="tipoCampo == 'text' && esCombo == true" class="form-group col-md-4 mb-1">
				<label>Valor</label>
				<ng-select bindLabel="text" bindValue="id" [items]="arrDominio" name="valor" id="valor"
					[(ngModel)]="valor">
				</ng-select>
			</div>
			<div *ngIf="tipoCampo == 'text'  && esCombo == false" class="form-group col-md-4 mb-1">
				<label for="valor">Valor</label>
				<input type="text" id="valor" class="form-control" name="valor" placeholder="" [(ngModel)]="valor">
			</div>
			<div *ngIf="tipoCampo == 'numeric'" class="form-group col-md-4 mb-1">
				<label for="valor">Valor</label>
				<input type="number" id="valor" class="form-control" name="valor" placeholder="" [(ngModel)]="valor">
			</div>
			<div *ngIf="tipoCampo == 'date'" class="form-group col-md-4 mb-1">
				<label for="valor">Valor</label>
				<input type="date" maxlength="10" id="valor" class="form-control" name="valor" placeholder="" [(ngModel)]="valor">
			</div>
			<div *ngIf="tipoCampo == 'time'" class="form-group col-md-4 mb-1">
				<label for="valor">Valor</label>
				<input type="time" id="valor" class="form-control" name="valor" placeholder="" [(ngModel)]="valor">
			</div>
		</div>
		<br>
		<div class="row justify-content-center">
			<button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="agregarFiltro()">
				<em>Agregar</em>
			</button>
			<br>
			<button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="eliminarFiltro()">
				<em>Eliminar</em>
			</button>
			<br>
			<button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="enviarFiltro()">
				<em>Enviar Filtro</em>
			</button>
		</div>
		<br>
		<div class="form-group">
			<app-lista-informacion-v2 [rows]="rows" [columns]="columns" [settings]="configuracionLista"
				(salida)="ObjetoListaSeleccionado($event)">
			</app-lista-informacion-v2>
		</div>
		<br>

	</div>
</div>