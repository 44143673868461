import { Injectable } from '@angular/core';
import * as writtenNumber from 'written-number';
import * as proj4x from 'proj4';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class ServicioUtilidadesService {
  proj4: any;

  gradosXMin: any;
  minutosXMin: any;
  segundosXMin: any;
  gradosXMax: any;
  minutosXMax: any;
  segundosXMax: any;

  gradosYMin: any;
  minutosYMin: any;
  segundosYMin: any;
  gradosYMax: any;
  minutosYMax: any;
  segundosYMax: any;

  coorXMin: any;
  coorXMax: any;

  coorYMin: any;
  coorYMax: any;

  projInicial: any;

  projFinal: any;

  projCtm12: any;

  projMapa: any;

  latitudMin: any;
  latitudMax: any;
  longitudMin: any;
  longitudMax: any;


  sistema = sessionStorage.getItem('sistemaLogueado');
  perfil: any;
  usuario: any;

  constructor() {
    this.PoblarCoordenadas(this.sistema);
  }


  PoblarCoordenadas(codigoSistema){
    this.sistema = codigoSistema;
    this.perfil = JSON.parse(sessionStorage.infoUsuario ?? '{}').per_codigo;
    this.usuario = JSON.parse(sessionStorage.infoUsuario ?? '{}').usu_codigo;
    writtenNumber.defaults.lang = 'es';
    this.proj4 = (proj4x as any).default;
    let projections = [
      ["EPSG:3116", "+proj=tmerc +lat_0=4.596200416666666 +lon_0=-74.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:21897", "+proj=tmerc +lat_0=4.599047222222222 +lon_0=-74.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs"],
      ["EPSG:12", "+proj=tmerc +lat_0=4.0 +lon_0=-73.0 +k=0.9992 +x_0=5000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs "],
      ["EPSG:4686", "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs"],
      ["EPSG:3115", "+proj=tmerc +lat_0=4.596200416666666 +lon_0=-77.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"]];
    this.proj4.defs(projections);
    if (this.sistema === "SIS_01") {
      this.gradosXMin = -75;
      this.minutosXMin = 55;
      this.segundosXMin = 18.63398;

      this.gradosXMax = -74;
      this.minutosXMax = 37;
      this.segundosXMax = 31.02427;

      this.gradosYMin = 4;
      this.minutosYMin = 47;
      this.segundosYMin = 54.25175;

      this.gradosYMax = 5;
      this.minutosYMax = 46;
      this.segundosYMax = 44.59165;

      this.coorXMin = 795370.207056317;
      this.coorXMax = 939326.190696819;

      this.coorYMin = 1022627.89832985;
      this.coorYMax = 1130827.08488371;


      this.projInicial = "EPSG:4326";
      this.projFinal = "EPSG:21897";
      this.projCtm12 = "EPSG:12";
      this.projMapa = "EPSG:4326";





    }
    else if (this.sistema === "SIS_02") {

      this.gradosXMin = -71;
      this.minutosXMin = 30;
      this.segundosXMin = 38.00;

      this.gradosXMax = -74;
      this.minutosXMax = 52;
      this.segundosXMax = 47.00;



      this.gradosYMin = 4;
      this.minutosYMin = 35;
      this.segundosYMin = 59.00;

      this.gradosYMax = 7;
      this.minutosYMax = 6;
      this.segundosYMax = 22.00;

      this.coorXMin = 910979;
      this.coorXMax = 1285658;

      this.coorYMin = 996627;
      this.coorYMax = 1280750;

      this.projInicial = "EPSG:4686";
      this.projFinal = "EPSG:21897";
      this.projCtm12 = "EPSG:12";
      this.projMapa = "EPSG:4326";
    }
    else if (this.sistema === "SIS_03") {

      this.gradosXMin = -75;
      this.minutosXMin = 37;
      this.segundosXMin = 54.99;

      this.gradosXMax = -76;
      this.minutosXMax = 21;
      this.segundosXMax = 14.27;



      this.gradosYMin = 4;
      this.minutosYMin = 66;
      this.segundosYMin = 31.73;

      this.gradosYMax = 5;
      this.minutosYMax = 58;
      this.segundosYMax = 9.73;

      this.coorXMin = 1096098;
      this.coorXMax = 1188599;

      this.coorYMin = 1007465;
      this.coorYMax = 1110140;

      this.projInicial = "EPSG:3115";
      this.projFinal = "EPSG:3116";
      this.projCtm12 = "EPSG:12";
      this.projMapa = "EPSG:4326";

    } else if (this.sistema === "SIS_04") {
      this.gradosXMax = -74;
      this.minutosXMax = 53;
      this.segundosXMax = 24.27681237;



      this.gradosXMin = -73;
      this.minutosXMin = 28;
      this.segundosXMin = 35.44950587;



      this.gradosYMin = 3;
      this.minutosYMin = 43;
      this.segundosYMin = 47.39413077;



      this.gradosYMax = 5;
      this.minutosYMax = 50;
      this.segundosYMax = 13.18176775;



      this.coorXMin = 909732.53;
      this.coorXMax = 1066559;

      this.coorYMin = 904238.46;
      this.coorYMax = 1137246.9;


      this.projInicial = "EPSG:4326";
      this.projFinal = "EPSG:3116";
      this.projCtm12 = "EPSG:12";
      this.projMapa = "EPSG:4326";

    } else if (this.sistema === "SIS_06") {
      this.gradosXMax = -74;
      this.minutosXMax = 50;
      this.segundosXMax = 36.62059629;



      this.gradosXMin = -75;
      this.minutosXMin = 29;
      this.segundosXMin = 50.91932820;



      this.gradosYMin = 9;
      this.minutosYMin = 6;
      this.segundosYMin = 26.09389319;



      this.gradosYMax = 10;
      this.minutosYMax = 8;
      this.segundosYMax = 43.73748786;



      this.coorXMin = 844370.1331185482;
      this.coorXMax = 915794.9917949815;

      this.coorYMin = 1498968.7622530216;
      this.coorYMax = 1614053.7006973946;


      this.projInicial = "EPSG:4326";
      this.projFinal = "EPSG:21897";
      this.projCtm12 = "EPSG:12";
      this.projMapa = "EPSG:4326";

    }
    else if (this.sistema === "SIS_07") {    
      this.gradosXMin= -74;
      this.minutosXMin= 56;
      this.segundosXMin= 50.4996;
      this.gradosXMax= -73;
      this.minutosXMax= 32;
      this.segundosXMax= 31.1388;
    
      this.gradosYMin= 8;
      this.minutosYMin= 56;
      this.segundosYMin= 17.5848;
      this.gradosYMax= 11;
      this.minutosYMax= 20;
      this.segundosYMax= 56.6412;
    
      this.coorXMin= 904665.368549044;
      this.coorXMax= 1058574.92281785;
    
      this.coorYMin= 1480191.23690308;
      this.coorYMax= 1746846.1940706;

      this.projInicial= "EPSG:4326";
      this.projFinal= "EPSG:3116";
      this.projCtm12= "EPSG:12";
      this.projMapa = "EPSG:4326";          
      
    }


    this.longitudMin = `${this.gradosXMin}g ${this.minutosXMin}m ${this.segundosXMin}s`;
    this.longitudMax = `${this.gradosXMax}g ${this.minutosXMax}m ${this.segundosXMax}s`;
    this.latitudMin = `${this.gradosYMin}g ${this.minutosYMin}m ${this.segundosYMin}s`;
    this.latitudMax = `${this.gradosYMax}g ${this.minutosYMax}m ${this.segundosYMax}s`;
  }

  getFechaActual() {
    let fecha = new Date();
    let ano = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minuto = fecha.getMinutes();
    let segundo = fecha.getSeconds();
    let dd = ''
    let mm = ''
    let ss = ''
    if (dia < 10) {
      dd = '0' + dia;
    } else {
      dd = '' + dia;
    }
    if (mes < 10) {
      mm = '0' + mes;
    } else {
      mm = '' + mes;
    }

    if (segundo < 10) {
      ss = '0' + segundo;
    } else {
      ss = '' + segundo;
    }
    return ano + '-' + mm + '-' + dd;
  }
  /**
     * @description Construye el filtro para la sigma consulta con la operación seleccionada
     * @author Lukas Mesa Buriticá
     * @date 22/12/2020
     * @returns string
     */
  construirFiltroOperacion(operacion: string): string {
    let retorno = '';
    try {
      const arreglo = operacion.split(',');
      for (let index = 0; index < arreglo.length; index++) {
        if (retorno === '') {
          retorno = '&apos;' + arreglo[index] + '&apos;';
        } else {
          retorno += ',&apos;' + arreglo[index] + '&apos;';
        }
      }
    } catch (error) {
      retorno = error;
    }
    return retorno;
  }

  getFechaHoraActual() {
    let fecha = new Date();
    let ano = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minuto = fecha.getMinutes();
    let segundo = fecha.getSeconds();
    let dd = ''
    let mm = ''
    let ss = ''
    if (dia < 10) {
      dd = '0' + dia;
    } else {
      dd = '' + dia;
    }
    if (mes < 10) {
      mm = '0' + mes;
    } else {
      mm = '' + mes;
    }

    if (segundo < 10) {
      ss = '0' + segundo;
    } else {
      ss = '' + segundo;
    }
    return ano + '-' + mm + '-' + dd + ' ' + hora + ':' + minuto + ':' + ss;
  }

  getHoraActual() {
    let fecha = new Date();
    let ano = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minuto = fecha.getMinutes();
    let segundo = fecha.getSeconds();
    let dd = ''
    let mm = ''
    let ss = ''
    if (dia < 10) {
      dd = '0' + dia;
    } else {
      dd = '' + dia;
    }
    if (mes < 10) {
      mm = '0' + mes;
    } else {
      mm = '' + mes;
    }

    if (segundo < 10) {
      ss = '0' + segundo;
    } else {
      ss = '' + segundo;
    }
    return hora + ':' + minuto + ':' + ss;
  }

  getAnioActual() {
    let fecha = new Date();
    let ano = fecha.getFullYear();
    return String(ano);
  }

  getDecimales(numero) {
    let decimales = numero.split('');
    return decimales
  }


  ValorEnLetras(evento) {
    let resultado = "";
    if (evento) {
      let numero;
      let unidades;
      let decimales;
      let array;
      if (evento.toString().includes(".")) {
        numero = evento.split(".");
        unidades = numero[0];
        decimales = numero[1];
        array = decimales.split("");

        unidades = writtenNumber(unidades,
          {
            "unitExceptions": {
              "1": "un"
            }
          }).toUpperCase();

        if (decimales) {
          for (let i = 0; i < array.length; i++) {
            array[i] = writtenNumber(array[i],
              {
                "unitExceptions": {
                  "1": "un"
                }
              }).toUpperCase();

          }
          decimales = array.join(" ");

          resultado = `${unidades} COMA ${decimales}`;
        } else {
          resultado = unidades;
        }
      }
      else {
        resultado = writtenNumber(evento,
          {
            "unitExceptions": {
              "1": "un"
            }
          }).toUpperCase();
      }
    }
    else {
      resultado = "";

    }
    return resultado;
  }

  //Función Javascript para calcular el Dígito de Verificación para la DIAN (Colombia) de una cédula o un nit. Se tomó como base la función de Calcular Digito de Verificación DIAN de mundonets.com.
  //url: https://github.com/gabrielizalo/calculo-digito-de-verificacion-dian
  calcularDigitoVerificacion(myNit) {
    let vpri,
      x,
      y,
      z;

    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ""); // Espacios
    myNit = myNit.replace(/,/g, ""); // Comas
    myNit = myNit.replace(/\./g, ""); // Puntos
    myNit = myNit.replace(/-/g, ""); // Guiones

    // Se valida el nit
    if (isNaN(myNit)) {
      //console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return "";
    };

    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = (myNit.substr(i, 1));
      // console.log ( y + "x" + vpri[z-i] + ":" ) ;

      x += (y * vpri[z - i]);
      // console.log ( x ) ;
    }

    y = x % 11;
    // console.log ( y ) ;

    return (y > 1) ? 11 - y : y;
  }

  ///Cálculos de coordenadas

  /**
   * Transforma coordenadas geográficas en coodenadas planas con la proyección EPSG:21897 Bogota 1975 / Colombia Bogota zone
   * @author Lukas Mesa Buriticá
   * @date 2019-10-04.
  */
  toProjected(coordinates: any) {
    let projected = [];
    for (let index = 0; index < coordinates.length; index++) {
      let xGrados = coordinates[index].split("g");
      let sign = Math.sign(xGrados[0]);
      let xMinutos = xGrados[1].split("m");
      let xSegundos = xMinutos[1].replace(/s/i, "");
      if (xGrados && xMinutos && xSegundos) {
        let grados = Math.abs(parseFloat(xGrados[0]));
        let minutos = parseFloat(xMinutos[0]);
        let segundos = parseFloat(xSegundos);
        var project = grados + (minutos / 60) + (segundos / 3600);
        project = project * sign;
        projected.push(project);
      }
    }
    return projected;
  }

  /**
   * Transforma coodenadas planas en coordenadas geográficas con el formato grados, minutos, segundos.
   * @author Lukas Mesa Buriticá
   * @date 2019-10-04.
  */
  toDms(coordinates: any) {
    let dmsCoor = [];
    for (let index = 0; index < coordinates.length; index++) {
      let dms = "";
      if (coordinates[index]) {
        if (Math.sign(coordinates[index]) == -1) {
          dms = "-";
        }
        let float = parseFloat(coordinates[index]);
        let absolute = Math.abs(float);
        let degrees = Math.trunc(absolute);
        let step = (absolute - degrees);
        let minutes = Math.trunc(step * 60);
        let sec = (minutes / 60);
        let seconds = (step - sec) * 3600;
        dms += degrees + "g " + minutes + "m " + Number(seconds).toFixed(8) + "s";
        dmsCoor.push(dms);
      }
    }
    return dmsCoor;
  }




  CalcularGeoY(json) {
    json["geoYValid"] = false;
    if (json["geoy"]) {
      let xGrados = json["geoy"].split("g");
      let xMinutos = xGrados[1].split("m");
      let xSegundos = xMinutos[1].replace(/s/i, "");
      if (xGrados && xMinutos && xSegundos) {
        let grados = parseFloat(xGrados[0]);
        let minutos = parseFloat(xMinutos[0]);
        let segundos = parseFloat(xSegundos);



        if ((grados == this.gradosYMin)) {
          if (minutos == this.minutosYMin) {
            if ((segundos >= this.segundosYMin && segundos <= 59.9999999)) {
              json["geoYValid"] = true;
            }
          } else if ((minutos >= (this.minutosYMin + 1) && minutos <= 59)) {
            if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
              json["geoYValid"] = true;
            }
          }
        } else if (grados == this.gradosYMax) {
          if ((minutos == this.minutosYMax)) {
            if ((segundos >= 0.0000000 && segundos <= this.segundosYMax)) {
              json["geoYValid"] = true;
            }
          } else if ((minutos >= 0 && minutos <= (this.minutosYMax - 1))) {
            if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
              json["geoYValid"] = true;
            }
          }
        }


        if (this.sistema === 'SIS_02' || this.sistema === 'SIS_03' || this.sistema === 'SIS_06') {
          let ymin = this.validateYMin(this.minutosYMin, this.segundosYMin, this.gradosYMin, minutos, segundos, grados);
          let ymax = this.validateYMax(this.minutosYMax, this.segundosYMax, this.gradosYMax, minutos, segundos, grados);

          if (ymin && ymax) {
            json["geoYValid"] = true;
          }
        }

        if (json["geoYValid"] == true) {
          if (json["geox"]) {
            let coordenadaY = this.toProjected([json["geox"], json["geoy"]]);
            let latitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
            json["coorx"] = latitud[0];
            json["coory"] = latitud[1];

            let latitud2 = this.proj4(this.projInicial, this.projCtm12, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
            json["ctm12x"] = latitud2[0];
            json["ctm12y"] = latitud2[1];
          }
        }
      }
    } else {
      if (json["coory"]) {
        json["coory"] = "";
        json["ctm12y"] = "";
        json["geoYValid"] = true;
      }
    }
    return json;
  }




  CalcularGeoX(json) {
    json["geoXValid"] = false;
    if (json["geox"]) {
      let yGrados = json["geox"].split("g");
      let yMinutos = yGrados[1].split("m");
      let ySegundos = yMinutos[1].replace(/s/i, "");
      if (yGrados && yMinutos && ySegundos) {
        let grados = parseFloat(yGrados[0]);
        let minutos = parseFloat(yMinutos[0]);
        let segundos = parseFloat(ySegundos);
        if ((grados == this.gradosXMin)) {
          if ((minutos == this.minutosXMin)) {
            if ((segundos >= this.segundosXMin && segundos <= 59.9999999)) {
              json["geoXValid"] = true;
            }
          } else if ((minutos >= 0 && minutos <= (this.minutosXMin - 1))) {
            if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
              json["geoXValid"] = true;
            }
          }
        } else if (grados == this.gradosXMax) {
          if ((minutos == this.minutosXMax)) {
            if ((segundos >= 0.0000000 && segundos <= this.segundosXMax)) {
              json["geoXValid"] = true;
            }
          } else if ((minutos >= (this.minutosXMax + 1) && minutos <= 59)) {
            if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
              json["geoXValid"] = true;
            }
          }
        }

        if (this.sistema === 'SIS_02' || this.sistema === 'SIS_03' || this.sistema === 'SIS_06') {
          let xmin = this.validateXMin(this.minutosXMin, this.segundosXMin, this.gradosXMin, minutos, segundos, grados);
          let xmax = this.validateXMax(this.minutosXMax, this.segundosXMax, this.gradosXMax, minutos, segundos, grados);

          if (xmin && xmax) {
            json["geoXValid"] = true;
          }

        }

        if (json["geoXValid"] == true) {
          if (json["geoy"]) {
            let coordenadaX = this.toProjected([json["geox"], json["geoy"]]);
            let longitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaX[0]), Number.parseFloat(coordenadaX[1])]);
            json["coorx"] = longitud[0];
            json["coory"] = longitud[1];

            let longitud2 = this.proj4(this.projInicial, this.projCtm12, [Number.parseFloat(coordenadaX[0]), Number.parseFloat(coordenadaX[1])]);
            json["ctm12x"] = longitud2[0];
            json["ctm12y"] = longitud2[1];
          }
        }
      }
    } else {
      if (json["coorx"]) {
        json["coorx"] = "";
        json["ctm12x"] = "";
        json["geoXValid"] = true;
      }
    }
    return json;
  }

  CalcularCoorX(json) {
    json["coorXValid"] = false;
    if (json["coorx"]) {
      let coorx = parseFloat(json["coorx"]);
      if ((coorx >= this.coorXMin) && (coorx <= this.coorXMax)) {
        json["coorXValid"] = true;
      }
      if (json["coorXValid"] == true) {
        if (json["coory"]) {
          let longitud = this.proj4(this.projFinal, this.projInicial, [Number.parseFloat(json["coorx"]), Number.parseFloat(json["coory"])]);
          let geograficaX = this.toDms(longitud);

          json["geox"] = geograficaX[0];
          json["geoy"] = geograficaX[1];

          let longitud2 = this.proj4(this.projFinal, this.projCtm12, [Number.parseFloat(json["coorx"]), Number.parseFloat(json["coory"])]);

          json["ctm12x"] = longitud2[0];
          json["ctm12y"] = longitud2[1];



        }
      }
    } else {
      if (json["geox"]) {
        json["geox"] = "";
        json["ctm12x"] = "";
        json["coorXValid"] = true;
      }
    }
    return json;
  }

  CalcularCoorY(json) {
    json["coorYValid"] = false;
    if (json["coory"]) {
      let coory = parseFloat(json["coory"]);
      if ((coory >= this.coorYMin) && (coory <= this.coorYMax)) {
        json["coorYValid"] = true;
      }
      if (json["coorYValid"] == true) {
        if (json["coorx"]) {
          let latitud = this.proj4(this.projFinal, this.projInicial, [Number.parseFloat(json["coorx"]), Number.parseFloat(json["coory"])]);
          let geograficaY = this.toDms(latitud);

          json["geox"] = geograficaY[0];
          json["geoy"] = geograficaY[1];

          let latitud2 = this.proj4(this.projFinal, this.projCtm12, [Number.parseFloat(json["coorx"]), Number.parseFloat(json["coory"])]);

          json["ctm12x"] = latitud2[0];
          json["ctm12y"] = latitud2[1];

        }
      }
    } else {
      if (json["geoy"]) {
        json["geoy"] = "";
        json["ctm12y"] = "";
        json["coorYValid"] = true;
      }
    }
    return json;
  }

  CalcularCoorVisor(json, projection) {

    let coorx = parseFloat(json.geometry.coordinates[0]);
    let coory = parseFloat(json.geometry.coordinates[1]);

    let longitud = this.proj4("EPSG:" + projection, this.projMapa, [coorx, coory]);

    json.geometry.coordinates[0] = longitud[0];
    json.geometry.coordinates[1] = longitud[1];

    return json;
  }





  CalcularGeoYBOY(json) {
    json["geoYValid"] = false;
    if (json["geoy"]) {
      let xGrados = json["geoy"].split("g");
      let xMinutos = xGrados[1].split("m");
      let xSegundos = xMinutos[1].replace(/s/i, "");
      if (xGrados && xMinutos && xSegundos) {
        let grados = parseFloat(xGrados[0]);
        let minutos = parseFloat(xMinutos[0]);
        let segundos = parseFloat(xSegundos);

        // let valor = grados + "" + minutos + "" + segundos;
        // let valorYmin = this.gradosYMin + "" + this.minutosYMin + "" + this.segundosYMin;
        // let valorYmax = this.gradosYMax + "" + this.minutosYMax + "" + this.segundosYMax;

        // if (valor <= valorYmin && valor >= valorYmax) {
        //   json["geoYValid"] = true;
        // }


        // if(grados <= this.gradosYMin && grados >= this.gradosYMax){
        //   if(minutos <= this.minutosYMin && minutos >= this.minutosYMax){
        //     if(segundos <= this.segundosYMin && segundos >= this.segundosYMax){

        //     }
        //   }
        // }


        let ymin = this.validateYMin(this.minutosYMin, this.segundosYMin, this.gradosYMin, minutos, segundos, grados);
        let ymax = this.validateYMax(this.minutosYMax, this.segundosYMax, this.gradosYMax, minutos, segundos, grados);

        if (ymin && ymax) {
          json["geoYValid"] = true;
        }


        // if ((grados <= this.gradosYMin && grados >= this.gradosYMax)) {
        //   if ((minutos >= this.minutosYMin && minutos <= this.minutosYMax)) {

        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }

        //     }
        //   } else if ((minutos >= (this.minutosYMin + 1) && minutos <= 59)) {
        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }
        //     }
        //   }
        // }


        // if (grados <= this.gradosYMax && grados >= this.gradosYMin) {
        //   if ((minutos >= this.minutosYMax && minutos <= this.minutosYMin)) {
        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }
        //     }
        //   } else if ((minutos >= (this.minutosYMin + 1) && minutos <= 59)) {
        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }
        //     }
        //   }
        // }

        // if (grados >= this.gradosYMax && grados <= this.gradosYMin) {
        //   if ((minutos <= this.minutosYMax && minutos >= this.minutosYMin)) {
        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }
        //     }
        //   } else if ((minutos >= (this.minutosYMin + 1) && minutos <= 59)) {
        //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
        //       if (!json["geoYValid"]) {
        //         json["geoYValid"] = true;
        //       }
        //     }
        //   }
        // }


        if (json["geoYValid"] == true) {
          if (json["geox"]) {
            let coordenadaY = this.toProjected([json["geox"], json["geoy"]]);
            let latitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
            json["coorx"] = latitud[0];
            json["coory"] = latitud[1];

            let latitud2 = this.proj4(this.projInicial, this.projCtm12, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
            json["ctm12x"] = latitud2[0];
            json["ctm12y"] = latitud2[1];
          }
        }
      }
    } else {
      if (json["coory"]) {
        json["coory"] = "";
        json["ctm12y"] = "";
        json["geoYValid"] = true;
      }
    }
    return json;
  }






  CalcularGeoXBOY(json) {
    try {
      json["geoXValid"] = false;
      if (json["geox"]) {
        let yGrados = json["geox"].split("g");
        let yMinutos = yGrados[1].split("m");
        let ySegundos = yMinutos[1].replace(/s/i, "");
        if (yGrados && yMinutos && ySegundos) {
          let grados = parseFloat(yGrados[0]);
          let minutos = parseFloat(yMinutos[0]);
          let segundos = parseFloat(ySegundos);
          // let valor = grados + "" + minutos + "" + segundos;
          // let valorxmin = this.gradosXMin + "" + this.minutosXMin + "" + this.segundosXMin;
          // let valorxmax = this.gradosXMax + "" + this.minutosXMax + "" + this.segundosXMax;

          // if (valor <= valorxmin && valor >= valorxmax) {
          //   json["geoXValid"] = true;
          // }


          let xmin = this.validateXMin(this.minutosXMin, this.segundosXMin, this.gradosXMin, minutos, segundos, grados);
          let xmax = this.validateXMax(this.minutosXMax, this.segundosXMax, this.gradosXMax, minutos, segundos, grados);

          if (xmin && xmax) {
            json["geoXValid"] = true;
          }


          // if (grados <= this.gradosXMin && grados <= this.gradosXMax) {
          //   if (minutos <= this.minutosXMin && minutos >= this.minutosXMax) {
          //     if (segundos <= this.segundosXMin && segundos >= this.segundosXMax) {

          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }

          //     }
          //   }
          // }
          // if ((grados <= this.gradosXMin && grados >= this.gradosXMax)) {
          //   if ((minutos <= this.minutosXMin && minutos >= this.minutosXMax)) {
          //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   } else if ((minutos >= (this.minutosXMax + 1) && minutos <= 59)) {
          //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   }

          // }


          // if (grados <= this.gradosXMax && grados >= this.gradosXMin) {
          //   if ((minutos >= this.minutosXMax && minutos <= this.minutosXMin)) {
          //     if ((segundos >= 0.0000000 && segundos <= this.segundosXMax)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   } else if ((minutos >= (this.minutosXMax + 1) && minutos <= 59)) {
          //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   }
          // }

          // if (grados >= this.gradosXMax && grados <= this.gradosXMin) {
          //   if ((minutos <= this.minutosXMax && minutos >= this.minutosXMin)) {
          //     if ((segundos >= 0.0000000 && segundos <= this.segundosXMax)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   } else if ((minutos >= (this.minutosXMax + 1) && minutos <= 59)) {
          //     if ((segundos >= 0.0000000 && segundos <= 59.9999999)) {
          //       if (!json["geoXValid"]) {
          //         json["geoXValid"] = true;
          //       }
          //     }
          //   }
          // }

          if (json["geoXValid"] == true) {
            if (json["geoy"]) {
              let coordenadaX = this.toProjected([json["geox"], json["geoy"]]);
              let longitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaX[0]), Number.parseFloat(coordenadaX[1])]);
              json["coorx"] = longitud[0];
              json["coory"] = longitud[1];

              let longitud2 = this.proj4(this.projInicial, this.projCtm12, [Number.parseFloat(coordenadaX[0]), Number.parseFloat(coordenadaX[1])]);
              json["ctm12x"] = longitud2[0];
              json["ctm12y"] = longitud2[1];
            }
          }
        }
      } else {
        if (json["coorx"]) {
          json["coorx"] = "";
          json["ctm12x"] = "";
          json["geoXValid"] = true;
        }
      }
    } catch (e) {
      json["coorx"] = "";
      json["ctm12x"] = "";
      json["geoXValid"] = true;
    }

    return json;
  }


  validateXMin(minutos: any, segundos: any, horas: any, minutosEntrada: any, segundosEntrada: any, horasEntrada: any) {

    let retorno = false;
    try {
      const fechaActual = new Date();
      fechaActual.setHours(Number(String(horas).substring(2, 3)));
      fechaActual.setMinutes(minutos);
      fechaActual.setSeconds(segundos);

      const fecha = new Date();
      fecha.setHours(Number(String(horasEntrada).substring(2, 3)));
      fecha.setMinutes(minutosEntrada);
      fecha.setSeconds(segundosEntrada);

      if (fecha >= fechaActual) {
        retorno = true;
      }

    } catch (e) {
      retorno = false;
    }


    return retorno;

  }

  validateXMax(minutos: any, segundos: any, horas: any, minutosEntrada: any, segundosEntrada: any, horasEntrada: any) {

    let retorno = false;
    try {
      const fechaActual = new Date();
      fechaActual.setHours(Number(String(horas).substring(2, 3)));
      fechaActual.setMinutes(minutos);
      fechaActual.setSeconds(segundos);

      const fecha = new Date();
      fecha.setHours(Number(String(horasEntrada).substring(2, 3)));
      fecha.setMinutes(minutosEntrada);
      fecha.setSeconds(segundosEntrada);

      if (fecha <= fechaActual) {
        retorno = true;
      }

    } catch (e) {
      retorno = false;
    }

    return retorno;

  }


  validateYMin(minutos: any, segundos: any, horas: any, minutosEntrada: any, segundosEntrada: any, horasEntrada: any) {

    let retorno = false;
    try {
      const fechaActual = new Date();
      fechaActual.setHours(horas);
      fechaActual.setMinutes(minutos);
      fechaActual.setSeconds(segundos);

      const fecha = new Date();
      fecha.setHours(horasEntrada);
      fecha.setMinutes(minutosEntrada);
      fecha.setSeconds(segundosEntrada);

      if (fecha >= fechaActual) {
        retorno = true;
      }

    } catch (e) {
      retorno = false;
    }

    return retorno;

  }

  validateYMax(minutos: any, segundos: any, horas: any, minutosEntrada: any, segundosEntrada: any, horasEntrada: any) {

    let retorno = false;
    try {
      const fechaActual = new Date();
      fechaActual.setHours(horas);
      fechaActual.setMinutes(minutos);
      fechaActual.setSeconds(segundos);

      const fecha = new Date();
      fecha.setHours(horasEntrada);
      fecha.setMinutes(minutosEntrada);
      fecha.setSeconds(segundosEntrada);

      if (fecha <= fechaActual) {
        retorno = true;
      }
    } catch (e) {
      retorno = false;
    }


    return retorno;

  }



  CalcularCTMX(json) {


    if (json["ctm12y"]) {
      const longitud = this.proj4(this.projCtm12, this.projInicial, [Number.parseFloat(json["ctm12x"]), Number.parseFloat(json["ctm12y"])]);
      const geograficaX = this.toDms(longitud);

      json["geox"] = geograficaX[0];
      json["geoy"] = geograficaX[1];

      const coordenadaY = this.toProjected([geograficaX[0], geograficaX[1]]);
      const latitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
      json["coorx"] = latitud[0];
      json["coory"] = latitud[1];
    }



    return json;
  }

  CalcularCTMY(json) {

    if (json["ctm12x"]) {
      const longitud = this.proj4(this.projCtm12, this.projInicial, [Number.parseFloat(json["ctm12x"]), Number.parseFloat(json["ctm12y"])]);
      const geograficaX = this.toDms(longitud);

      json["geox"] = geograficaX[0];
      json["geoy"] = geograficaX[1];

      const coordenadaY = this.toProjected([geograficaX[0], geograficaX[1]]);
      const latitud = this.proj4(this.projInicial, this.projFinal, [Number.parseFloat(coordenadaY[0]), Number.parseFloat(coordenadaY[1])]);
      json["coorx"] = latitud[0];
      json["coory"] = latitud[1];

    }


    return json;
  }



  ObjetoControlPermisos(coleccion, permisos) {
    const nuevaColeccion = [];
    for (const i in coleccion) {
      if (permisos[coleccion[i].json.obc_nombre]?.activo !== 'no') {
        nuevaColeccion.push(coleccion[i]);
      }
    }
    return nuevaColeccion;
  }

  SubsistemasPermisos(coleccion, permisos) {
    const nuevaColeccion = [];
    try {

      if (JSON.stringify(permisos) != "{}") {
        for (const i in coleccion) {
          try {
            if (permisos[String(coleccion[i].json.sub_nombre).toUpperCase()]?.activo !== 'no') {
              nuevaColeccion.push(coleccion[i]);
            }
          } catch (e) {
            nuevaColeccion.push(coleccion[i]);
          }

        }
      } else {
        return coleccion;
      }
    } catch (e) {

    }

    return nuevaColeccion;
  }

  //Metodo hecho por lukas
  ObjetoControlPermisosPsa(coleccion, permisos) {
    let nuevaColeccion = [];
    if (permisos["todos-activos"] === "no") {
      for (const i in coleccion) {
        //console.log("coleccion: " + coleccion[i].json.obc_nombre);
        for (const j in permisos.children) {
          //console.log("permisos: " + permisos.children[j].name);
          if (coleccion[i].json.obc_nombre == permisos.children[j].name) {
            if (permisos.children[j]["solo-visualizar"] === 'si') {
              nuevaColeccion.push(coleccion[i]);
            }
            break;
          }
        }
      }
    } else if (permisos["todos-activos"] === "si") {
      nuevaColeccion = coleccion;
    }
    return nuevaColeccion;
  }



  //Método Hecho por lukas
  SubsistemasPermisosPsa(coleccion, permisos) {
    let nuevaColeccion = [];
    if (permisos["todos-activos"] === "no") {
      for (const i in coleccion) {
        //console.log("coleccion: " + coleccion[i].json.sub_nombre);
        for (const j in permisos.children) {
          //console.log("permisos: " + permisos.children[j].name);
          if (coleccion[i].json.sub_nombre == permisos.children[j].name) {
            if (permisos.children[j]["solo-visualizar"] === 'si') {
              nuevaColeccion.push(coleccion[i]);
            }
            break;
          }
        }
      }
    } else if (permisos["todos-activos"] === "si") {
      nuevaColeccion = coleccion;
    }
    return nuevaColeccion;
  }

  TabsPermisos(coleccion, permisos) {
    let nuevaColeccion = [];
    if (permisos["todos-activos"] === "no") {
      for (const i in coleccion) {
        //console.log("coleccion: " + coleccion[i]);
        for (const j in permisos.children) {
          //console.log("permisos: " + permisos.children[j].name);
          if (coleccion[i] == permisos.children[j].name) {
            if (permisos.children[j]["activo"] === 'si') {
              nuevaColeccion.push(coleccion[i]);
            }
            break;
          }
        }
      }
    } else if (permisos["todos-activos"] === "si") {
      nuevaColeccion = coleccion;
    }
    return nuevaColeccion;
  }

  /**
     * Recorre el json que se va a enviar al servicio y 
     * reemplaza caracteres especiales por vacío 
     * @author Michael Agudelo
     * @date 2021-08-20
     * @param data json que se va a enviar al servicio
     * @returns 
     */
  EliminarCaracteres(data: any) {
    try {
      const arrKeys = Object.keys(data);
      for (const column of arrKeys) {
        if(column!="per_codigo" &&  column!="vis_codigo" && column!="poc_codigo")
        {
          if (data[column] !== undefined && data[column] !== null) {
            if (data[column].constructor === Object) {
              data[column] = this.getValueObject(data[column]);
            } else if (data[column].constructor === Array) {
              data[column] = this.getValueArray(data[column]);
            } else {
              data[column] = this.validateValue(data[column]);
            }
          }
        }

      }
    } catch (e) {
      console.log("ERROR: " + e);
    }

    return JSON.stringify(data);
  }


  private getValueObject(data: any) {
    try {
      const arrKeys = Object.keys(data);
      for (const column of arrKeys) {
        if (data[column] !== undefined && data[column] !== null) {
          if (data[column].constructor === Object) {
            data[column] = this.getValueObject(data[column]);
          } else if (data[column].constructor === Array) {
            data[column] = this.getValueArray(data[column]);
          } else {
            data[column] = this.validateValue(data[column]);
          }
        }
      }
    } catch (e) {
      console.log("ERROR: " + e);
    }

    return data;
  }


  private getValueArray(data: any[]) {
    try {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined && data[i] !== null) {
          if (data[i].constructor === Object) {
            data[i] = this.getValueObject(data[i]);
          } else if (data[i].constructor === Array) {
            data[i] = this.getValueArray(data[i]);
          } else {
            data[i] = this.validateValue(data[i]);
          }
        }

      }
    } catch (e) {
      console.log("ERROR: " + e);
    }

    return data;
  }


  private validateValue(value: any): string {
    let validatedValue = ' ';
    try {
      if (value !== true && value !== false) {
        if (value !== undefined && value !== null) {
          validatedValue = String(value).replace(/[^a-zA-Z0-9ÁÉÍÓÚÑáéíóúäëïöüñ@%&.,;:*+/ _^\\=!¡#$()¿?\-\n\"]*/g, '')
        }
      } else {
        validatedValue = value;
      }

    } catch (e) {
      console.log("ERROR: " + e);
    }

    return validatedValue;
  }


  RetornarStringObjeto(datos): any {
    let retorno = "";
    const tipo = typeof datos;

    if (tipo === 'string') {
      retorno = JSON.parse(datos);
    } else if (tipo === 'object') {
      retorno = datos;
    }

    return retorno;
  }




}
