import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselStore, NguCarouselConfig } from '@ngu/carousel';
import { GlobalSeguridad } from '@seguridad';

@Component({
  selector: 'app-subsistemas-v2',
  templateUrl: './subsistemas-v2.component.html',
  styleUrls: ['./subsistemas-v2.component.scss']
})
export class SubsistemasComponentV2 implements OnInit {

  public configsCarrusel: NguCarouselConfig;
  public gradientes: any = [];
  public iconos: any = [];
  @Input() coleccionSubsistemas: any = [];
  @Input() subsistemaActual: any = [];
  @Input() permiteOcultar: boolean = false;
  @Output() salida = new EventEmitter();
  @ViewChild('carousel') carousel: NguCarousel<any>;
  permisos: any;
  ocultarCantidad: boolean = false;

  constructor(
    private servicioglobal : GlobalSeguridad
  ) { }

  ngOnInit() {
    this.configsCarrusel = {
      grid: { xs: 5, sm: 5, md: 5, lg: 5, all: 0 },
      slide: 1,
      animation: 'lazy',
      point: {
        visible: false
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner',
    };
  }

  ngOnChanges() {
    if (this.coleccionSubsistemas != undefined) {
      if (this.coleccionSubsistemas.length > 0) {
        this.mostrarCantidad();
      }
    }
  }

  mostrarCantidad(){
    if (this.coleccionSubsistemas.length > 0) {
      for (let index = 0; index < this.coleccionSubsistemas.length; index++) {
        let element = this.coleccionSubsistemas[index];
        if (element.cantidad != undefined) {
          if (element.cantidad == 0 && this.permiteOcultar) {
            this.ocultarCantidad = true;
          } else {
            this.ocultarCantidad = false;
          }
        } else {
          this.ocultarCantidad = true;
        }
      }
    }
  }

}
