<div class="row">
  <div class="col-md-12 bg-blue-white rounded p-0 contenedor-panel-left">
    <div class="row col-md-12 col-12 p-0 ml-2 mt-2">
      <div class="col-md-11 col-11 p-0">
        <i class='fab fa fa-search'></i>
        <input placeholder="Digite valor, luego presione 'enter'" name="name" id="name" #filter [(ngModel)]="name"
          class="rounded form-control" style='padding:8px; padding-right: 28px;' (keyup.enter)="setFilter(filter.value)" />
      </div>
    </div>
    <div class="row col-md-12 col-11 p-0 ml-2 mt-2 mr-1">
      <button [disabled]="!canDelete"
        (click)="delNode()" type="button"
        class="btn btn-raised   btn-warning mr-1 p-1 mb-1">
        Eliminar
        <span *ngIf="nodeActual && nodeActual.name">
          {{nodeActual.name}}
        </span>
      </button>
      <button _ngcontent-c34="" (click)="cloneNode()" 
      [disabled]="
      !nodeActual ||
      !nodeActual.data ||
      !nodeActual.data.clone
      " class="btn btn-raised btn-primary mb-1 p-1 mr-1" type="button">
      Duplicar
    </button>
    <button _ngcontent-c34="" (click)="changeAlias()" 
      [disabled]="
      !nodeActual ||
      !nodeActual.data ||
      !nodeActual.data.alowChangeAlias ||
      !name
      " class="btn btn-raised btn-primary mb-1 p-1 mr-1" type="button">
      Cambiar nombre
    </button>    
    </div>
    <div class="row  col-md-12 col-12 p-0 mt-2 pr-2">
      <tree-root #tree class="arbol reverse"  [options]="options" [nodes]="nodes"
        (toggleExpanded)="onEvent($event)" (activate)="onEvent($event)"></tree-root>
    </div>
  </div>
</div>