import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from '@seguridad';


@Injectable({
  providedIn: 'root'
})
export class ShaSerAlmeraService {

  header: any = {};

  constructor(
    private http: HttpClient
  ) {

  }


  getLoginAlmera(): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlAlmera"] + "/login",
      {
        login: 'integracionsigma',
        password: 'integracionsigma22'
      },
      { headers: new HttpHeaders({ "instance": "sgicorpoboyaca" }) }
    );
  }

  getLoginApiAlmera(): Observable<any> {
    const authorizationData = 'Basic ' + btoa('almera:test.wsAlm3ra');

    const url = `${JSON.parse(sessionStorage.getItem('webConfig'))["urlApiAlmera"]}/login/authenticate`;
    return this.http.post(
      url, {},
      { headers: new HttpHeaders({'Authorization': authorizationData }) }
    );
    
  }

  async getApplicantAlmera(sol_identi): Promise<any> {

    let retorno = { "status": false, "datos": "" };

    const respuestaLogin = await this.getLoginAlmera().toPromise();
    if (respuestaLogin.status) {
      const payload = respuestaLogin.payload;
      const jwt = payload.jwt;


      const respuestaSolicitante = await this.getApplicant(jwt, sol_identi).toPromise();
      if (respuestaSolicitante.status) {
        let payloadSol = respuestaSolicitante.payload;
        payloadSol["fuente"] = "Almera";
        retorno["status"] = true;
        retorno["datos"] = JSON.stringify(payloadSol);
        
      }
	  return retorno;
    }

  }


  getApplicant(token, sol_identi): Observable<any> {
    return this.http.get(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlAlmera"] + `/custom/thirdparty?apikey=9ade1a493054584c2f9d15235084870ed89caece&code=${sol_identi}`,
      { headers: new HttpHeaders({ "instance": "sgicorpoboyaca", "Authorization": `Bearer ${token}` }) }
    );
  }


  /**
   * Manda los datos a la api para que sea adicionar o actualizado, en la logica de la api ya se valida
   * @author Esteban Muñoz
   * @date 2024-05-23
   * @param datos
   * @returns
   */
  AddApplicants(datos, token) {
    const url = `${JSON.parse(sessionStorage.getItem('webConfig'))["urlApiAlmera"]}/almera/applicants`;

    return this.http.post(url, datos,
      {
        headers: new HttpHeaders({ "Content-Type": "application/json", accept: "text/plain", 'Authorization': `Bearer ${token}` }),
      }
    );
  }




}
