import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { Observable } from "rxjs/Rx";


@Injectable()
export class FwWebConfigService {
  constructor(public http: HttpClient) {}

  public getWebConfig(): Observable<any> {
    return this.http
      .get("./webConfig.json");  
  }
}
