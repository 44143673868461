<div class="card">
  <div class="card-body">
    <div class="card-block">
      <div>
        <div style="display: block" *ngIf="elementosGraficaFiltro!= undefined && elementosGraficaFiltro.barChartData.length > 0">
          <canvas 
            baseChart height="auto" 
            #baseChart="base-chart" 
            [(datasets)]="elementosGraficaFiltro.barChartData" 
            [(labels)]="elementosGraficaFiltro.variables"
            [options]="barChartOptions" 
            [legend]="barChartLegend" 
            [chartType]="barChartType" 
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>
        <!-- <h1 [hidden]="elementosGraficaFiltro === undefined">No hay datos</h1> -->
        <button type="button" class="btn btn-light btn-sm botonGrafica" (click)="randomize()">Ver Todas</button>
      </div>
    </div>
  </div>
</div>