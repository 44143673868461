<form class="form">
  <span [hidden]="modelo!=undefined">Seleccione un registro de la lista para ver información.</span>
  <div class="form-body" *ngIf="modelo!=undefined">
    <div *ngFor="let seccion of secciones">
      <h4 _ngcontent-c7="" class="form-section">
        <em _ngcontent-c7="" class="{{seccion.icono}}"></em>{{seccion.titulo}}</h4>

      <div class="row">
        <div *ngFor="let mapeo of seccion.jsonMapeo" class="form-group col-md-{{seccion.flex}} mb-2">
          <span><strong>{{mapeo["label"]}}: </strong></span>{{modelo[mapeo["codigo"]]}}
        </div>
      </div>
      <br>
    </div>
  </div>
</form>
