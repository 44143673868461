import * as fromActions from '../actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface IWidgetState {
    title: string;
    active: boolean;
    toggled: boolean;
}

export const widgetState: IWidgetState = {
    title: '',
    active: false,
    toggled: false,
};

const featureReducerWidget = createReducer(
    widgetState,
    on(fromActions.ToggleWidget, (state) => ({ ...state, toggled: !state.toggled })),
    on(fromActions.SetWidget, (state, { payload }) => ({ ...state, active: true, title: payload.title })),
    on(fromActions.CloseWidget, () => ({ active: false, title: '', toggled: false }))
);

export function reducerMenu(state = widgetState, action: Action): IWidgetState {
    return featureReducerWidget(state, action);
}
