import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let sesionActiva = this.authService.isAuthenticated();
    if(!sesionActiva){
      this.router.navigate(["/content-layout/login"]);   
    }
    return sesionActiva;
  }
}
