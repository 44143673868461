import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselStore, NguCarouselConfig } from '@ngu/carousel';
import { HttpErrorResponse } from '@angular/common/http';
import { Mensaje } from "@mensaje";
import { ServicioCarruselService } from './servicio-carrusel.service';
import { ServicioModuloGeneralService } from '@shared/services/servicio-modulo-general.service';




@Component({
  selector: 'app-carrusel-imagenes',
  templateUrl: './carrusel-imagenes.component.html',
  styleUrls: ['./carrusel-imagenes.component.scss']
})
export class CarruselImagenesComponent implements OnInit {

  public configsCarrusel: NguCarouselConfig;
  public gradientes: any = [];
  rutaActual: String;
  nombreImagenActual: String;
  rutaImagenesActual: any = "";
  coleccionImagenes: any;
  coleccionRutasImagenes: any = [];
  archivoActual: File = null;
  usuario: String = "";
  tipoActividad: any = "";
  codigoActividad: any = "";
  jsonActividad: any = "";
  mensaje: Mensaje;
  imagenes: any;
  codigoImagenes: any;
  ruta: any;
  botonesVisibles: boolean = true;
  private carouselToken: string;
  metodo: any;
  // modeloActual: any;
  @Input() modeloActual: any = "";
  @ViewChild('carousel') carousel: NguCarousel<any>;
  // @Output() salida = new EventEmitter();

  tipoElemento: string;
  codigoElemento: string;
  tipoPeticion: any;

  constructor(private servicio: ServicioCarruselService,
    private servicioCom: ServicioModuloGeneralService) { }

  ngOnInit() {
    this.servicioCom.hijo = this;
    this.mensaje = new Mensaje("", "", "");
    this.usuario = JSON.parse(sessionStorage.getItem('infoUsuario'));
    this.configsCarrusel = {
      grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
      slide: 3,
      // speed: 900,
      // interval: 4000,
      //animation: 'lazy',
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner',
    };
    this.imagenes = this.servicioCom.urlFotos;
    this.tipoElemento = this.servicioCom.nombreElementoActual;
    this.codigoImagenes = this.servicioCom.codigoUrlFotos;
    this.codigoElemento = this.servicioCom.codigoUrlFotos;
    this.tipoPeticion = this.servicioCom.tipo;
    this.metodo = this.servicioCom.metodo;
    this.configuracionInicial();

  }

  ngOnChanges() {
    if (this.modeloActual) {
      this.configuracionInicial();
      this.imagenes = this.servicioCom.urlFotos;
      this.tipoElemento = this.servicioCom.nombreElementoActual;
      this.codigoImagenes = this.servicioCom.codigoUrlFotos;
      this.codigoElemento = this.servicioCom.codigoUrlFotos;
      this.tipoPeticion = this.servicioCom.tipo;
      this.ObtenerCodigoElemento();
    }
  }

  configuracionInicial() {
    this.coleccionRutasImagenes = [];
    if (this.imagenes != undefined) {
      this.ObtenerImagenes(this.imagenes);
    }
  }

  imagenSeleccionada(ruta) {
    this.rutaActual = ruta;
    let posicionesRuta = this.rutaActual.split("/");
    this.nombreImagenActual = posicionesRuta[posicionesRuta.length - 1];
  }

  clicBotonAdicionarImagen() {
    document.getElementById('fileImagen').click();
  }

  cambioArchivo(files: FileList, fileImagen) {
    let archivo = files.item(0);
    let extension = this.obtenerExtension(archivo.name);

    if (extension.toLowerCase() == "png" || extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg") {
      this.adicionarImagen(archivo, extension.toLowerCase());
    }
    else {
      this.mensaje.retornarMensaje("error", "Formato de imagen incorrecto", "Los formatos permitidos son jpg, jpeg", "");
    }
    fileImagen.value = "";
  }

  adicionarImagen(archivo, extension) {
    if (this.modeloActual != undefined && this.codigoElemento != "") {
      this.mensaje.retornarMensajeFuncion("loading", "Adicionando imagen", "", "", null, null, null);
      let datosAEnviar = { extension: extension, codigo: this.codigoElemento, datosPeticion: "" }

      const fd = new FormData();
      fd.append("file", archivo);
      fd.append("sistema", "");
      fd.append("nombreObjeto", this.tipoElemento);
      fd.append("jsonDatos", JSON.stringify(datosAEnviar));
      fd.append("jsonDatosPeticion", "");
      fd.append("accion", "adicionarImagen");

      this.servicio.getServicioRealizarAccionImagen(fd, this.tipoPeticion, this.metodo).subscribe(res => {
        if (res.respuesta == "true") {
          if (res.datos != undefined && res.datos != "") {
            this.mensaje.retornarMensaje("exito", "Correcto", "La imagen se ha adicionado correctamente", "");
            this.servicioCom.fotos = res.sql;
            this.servicioCom.recargarLista();
            this.ObtenerImagenes(res.sql);
            
          }
          else {
            this.mensaje.cerrarMensajeCarga();
            this.mensaje.retornarMensaje("error", "Error", "Ocurrió un error al adicionar la imagen, contactese con el administrador del sistema", "");
          }
        }
        else {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", res.error, "");
        }
      }),
        (err: HttpErrorResponse) => {
          this.mensaje.cerrarMensajeCarga();
          this.mensaje.retornarMensaje("error", "Error", err.message, "");
        };
    }
    else {
      this.mensaje.retornarMensaje("error", "Error", "Debe seleccionar un Elemento de la Lista", "");
    }
  }

  eliminarImagen() {
    try {
      if (this.rutaActual != "" && this.rutaActual != undefined) {
        let parametros = {
          contenedor: this,
          datos: {
            accion: "eliminarImagen",
            modelo: this.modeloActual
          }
        };
        //Muestra un modal para asegurar la accion que quiere realizar el usuario
        this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea Eliminar la Imagen?", "", "", this, "eliminarImagenConfirmacion", parametros);
      }
      else {
        this.mensaje.retornarMensaje("error", "Error", "Debe seleccionar una imagen para eliminar", "");
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  eliminarImagenConfirmacion(parametros) {

    parametros.contenedor.mensaje.retornarMensajeFuncion("loading", "Eliminando imagen", "", "", null, null, null);
    let extension = parametros.contenedor.obtenerExtension(parametros.contenedor.nombreImagenActual);
    let nombreImagen = parametros.contenedor.nombreImagenActual.replace("." + extension, "");


    let datosAEnviar =
    {
      extension: extension,
      nombreImagen: nombreImagen,
      codigo: parametros.contenedor.codigoElemento,
      datosPeticion: "",
      rutaAEliminar: parametros.contenedor.rutaActual
    }
    let datosPeticion = "";
    parametros.contenedor.servicio.getServicioRealizarAccion("", parametros.contenedor.tipoElemento, JSON.stringify(datosAEnviar), "", "eliminarImagen", parametros.contenedor.tipoPeticion, parametros.contenedor.metodo).subscribe(res => {
      parametros.contenedor.mensaje.cerrarMensajeCarga();
      if (res.respuesta == "true") {

        parametros.contenedor.mensaje.retornarMensaje("exito", "Correcto", "La imagen se ha eliminado correctamente", "");
        parametros.contenedor.servicioCom.fotos = res.sql;
        parametros.contenedor.servicioCom.recargarLista();
        
        if (res.sql != "") {
          parametros.contenedor.ObtenerImagenes(res.sql);
        } else {
          parametros.contenedor.ObtenerImagenes(res.sql);
        }
      }
      else {
        parametros.contenedor.mensaje.retornarMensaje("error", "Error", "Ocurrió un error al eliminar la imagen", "");
      }

    }),
      (err: HttpErrorResponse) => {
        parametros.contenedor.mensaje.cerrarMensajeCarga();
      };



  }

  ObtenerCodigoElemento() {
    this.codigoElemento = this.codigoImagenes;
  }

  ObtenerImagenes(arreglo) {
    this.coleccionRutasImagenes = [];
    for (let entry of arreglo) {
      let ruta = entry.imagen;
      //se almacenan todas las rutas de las imagenes a mostrar
      this.coleccionRutasImagenes.push(ruta);
    }
  }

  obtenerExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  initDataFn(key: NguCarouselStore) {
    this.carouselToken = key.token;
  }

  /*resetFn() {
    this.carousel.reset(this.carouselToken);
  }

  moveToFn() {
    this.carousel.moveToSlide(this.carouselToken, 0, false);
  }
  */
}
