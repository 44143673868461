import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,number
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'textbox',
  template: `
      <div [formGroup]="form">
        <input
          *ngIf="!field.rows"
          [attr.type]="field.type"
          [name]="field.name"
          [required]="required"
          [attr.disabled] = "field.disabled ? '' : null"
          blockSpecialChars  [activeBlockSpecialChars]="field.blockSpecialChars ? field.blockSpecialChars : ''"
          [formControlName]="field.name"
          [placeholder]="field.placeholder? field.placeholder: ''"
          class="form-control">
        <textarea
          *ngIf="field.rows"
          [rows]="field.rows"
          [required]="required"
          [attr.disabled] = "field.disabled ? '' : null"
          blockSpecialChars  [activeBlockSpecialChars]="field.blockSpecialChars ? field.blockSpecialChars : ''"
          [formControlName]="field.name"
          [placeholder]="field.placeholder?field.placeholder:''"
          class="form-control">
        </textarea>
      </div>
    `,
    styles: ['.disa{color: darkGray; pointer-events: none;background-color: #eceff1;}']
})
export class TextBoxComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;
  required = false;

  constructor() { }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterContentInit() {
    setTimeout(() => {
      this.required = this.field.required;
    });
  }
}
