<button id="button-close" type="button" (click)="cerrarModal()" class="btn btn-raised btn-warning mr-1">
  <em class="ft-x"></em>
</button>
<div class="row col-md-12">
  <div class="col-md-12">
    <form class="form" novalidate>
      <div class="form-body">
        <h4 class="form-section">
          <em class="ft-filter"></em>Filtro
        </h4>
        <div class="row mb-1">

          <div class="form-group col-md-4 mb-1">
            <label>Campos</label>
            <ng-select bindLabel="text" bindValue="id" [items]="arrCampos" (change)="validarTipoCampo($event)"
              name="campos" id="campos" [(ngModel)]="campo">
            </ng-select>
          </div>

          <div class="form-group col-md-4 mb-1">
            <label>Operador</label>
            <ng-select bindLabel="text" bindValue="id" [items]="arrOperadores" name="operador" id="operador"
              [(ngModel)]="operador">
            </ng-select>
          </div>

          <div class="form-group col-md-4 mb-1">

            <label>Valor</label>
            <ng-select bindLabel="text" bindValue="id" [items]="arrDominio" name="valor" id="valor" [(ngModel)]="valor"
              *ngIf="tipoCampo == 'text' && esCombo == true && bind">
            </ng-select>




            <ng-select bindLabel="text" bindValue="text" [items]="arrDominio" name="valor" id="valor"
              [(ngModel)]="valor" *ngIf="tipoCampo == 'text' && esCombo == true && !bind">
            </ng-select>


            <input *ngIf="tipoCampo == 'text' && esCombo == false" type="text" id="valor" class="form-control"
              name="valor" placeholder="" [(ngModel)]="valor">

            <input *ngIf="tipoCampo == 'numeric'" type="number" id="valor" class="form-control" name="valor"
              placeholder="" [(ngModel)]="valor">

            <input *ngIf="tipoCampo == 'date'" type="date" id="valor" class="form-control" name="valor" placeholder=""
              [(ngModel)]="valor">

            <input *ngIf="tipoCampo == 'time'" type="time" id="valor" class="form-control" name="valor" placeholder=""
              [(ngModel)]="valor">
          </div>
        </div>

        <div class="row justify-content-center">
          <button class="col-md-2 btn btn-raised btn-success mr-1" (click)="agregarFiltro()">
            <em>Agregar</em>
          </button>
          <br>
          <button class="col-md-2 btn btn-raised btn-danger mr-1" (click)="eliminarFiltro()">
            <em>Eliminar</em>
          </button>
          <br>
          <button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="enviarFiltro()">
            <em>Enviar Filtro</em>
          </button>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <app-lista-informacion-v2 [rows]="rows" [columns]="columns" [settings]="configuracionLista"
              (salida)="ObjetoListaSeleccionado($event)">
            </app-lista-informacion-v2>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>