import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByCategory',
    pure: false
})
export class FilterCategory implements PipeTransform {

    transform(notifications: Array<any>, category: string): Array<any> {
        const res = notifications?.filter(notification => {
            return notification?.contenido?.eve_catego === category;
        }) || [];
        return res;
    }

}

@Pipe({
    name: 'countNotificationByCategory',
    pure: false
})
export class CountNotificationCategory implements PipeTransform {

    transform(notifications: Array<any>, category: string): number {
        const res = notifications?.filter(notification => {
            return notification?.contenido?.eve_catego === category;
        }) || [];
        return res.length;
    }

}
