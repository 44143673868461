import { createSelector } from '@ngrx/store';
import { IVisorState } from 'app/visor/shared/models/mapModels';
import { IGalleryMapState } from '../reducers';
import { getVisorState } from './config-leaflet.selector';

export const getGalleryMapState = createSelector(getVisorState, (state: IVisorState) => state.galleryMapState);

export const getGalleryMapOpenedState = createSelector(getGalleryMapState, (state: IGalleryMapState) => state.opened);

export const getBaseMapState = createSelector(getGalleryMapState, (state: IGalleryMapState) => state.baseMap);
