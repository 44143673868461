import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: "root"
})
export class TServicioNotificacionService {
  constructor(private servicioMensajes: HttpClient) { }

  getMensajes(): Observable<any> {
    return this.servicioMensajes.get(
      "https://jsonplaceholder.typicode.com/posts"
    );
  }
}
