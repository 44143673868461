import { IConfigMap } from '../../shared/models/configMap';
import { createAction, props } from '@ngrx/store';

const LOAD_CONFIG_LEAFLET = '[LEAFLET] Load config leaflet';
const LOAD_CONFIG_LEAFLET_SUCCESS = '[LEAFLET] Load config leaflet success';
const TOGGLE_COLLAPSE_SERVICE = '[SERVICE] Toggle service';
const TOGGLE_SERVICE_LAYER = '[SERVICE] Toggle service layer';
const TOGGLE_SERVICE_GROUP = '[SERVICE] Toggle service layers group';
const TOGGLE_SERVICE_SUBLAYER = '[SERVICE] Toggle service sublayer';
const TOGGLE_ACTIVE_SERVICE = '[SERVICE] Toggle active service';
const TOGGLE_ACTIVE_SERVICE_LAYER = '[SERVICE] Toggle active service layer';
const TOGGLE_ACTIVE_SERVICE_GROUP = '[SERVICE] Toggle active service group';
const TOGGLE_ACTIVE_SERVICE_SUBLAYER = '[SERVICE] Toggle active service sublayer';
const TOGGLE_SELECTED_LAYERS = '[SERVICE] Toggle selected layers';

const ACTIVE_ALL_SERVICE_LAYER = '[SERVICE] Active all service layer';
const INACTIVE_ALL_SERVICE_LAYER = '[SERVICE] Inactive all service layer';
const COLLAPSE_ALL_SERVICE_LAYER = '[SERVICE] Collaspse all service layer';
const EXPAND_ALL_SERVICE_LAYER = '[SERVICE] Expand all service layer';
const FILTER_SET_SERVICE_LAYER = '[SERVICE] filter service layer';
const FILTER_DELETE_SERVICE_LAYER = '[SERVICE] Delete filter service layer';
const ADD_SERVICE_LAYER = '[SERVICE] Add Service layer';
const CHANGE_OPACITY_OF_LAYER = '[SERVICE] Change opacity of layer service';
const CHANGE_SERVICES_LAYERS = '[SERVICE] Change services layers';


export const LoadConfigLeaflet = createAction(LOAD_CONFIG_LEAFLET, props<{ mapId: string }>());
export const LoadConfigLeafletSuccess = createAction(LOAD_CONFIG_LEAFLET_SUCCESS, props<{ map: IConfigMap; service: Array<any> }>());
export const toggleCollapseService = createAction(TOGGLE_COLLAPSE_SERVICE, props<{ serviceName: string }>());
export const toggleCollapseServiceLayer = createAction(TOGGLE_SERVICE_LAYER, props<{ id: string, serviceName: string }>());
export const toggleCollapseServiceGroup = createAction(TOGGLE_SERVICE_GROUP, props<{ serviceName:string, layerId:string, groupId:string }>());
export const toggleCollapseServiceSubLayer = createAction(TOGGLE_SERVICE_SUBLAYER, props<{ serviceName:string, layerId:string, groupId:string, sublayerId:string }>());
export const toogleActiveService = createAction(TOGGLE_ACTIVE_SERVICE, props<{ serviceName: string }>());
export const toggleActiveServiceLayer = createAction(TOGGLE_ACTIVE_SERVICE_LAYER, props<{ id: string, serviceName: string }>());
export const toggleActiveServiceGroup = createAction(TOGGLE_ACTIVE_SERVICE_GROUP, props<{ serviceName:string, layerId:string, groupId:string}>());
export const toggleActiveServiceSubLayer = createAction(TOGGLE_ACTIVE_SERVICE_SUBLAYER, props<{ serviceName:string, layerId:string, groupId:string, sublayerId:string }>());
export const changeOpacity = createAction(CHANGE_OPACITY_OF_LAYER, props<{ id: string, serviceName: string, opacity: number }>());
export const toggleSelectedLayers = createAction(TOGGLE_SELECTED_LAYERS, props<{ id: Array<string>, serviceName: string }>());

export const activeAllServiceLayer = createAction(ACTIVE_ALL_SERVICE_LAYER);
export const inactiveAllServiceLayer = createAction(INACTIVE_ALL_SERVICE_LAYER);
export const collapseAllServiceLayer = createAction(COLLAPSE_ALL_SERVICE_LAYER);
export const expandAllServiceLayer = createAction(EXPAND_ALL_SERVICE_LAYER);
export const setFilterServiceLayer = createAction(FILTER_SET_SERVICE_LAYER, props<{ id: string, serviceName: string, filtro: string }>());
export const deleteFilterServiceLayer = createAction(FILTER_DELETE_SERVICE_LAYER, props<{ id: string, serviceName: string }>());
export const addServiceLayer = createAction(ADD_SERVICE_LAYER, props<{ service: any }>());
export const changeLayersService = createAction(CHANGE_SERVICES_LAYERS, props<{ services: any[] }>());



