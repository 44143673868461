import { getVisorState } from '@leafletStore/selectors/config-leaflet.selector';
import { createAction, props } from '@ngrx/store';
import { createSelector } from '@ngrx/store';
import { createReducer, on, Action } from '@ngrx/store';
import { IDrawMode, IGeometry, IVisorState } from 'app/visor/shared/models/mapModels';



export class DrawMapStore {
    private ACTIVE_DRAW_MODE: string = '[DRAW] Draw mode';
    private SET_GEOMETRY: string = '[DRAW] Set geometry';
    private SET_GEOMETRY_EDIT: string = '[DRAW] Set geometry edit';
    private SET_GEOMETRY_BY_HAND: string = '[DRAW] Set geometry edit';
    private SET_VISIBLE: string = '[DRAW] Set Visibility';
    private SET_DRAW_MODE = '[DRAW] Get draw mode';
    private drawModeI: IDrawMode = {
        mode: false,
        geometry: { tipo: '', radio: 0, coordenadas: [] },
        geometryEdit: [],
        showDrawTool:false,
        geometryByHand: { tipo: '', radio: 0, coordenadas: [] },
    };

    constructor() {

    }
    drawMode = createAction(this.ACTIVE_DRAW_MODE, props<{ mode: boolean }>());
    setGeometry = createAction(this.SET_GEOMETRY, props<{ geometry: IGeometry }>());
    setGeometryByHand = createAction(this.SET_GEOMETRY_BY_HAND, props<{ geometry: IGeometry }>());
    setGeometryEdit = createAction(this.SET_GEOMETRY_EDIT, props<{ geometry: IGeometry[] }>());
    showHideDrawTool = createAction(this.SET_VISIBLE, props<{ isVisible: boolean }>());
    setDrawMode = createAction(this.SET_DRAW_MODE, props<{ drawMode: IDrawMode }>());

    ReducerdrawMode = createReducer(
        this.drawModeI,
        on(this.drawMode, (state, { mode }) => ({
            ...state,
            mode: mode
        })),

        on(this.setGeometry, (state, { geometry }) => ({
            ...state,
            geometry: geometry
        })),
        on(this.setGeometryEdit, (state, { geometry }) => ({
            ...state,
            geometryEdit: geometry
        })),
        on(this.setGeometryByHand, (state, { geometry }) => ({
            ...state,
            geometryByHand: geometry
        })),

        on(this.showHideDrawTool, (state, { isVisible }) => ({
            ...state,
            showDrawTool:isVisible
        })),
        on(this.setDrawMode, (state, { drawMode }) => ({
            ...drawMode,
        }))
    );

    reducerDrawMode = (state = this.drawModeI, action: Action): IDrawMode =>{
        return this.ReducerdrawMode(state, action);
    }

}
export class DrawMapSelector {
    getDrawMode = createSelector(getVisorState, (state: IVisorState) => state.drawMode);
    getDrawModeState = createSelector(this.getDrawMode, (state: IDrawMode) =>  state.mode);
    getGeometryState = createSelector(this.getDrawMode, (state: IDrawMode) =>  state.geometry);
    getGeometryEditState = createSelector(this.getDrawMode, (state: IDrawMode) =>  state.geometryEdit);
    getGeometryByHandState = createSelector(this.getDrawMode, (state: IDrawMode) =>  state.geometryByHand);
    getDrawShowState = createSelector(this.getDrawMode, (state: IDrawMode) => state.showDrawTool); 
}