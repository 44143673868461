import * as fromActions from '../actions';
import { ActionReducer, INIT } from '@ngrx/store';

export function resetStoreReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action?.type === fromActions.resetStore.type) {
            return reducer(undefined, { type: INIT });
        }
        return reducer(state, action);
    };
}
