import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from './shared/auth/auth.service';
import { Mensaje } from '@mensaje';
import { ServicioModuloGeneralService } from "@shared/services/servicio-modulo-general.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: 'root'
})

export class GlobalSeguridad {

  atributoSesion: any;
  atributoInicial: any;
  usuario: any;
  contrasena: any;
  sistema: any;
  apiKey: any;
  ip: any = window.location.origin;
  mensaje: Mensaje;
  activada: any = "false";
  sistemaCarga = "";


  constructor(
    private servicioFw: HttpClient,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private servicioGeneral: ServicioModuloGeneralService) {
    this.mensaje = new Mensaje("", "", "");
  }

  getServicioSesion(): Observable<any> {
    return this.servicioFw.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNLogin/ValidarS",
      {
        sistema: ""
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.atributoSesion }) }
    );
  }
  // validarSesion() {
  //   if (this.servicioGeneral.validarSesion()) {
  //     if (this.activada == "true" && this.activada != "null") {
  //       this.getServicioSesion().subscribe(data => {
  //         if (data.respuesta == "true") {            
  //           sessionStorage.setItem('horaLogueo', data.hora);
  //         } else {
  //           sessionStorage.setItem('horaLogueo', "");
  //           this.mensaje.retornarMensaje("error", "Atención", data.noticia, "");
  //           this.authService.logout();
  //           this.router.navigate(["/content-layout/login"], {
  //             relativeTo: this.route.root
  //           });
  //           this.activada = "false";
  //         }
  //       });
  //     }
  //   }

  // }

  validarSesion() {
    if (this.activada === 'true' && this.activada !== 'null') {
      this.getServicioSesion().subscribe((data) => {
        if (data.respuesta === 'true') {
        } else {
          this.mensaje.retornarMensaje('error', 'Atención', data.noticia, '');
          this.authService.logout();
          this.router.navigate(['/content-layout/login'], {
            relativeTo: this.route.root,
          });
          this.activada = 'false';
        }
      });
    }
  }

  activarSesion(tiempo) {
    setInterval(() => { this.validarSesion(); }, tiempo);
  }

  obtenerPermisos(modulo, objeto) {
    let permisosAux = [];
    let permisos = {};
    let arrayPermisos = JSON.parse(sessionStorage.getItem('perfilPermisos'));
    try {


      if (arrayPermisos != undefined) {
        for (let i in arrayPermisos) {
          for (let j in arrayPermisos[i].children) {
            if (arrayPermisos[i].children[j].name == modulo) {
              permisosAux = arrayPermisos[i].children[j].children;
              if (objeto == "todos") {
                for (let k in permisosAux) {
                  permisos[permisosAux[k].name] = permisosAux[k];
                }
                return permisos;
              }
              else {
                for (let k in permisosAux) {
                  if (permisosAux[k].name == objeto) {
                    if (permisosAux[k].children.length > 0) {
                      for (let l in permisosAux[k].children) {
                        permisos[permisosAux[k].children[l].name] = permisosAux[k].children[l];
                      }
                      return permisos;
                    }
                    else {
                      permisos[permisosAux[k].name] = permisosAux[k];
                    }
                    return permisos;
                  }
                }
              }
            }
          }
        }

      }

    } catch (e) {
      console.log('Error permisos: ' + e);
    }
    return permisos;
  }

}
