import { createAction, props } from '@ngrx/store';
import { createSelector } from '@ngrx/store';
import { createReducer, on, Action } from '@ngrx/store';
import { ILayer } from 'app/visor/visor-container/visor-widget-container/widget-data-table/Interfaces/ILayer';

import { IActualizar, IBounds, IDataTable, IGeometryLayer, ILocate, IOpacity, ISeleccion, IVisor, IVisorState } from 'app/visor/shared/models/mapModels';
import { getVisorState } from '@leafletStore/selectors/config-leaflet.selector';
import { LatLng } from 'leaflet';





export class VisorStore {


    private estado: IVisor = {
        capaActualizar: { capaActualizar: '', time: 0 }, dataTable: { layers: [], active: false }, seleccion: [], operacion: '', locate: { locate: '', info: false, id: '', serNombre: '', serOrigen: '' }, modo: '', latLng: { lat: 0, lng: 0 },
        xCenter: 0, yCenter: 0, WIDTH: 0,
        HEIGHT: 0, BBOX: '', radio: 0, reduce: -1, opacity: { opacity: 0, layerName: '' }, screenShot: '', geoJson: {}, loadRequestReport: false, clicPoint: { x: 0, y: 0 }, zoom: 0,
        localDataTable: null,
        pageCsvService: { plus: false, service: null },
        paginasCsv: { inicio: 0, fin: 0, total: 0, serNombre: '' },
        toggleSwipe: false
    };
    private ACTUALIZAR_CAPA: string = '[Visor] capa a actualizar';
    private ADD_LAYER: string = '[DATA TABLE] Add Layer';
    private REMOVE_LAYER: string = '[DATA TABLE] Remove Layer';
    private ACTUALIZAR_BOUNDS: string = '[DATA TABLE] Actualizar  bounds';
    private AGREGAR_SELECCION: string = '[DATA TABLE] Agregar seleccion';
    private BORRAR_SELECCION: string = '[DATA TABLE] Borrar seleccion';
    private RESIZE_VISOR: string = '[DATA TABLE] Resize Visor';
    private LOCATE_FEATURE: string = '[DATA TABLE] locate feature';
    private OPACITY_LAYER: string = '[Visor] Cambiar transparencia';
    private SET_MODE: string = '[Visor] Cambiar modo reporte';
    private SET_XY: string = '[Visor] Asiganar XY';
    private SET_IMG: string = '[Visor] Asignar Imagen (Screen Shot)'
    private SET_GEOJSON: string = '[Visor] Asignar GeoJSON'
    private SET_LOADREQUESTREPORT: string = '[Visor] Cambiar carga del request reporte'
    private SET_CLICK_POINT: string = '[Localizar Orden] SetClicKPoint';
    private SET_DISTANCIA_PROFILE: string = '[Visor - WidgetProfile] Calcular y asignar distancia';
    private SET_VISOR = '[Visor] Set visor';
    private ADD_LAYER_GEOJSON: string = '[Visor] Add layer geojson';
    private ACTIVE_LAYER_GEOJSON: string = '[Visor] Active layer geojson';
    private INACTIVE_LAYER_GEOJSON: string = '[Visor] Inactive layer geojson';
    private ADD_LOCAL_DATATABLE: string = '[Visor] Añadir un datatable local';
    private CHANGE_PAGE_CSV_SERVICE: string = '[Visor] Cambiar pagina servicio Csv';
    private CHANGE_PAGE_CSV_SERVICE_INFO: string = '[Visor] Actualizar información páginas csv';
    private TOGGLE_WIDGET_SWIPE = '[WIDGET_SWIPE] Toggle widget swipe';

    private dataTable: IDataTable = {
        layers: [],
        active: false
    };


    actualizarCapa = createAction(this.ACTUALIZAR_CAPA, props<{ idCapa: IActualizar }>());
    addLayer = createAction(this.ADD_LAYER, props<{ layer: ILayer }>());
    removerLayer = createAction(this.REMOVE_LAYER, props<{ layer: ILayer }>());
    actualizarBounds = createAction(this.ACTUALIZAR_BOUNDS, props<{ bounds: IBounds }>());
    agregarSeleccion = createAction(this.AGREGAR_SELECCION, props<{ feature: ISeleccion }>());
    borrarSeleccion = createAction(this.BORRAR_SELECCION, props<{ id: string }>());
    resizeVisor = createAction(this.RESIZE_VISOR, props<{ reduce: number }>());
    locateFeature = createAction(this.LOCATE_FEATURE, props<{ locate: ILocate }>());
    opacityLayer = createAction(this.OPACITY_LAYER, props<{ opacity: IOpacity }>());
    setMode = createAction(this.SET_MODE, props<{ modo: string }>());
    setXY = createAction(this.SET_XY, props<{ xy: LatLng }>());
    setImage = createAction(this.SET_IMG, props<{ screenShot: string }>());
    setGeoJson = createAction(this.SET_GEOJSON, props<{ geoJSON: any }>());
    setRequestReport = createAction(this.SET_LOADREQUESTREPORT, props<{ loadRequestReport: boolean }>());
    setClicKPoint = createAction(this.SET_CLICK_POINT, props<{ x: number, y: number }>());
    setDistanciaProfile = createAction(this.SET_DISTANCIA_PROFILE, props<{ distanciaProfile: number }>());
    addLayers = createAction(this.ADD_LAYER_GEOJSON, props<{ jsonLayers: IGeometryLayer }>());
    activeLayers = createAction(this.ACTIVE_LAYER_GEOJSON, props<{ jsonLayers: IGeometryLayer }>());
    inactiveLayers = createAction(this.INACTIVE_LAYER_GEOJSON, props<{ jsonLayers: IGeometryLayer }>());
    setVisor = createAction(this.SET_VISOR, props<{ visor: IVisor }>());
    addLocalDataTable = createAction(this.ADD_LOCAL_DATATABLE, props<{ layer: ILayer, feature: any }>());
    changePageCsvService = createAction(this.CHANGE_PAGE_CSV_SERVICE, props<{ plus: boolean, service: string }>());
    changePageCsvServiceInfo = createAction(this.CHANGE_PAGE_CSV_SERVICE_INFO, props<{ inicio: number, fin: number, total: number, serNombre: string }>());
    setToggleWidgetSwipe = createAction(this.TOGGLE_WIDGET_SWIPE, props<{ toggleSwipe: boolean }>());

    ReducerEventosVisor = createReducer(
        this.estado,
        on(this.actualizarCapa, (state, { idCapa }) => ({
            ...state,
            operacion: 'actualizarCapa',
            capaActualizar: { ...idCapa }
        })),

        on(this.addLayer, (state, { layer }) => {
            let tablas = { ...state.dataTable }
            if (!state.dataTable.layers.some((capa) => layer.id == capa.id)) {
                tablas = { layers: [...state.dataTable.layers, { ...layer }], active: true }
            }
            return { ...state, dataTable: tablas, operacion: 'addLayer' };
        }),

        on(this.removerLayer, (state, { layer }) => {
            let layersAct = state.dataTable.layers.filter(layerFilter => {
                if (layer.id !== layerFilter.id) {
                    return true;
                }
                return false;
            })
            return {
                ...state,
                dataTable: { layers: layersAct, active: this.dataTable.active },
                operacion: 'removerLayer'
            };
        }),

        on(this.actualizarBounds, (state, { bounds }) => ({
            ...state,
            ...bounds,
            operacion: 'actualizarBounds'
        })),

        on(this.agregarSeleccion, (state, { feature }) => ({
            ...state,
            seleccion: [...state.seleccion, { ...feature }],
            operacion: 'agregarSeleccion'
        })),
        on(this.borrarSeleccion, (state, { id }) => {
            let features = state.seleccion.filter((feature) => {
                return (feature.llave !== id);
            })
            return { ...state, seleccion: [...features], operacion: 'borrarSeleccion' }

        }),
        on(this.resizeVisor, (state, { reduce }) => ({
            ...state,
            reduce: reduce,
            operacion: 'resizeVisor'
        })),
        on(this.locateFeature, (state, { locate }) => ({
            ...state,
            locate: { ...locate },
            operacion: 'locateFeature'
        })),
        on(this.opacityLayer, (state, { opacity }) => ({
            ...state,
            opacity: opacity,
            operacion: 'opacityLayer'
        })),
        on(this.setMode, (state, { modo }) => ({
            ...state,
            modo: modo
        })),
        on(this.setXY, (state, { xy }) => ({
            ...state,
            xy: xy
        })),
        on(this.setImage, (state, { screenShot }) => ({
            ...state,
            screenShot: screenShot
        })),
        on(this.addLocalDataTable, (state, { layer, feature }) => ({
            ...state,
            localDataTable: { layer: layer, feature: feature },
        })),
        on(this.changePageCsvService, (state, { plus, service }) => ({
            ...state,
            pageCsvService: { plus, service },
        })),
        on(this.changePageCsvServiceInfo, (state, { inicio, fin, serNombre, total }) => ({
            ...state,
            paginasCsv: { inicio, fin, serNombre, total },
        })),
        on(this.setGeoJson, (state, { geoJSON }) => ({
            ...state,
            geoJson: { ...geoJSON }
        })),
        on(this.setRequestReport, (state, { loadRequestReport }) => ({
            ...state,
            loadRequestReport: loadRequestReport
        })),
        on(this.setClicKPoint, (state, { x, y }) => {
            return {
                ...state,
                clicPoint: { x: x, y: y }
            }
        }),
        on(this.setDistanciaProfile, (state, { distanciaProfile }) => {
            return {
                ...state,
                distanciaProfile: distanciaProfile
            }
        }),
        on(this.setToggleWidgetSwipe, (state, { toggleSwipe }) => {
            return {
                ...state,
                toggleSwipe: toggleSwipe
            }
        }),
        // on(this.addLayers, (state, { jsonLayers }) => {
        //     return {
        //         ...state,
        //         jsonLayers:jsonLayers
        //     }
        // }),
        // on(this.activeLayers, (state, { jsonLayers }) => {
        //     jsonLayers.active = true;
        //     return {
        //         ...state,
        //         jsonLayers:jsonLayers
        //     }
        // }),
        // on(this.inactiveLayers, (state, { jsonLayers }) => {
        //     jsonLayers.active = false;
        //     return {
        //         ...state,
        //         jsonLayers:jsonLayers
        //     }
        // }),
        on(this.setVisor, (state, { visor }) => ({
            ...visor,
        }))
    );

    reducerEventosVisor = (state = this.estado, action: Action): IVisor => {
        return this.ReducerEventosVisor(state, action);
    }




}

export class VisorSelector {

    getVisorState = createSelector(getVisorState, (state: IVisorState) => state.visorState);
    getCapaActualizada = createSelector(this.getVisorState, (state: IVisor) => state.capaActualizar);
    getLastLayer = createSelector(this.getVisorState, (state: IVisor) => state.dataTable.layers);
    getSeleccion = createSelector(this.getVisorState, (state: IVisor) => state.seleccion);
    getLastAction = createSelector(this.getVisorState, (state: IVisor) => state.operacion);
    getReduceSize = createSelector(this.getVisorState, (state: IVisor) => state.reduce);
    getLocateFeature = createSelector(this.getVisorState, (state: IVisor) => state.locate);
    getOpacityLayer = createSelector(this.getVisorState, (state: IVisor) => state.opacity);
    getMode = createSelector(this.getVisorState, (state: IVisor) => state.modo);
    //  getXY = createSelector(this.getVisorState, (state: IVisor) => state.xy);
    getImg = createSelector(this.getVisorState, (state: IVisor) => state.screenShot);
    getGeoJson = createSelector(this.getVisorState, (state: IVisor) => state.geoJson);
    getloadRequestReport = createSelector(this.getVisorState, (state: IVisor) => state.loadRequestReport);
    getPoint = createSelector(this.getVisorState, (state: IVisor) => state.clicPoint);
    getBBOX = createSelector(this.getVisorState, (state: IVisor) => state.BBOX);
    getDistanciaProfile = createSelector(this.getVisorState, (state: IVisor) => state.distanciaProfile);
    getLocalDataTable = createSelector(this.getVisorState, (state: IVisor) => state.localDataTable);
    getPageCsvService = createSelector(this.getVisorState, (state: IVisor) => state.pageCsvService);
    getPageCsvServiceInfo = createSelector(this.getVisorState, (state: IVisor) => state?.paginasCsv);
    getToggleWidgetSwipe = createSelector(this.getVisorState, (state: IVisor) => state?.toggleSwipe);
    // addLayerGeojson = createSelector(this.getVisorState, (state: IVisor) => state.jsonLayers);
    // activeLayerGeojson = createSelector(this.getVisorState, (state: IVisor): any => state.jsonLayers);
    // inactiveLayerGeojson = createSelector(this.getVisorState, (state: IVisor): any => state.jsonLayers);
}
