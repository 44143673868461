import * as fromActions from '../actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface INotificationState {
    notification: any;
}

export const notificationState: INotificationState = {
    notification: Object.assign({}),
};

const featureReducerNotification = createReducer(
    notificationState,
    on(fromActions.ShowNotification, (state, { notification }) => (
        {
            ...state,
            notification: { ...notification }
        })),
);

export function reducerNotification(state = notificationState, action: Action): INotificationState {
    return featureReducerNotification(state, action);
}
