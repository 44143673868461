import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sbi } from 'knowagesdk';
import webConfigjson from 'app/webConfig.json'
import { Mensaje } from '@mensaje';

@Component({
  selector: 'app-fra-con-dwh-knowage',
  templateUrl: './fra-con-dwh-knowage.component.html',
  styleUrls: ['./fra-con-dwh-knowage.component.scss']
})

export class FraConDwhKnowageComponent implements OnInit {
  mensaje: Mensaje;
  public url;

  constructor(
    public sanitizer: DomSanitizer
  ) {
    this.mensaje = new Mensaje("","","");
  }
  sistemaLogueado: any;
  perfilLogueado: any;
  Url = JSON.parse(sessionStorage.getItem('webConfig'))["KnowageURL"];
  urlSafe: SafeResourceUrl;

  ngOnInit(): void {
    const KnowageObjeto = JSON.parse(sessionStorage.getItem('webConfig'))["KnowageObjeto"]
    this.sistemaLogueado = sessionStorage.getItem("sistemaLogueado");
    this.perfilLogueado = sessionStorage.getItem("perfilLogueado");
    const cod: string = this.sistemaLogueado + this.perfilLogueado;
    const sbi = new Sbi('https', KnowageObjeto.host, KnowageObjeto.port, KnowageObjeto.herramienta, KnowageObjeto.ruta);
    this.mensaje.retornarMensajeFuncion("loading", "Cargando Herramienta", "", "", null, null, null);
    sbi.authenticate(cod, cod, (r) => {
      this.mensaje.cerrarMensajeCarga();
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.Url);
    });
  }
}

