import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicService } from '../dynamic.service';

// text,email,tel,textarea,password,
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'butt',
  template: `

    <button  (click)="actionSend(this.form.value)"
        [class]="field.class? field.class : ''"
        type="button"
        [disabled] = "!isValid " >
        {{field.name}}
      </button>
    `
})
export class ButtonComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;

  constructor(private service: DynamicService) { }

  get isValid() {
    return this.form.valid;
  }

  actionSend(form) {

    switch (this.field.typeForm) {
      case 'tree-node':
        this.service.treeComponent.saveFormNode(
          this.field.nameObject,
          this.field.action,
          form);
        break;
      case 'tree-child':
        this.service.treeComponent.saveFormChild(
          this.field.nameObject,
          this.field.action,
          form);
        break;
      case 'custom-form':
        this.service.dynamicForm.customEvent(
          this.field.nameObject,
          this.field.action,
          form);
        break;
      case 'custom-tree-form':
        this.service.dynamicFormTree.customEvent(
          this.field.nameObject,
          this.field.action,
          form);
        break;
    }

  }
}
