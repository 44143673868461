import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FiltroCompuestoComponent } from '@components/filtros/filtro-compuesto/filtro-compuesto.component';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ServicioFiltrosService } from '@shared/components/filtros/servicio-filtros.service';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';

@Component({
  selector: 'app-lista-informacion-tabs-v3',
  templateUrl: './lista-informacion-tabs-v3.component.html',
  styleUrls: ['./lista-informacion-tabs-v3.component.scss']
})
export class ListaInformacionTabsV3Component implements OnInit {
  @Input() rows = [];
  @Input() columns = [];
  @Input() settings: any;
  @Input() mostrarExportar: any = true;
  @Input() mostrarFiltro = true;
  @Output() cambioTabLista = new EventEmitter();
  @Output() salida = new EventEmitter();
  @Output() page = new EventEmitter();
  @Output() filter = new EventEmitter();


  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("nav") nav: NgbNav;

  @Input() tabs: any;
  @Input() tabActivo: any;

  selected: any[] = [];
  temp: any = [];
  loading: any;
  paging: any;
  title: any;
  type: any;
  size: any;
  offset: any;
  rowCount: any;
  filtro: any;
  messages = {
    emptyMessage: `<div class="empty-row">No hay datos a mostrar</div>`
  }

  //Variables filtro compuesto
  @Input() idSigmaConsultaFiltro: any;
  @Output() filtroCompuesto = new EventEmitter();
  @Output() exportarFiltro = new EventEmitter();
  valorFiltroCompuesto: any[] = [];
  filtroTotal: any[] = [];
  idSigmaConsultaExportar: any;
  configFiltroCompuesto: any;

  constructor(
    private modalService: NgbModal,
    private servicioDatos: ServicioFiltrosService,
    public utilidades: ServicioUtilidadesService
  ) {
    this.temp = [...this.rows];
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.table.selected = [];
  }

  ngOnChanges() {
    this.temp = [...this.rows];
    this.loading = this.settings.loading;
    this.paging = this.settings.paging;
    this.title = this.settings.title;
    this.type = this.settings.type;
    this.size = this.settings.size;
    this.offset = this.settings.pageNumber;
    this.rowCount = this.settings.totalElements;
    this.limpiarArreglo();
  }

  filtrar(event) {
    const val = event.target.value.toLowerCase();
    if (val === "") {
      this.filtro = "";
      this.FilterFromServer();
      this.table.offset = 0;
    }
    if (this.paging === false) {
      if (val) {

        const rowsFilter = this.temp.filter(function (objeto) {

          for (let i in objeto) {
            let valorObjeto = String(objeto[i]);
            let existe = valorObjeto.toLowerCase().indexOf(val) !== -1 || !val;
            if (existe) {
              return objeto;
            }
          }
        });
        // update the rows
        this.rows = rowsFilter;

        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }
      else {
        this.rows = [...this.temp];
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
      }

    }
  }

  FilterFromServer() {
    const val = this.filtro.toLowerCase();
    if (this.paging === true) {
      this.filter.next(val);
    }
  }

  GetSearch(event) {
    if (this.paging === true) {
      this.FilterFromServer()
    }
    else {
      this.filtrar(event)
    }

  }

  limpiarArreglo() {
    if (this.table != undefined) {
      this.table.selected = [];
    }
  }

  /**
   * Abre un popup de acuerdo a un sigma consulta definido
   * @author Michael Agudelo
   * @date 2020-07-10.
  */
  AbrirPopup() {
    try {

      let modalRef
      modalRef = this.modalService.open(FiltroCompuestoComponent, { backdrop: 'static', size: 'lg', centered: true });
      modalRef.result.then(resultado => {
        if (resultado) {
          console.log(resultado);
          this.filtroCompuesto.next(resultado["filtroTotal"]);
          this.valorFiltroCompuesto = resultado["registros"];
          this.filtroTotal = resultado["filtroTotal"];
        }
      });
      let camposSet: any = {};
      camposSet["id_sigma_consulta"] = this.idSigmaConsultaFiltro;
      camposSet["valoresLista"] = this.valorFiltroCompuesto;
      modalRef.componentInstance.esPopup = true;
      modalRef.componentInstance.camposSet = camposSet;
      modalRef.componentInstance.modalRef = modalRef;

    } catch (e) {
      console.log("Error: " + e);
    }
  }

   /**
    * @description exporta a excel los datos de la lista luego de aplicar un filtro compuesto
    * @author Michael Agudelo
    * @date 23/07/2020
    * @param
    * @returns void
  */
    async ExportarExcel() {
      let filtro = this.idSigmaConsultaFiltro;
      let resultado = await this.servicioDatos.getServicioObtenerInformacionCache("FILTRO_COMPUESTO", filtro, "").toPromise();
      if (resultado.respuesta == "true") {
        this.configFiltroCompuesto = this.utilidades.RetornarStringObjeto(resultado.datos);
        this.configFiltroCompuesto = this.configFiltroCompuesto.map(x =>
          ({ id: x.id, text: x.text, campo_json: x.campo_json })
        )
      }
      // this.idSigmaConsultaExportar = `EXP_${this.idSigmaConsultaFiltro}`;
      this.idSigmaConsultaExportar = this.idSigmaConsultaFiltro;
      let objetoExportar = { filtroTotal: this.filtroTotal, idSigmaConsultaExportar: this.idSigmaConsultaExportar, titulosFiltro: this.configFiltroCompuesto };
      this.exportarFiltro.next(objetoExportar);
    }
  
    ExportarFiltro(filter) {
      let idSigmaConsultaExportar = filter["idSigmaConsultaExportar"];
      let filtroC = { "offset": "0", "limite": "", "filtro": "%%", "filtroCompuesto": "" }
      filtroC["titulosFiltro"] = filter["titulosFiltro"];
      if (this.filtro) {
        filtroC["filtro"] = `%${this.filtro}%`;
      }
      let filtro = filtroC;
      let tipo = "xls";
      let sistema = sessionStorage.getItem("sistemaLogueado");
      let nombreArchivo = "ReporteLista";
      let url = JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/ExportarArchivo";
  
      let param = { "sistema": sistema, "sigmaConsulta": idSigmaConsultaExportar, "filtro": JSON.stringify(filtro), "tipo": tipo, "nombreArchivo": nombreArchivo };
      try {
        this.servicioDatos.descargarArchivo(url, param).subscribe(
          (val) => {
            let blob = new Blob([val], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            let url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.download = nombreArchivo + ".xlsx";
            a.href = url;
            a.target = "_blank";
            a.click();
            a.remove();
          },
          response => {
            console.log("POST in error", response);
          },
          () => {
            console.log("POST observable is now completed.");
          });
      } catch (err) {
        console.log("Error Descarga:" + err);
      }
    }
  

}
