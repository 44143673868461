<ngu-carousel #myCarousel class="px-1 py-0" [inputs]="carouselConfig" [dataSource]="coleccionObjetosControl" *ngIf="objetoControlActual!=undefined">
  <div *nguCarouselDef="let item;" class="item">
    <div class="tile">
      <div class="card-body" (click)="salida.next(item)" [className]="item.json.obc_gradie"
      [ngClass]="{'bordeTarjetaSeleccionado' : objetoControlActual.json.obc_nombre.toUpperCase() == item.json.obc_nombre.toUpperCase()}">
        <div class="card-block pt-2 pb-0">
          <div class="media">
            <div class="media-body white text-left">
              <h3 class="font-large-1 mb-0" [ngClass]="{'oculto' : mostrarCantidad(item)}">{{item.cantidad || 0}} </h3>
              <span>{{item.json.obc_alias}}</span>
            </div>
            <div class="media-right white text-right">
              <img [src]="item.json.obc_icono.valor" *ngIf="item.json.obc_icono.tipo === 'imagen'; else icono">
              <ng-template #icono>
                <em [class]="item.json.obc_icono.valor"></em> 
              </ng-template>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
    <button NguCarouselNext class="color-obc rightRs" [hidden]="mostrar">&gt;</button>
    <button NguCarouselPrev class="color-obc leftRs" [hidden]="mostrar">&lt;</button>
</ngu-carousel>
