import { createAction, props } from '@ngrx/store';
import { IGeometry } from 'app/visor/shared/models/mapModels';
import { FeatureCollection, Point } from 'geojson';

export interface IHistoricalGpsRequest {
    idFuncion: string;
    veh_codigo: string;
    fecha1: string;
    hora_inicio: string;
    fecha2: string;
    hora_fin: string;
    funcion: string;
    filtro: string;
    hoja: string;
    ciclos: string;
    eventCheckboxState:any;
    cycleCheckboxState:any;
    geometria: IGeometry;
}

export interface IFeatureProperties {
    vehiculo: string,
    velocidad: number,
    evento: number,
    fecha: Date,
    norte: number,
    odometro: number
}

export interface IHistoricalGpsResponse {
    km: string,
    horas: string,
    Eventos: FeatureCollection<Point, IFeatureProperties>
    Ciclos: Array<{
        cic_codigo: string
        cic_fecha: string
        cic_nombre: string
        latitud: string
        longitud: string,
        motivo?: string;
    }>,
    Vehiculo: string;
    color: string;
}

const LOAD_HISTORICAL_GPS = '[HISTORICAL_GPS] Load historical gps';
const LOAD_HISTORICAL_GPS_SUCCESS = '[HISTORICAL_GPS] Load historical gps success';
const CLEAN_HISTORICAL_GPS = '[HISTORICAL_GPS] Clean historical gps success';
const LOAD_HISTORICAL_FORM = '[HISTORICAL_GPS] Load historical form';

export const LoadHistoricalGps = createAction(LOAD_HISTORICAL_GPS, props<{ payloadRequest: IHistoricalGpsRequest }>());
export const LoadHistoricalGpsSuccess = createAction(LOAD_HISTORICAL_GPS_SUCCESS, props<{ payloadResponse: Array<IHistoricalGpsResponse> }>());
export const CleanHistoricalGps = createAction(CLEAN_HISTORICAL_GPS);