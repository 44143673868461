import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GlobalSeguridad } from '@seguridad';

@Injectable({
  providedIn: 'root'
})
export class DynamicService {

  dynamicForm: any;
  dynamicFormTree: any;
  treeComponent: any;
  configService: any;

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) { }

  getServicioSigmaConsulta(sistema,
    idFuncion,
    filtro,
    datosPeticion
    ): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(localStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion',
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  getServicioRealizarAccion(
    sistema,
    usuario,
    nombreObjeto,
    jsonDatos,
    jsonDatosPeticion,
    accion
     ): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(localStorage.getItem('webConfig'))[this.configService.idWebConfig] + '/' + this.configService.controller,
      {
        sistema: sistema,
        usuario: usuario,
        nombreObjeto: nombreObjeto,
        jsonDatos: jsonDatos,
        jsonDatosPeticion: jsonDatosPeticion,
        accion: accion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  getServicioRealizarAccion2(
    sistema,
    nombreObjeto,
    accion,
    jsonDatos,
    jsonDatosPeticion
     ): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(localStorage.getItem('webConfig'))[this.configService.idWebConfig] + '/' + this.configService.controller,
      {
        sistema: sistema,
        nombreObjeto: nombreObjeto,
        jsonData: jsonDatos,
        datosPeticion: jsonDatosPeticion,
        accion: accion
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

}
