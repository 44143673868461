<div class="row col-md-12">
  <div class="col-md-5 p-0">
      <tree *ngIf="!nodesIsEmpty" 
      [nodes]="nodes" 
      [pk]="pk" 
      [useCheckbox] = "useCheckbox"
      (delEvent)="delEvent()" 
      (selectNode)="setForm($event)">
      </tree>
      <div class="message-empty" *ngIf="nodesIsEmpty">
          Valide:
          <ul>
              <li>No ha seleccionado un elemento para cargar el arbol.</li>
              <li>No hay plantilla para el objeto seleccionado.</li>
          </ul>
      </div>
  </div>
  <div class="col-md-7 scrool-form pl-3">
      <!--Debe ser hidden para poder registrar dynamic form en dynamic.service-->
      <dynamic-form [hidden]="nodesIsEmpty || !idFormDynamicCurrent"></dynamic-form>
      <div class="message-empty" *ngIf="!nodesIsEmpty && !idFormDynamicCurrent">
          Valide:
          <ul>
              <li>No ha seleccionado un elemento del arbol.</li>
              <li>El elemento seleccionado del arbol es una agrupación.</li>
              <li>El elemento seleccionado del arbol no cuenta con formulario.</li>
          </ul>

      </div>
  </div>
</div>