import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'field-builder',
  template: `
  <div class="form-group row" class="mb-2" [formGroup]="form" *ngIf="isReady">
    <label class="form-control-label" *ngIf="field.type !== 'button'" [attr.for]="field.label">
      {{field.label}}
      <strong class="text-danger" *ngIf="field.required">*</strong>
    </label>
    <div class="" [ngSwitch]="field.type">
      <textbox *ngSwitchCase="'text'" [field]="field" [form]="form"></textbox>
      <textbox *ngSwitchCase="'email'" [field]="field" [form]="form"></textbox>
      <textbox *ngSwitchCase="'tel'" [field]="field" [form]="form"></textbox>
      <textbox *ngSwitchCase="'password'" [field]="field" [form]="form"></textbox>
      <textbox *ngSwitchCase="'number'" [field]="field" [form]="form"></textbox>
      <ngselect *ngSwitchCase="'select'" [field]="field" [form]="form"></ngselect>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
      <date *ngSwitchCase="'date'" [field]="field" [form]="form"></date>
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form"></radio>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form"></file>
      <butt *ngSwitchCase="'button'" [field]="field" [form]="form" ></butt>
      <div class="alert alert-danger my-1 p-2 fadeInDown animated"
      *ngIf="isReady && messageRequired && !isValid">{{field.label}} es requerido!</div>
    </div>
  </div>
  `
})
export class FieldBuilderComponent {
  @Input() field: any;
  @Input() form: any;
  @Input() messageRequired: Boolean = false;
  isReady = false;

  constructor() { }

  get isValid() {
    if (this.field.type !== 'button') {
      if (this.form.controls[this.field.name]) {
        return this.form.controls[this.field.name].valid;
      }
    } else {
      return true;
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterContentInit  () {
    setTimeout(() => {
      this.isReady = true;
    });
  }
}
