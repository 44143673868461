import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { UtilidadesTemplateGeneratorService } from '@shared/generador-de-plantillas/utilidadesTemplateGenerator.service'

function loadFile(url: string, callback: { (error: any, content: any): void; (err: Error, data: string): void; }) {
  PizZipUtils.getBinaryContent(url, callback);
}

@Injectable({ 
  providedIn: 'root'
})
export class TemplateInformeVRDGeneratorService {

  constructor(
    private utilidades: UtilidadesTemplateGeneratorService
  ) { }  

  generarReporte(nombrePlantilla: string, rutaPlantilla: string, datos: any) {


    loadFile(
      rutaPlantilla,
      (error, content) => {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

       
        
        doc.render(datos
        );


        const blob = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(blob, nombrePlantilla);
      }
    );

  }

}
