import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicioPopupService } from '../servicio-popup.service';
import { Mensaje } from "@mensaje";
import { SettingsList } from '@settingsList';


@Component({
  selector: 'app-popup-resumen-v2',
  templateUrl: './popup-resumen-lista-v2.component.html',
  styleUrls: ['./popup-resumen-lista-v2.component.scss']
})
export class PopupResumenListaV2Component implements OnInit {
  @Input() public data;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  datosConfiguracionLista: any;
  resultado: any;
  elementosListaInformacion: any;
  configuracionColumnasListas: any;
  mensaje: any;
  bloqueoBoton: any = false;
  esLectura: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    console.log(this.data);
    this.datosConfiguracionLista = this.data.datosConfiguracionLista;
    this.configuracionColumnasListas = this.data.configuracionColumnasListas;
    this.elementosListaInformacion = this.data.elementosListaInformacion;
  }

  passBack() {
    this.passEntry.emit(this.resultado);
    this.activeModal.close(this.resultado);
  }

  Page(event) {
    console.log(event);
  }

  /**
   * Recibe el Modelo cuando se da clic al grid
   * @author Andrés Quintero
   * @date 2019-07-05.
   * @param objeto elemento seleccionado
  */
 ObjetoListaSeleccionado(objeto) {
  let registro = objeto.selected[0]
  this.resultado = registro;
  this.bloqueoBoton = true;
}

confirmarSeleccion() {
  let parametros = {
    contenedor: this
  };
  //Muestra un modal para asegurar la accion que quiere realizar el usuario
  this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea agregar el registro seleccionado?", "", "", this, "retornarResultado", parametros);
}

retornarResultado(parametros) {
  parametros.contenedor.activeModal.close(parametros.contenedor.resultado);
}

cerrarModal() {
  this.activeModal.close();
}
}
