<ngb-tabset (tabChange)="cambioTabLista.next($event)" [activeId]="tabActivo">
    <ngb-tab *ngFor="let tab of tabs" title="{{tab.title}}" id="{{tab.id}}">
        <ng-template ngbTabContent class="pt-1">
            <div class="card-block">
                <section *ngIf="configuracionColumnasListas != undefined" id="selection">
                    <!-- <div class="row">
                        <div class="col-12">
                            <div class="content-header">{{configuracionColumnasListas.titulo}}
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 pr-0"> 
                            <!-- <input type='text' id="txt_filtrar" style='padding:8px;margin:15px auto;width:100%;' placeholder='Escriba el valor a filtrar' 
                        (keyup)='filtrar($event)' [(ngModel)]="txt_filtrar"/> -->
                            <div class="input-group mb-3">
                                <input type="text" id="txt_filtrar" class="form-control"
                                    placeholder="Escriba el valor a filtrar" aria-label="Escriba el valor a filtrar"
                                    aria-describedby="basic-addon2" (keyup)='filtrar($event)' [(ngModel)]="txt_filtrar">
                                <div class="input-group-append">
                                    <button (click)="buscarFiltro()" class="btn btn-outline-secondary" type="button"
                                        [hidden]="!datosConfiguracionLista.paginacion"><em   class="ft-search"></em></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pr-0">
                            <ngx-datatable #table class="bootstrap selection-cell" [scrollbarH]="true"
                                [rows]="elementosListaInformacion" [rowHeight]="'auto'" [columnMode]="'force'"
                                [columns]="configuracionColumnasListas.columnas" [headerHeight]="50" [footerHeight]="50"
                                [externalPaging]="datosConfiguracionLista.paginacion" [selected]="selected"
                                [selectionType]="'multiClick'" [loadingIndicator]="true"
                                [limit]="datosConfiguracionLista.size" [count]="datosConfiguracionLista.totalElements"
                                [offset]="datosConfiguracionLista.pageNumber" (select)="salida.next($event)"
                                (activate)="onActivate($event)">
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template
                                        let-rowCount="datosConfiguracionLista.totalElements" let-pageSize="pageSize"
                                        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                        let-isVisible="isVisible">
                                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                            [hidden]="!((datosConfiguracionLista.cantidadRegistros / pageSize) > 1)"
                                            [count]="datosConfiguracionLista.totalElements"
                                            (change)="table.onFooterPage($event)">
                                        </datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>

                            </ngx-datatable>

                        </div>
                    </div>
                </section>
            </div>
        </ng-template>
    </ngb-tab>
</ngb-tabset>