<div *ngIf="objetoControlActual!=undefined">
  <ngu-carousel #carousel
  [inputs]="configsCarrusel"
  class="px-1 py-0">
    <ngu-item
    NguCarouselItem
    class="bannerStyle"
     *ngFor="let objetoControl of coleccionObjetosControl">
      <div
      class="card-body"
      (click)="salida.next(objetoControl)"
      [className]="objetoControl.json.obc_gradie"
      [class.bordeTarjetaSeleccionado]="objetoControlActual.json.obc_nombre.toUpperCase() == objetoControl.json.obc_nombre.toUpperCase()">
        <div class="card-block pt-2 pb-0">
          <div class="media">
            <div class="media-body white text-left">
              <h3 class="font-large-1 mb-0" [hidden] = "ocultarCantidad">{{objetoControl.cantidad || 0}} </h3>
              <span>{{objetoControl.json.obc_alias}}</span>
            </div>
            <div class="media-right white text-right">
              <img [src]="objetoControl.json.obc_icono">
            </div>
          </div>
        </div>
      </div>
    </ngu-item>
    <button NguCarouselPrev class='btn btn-outline-primary btn-round .btn-sm leftRs'>&lt;</button>
    <button NguCarouselNext class='btn btn-outline-primary btn-round .btn-sm rightRs'>&gt;</button>
  </ngu-carousel>

</div>
