<div class="card">
  <div class="card-body">
    <div class="card-block">
      <label *ngIf="resultadoElementosGrafica">{{resultadoElementosGrafica[0].titulo}}</label>
      <div>
        <div style="display: block" *ngIf="elementosGraficaFiltro!= undefined && elementosGraficaFiltro.barChartData.length > 0; else vacio">
          <canvas 
            baseChart height="auto" 
            #baseChart="base-chart" 
            [(datasets)]="elementosGraficaFiltro.barChartData" 
            [(labels)]="elementosGraficaFiltro.variables"
            [options]="barChartOptions" 
            [legend]="barChartLegend"
            [colors]="pieChartColors.length !=0 ? pieChartColors: []"
            [chartType]="barChartType" 
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>
        <ng-template #vacio>
          <h1>No hay datos</h1>
        </ng-template>
        
        <!-- <button type="button" class="btn btn-light btn-sm botonGrafica" (click)="randomize()">Ver Todas</button> -->
      </div>
    </div>
  </div>
</div>