<!--Theme customizer Starts-->
<!-- <div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-sm-none d-md-block open"> -->
	<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-sm-none d-md-block">
	<a class="customizer-close">
		<em class="ft-x font-medium-3"></em>
	</a>
	<a class="customizer-toggle bg-danger" id="customizer-toggle-icon" [hidden]="totalMensajes==0">
		<em class="ft-bell font-medium-3 blue-grey darken-4"></em>
		<span  class="notification badge badge-pill badge-danger">{{totalMensajes}}</span>
	</a>
	<div class="customizer-content pt-0 ps-container ps-theme-dark" data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
		<h4 class="text-uppercase m-3 text-bold-400">Notificaciones</h4>
		<!-- <app-lista-notificacion (totalesMensajes)="AsignarCantidadNotificaciones($event)"></app-lista-notificacion> -->
		<app-lista-notificacion-grupos (totalesMensajes)="AsignarCantidadNotificaciones($event)"></app-lista-notificacion-grupos>
		<!--Sidebar Width Ends-->
	</div>
</div>
<!--Theme customizer Ends-->