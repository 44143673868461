<div class="form-group col-md-12 mb-1">
    <div class="m-2 float-right">
        <button type="button" id="button-close" (click)="cerrarPopup()" class=" btn btn-raised btn-danger mr-1">
            <em class="ft-x"></em>
        </button>
    </div>
</div>
<div class="row">
    <div class="form-group col-md-12 md-2" *ngFor="let combo of configCombos">
        <label>{{combo.nombre}}</label>
        <ng-select bindLabel="text" bindValue="id" [items]="combo.items" [multiple]="valor"
            [(ngModel)]="combo.modelo">
        </ng-select>
    </div>


    <div class="form-group col-12 mb-2">
        <button  *ngFor="let boton of configBotones"  type="button" class="btn btn-raised btn-primary mr-1"
                [disabled]="bloqueBoton" (click)="confirmarSeleccion($event)">
                <em class="fa boton.icono" id="btn">{{boton.nombre}}</em>
            </button>
    </div>
</div>