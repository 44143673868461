import { Component, OnInit } from '@angular/core';
import { Mensaje } from "@mensaje";
import { SettingsList } from '@settingsList';
import { ServicioPopupService } from '@shared/components/popups/servicio-popup.service';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';
import { ShaSerAlmeraService } from '@shared/services/sha-ser-almera';




@Component({
  selector: 'app-frm-obtener-solicitantes',
  templateUrl: './frm-obtener-solicitantes.component.html',
  styleUrls: ['./frm-obtener-solicitantes.component.scss']
})
export class FrmObtenerSolicitantesComponent implements OnInit {

  //variables para configuracion de popup
  camposSet: any = {};
  modalRef: any;
  modelo: any;
  mensaje: any;
  resultado: any;
  bloqueoBoton: any = false;
  esLectura: boolean = false;
  titulo: string = "";


  rows: any;
  columns: any;
  filter: any;
  configuracionLista = new SettingsList();
  sol_identi: String;

  datosAlmera: any;
  //Lista de Informacion Casetas
  datosConfiguracionListaPopup = {
    sigmaConsultaPaginacion: "",
    sigmaConsulta: "",
    filtroSigmaConsultaPaginacion: "",
    filtroSigmaConsulta: "",
  };
  // '[{"prop":"resumen","name":"Resumen"}]'
  temp_configuracionColumnasPopup: any;


  constructor(
    private servicio: ServicioPopupService,
    private utilidades: ServicioUtilidadesService,
    private servicioAlmera: ShaSerAlmeraService
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    this.configuracionLista.paging = true;
    this.SetearCampos();
    if (this.temp_configuracionColumnasPopup[0].prop != undefined) {
      this.columns = this.temp_configuracionColumnasPopup;
    } else {
      this.columns = JSON.parse(this.temp_configuracionColumnasPopup);
    }
    this.rows = [];
    if (this.configuracionLista.paging) {
      this.ObtenerCantidadElementos();
    }



  }

  SetearCampos() {

    this.titulo = "Información solicitante";
    this.sol_identi = this.camposSet["sol_identi"];
    this.modelo = this.camposSet["modelo"];
    const jsonPaginacion = `{"filtro":"%%", "sol_identi":"${this.sol_identi}"}`;
    const json = `{"offset":"0","limite":"5","filtro":"%%","sol_identi":"${this.sol_identi}"}`;
    this.datosConfiguracionListaPopup.sigmaConsultaPaginacion = "OBTENER_SOLICITANTE_LISTA_PAGINACION_Popup_Almera";
    this.datosConfiguracionListaPopup.filtroSigmaConsultaPaginacion = jsonPaginacion;
    this.datosConfiguracionListaPopup.sigmaConsulta = "OBTENER_SOLICITANTE_LISTA_Popup_Almera";
    this.datosConfiguracionListaPopup.filtroSigmaConsulta = json;
    this.temp_configuracionColumnasPopup = '[{"prop":"resumen","name":"Resumen"}]';
    this.configuracionLista.paging = true;

  }

  confirmarSeleccion() {
    let parametros = {
      contenedor: this
    };
    //Muestra un modal para asegurar la accion que quiere realizar el usuario
    this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea agregar el registro seleccionado?", "", "", this, "retornarResultado", parametros);
  }

  retornarResultado(parametros) {


    const registro = parametros.contenedor.resultado;
    const fuente = registro.fuente;

 



    //Si la fuente es almera se debe armar un json para enviar los datos a la api
    if (fuente == 'Almera') {

         //Se asigna al modelo que llega desde el formulario principal
      parametros.contenedor.modelo.sol_identi = registro.documento;
      parametros.contenedor.modelo.sol_nombre = registro.nombres;
      parametros.contenedor.modelo.sol_priape = registro.primerapellido;
      parametros.contenedor.modelo.sol_segape = registro.segundoapellido;
      parametros.contenedor.modelo.mun_codigo = registro.municipio;
      parametros.contenedor.modelo.sol_json.sol_natjur = registro.naturaleza;
      parametros.contenedor.modelo.sol_json.sol_direcc = registro.direccionCorrespondecia;
      parametros.contenedor.modelo.sol_json.sol_depto = registro.departamento;
      parametros.contenedor.modelo.sol_json.sol_numtel = registro.telefono;
      parametros.contenedor.modelo.sol_json.sol_numcel = registro.celular;
      parametros.contenedor.modelo.sol_json.sol_email = registro.email;
      parametros.contenedor.modelo.sol_json.sol_mednot = registro.medionotificacion;
      parametros.contenedor.modelo.sol_json.sol_activi = registro.actividadciiu;
      parametros.contenedor.modelo.sol_json["sol_fuente"] = registro.fuente;

      const json = {
        sol_identi: registro.documento,
        sol_nombre: registro.nombres,
        sol_priape: registro.primerapellido,
        sol_segape: registro.segundoapellido,
        mun_codigo: registro.municipio,
        sol_tipdoc: parametros.contenedor.modelo.sol_json.sol_tipdoc,
        sol_natjur: registro.naturaleza,
        sol_direcc: registro.direccionCorrespondecia,
        sol_depto: registro.departamento,
        sol_numtel: registro.telefono,
        sol_numcel: registro.celular,
        sol_email: registro.email,
        sol_mednot: registro.medionotificacion,
        sol_activi: registro.actividadciiu
      };

      //Primero se obtiene el token para consumir la api
      parametros.contenedor.servicioAlmera.getLoginApiAlmera().subscribe(res => {
        if (res.state == 'success') {
          const token = res.token;

          //Se envían los datos a la api para adicionar o editar 
          parametros.contenedor.servicioAlmera.AddApplicants(json, token).subscribe(res => {
            if (res.state == 'fail') {
              parametros.contenedor.mensaje.mensajeNotificacion("center", "warning", res.message);
            }
          });
        }
      });




    } else {


         //Se asigna al modelo que llega desde el formulario principal
    parametros.contenedor.modelo.sol_identi = registro.documento;
    parametros.contenedor.modelo.sol_nombre = registro.nombres;
    parametros.contenedor.modelo.sol_priape = registro.primerapellido;
    parametros.contenedor.modelo.sol_segape = registro.segundoapellido;
    parametros.contenedor.modelo.mun_codigo = registro.mun_codigo;
    parametros.contenedor.modelo.sol_json = registro.sol_json;
    parametros.contenedor.modelo.sol_json["sol_fuente"] = registro.fuente;

      //Si la fuente no es almera se cargan los datos del representante y el apoderado
      if (registro.rep_identi) {
        parametros.contenedor.modelo["rep_identi"] = registro.rep_identi;
        parametros.contenedor.modelo["rep_nombre"] = registro.rep_nombre;
        parametros.contenedor.modelo["rep_priape"] = registro.rep_priape;
        parametros.contenedor.modelo["rep_segape"] = registro.rep_segape;
        parametros.contenedor.modelo["rep_mun_codigo"] = registro.rep_mun_codigo;
        parametros.contenedor.modelo["rep_json"] = registro.rep_json;
      }

      if (registro.apo_identi) {
        parametros.contenedor.modelo["apo_identi"] = registro.apo_identi;
        parametros.contenedor.modelo["apo_nombre"] = registro.apo_nombre;
        parametros.contenedor.modelo["apo_priape"] = registro.apo_priape;
        parametros.contenedor.modelo["apo_segape"] = registro.apo_segape;
        parametros.contenedor.modelo["apo_mun_codigo"] = registro.apo_mun_codigo;
        parametros.contenedor.modelo["apo_json"] = registro.apo_json;
      }
    }



    parametros.contenedor.modalRef.close(parametros.contenedor.modelo);
  }

  cerrarModal() {
    this.modalRef.close();
  }

  /**
   * Recibe el Modelo cuando se da clic al grid
   * @author Andrés Quintero
   * @date 2019-07-05.
   * @param objeto elemento seleccionado
  */
  ObjetoListaSeleccionadoPopup(objeto) {
    let registro
    if (objeto.selected.length > 1) {
      registro = objeto.selected;
      this.bloqueoBoton = true;
    } else {
      registro = objeto.selected[0]
      this.bloqueoBoton = true;
    }
    if (objeto.selected[0] == undefined) {
      this.bloqueoBoton = false;
    }


    this.resultado = registro;
  }



  /*
  Realiza la consulta de la cantidad total de elementos 
  */
  ObtenerCantidadElementos() {
    this.configuracionLista.loading = true;
    let idSigmaConsulta = this.datosConfiguracionListaPopup.sigmaConsultaPaginacion;
    let filtro = this.datosConfiguracionListaPopup.filtroSigmaConsultaPaginacion;
    filtro = JSON.parse(filtro);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "")
      .subscribe(res => {
        if (res.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
          this.configuracionLista.totalElements = respuesta[0].total;
          this.configuracionLista = { ...this.configuracionLista };

          this.SetData({ offset: 0 });
        }
      });
  }

  /*
  Trae los elementos paginando desde el servidor
  */
  SetData(pageInfo) {
    this.configuracionLista.pageNumber = pageInfo.offset;
    const limiteAux = this.configuracionLista.size;
    const offset = this.configuracionLista.pageNumber;
    let idSigmaConsulta = this.datosConfiguracionListaPopup.sigmaConsulta;
    let filtro = this.datosConfiguracionListaPopup.filtroSigmaConsulta;
    filtro = JSON.parse(filtro);
    filtro["limite"] = String(limiteAux);
    filtro["offset"] = String(offset);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "").subscribe(res => {
      if (res.respuesta == "true") {
        let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
        let coleccionDatosLista = [];

        for (let i in respuesta) {
          if (respuesta[i].resumen) {
            respuesta[i].resumen = respuesta[i].resumen.replace(/@%@/g, "<br>");
          }
          coleccionDatosLista.push(respuesta[i]);
        }

        this.servicioAlmera.getApplicantAlmera(this.sol_identi).then(
          (data) => {
            console.log(data);
            this.datosAlmera = data;
            if (this.datosAlmera.status) {
              const json = JSON.parse(this.datosAlmera.datos);
              const resumen = `No. Identificación: ${json.documento} <br>Nombre: ${json.nombres} <br>Primer Apellido: ${json.primerapellido} <br>Segundo Apellido: ${json.segundoapellido} <br>Fecha actualización: ${json.fechaUltimaActualizacion} <br>Fuente: Almera`;
              json["resumen"] = resumen;

              coleccionDatosLista.push(json);
              this.configuracionLista.totalElements = this.configuracionLista.totalElements + 1;
            }
            this.rows = coleccionDatosLista;
          }
        );






      } else {
        let coleccionDatosLista = [];
        this.servicioAlmera.getApplicantAlmera(this.sol_identi).then(
          (data) => {
            console.log(data);
            this.datosAlmera = data;
            if (this.datosAlmera.status) {
              const json = JSON.parse(this.datosAlmera.datos);              
              const resumen = `No. Identificación: ${json.documento} <br>Nombre: ${json.nombres} <br>Primer Apellido: ${json.primerapellido} <br>Segundo Apellido: ${json.segundoapellido} <br>Fecha actualización: ${json.fechaUltimaActualizacion} <br>Fuente: Almera`;
              json["resumen"] = resumen;

              coleccionDatosLista.push(json);
              this.configuracionLista.totalElements = this.configuracionLista.totalElements + 1;
            } else {
              this.rows = [];
            }
            this.rows = coleccionDatosLista;
          }
        );
      }
      this.configuracionLista.loading = false;
    });
  }


  /*
  Filtra la lista haciendo la consulta a base de datos
  */
  Filter(filter) {
    this.filter = filter;
    this.ObtenerCantidadElementos();
  }

}

