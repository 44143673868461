import { Action, createAction, createReducer, createSelector, on, props } from "@ngrx/store";
import { IBuscar, IModule, IObjetoControl, IRutas, ISigmaConsulta, IOperation, IAPPState, ISubsistema, IOperations } from "./estados/estados";
export class AppStore {
  private estado: any = {};
  private rutas: IRutas = { rutaActual: "", rutaAnterior: "" };
  private operaciones: IOperations = { operations: [], loading: false, query: { id: "", filtro: "", modulo: "" } };
  private CARGAR_APP: string = "[APP_STORE] Cargar aplicación";
  private CARGAR_TARJETAPRINCIPAL: string = "[APP_STORE] Cargando tarjeta principal";
  private ASIGNAR_OPERACIONES: string = "[APP_STORE] Asignar Tarjeta Principal";
  private ASIGNAR_OPERACION: string = "[APP_STORE] Seleccionar elemento tarjeta principal";
  private ABRIR_MODULO: string = "[APP_STORE] Cargar módulo";
  private ASIGNAR_CONSULTA_OBJETOS_CONTROL = "[APP_STORE] Asignar Sigma Consulta Objetos Control";
  private CARGAR_OBJETOSCONTROL: string = "[APP_STORE] Cargando Objetos Control";
  private ASIGNAR_OBJETOSCONTROL: string = "[APP_STORE] Asignar Objetos Control";
  private ASIGNAR_OBJETOCONTROL: string = "[APP_STORE] Seleccionar Objeto Control";
  private ASIGNAR_CONSULTA_SUBSISTEMAS = "[APP_STORE] Asignar Sigma Consulta Subsistemas";
  private CARGAR_SUBSISTEMAS: string = "[APP_STORE] Cargando Subsistemas";
  private ASIGNAR_SUBSISTEMAS: string = "[APP_STORE] Asignar Subsistemas";
  private ASIGNAR_SUBSISTEMA: string = "[APP_STORE] Selecionar Subsistema";
  private CARGAR_LISTA: string = "[APP_STORE] Cargar datos Lista";
  private ASIGNAR_CONFIG_LISTA: string = "[APP_STORE] Asignar config Lista";
  private ASIGNAR_DATOS_LISTA: string = "[APP_STORE] Asignar datos Lista";

  cargarApp = createAction(this.CARGAR_APP);
  cargarModulo = createAction(this.ABRIR_MODULO, props<IModule>());
  cargarObjetosControl = createAction(this.CARGAR_OBJETOSCONTROL, props<ISigmaConsulta>());
  cargarTarjetaPrincipal = createAction(this.CARGAR_TARJETAPRINCIPAL, props<ISigmaConsulta>());
  asignarOperaciones = createAction(this.ASIGNAR_OPERACIONES, props<{ datos: Array<IOperation>; consulta: ISigmaConsulta }>());
  asignarOperacion = createAction(this.ASIGNAR_OPERACION, props<{ ruta: string; codigo: string }>());
  asignarSigmaConsultaObjetoControl = createAction(this.ASIGNAR_CONSULTA_OBJETOS_CONTROL, props<ISigmaConsulta>());
  asignarObjetoControl = createAction(this.ASIGNAR_OBJETOCONTROL, props<{ ruta: string; codigo: string }>());
  asignarObjetosControl = createAction(this.ASIGNAR_OBJETOSCONTROL, props<{ ruta: string; objs: Array<IObjetoControl> }>());
  asignarSigmaConsultaSubsistemas = createAction(this.ASIGNAR_CONSULTA_SUBSISTEMAS, props<ISigmaConsulta>());
  cargarSubsistemas = createAction(this.CARGAR_SUBSISTEMAS, props<ISigmaConsulta>());
  asignarSubsistemas = createAction(this.ASIGNAR_SUBSISTEMAS, props<{ ruta: string; subs: Array<ISubsistema> }>());
  asignarSubsistema = createAction(this.ASIGNAR_SUBSISTEMA, props<{ ruta: string; subCodigo: string }>());
  cargarLista = createAction(this.CARGAR_LISTA, props<ISigmaConsulta>());
  asignarConfigLista = createAction(this.ASIGNAR_CONFIG_LISTA, props<{ ruta: string; idLista: string; datos: any }>());
  asignarDatosLista = createAction(this.ASIGNAR_DATOS_LISTA, props<{ ruta: string; idLista: string; datos: Array<any> }>());

  ReducerEventosModulos = createReducer(
    this.estado,
    on(this.cargarApp, (state) => {
      return { ...state };
    }),
    on(this.cargarModulo, (state, modulo: IModule) => {
      let mod = { ...state };
      mod[modulo.ruta] = { ...modulo };
      return { ...mod };
    }),
    on(this.asignarOperacion, (state, op) => {
      let mod = { ...state };
      let module: IModule = { ...mod[op.ruta] };
      module.operacion = op.codigo;
      mod[op.ruta] = module;
      return { ...mod };
    }),
    on(this.asignarObjetosControl, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...mod[obj.ruta] };
      let objs: Array<IObjetoControl> = [...obj.objs];
      if (module.objetosControl != undefined) {
        for (let i = 0; i < module.objetosControl.length; i++) {
          for (let j = 0; j < obj.objs.length; j++) {
            if (module.objetosControl[i].obc_codigo == obj.objs[j].obc_codigo) {
              objs[j] = { ...objs[j], subsistemas: module.objetosControl[i].subsistemas, subsistema: module.objetosControl[i].subsistema };
            }
          }
        }
      }
      module.objetosControl = [...objs];
      if (module.objetoControl == "") module.objetoControl = module.objetosControl[0].obc_codigo;
      mod[obj.ruta] = module;
      return { ...mod };
    }),
    on(this.asignarSigmaConsultaObjetoControl, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...mod[obj.modulo] };
      module.consultaObjetosControl = { ...obj };
      mod[obj.modulo] = module;
      return { ...mod };
    }),
    on(this.asignarObjetoControl, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...mod[obj.ruta] };
      module.objetoControl = obj.codigo;
      mod[obj.ruta] = module;
      return { ...mod };
    }),
    on(this.asignarSigmaConsultaSubsistemas, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...mod[obj.modulo] };
      module.consultaSubsistemas = { ...obj };
      mod[obj.modulo] = module;
      return { ...mod };
    }),
    on(this.asignarSubsistemas, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...state[obj.ruta] };
      module.objetosControl = module.objetosControl.map((objeto) => (objeto.obc_codigo == module.objetoControl ? { ...objeto, subsistemas: [...obj.subs], subsistema: objeto.subsistema == undefined ? obj.subs[0].sub_codigo : objeto.subsistema } : objeto));
      mod[obj.ruta] = module;
      return mod;
    }),
    on(this.asignarSubsistema, (state, obj) => {
      let mod = { ...state };
      let module: IModule = { ...state[obj.ruta] };
      module.objetosControl = module.objetosControl.map((objeto) => (objeto.obc_codigo == module.objetoControl ? { ...objeto, subsistema: obj.subCodigo } : objeto));
      mod[obj.ruta] = module;
      return mod;
    }),
    on(this.asignarConfigLista, (state, datos) => {
      let mod = { ...state };
      let module: IModule = { ...mod[datos.ruta] };
      module.listas = module.listas.map((lista) => (lista.id == datos.idLista ? { ...lista, config: datos.datos } : lista));
      mod[datos.ruta] = module;
      return { ...mod };
    }),
    on(this.asignarDatosLista, (state, datos) => {
      let mod = { ...state };
      let module: IModule = { ...mod[datos.ruta] };
      module.listas = module.listas.map((lista) => (lista.id == datos.idLista ? { ...lista, datos: datos.datos.length > 0 ? [...datos.datos] : [] } : lista));
      mod[datos.ruta] = module;
      return { ...mod };
    })
  );

  ReducerEventosRutas = createReducer(
    this.rutas,
    on(this.cargarApp, (state) => ({
      ...state,
    }))
  );

  ReducerEventosOperacion = createReducer(
    this.operaciones,
    on(this.asignarOperaciones, (state, obj) => {
      return { ...state, loading: false, operations: [...obj.datos], query: obj.consulta };
    }),
    on(this.cargarTarjetaPrincipal, (state) => ({ ...state, loading: true }))
  );

  reducerEventosModule = (state = this.estado, action: Action): Array<IModule> => {
    return this.ReducerEventosModulos(state, action);
  };
  reducerEventosRutas = (state = this.rutas, action: Action): IRutas => {
    return this.ReducerEventosRutas(state, action);
  };
  reducerEventosOperaciones = (state = this.operaciones, action: Action): IOperations => {
    return this.ReducerEventosOperacion(state, action);
  };
}
export class AppSelector {
  getOperacion = () =>
    createSelector(
      (state, props) => ({ modulo: state?.modulos[props.ruta], loading: state?.operations?.loading }),
      (state) => {
        return { operation: state?.modulo?.operacion, loading: state?.loading };
      }
    );
  getOperations = () =>
    createSelector(
      (state) => state,
      (state: IAPPState) => {
        return { operations: state?.operations?.operations, loading: state?.operations?.loading };
      }
    );
  getModule = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state: IModule) => {
        if (state != undefined) {
          return { ...state };
        } else {
          return undefined;
        }
      }
    );
  getContedeor = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state) => {
        return { ...state.contenedor };
      }
    );
  getSigmaConsultaObjControl = () =>
    createSelector(
      (state, props) => state.modulos[props.ruta],
      (state: IModule) => {
        if (state != undefined) {
          return { ...state?.consultaObjetosControl };
        }
        return undefined;
      }
    );
  getObjetoControl = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state: IModule) => {
        return { ...state?.objetosControl.filter((objeto) => objeto.obc_codigo == state.objetoControl)[0] };
      }
    );
  getObjetosControl = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state) => {
        if (state != undefined) {
          return [...state?.objetosControl];
        }
        return [];
      }
    );
  getSigmaConsultaSubsistemas = () =>
    createSelector(
      (state, props) => state.modulos[props.ruta],
      (state: IModule) => {
        if (state != undefined) {
          return { ...state.consultaSubsistemas };
        }
        return undefined;
      }
    );
  getSubsistemas = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state) => {
        let objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo == state.objetoControl)[0];
        if (objControl != undefined && objControl.subsistemas != undefined) return [...objControl?.subsistemas];
        return [];
      }
    );
  getSubsistema = () =>
    createSelector(
      (state, props: IBuscar) => state.modulos[props.ruta],
      (state) => {
        let objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo == state.objetoControl)[0];
        if (objControl != undefined && objControl.subsistemas != undefined) {
          let subsistema = objControl.subsistemas.findIndex((subs) => subs.sub_codigo == objControl.subsistema);
          return { ...objControl.subsistemas[subsistema] };
        }
        return undefined;
      }
    );
}
