import { IConfigMap } from "../../shared/models/configMap";
import * as fromActions from "../actions";
import { createReducer, on, Action } from "@ngrx/store";

export interface IConfigLeafletState {
  map: IConfigMap;
  service: Array<any>;
  loading: boolean;
  loaded: boolean;
  action: string;
}

export const configLeafletState: IConfigLeafletState = {
  map: undefined,
  service: [],
  loading: false,
  loaded: false,
  action: "",
};

const featureReducerConfigLeaflet = createReducer(
  configLeafletState,
  on(fromActions.LoadConfigLeaflet, (state) => {
    return {
      ...state,
      loading: true,
      action: "LoadConfigLeaflet",
    };
  }),

  on(fromActions.LoadConfigLeafletSuccess, (state, { map, service }) => {
    return {
      ...state,
      map,
      service,
      loaded: true,
      loading: false,
      action: "LoadConfigLeafletSuccess",
    };
  }),

  on(fromActions.toggleCollapseService, (state, { serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName ? { ...x, collapsed: !x?.collapsed } : x
    );
    return {
      ...state,
      service: [...service],
      action: "toglleCollapseService",
    };
  }),

  on(fromActions.toggleCollapseServiceLayer, (state, { id, serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName
        ? {
          ...x,
          serCapasActivas: x?.serCapasActivas.map((z) =>
            z.id === id ? { ...z, collapsed: !z.collapsed } : z
          ),
        }
        : x
    );
    return {
      ...state,
      service: [...service],
      action: "toggleCollapseServiceLayer",
    };
  }),
  on(
    fromActions.toggleCollapseServiceGroup,
    (state, { groupId, layerId, serviceName }) => {
      const service = state.service.map((x) =>
        x.serNombre === serviceName
          ? {
            ...x,
            serCapasActivas: x?.serCapasActivas.map((z) =>
              z.id === layerId
                ? {
                  ...z,
                  agrupamientos: z?.agrupamientos.map((agrupamiento: any) =>
                    agrupamiento.id === groupId
                      ? {
                        ...agrupamiento,
                        collapsed: !agrupamiento.collapsed,
                      }
                      : agrupamiento
                  ),
                }
                : z
            ),
          }
          : x
      );
      return {
        ...state,
        service: [...service],
        action: "toggleCollapseServiceGroup",
      };
    }
  ),
  on(
    fromActions.toggleActiveServiceGroup,
    (state, { groupId, layerId, serviceName }) => {
      const service = state.service.map((x) =>
        x.serNombre === serviceName
          ? {
            ...x,
            serCapasActivas: x?.serCapasActivas.map((z) =>
              z.id === layerId
                ? {
                  ...z,
                  agrupamientos: z?.agrupamientos.map((agrupamiento: any) =>
                    agrupamiento.id === groupId
                      ? {
                        ...agrupamiento,
                        activo: !agrupamiento.activo,
                      }
                      : agrupamiento
                  ),
                }
                : z
            ),
          }
          : x
      );
      return {
        ...state,
        service: [...service],
        action: "toggleActiveServiceGroup",
      };
    }
  ),
  on(
    fromActions.toggleCollapseServiceSubLayer,
    (state, { groupId, layerId, serviceName, sublayerId }) => {
      const service = state.service.map((x) =>
        x.serNombre === serviceName
          ? {
            ...x,
            serCapasActivas: x?.serCapasActivas.map((z) =>
              z.id === layerId
                ? {
                  ...z,
                  agrupamientos: z?.agrupamientos.map((agrupamiento: any) =>
                    agrupamiento.id === groupId
                      ? {
                        ...agrupamiento,
                        subcapas: agrupamiento.subcapas.map(
                          (subcapa: any) =>
                            subcapa.id === sublayerId
                              ? {
                                ...subcapa,
                                collapsed: !subcapa?.collapsed,
                              }
                              : subcapa
                        ),
                      }
                      : agrupamiento
                  ),
                }
                : z
            ),
          }
          : x
      );
      return {
        ...state,
        service: [...service],
        action: "toggleCollapseServiceSubLayer",
      };
    }
  ),
  on(
    fromActions.toggleActiveServiceSubLayer,
    (state, { groupId, layerId, serviceName, sublayerId }) => {
      const service = state.service.map((x) =>
        x.serNombre === serviceName
          ? {
            ...x,
            serCapasActivas: x?.serCapasActivas.map((z) =>
              z.id === layerId
                ? {
                  ...z,
                  agrupamientos: z?.agrupamientos.map((agrupamiento: any) =>
                    agrupamiento.id === groupId
                      ? {
                        ...agrupamiento,
                        subcapas: agrupamiento.subcapas.map(
                          (subcapa: any) =>
                            subcapa.id === sublayerId
                              ? {
                                ...subcapa,
                                activo: !subcapa?.activo,
                              }
                              : subcapa
                        ),
                      }
                      : agrupamiento
                  ),
                }
                : z
            ),
          }
          : x
      );
      return {
        ...state,
        service: [...service],
        action: "toggleActiveServiceSubLayer",
      };
    }
  ),

  on(fromActions.toogleActiveService, (state, { serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName ? { ...x, serActivo: !x?.serActivo } : x
    );
    return {
      ...state,
      service: [...service],
      action: "toogleActiveService",
    };
  }),

  on(fromActions.toggleActiveServiceLayer, (state, { id, serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName
        ? {
          ...x,
          serCapasActivas: x?.serCapasActivas.map((z: any) =>
            z.id === id ? { ...z, activo: !z.activo } : z
          ),
        }
        : x
    );
    return {
      ...state,
      service: [...service],
      action: "toggleActiveServiceLayer",
    };
  }),
  on(fromActions.changeOpacity, (state, { id, serviceName, opacity }) => {
    const service = state.service.map((x) => {
      if (x.serNombre === serviceName) {
        return {
          ...x,
          serCapasActivas: x?.serCapasActivas.map((z: any) =>
            z?.tieneAgrupamientos
              ? {
                ...z,
                agrupamientos: z.id === id ? z.agrupamientos.map((agrupamiento) => {
                  return {
                    ...agrupamiento,
                    subcapas: agrupamiento.subcapas.map((subcapa) => {
                      return { ...subcapa, opacity };
                    }),
                  };
                }) : z.agrupamientos,
                opacity
              }
              : {
                ...z,
                opacity: z.id === id ? opacity : z?.opacity
              }
          ),
        };
      }
      return x;
    });
    return {
      ...state,
      service: [...service],
      action: "changeOpacity",
    };
  }),
  on(fromActions.changeLayersService, (state, { services }) => {
    return {
      ...state,
      service: [...services],
      action: "changeServicesLayers"
    }
  }),

  on(fromActions.toggleSelectedLayers, (state, { id, serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName
        ? {
          ...x,
          serCapasActivas: x?.serCapasActivas.map((z) =>
            id[0] === z.id ? { ...z, activo: true } : { ...z, activo: false }
          ),
        }
        : x
    );
    return {
      ...state,
      service: [...service],
      action: "toggleSelectedLayers",
    };
  }),

  on(fromActions.activeAllServiceLayer, (state) => ({
    ...state,
    service: state.service.map((service) => ({
      ...service,
      serActivo: true,
      serCapasActivas: service?.serCapasActivas?.map((layer: any) => ({
        ...layer,
        activo: true,
        agrupamientos: layer?.agrupamientos?.map((agrupamiento: any) => ({
          ...agrupamiento,
          activo: true,
          subcapas: agrupamiento?.subcapas?.map((subcapa: any) => ({
            ...subcapa,
            activo: true,
          })),
        })),
      })),
      action: "activeAllServiceLayer",
    })),
  })),

  on(fromActions.inactiveAllServiceLayer, (state) => ({
    ...state,
    service: state.service.map((service) => ({
      ...service,
      serActivo: false,
      action: "inactiveAllServiceLayer",
      serCapasActivas: service?.serCapasActivas?.map((layer:any) => ({
        ...layer,
        activo: false,
        agrupamientos: layer?.agrupamientos?.map((agrupamiento:any) => ({
          ...agrupamiento,
          activo: false,
          subcapas: agrupamiento?.subcapas?.map((subcapa:any ) => ({
            ...subcapa,
            activo: false,
          })),
        })),
      })),
    })),
  })),

  on(fromActions.collapseAllServiceLayer, (state) => ({
    ...state,
    service: state.service.map((service) => ({
      ...service,
      collapsed: false,
      action: "collapseAllServiceLayer",
      serCapasActivas: service.serCapasActivas.map((layer) => ({
        ...layer,
        collapsed: false,
      })),
    })),
  })),

  on(fromActions.expandAllServiceLayer, (state) => ({
    ...state,
    service: state.service.map((service) => ({
      ...service,
      collapsed: true,
      action: "expandAllServiceLayer",
      serCapasActivas: service.serCapasActivas.map((layer) => ({
        ...layer,
        collapsed: true,
      })),
    })),
  })),

  on(
    fromActions.setFilterServiceLayer,
    (state, { id, serviceName, filtro }) => {
      const service = state.service.map((x) =>
        x.serNombre === serviceName
          ? {
            ...x,
            isFiltered: true,
            serCapasActivas: x?.serCapasActivas.map((z) =>
              z.id === id
                ? {
                  ...z,
                  filtro_: z.filtro_ ? [...z.filtro_, filtro] : [filtro],
                }
                : z
            ),
          }
          : x
      );
      return {
        ...state,
        service: [...service],
        action: "setFilterServiceLayer",
      };
    }
  ),

  on(fromActions.deleteFilterServiceLayer, (state, { id, serviceName }) => {
    const service = state.service.map((x) =>
      x.serNombre === serviceName
        ? {
          ...x,
          isFiltered: false,
          serCapasActivas: x?.serCapasActivas.map((z) =>
            z.id === id ? { ...z, filtro_: [] } : z
          ),
        }
        : x
    );
    return {
      ...state,
      service: [...service],
      action: "deleteFilterServiceLayer",
    };
  }),

  on(fromActions.addServiceLayer, (state, { service }) => {
    let services = state.service.map((x) => x);
    services.push(service);
    //Pare reductor que elimina capa temporal
    /*const index = state.service.findIndex(x => (x.serviceName == service.serviceName));
        let servicesTemp = state.service.splice(index, 1);
        */
    // retornar service: [...servicesTemp]
    return {
      ...state,
      service: [...services],
      action: "addServiceLayer",
    };
  })
);

export function reducerConfigMap(
  state = configLeafletState,
  action: Action
): IConfigLeafletState {
  return featureReducerConfigLeaflet(state, action);
}

export function lastAction(state = null, action) {
  return action;
}
