<div class="row col-md-12">
  <button id="button-close" type="button" (click)="cerrarModal()" class="btn btn-raised btn-warning mr-1">
    <em class="ft-x"></em>
  </button>
  <div class="col-md-12">
    <form class="form" novalidate>
      <div class="form-body">
        <h4 class="form-section">
          <em class="ft-clipboard"></em>{{titulo}}</h4>
        <div class="row">
          <div class="form-group col-md-12 mb-2">

            <app-lista-informacion-v2 
              [rows]="rows" 
              [columns]="columns"
              [settings]="configuracionLista"
              (salida)="ObjetoListaSeleccionadoPopup($event)"
              (page)="SetData($event)"
              (filter)="Filter($event)">
            </app-lista-informacion-v2>

          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-actions ml-4" [hidden]="esLectura">
    <div class="row">
      <button type="button" (click)="confirmarSeleccion()" class="btn btn-raised btn-primary mr-1"
        [disabled]="bloqueoBoton==false">
        Agregar
      </button>
    </div>
  </div>
</div>