<aside #sidebar id="notification-sidebar" class="notification-sidebar d-none d-sm-none d-md-block">
    <a class="notification-sidebar-close" (click)="onClose()">
        <em class="ft-x font-medium-3"></em>
    </a>
    <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
        <div class="row">
            <div class="col-12 mt-1">
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel [id]="category?.nombre" *ngFor="let category of categoryNotifications$ | async">
                        <ng-template ngbPanelHeader>
                            <div (click)="acc.toggle(category?.nombre)"
                                class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                <img class="float-left noti-img mr-2" [src]="category?.icono" [alt]="category?.nombre">
                                <span class="noti-wrapper">
                                    <span
                                        class="noti-title line-height-1 text-bold-400 grey">{{category?.nombre}}</span>
                                </span>
                                <p class="d-inline mr-auto float-right">
                                    <span class="notification badge badge-pill badge-primary">
                                        <app-counter-bubble
                                            [counter]="notifications$ | async | countNotificationByCategory:category?.categoria">
                                        </app-counter-bubble>
                                    </span>
                                </p>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"
                                    *ngFor="let notification of notifications$ | async | filterByCategory:category?.categoria">
                                    <div class="d-flex">
                                        <div class="d-flex"(click)="showInfoLayer(notification, category)">
                                            <img class="mt-2"
                                                [src]="renderUrlImg(category?.tipos, notification?.contenido?.eve_codigo) || category?.icono"
                                                width="20" height="20" [alt]="category?.nombre">
                                            <div class="p-2">
                                                {{buildNameNotification(category?.texto, notification?.contenido)}}
                                            </div>
                                        </div>
                                        <em *ngIf="notification?.contenido?.estado == 'false'"
                                            class="ml-auto p-2 fa fa-check notification-view mr-1" data-toggle="tooltip"
                                            data-placement="left" title="Visto"
                                            (click)="markAsViewed(notification?.idNotificacion)"></em>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</aside>

<!-- END Notification Sidebar -->
