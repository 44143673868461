import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class ServicioFiltrosService {

  header: any = {};

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) {

  }

  getServicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioPizarra"] + "/FUNDatosCache/ConsultarInformacionCache",
      {
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion

      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  descargarArchivo(url, param):  Observable<any> {
    this.seguridadGlobal.validarSesion();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Content-Types": this.seguridadGlobal.atributoSesion
    });
    return this.http.post<Blob>(url,
      param, {headers: headers, responseType: 'blob' as 'json' });
  }

}
