import { Component, Input, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicService } from './dynamic.service';
import { Mensaje } from '@mensaje';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form',
  template: `
    <form [formGroup]="form" class="form-horizontal col-md-12 row">
      <div *ngFor="let field of fields" [class]="field.ancho"
         [class.hidden]="field.hidden? field.hidden : false">
          <field-builder
          class="p-0 m-0"
          [field]="field"
          [form]="form"
          [messageRequired]="messageRequired"></field-builder>
      </div>
      <div class="form-row"></div>
    </form>
  `,
  styles: ['form{padding: 10px;} .hidden{display: none}']
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: any[] = [];
  @Input() forms: any[] = [];
  @Input() pk: String = '';
  @Input() systemCurrent: String = '';
  @Input() type: String = '';
  @Input() messageRequired: Boolean = false;
  @Output() buttonEvent = new EventEmitter();
  message: Mensaje;
  form: FormGroup;
  constructor(private service: DynamicService) {
    this.service.dynamicForm = this;
    this.message = new Mensaje('', '', '');
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    if (this.isEmpty(this.forms)) {
      this.renderizarForm(this.fields);
    } else {
      this.renderizarFormValues(this.fields, this.forms);
    }

  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.service.dynamicForm = this;
    if (this.type === 'form') {
      if (changes.forms) {
        if (changes.forms.currentValue !== changes.forms.previousValue) {
          this.renderizarFormValues(this.fields, changes.forms.currentValue);
        }
      }
    }
  }

  renderizarForm(fields) {
    const fieldsCtrls = {};
    this.fields = JSON.parse(JSON.stringify(fields));
    this.forms = JSON.parse(JSON.stringify([]));
    for (const f of this.fields) {
      if (f.type !== 'checkbox' && f.type !== 'button') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', null)
      }
    }
    this.form = new FormGroup(fieldsCtrls);
  }

  renderizarFormValues(fields, forms) {
    const fieldsCtrls = {};
    if(JSON.stringify(this.fields) !== JSON.stringify(fields)) {
      this.fields = JSON.parse(JSON.stringify(fields));
    }
    this.forms = JSON.parse(JSON.stringify(forms));
    if (forms) {
      for (const f of this.fields) {
        if (f.type !== 'checkbox' && f.type !== 'button') {
          fieldsCtrls[f.name] = new FormControl(this.forms[f.name] || '', null)
        } else if (f.type === 'checkbox') {
          const opts = {};
          for (const opt of f.options) {
            opts[opt.key] = new FormControl(null, null);
          }
          if (this.forms[f.name]) {
            fieldsCtrls[f.name] = new FormGroup(opts)
          }
        }
      }
      this.form = new FormGroup(fieldsCtrls);
    }
  }

  customEvent(nameObject, action, json) {
    // this.eventAction(nameObject, action, this.pk, json, '');
    const object = { nameObject: nameObject, action: action, pk: this.pk, json: json };
    this.buttonEvent.next(object);
  }

  eventAction(nameObject, action, pk, json, message) {

    const htmlMensaje = '<br><br><B></B>';
    const dataRequest = '{}';

    const parameters = {
      component: this,
      system: this.systemCurrent,
      perfil: '',
      nameObject: nameObject,
      action: action,
      jsonData: json,
      dataRequest: dataRequest
    }

    if (message) {
      this.message.retornarMensajeFuncion('confirmacion', '¿Desea ' + message + '?', '', htmlMensaje, this, 'takeAction', parameters);
    } else {
      this.takeAction(parameters);
    }
  }

  takeAction(parameters) {
    parameters.component.message.retornarMensajeFuncion('loading', 'Procesando tu solicitud...', '', '', null, '', null);
    let json = JSON.stringify(parameters.jsonData);
    json = json.replace(/null/g, '"undefined"');
    parameters.component.dynamicService.getServicioRealizarAccion(
      parameters.system,
      '',
      parameters.nameObject,
      json,
      parameters.dataRequest,
      parameters.action
    ).subscribe(res => {
      const dataRespuesta = res;
      if (dataRespuesta.respuesta === 'true') {
        parameters.component.message.cerrarMensajeCarga();
        parameters.component.message.retornarMensaje('success', 'Exito', '' + dataRespuesta.noticia, '');
      } else {
        parameters.component.message.cerrarMensajeCarga();
        parameters.component.message.retornarMensaje('error', 'Error, inténtelo de nuevo', dataRespuesta.noticia, '');
        console.log(dataRespuesta.error);
      }
    });
  }

  isEmpty(obj) {
    if (!obj) {
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
